import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { selectedProfileTabState } from '../../../recoil/atoms';
import ChangePassword from './profile-change-password';
import ProfileInformation from './profile-information';
import ProfilePassport from './profile-passport';
// import ProfileNextOfKin from './profile.next-of-kin';

const Profile = () => {
  const [activeTab, setActiveTab] = useRecoilState(selectedProfileTabState);
  const tabs = [
    { title: 'Profile Information', component: <ProfileInformation /> },
    // { title: 'Next of Kin Details', component: <ProfileNextOfKin /> },
    { title: 'Passport', component: <ProfilePassport /> },
    { title: 'Update Password', component: <ChangePassword /> }
  ];

  const { subpath } = useParams();
  useEffect(() => {
    switch (subpath) {
      case 'change-password':
        setActiveTab(3);
        break;
      case 'passport':
        setActiveTab(2);
        break;
      case 'next-of-link':
        setActiveTab(1);
        break;

      default:
        setActiveTab(0);
        break;
    }
  }, [subpath, setActiveTab]);

  return (
    <div className='profile container'>
      <div className='profile__tabs'>
        <div className='profile__tabs__heading'>
          {tabs.map((tab, index) => (
            <div
              className={`profile__tab ${
                index === activeTab && 'profile__tab--active'
              }`}
              onClick={() => setActiveTab(index)}
              key={index}
            >
              {tab.title}
            </div>
          ))}
        </div>

        <div className='profile__tabs__body'>{tabs[activeTab].component}</div>
      </div>
    </div>
  );
};

export default Profile;
