import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { NavLink, useNavigate } from 'react-router-dom';
import { baseUrl } from '../../../../constants';
import {
  headerState,
  selectedProductState,
  priceInformationState,
  insuranceDataState,
  mafreKeyState,
  allDataState,
  passengerDetailsState,
  totalPriceState
} from '../../../../recoil/atoms';
import useLoginUser from '../../../../hooks/useLoginUser';
import { toast } from 'react-toastify';
import generateConfig from '../../../../helpers/generateConfig';

export default function ProductDetails() {
  const [headers] = useRecoilState(headerState);
  const [insuranceData, setInsuranceData] = useRecoilState(insuranceDataState);
  const [priceInfo, setPriceInfo] = useRecoilState(priceInformationState);
  const [selectedProduct] = useRecoilState(selectedProductState);
  const [mapKey, setMapKey] = useRecoilState(mafreKeyState);
  const [allData, setAllData] = useRecoilState(allDataState);
  const [passengerDetails] = useRecoilState(passengerDetailsState);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { updateMapKey } = useLoginUser();
  const [coverDetails, setCoverDetails] = useState(null);
  const [indx, setIndx] = useState(0);
  const [price, setPrice] = useState([]);
  const [totalInsured, setTotalInsured] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const nav = useNavigate();
  const getProductXml = async insuranceData => {
    try {
      const res = await fetch(`${baseUrl}/products-xml-formatted`, {
        method: 'POST',
        headers,
        body: JSON.stringify(insuranceData)
      });

      let data = await res.json();
      if (data.error || data.errors) {
        throw new Error('Missing Token');
      }
      return data;
    } catch (error) {
      console.error(error);
      setError(true);
      updateMapKey();
    }
  };

  const naira = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'NGN'
  });

  const getPrices = async insuranceData => {
    if (
      insuranceData &&
      insuranceData?.terms &&
      insuranceData?.idRegProduct &&
      insuranceData?.deliveryDate
    ) {
      // all required data is available, proceed
      const xml = await getProductXml(insuranceData);

      try {
        if (!xml || !xml.data) {
          throw new Error("Couldn't load xml");
        }
        if (
          xml &&
          xml?.raw_data?.root[0] ===
            'THE SESSION DOES Not EXIST, THE USER HAS Not BEEN AUTHENTICATED'
        ) {
          throw new Error(
            'THE SESSION DOES Not EXIST, THE USER HAS Not BEEN AUTHENTICATED'
          );
        }
        const res = await fetch(`${baseUrl}/product-quotes`, {
          method: 'POST',
          body: JSON.stringify({
            action: 'getPrices',
            xml: xml.data,
            xml_raw: xml.xml
          }),
          headers
        });

        let data = await res.json(); // contains price info
        if (data.error || data.errors) {
          throw new Error('Unable to generate price');
        }
        return { ...xml?.cover_details, ...data };
      } catch (error) {
        console.log('error: ', error);
      }
    } else {
      console.log('InsuranceData is missing some data');
      return;
    }
  };

  const handlePayNow = async data => {
    //! generate payment link for bulk sum
    // generateConfig(travelDetailForm, totalPrice, selectedProduct.dsProduct)
    //! redirect to payment link
    await fetch('https://api.flutterwave.com/v3/payments', {
      method: 'POST',
      body: data
    });
  };

  //! probably going to have to loop through insurance data for each customer
  useEffect(() => {
    const updatedAllData = allData.map(userData =>
      // update allData's insuranceData state with idRegProduct
      ({
        ...userData,
        insuranceData: {
          ...userData.insuranceData,
          idRegProduct: userData.selectedProduct.cd
        }
      })
    );

    setAllData(updatedAllData);

    const fetch = () =>
      Promise.all(
        updatedAllData.map(async data => await getPrices(data.insuranceData))
      );

    setLoading(true);

    fetch()
      .then(data => {
        if (data?.length) {
          data.map((datum, datumIndex) => {
            if (datum) {
              const {
                root: {
                  premiumBreakdown: { startPrice, numInsured }
                }
              } = datum;

              setAllData(prevData =>
                prevData.map((data, indx) => {
                  if (indx === datumIndex) {
                    return { ...data, price: startPrice };
                  }
                  return data;
                })
              );

              setPriceInfo(prevPriceInfo => [...prevPriceInfo, datum]);
              setTotalInsured(prevTotalInsured => [
                ...prevTotalInsured,
                numInsured
              ]);

              if (startPrice && !isNaN(startPrice)) {
                setPrice(prevPrice => [...prevPrice, startPrice]);
                setTotalPrice(
                  prevTotalPrice => prevTotalPrice + Number(startPrice)
                );
              }
            }
          });

          setLoading(false);
          setError(false);
        }
      })
      .catch(err => {
        console.log('error from getPrices: ', err);
        setError(true);
        setLoading(false);
      });
  }, []);

  useEffect(
    () => console.log('selectedProduct: ', selectedProduct),
    [selectedProduct]
  );

  let data = '';

  if (loading) {
    data = (
      <div className='my-auto align-self-center'>
        <Spinner color='primary' animation='border' role='status' />
      </div>
    );
  }

  if (error) {
    data = (
      <>
        <p className='text-muted'>
          An error occurred while loading the products
          <br /> please try again.
        </p>
        <br />
        <button onClick={() => getPrices()}>
          <svg
            version='1.1'
            id='Capa_1'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 489.533 489.533'
          >
            <path
              d='M268.175,488.161c98.2-11,176.9-89.5,188.1-187.7c14.7-128.4-85.1-237.7-210.2-239.1v-57.6c0-3.2-4-4.9-6.7-2.9
                            l-118.6,87.1c-2,1.5-2,4.4,0,5.9l118.6,87.1c2.7,2,6.7,0.2,6.7-2.9v-57.5c87.9,1.4,158.3,76.2,152.3,165.6
                            c-5.1,76.9-67.8,139.3-144.7,144.2c-81.5,5.2-150.8-53-163.2-130c-2.3-14.3-14.8-24.7-29.2-24.7c-17.9,0-31.9,15.9-29.1,33.6
                            C49.575,418.961,150.875,501.261,268.175,488.161z'
            />
          </svg>
          Reload
        </button>
      </>
    );
  }

  if (!loading && !error) {
    data = (
      <div className='table-responsive'>
        <table className='table coverage_details'>
          <tbody>
            <tr>
              <td colSpan='2' className='table-heading'>
                PRODUCT DETAILS
              </td>
            </tr>
            <tr>
              <td>Maximum number of risks:</td>
              <td>${allData[indx]?.selectedProduct?.numMaxRiesgos}</td>
            </tr>
            <tr>
              <td>Maximum days before issuing (useful with claims):</td>
              <td>{allData[indx]?.selectedProduct?.maxDiasAntes}</td>
            </tr>
            <tr>
              <td>Maximum days after issuing (useful with claims):</td>
              <td>{allData[indx]?.selectedProduct?.maxDiasDespues}</td>
            </tr>
            <tr>
              <td>Insurer’s minimum age:</td>
              <td>{allData[indx]?.selectedProduct?.edadMinimaAsegurado}</td>
            </tr>
            <tr>
              <td>Insurer’s Maximum age:</td>
              <td>{allData[indx]?.selectedProduct?.edadMaximaAsegurado}</td>
            </tr>
            <tr>
              <td>Policy-holder’s minimum age:</td>
              <td>{allData[indx]?.selectedProduct?.edadMinimaTomador}</td>
            </tr>
            <tr>
              <td colSpan='2' className='table-heading pt-5'>
                COVERAGE DETAILS
              </td>
            </tr>
            <tr>
              <td className='table-heading-condense'>BENEFITS</td>
              <td className='table-heading-condense'>Limits</td>
            </tr>

            {coverDetails &&
              Object.keys(coverDetails).map(detail => (
                <>
                  <tr>
                    <td colSpan='2' className='table-heading'>
                      {detail}
                    </td>
                  </tr>
                  {coverDetails[detail].map(cover => (
                    <tr>
                      <td>{cover.name}</td>
                      <td>{cover.value}</td>
                    </tr>
                  ))}
                </>
              ))}
          </tbody>
        </table>
        <div className='d-flex flex-column justify-content-between align-items-start gap-4 p-6'>
          <p className='' style={{ fontSize: '1.5rem' }}>
            Number Insured: <em>{totalInsured[indx]}</em>
          </p>
          <p className='' style={{ fontSize: '1.5rem' }}>
            Fee: <em>{naira.format(price[indx])}</em>
          </p>

          <p className='' style={{ fontSize: '2rem' }}>{`Total Price for all ${
            allData.length
          } customer ${allData.length > 1 ? 's' : ''}: ${naira.format(
            Number.parseFloat(totalPrice).toFixed(2)
          )}`}</p>
        </div>

        {indx > 0 && (
          <button
            className='button-secondary'
            onClick={() => setIndx(ind => ind - 1)}
          >
            View previous product details
          </button>
        )}

        {indx < allData.length - 1 && (
          <button
            className='button-primary'
            onClick={() => setIndx(ind => ind + 1)}
          >
            View next product details
          </button>
        )}
      </div>
    );
  }

  return (
    <div className='product_details d-flex flex-column gap-6 p-4'>
      <h1 className=''>
        {`Product Details and Price for passenger: ${allData[indx]?.passengerDetails?.firstName} ${allData[indx]?.passengerDetails?.lastName}`}
      </h1>

      <div className='product_details__main'>
        <h2 className='product_details__main__name'>{`${allData[indx]?.selectedProduct?.dsProduct} PLAN`}</h2>
        <em>{allData[indx]?.selectedProduct?.ds}</em>
      </div>

      <div
        className='d-flex flex-column justify-content-between align-items-center'
        style={{ minHeight: '80dvh' }}
      >
        {data}

        {!parseFloat(price[indx]) && !loading && (
          <div className='alert  alert-warning my-4 p-4'>
            <p style={{ fontSize: '1.4rem' }} className='m-0'>
              This product is not available at moment. <br />
              Please try other products or try again later.
            </p>
          </div>
        )}

        <div className='w-100 d-flex gap-6 justify-content-between p-6'>
          <button
            className='button-secondary'
            onClick={() => {
              nav(-1);
              if (allData?.length) {
                toast.info(
                  'Check list at bottom of page to edit or delete previous data.'
                );
              }
            }}
          >
            Back
          </button>

          <button className='button-primary' onClick={() => setShowModal(true)}>
            See payment options
          </button>
        </div>

        <br />
      </div>

      <Modal show={showModal}>
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>Pay now or send payment link to Policy Holder.</Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => nav('../payment')}>
            Send Payment Link
          </Button>
          <Button variant='secondary' onClick={handlePayNow}>
            Pay now
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
