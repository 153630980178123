import React from 'react';
import { useRecoilState } from 'recoil';
import { Spinner } from 'react-bootstrap';
import {
  selectedProductState,
  travelDetailsState
} from '../../../../../recoil/atoms';
import useProducts from '../../../../../hooks/useProducts';
import { getProductDescription } from '../../../../../constants';

const Plan = ({ setTravelPlanSelected, insurancePackage }) => {
  const { products, loading, error, getProducts } = useProducts();
  const [selectedProduct, setSelectedProduct] =
    useRecoilState(selectedProductState);
  const [travelDetails] = useRecoilState(travelDetailsState);

  const isProductSelected = Object.keys(selectedProduct).length;

  let product_ = <h4 className='text-muted'>No products available</h4>;

  if (loading) {
    product_ = <Spinner color='primary' animation='border' role='status' />;
  }

  if (error) {
    product_ = (
      <>
        <p className='text-muted'>
          An error occurred while loading the products. Please try again.
        </p>
        <button onClick={getProducts} className='select-button'>
          <svg
            version='1.1'
            id='Capa_1'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 489.533 489.533'
          >
            <path
              d='M268.175,488.161c98.2-11,176.9-89.5,188.1-187.7c14.7-128.4-85.1-237.7-210.2-239.1v-57.6c0-3.2-4-4.9-6.7-2.9
                                   l-118.6,87.1c-2,1.5-2,4.4,0,5.9l118.6,87.1c2.7,2,6.7,0.2,6.7-2.9v-57.5c87.9,1.4,158.3,76.2,152.3,165.6
                                   c-5.1,76.9-67.8,139.3-144.7,144.2c-81.5,5.2-150.8-53-163.2-130c-2.3-14.3-14.8-24.7-29.2-24.7c-17.9,0-31.9,15.9-29.1,33.6
                                   C49.575,418.961,150.875,501.261,268.175,488.161z'
            />
          </svg>
          Reload
        </button>
      </>
    );
  }

  if (products?.length) {
    product_ = products
      .filter(product => {
        const worldwidePackages = ['family', 'economy', 'pearl', 'traveller'];
        const productName = product.ds.split(' ')[0].toLowerCase();
        if (insurancePackage.toLowerCase() === 'worldwide') {
          return worldwidePackages.includes(productName);
        }
        return new RegExp(insurancePackage.toLowerCase()).test(productName);
      }) //only show plans for the selected package
      .map((product, i) => {
        const productDesc = getProductDescription(product);
        return (
          <div key={i} className='col-sm-6 pl-0'>
            <div className='card my-3'>
              <div className='card-header lead'>
                <div className='card-title px-3'>
                  {productDesc?.title} (
                  {`${productDesc?.minDays} - ${productDesc?.maxDays} Days`})
                </div>
              </div>
              <div className='card-body px-3 lead'>
                {productDesc?.description}
              </div>
              <div className='card-footer'>
                {!(
                  productDesc?.minDays < travelDetails?.duration &&
                  travelDetails?.duration > productDesc?.maxDays
                ) ? (
                  <button
                    disabled={
                      productDesc?.minDays < travelDetails?.duration &&
                      travelDetails?.duration > productDesc?.maxDays
                    }
                    className='button-primary select-button'
                    onClick={() => {
                      setSelectedProduct(product);
                      setTravelPlanSelected(true);
                    }}
                  >
                    Select plan
                  </button>
                ) : (
                  <div className='alert alert-warning p-2'>
                    <p style={{ fontSize: '1.4rem' }} className='m-0'>
                      Travel Details does not qualify for this Plan
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      });
  }

  return (
    <div className='row p-4'>
      {isProductSelected ? (
        <>
          <p className='col-sm-12 px-0 select-text'>
            Selected product: <em>{selectedProduct.ds}</em>
          </p>
          <p
            className='plan-link'
            onClick={() => {
              setSelectedProduct({});
              setTravelPlanSelected(false);
            }}
          >
            Select another plan
          </p>
        </>
      ) : (
        <>
          <div className='pt-3 mx-1'>
            <h2 className=''>Select Insurance Plan</h2>
          </div>
          <div className='row py-3 mx-1'>{product_}</div>
        </>
      )}
    </div>
  );
};

export default Plan;
