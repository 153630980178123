const nextOfKinSchema = {
  name: {
    required: true,
    maxLength: 50,
    label: 'Name'
  },

  email: {
    required: true,
    inputType: 'email',
    label: 'Email'
  },
  phoneNumber: {
    required: true,
    maxLength: 15,
    minLength: 9,
    maxWords: 1,
    label: 'Phone Number'
  },
  address: {
    required: true,
    maxLength: 45,
    label: 'Address'
  },
  relationship: { required: true, label: 'Relationship' },
  marketer: { label: 'Marketer' }
};

const guardianSchema = {
  guardianFirstName: {
    required: true,
    maxLength: 50,
    label: 'Name'
  },
  guardianLastName: {
    required: true,
    maxLength: 50,
    label: 'Name'
  },
  guardianOtherName: {
    required: true,
    maxLength: 50,
    label: 'Name'
  },

  guardianEmail: {
    required: true,
    inputType: 'email',
    label: 'Email'
  },
  guardianPhoneNumber: {
    required: true,
    maxLength: 15,
    minLength: 9,
    maxWords: 1,
    label: 'Phone Number'
  },
  guardianAddress: {
    required: true,
    maxLength: 45,
    label: 'Address'
  },
  guardianDateOfBirth: {
    required: true,
    olderThan: 17,
    label: 'Guardian Date of Birth'
  },
  guardianRelationship: { required: true, label: 'Relationship' },
  guardianPassportNumber: {
    required: true,
    minLength: 5,
    label: 'Passport Number'
  },
  gender: { required: true, label: 'Gender' },
  title: { required: false, label: 'Title' }
};

export { nextOfKinSchema, guardianSchema };
