import moment from 'moment';
import React, { useMemo, useState } from 'react';
import {
  Pagination,
  FormCheck,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTable, usePagination, useSortBy } from 'react-table';
import { useRecoilValue } from 'recoil';
import { permissionuserDataState } from '../../../recoil/atoms';
import RolesActiveProfile from './roles.active-profile';
import RolesDeleteProfile from './roles.delete-profile';
import RolesEditProfile from './roles.edit-profile';

export default function RolesTable({
  deleteLoading,
  data,
  updateRole,
  removeRole,
  activateRole,
  tasks
}) {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: d => d.name // accessor is the "key" in the data
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Date Created',
        accessor: b => moment(b.created_at).format('DD/MM/YYYY')
        // orderByFn: (a, b) => Date(a.created_at).getTime() - Date(b.created_at).getTime()
      }
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = tableInstance;

  const pages = useMemo(() => {
    const items = [];
    items.push(
      <Pagination.Item
        disabled={!canPreviousPage}
        key='-1'
        onClick={previousPage}
      >
        Previous
      </Pagination.Item>
    );

    if (pageCount)
      items.push(
        <Pagination.Item
          active={0 === pageIndex}
          disabled={!canPreviousPage}
          key='0'
          onClick={() => gotoPage(0)}
        >
          1
        </Pagination.Item>
      );

    if (pageIndex - 1 > 1) items.push(<Pagination.Ellipsis />);

    for (let num = pageIndex - 1; num < pageIndex + 2; num++) {
      if (num >= 1 && num < pageCount - 1)
        items.push(
          <Pagination.Item
            key={num}
            active={num === pageIndex}
            onClick={() => gotoPage(num)}
          >
            {num + 1}
          </Pagination.Item>
        );
    }

    if (pageIndex + 3 < pageCount) items.push(<Pagination.Ellipsis />);

    if (pageCount > 1)
      items.push(
        <Pagination.Item
          active={pageCount - 1 === pageIndex}
          key={pageCount - 1}
          onClick={() => gotoPage(pageCount - 1)}
        >
          {pageCount}
        </Pagination.Item>
      );

    items.push(
      <Pagination.Item disabled={!canNextPage} key='+1' onClick={nextPage}>
        Next
      </Pagination.Item>
    );
    return items;
  }, [
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    pageIndex
  ]);
  const [editRole, setEditRole] = useState(null);
  const [deleteRole, setDeleteRole] = useState(null);
  const [activeRole, setActiveRole] = useState(null);
  const permissions = useRecoilValue(permissionuserDataState);

  return (
    <div className='dashboard-users__table table-responsive'>
      <table className='table table-bordered' {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))
                }
                <th>Status</th>
                <th>Actions</th>
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            page.map(row => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()} key={row.original.id}>
                  {
                    // Loop over the rows cells
                    row.cells.map(cell => {
                      // Apply the cell props
                      let itemClassName =
                        cell.column.Header === 'Status'
                          ? cell.value
                            ? 'table-row-success'
                            : 'table-row-failed'
                          : '';
                      return (
                        <td
                          key={row.original.id + '-' + cell.column.Header}
                          className={itemClassName}
                          {...cell.getCellProps()}
                        >
                          {cell.column.Header === 'Status' && !cell.value
                            ? 'Incomplete'
                            : cell.render('Cell')}
                        </td>
                      );
                    })
                  }

                  <td>
                    {permissions['toggle_role'] ? (
                      <FormCheck
                        id={`activate-role-${row.original.id}`}
                        type='switch'
                        checked={row.original.enabled}
                        onChange={() => setActiveRole(row.original)}
                        label={row.original.enabled ? 'Active' : 'Suspended'}
                      />
                    ) : row.original.enabled ? (
                      'Active'
                    ) : (
                      'Suspended'
                    )}
                  </td>

                  <td>
                    {permissions['get_role_with_tasks'] && (
                      <NavLink
                        to={`/dashboard/role-details/${row.original.slug}`}
                      >
                        <OverlayTrigger
                          placement='top'
                          overlay={props => (
                            <Tooltip id='' {...props}>
                              <span style={{ fontSize: '1.3rem' }}>
                                View Role
                              </span>
                            </Tooltip>
                          )}
                        >
                          <button
                            data-toggle='tooltip'
                            data-tooltip='View'
                            onClick={() => null}
                          >
                            <svg
                              version='1.1'
                              xmlns='http://www.w3.org/2000/svg'
                              x='0px'
                              y='0px'
                              viewBox='0 0 512.008 512.008'
                              width='20'
                              height='20'
                            >
                              <g>
                                <g>
                                  <path d='M510.112,249.924c-4.032-5.845-100.928-143.253-254.101-143.253c-131.435,0-248.555,136.619-253.483,142.443c-3.371,3.968-3.371,9.792,0,13.781c4.928,5.824,122.048,142.443,253.483,142.443s248.555-136.619,253.483-142.443C512.587,259.204,512.864,253.892,510.112,249.924z M256.011,341.337c-47.061,0-85.333-38.272-85.333-85.333s38.272-85.333,85.333-85.333s85.333,38.272,85.333,85.333S303.072,341.337,256.011,341.337z' />
                                </g>
                              </g>
                            </svg>
                          </button>
                        </OverlayTrigger>
                      </NavLink>
                    )}
                    {permissions['update_role'] && (
                      <OverlayTrigger
                        placement='top'
                        overlay={props => (
                          <Tooltip id='' {...props}>
                            <span style={{ fontSize: '1.3rem' }}>
                              Edit Role
                            </span>
                          </Tooltip>
                        )}
                      >
                        <button
                          data-toggle='tooltip'
                          data-tooltip='Edit'
                          onClick={() => setEditRole(row.original)}
                        >
                          <svg
                            version='1.1'
                            xmlns='http://www.w3.org/2000/svg'
                            x='0px'
                            y='0px'
                            viewBox='0 0 300 300'
                            width='20'
                          >
                            <g>
                              <g>
                                <path d='M149.996,0C67.157,0,0.001,67.161,0.001,149.997S67.157,300,149.996,300s150.003-67.163,150.003-150.003S232.835,0,149.996,0z M221.302,107.945l-14.247,14.247l-29.001-28.999l-11.002,11.002l29.001,29.001l-71.132,71.126l-28.999-28.996L84.92,186.328l28.999,28.999l-7.088,7.088l-0.135-0.135c-0.786,1.294-2.064,2.238-3.582,2.575l-27.043,6.03c-0.405,0.091-0.817,0.135-1.224,0.135c-1.476,0-2.91-0.581-3.973-1.647c-1.364-1.359-1.932-3.322-1.512-5.203l6.027-27.035c0.34-1.517,1.286-2.798,2.578-3.582l-0.137-0.137L192.3,78.941c1.678-1.675,4.404-1.675,6.082,0.005l22.922,22.917C222.982,103.541,222.982,106.267,221.302,107.945z' />
                              </g>
                            </g>
                          </svg>
                        </button>
                      </OverlayTrigger>
                    )}
                    {permissions['delete_role'] && (
                      <OverlayTrigger
                        placement='top'
                        overlay={props => (
                          <Tooltip id='' {...props}>
                            <span style={{ fontSize: '1.3rem' }}>
                              View Certificate
                            </span>
                          </Tooltip>
                        )}
                      >
                        <button
                          data-toggle='tooltip'
                          data-tooltip='Delete'
                          onClick={() => setDeleteRole(row.original)}
                        >
                          <svg
                            height='512'
                            viewBox='0 0 512 512'
                            width='512'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <g>
                              <path d='m442.154 145c10.585 0 17.924-10.701 13.955-20.514-14.093-34.841-48.275-59.486-88.109-59.486h-18.414c-6.867-36.273-38.67-65-77.586-65h-32c-38.891 0-70.715 28.708-77.586 65h-18.414c-39.834 0-74.016 24.645-88.109 59.486-3.969 9.813 3.37 20.514 13.955 20.514zm-202.154-115h32c21.9 0 40.49 14.734 46.748 35h-125.496c6.258-20.266 24.848-35 46.748-35z' />
                              <path d='m111.053 470.196c1.669 23.442 21.386 41.804 44.886 41.804h200.121c23.5 0 43.217-18.362 44.886-41.804l21.023-295.196h-331.938zm185.966-214.945c.414-8.274 7.469-14.655 15.73-14.232 8.274.414 14.646 7.457 14.232 15.73l-8 160c-.401 8.019-7.029 14.251-14.969 14.251-8.637 0-15.42-7.223-14.994-15.749zm-97.768-14.232c8.263-.415 15.317 5.959 15.73 14.232l8 160c.426 8.53-6.362 15.749-14.994 15.749-7.94 0-14.568-6.232-14.969-14.251l-8-160c-.413-8.273 5.959-15.316 14.233-15.73z' />
                            </g>
                          </svg>
                        </button>
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>

      <RolesEditProfile
        role={editRole}
        addPermission={permissions['assign_role_permissions']}
        hide={() => setEditRole(null)}
        save={updateRole}
        tasks={tasks}
      />

      <RolesDeleteProfile
        role={deleteRole}
        hide={() => setDeleteRole(null)}
        deleteRole={removeRole}
        loading={deleteLoading}
      />

      <RolesActiveProfile
        role={activeRole}
        hide={() => setActiveRole(null)}
        activateRole={activateRole}
        loading={deleteLoading}
      />

      <div className='mt-2 mb-5'>
        <Pagination>{pages}</Pagination>
      </div>
    </div>
  );
}
