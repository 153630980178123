import { useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  nextOfKinDetailsState,
  policyHolderState,
  travelDetailsState
} from '../../../../../recoil/atoms';
import { getAge } from '../../../../../utilities';

export default function usePassenger() {
  const [policyHolder] = useRecoilState(policyHolderState);
  const [travelDetails] = useRecoilState(travelDetailsState);
  const [nextOfKinDetails] = useRecoilState(nextOfKinDetailsState);
  const [isMinor] = useState(false);

  const passenger = {
    name: policyHolder?.name,
    first_name: policyHolder?.firstName,
    last_name: policyHolder?.lastName,
    other_names: policyHolder?.otherNames,
    gender: policyHolder?.gender || 'N/A',
    email: policyHolder?.email,
    phone: policyHolder?.phoneNumber,
    passport_no: policyHolder?.passportNumber,
    destination: travelDetails.destination,
    title: policyHolder?.title || '',
    age: getAge(policyHolder?.dateOfBirth),
    date_of_birth: policyHolder?.dateOfBirth,
    medical_cond: 'N/A',
    address: policyHolder?.address,
    nok_name: nextOfKinDetails?.name,
    nok_relationship: nextOfKinDetails?.relationship,
    nok_phone: nextOfKinDetails?.phoneNumber,
    nok_email: nextOfKinDetails?.email,
    nok_address: nextOfKinDetails?.address,
    is_holder: nextOfKinDetails?.isPolicyHolder && !isMinor,
    no_of_days: travelDetails.duration,
    purpose: travelDetails.purpose,
    from_date: travelDetails.startDate,
    to_date: travelDetails.endDate
  };

  return passenger;
}
