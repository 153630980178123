import React, { useState, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { createPortal } from 'react-dom';

import {
  TravelDetails,
  PassengerDetails,
  GuardianDetails,
  PassportUpload
} from './components';
import {
  passengerDetailsState,
  userDataState,
  passportUrlState,
  headerState,
  travelDetailFormState,
  uploadState,
  fileState,
  formSubmittedState,
  travelDetailsState,
  profileDataState,
  insuranceDataState,
  nextOfKinDetailsState,
  guardianDetailsState,
  guardianHeightState,
  isMinorState,
  finalSubmitState,
  clearFieldsState,
  displayPopUpFormState,
  formIndexState
} from '../../../recoil/atoms';
import { baseUrl } from '../../../constants';
import { toast } from 'react-toastify';
import getPassenger from './helpers/getPassenger';
import { getAge } from '../../../utilities';
import getGuardian from './helpers/getGuardian';
import Referral from './components/Referral';
import formateMafreDate from '../../../helpers/formateMafreDate';
import EditModal from './components/EditModal';
import Back from '../../../components/commons/Back';
import Plan from '../Group/TravelDetails/components/Plan';

const AllInOneForm = ({
  submit,
  setFieldsSuccess,
  buyingForMultiple,
  insurancePackage
}) => {
  const headingRef = useRef();

  const [passengerDetails, setPassengerDetails] = useRecoilState(
    passengerDetailsState
  );
  const [user] = useRecoilState(userDataState);
  const [file, setFile] = useRecoilState(fileState);
  const [formSubmitted, setFormSubmitted] = useRecoilState(formSubmittedState);
  const [, setPassport] = useRecoilState(passportUrlState);
  const [headers] = useRecoilState(headerState);
  const [travelDetailForm] = useRecoilState(travelDetailFormState);
  const [, setUploadState] = useRecoilState(uploadState);
  const [travelDetails] = useRecoilState(travelDetailsState);
  const [profileData, setProfileData] = useRecoilState(profileDataState);
  const [insuranceData, setInsuranceData] = useRecoilState(insuranceDataState);
  const [isMinor] = useRecoilState(isMinorState);
  const [nextOfKinDetails, setNextOfKinDetails] = useRecoilState(
    nextOfKinDetailsState
  );
  const [guardianDetails] = useRecoilState(guardianDetailsState);
  const [guardianHeight, setGuardianHeight] =
    useRecoilState(guardianHeightState);
  const [finalSubmit, setFinalSubmit] = useRecoilState(finalSubmitState);
  const [clearFields, setClearFields] = useRecoilState(clearFieldsState);
  const [displayPopUpForm, setDisplayPopUpForm] = useRecoilState(
    displayPopUpFormState
  );
  const [formIndex, setFormIndex] = useRecoilState(formIndexState);

  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [allTravelDetailsInputSuccess, setAllTravelDetailsInputSuccess] =
    useState(false);
  const [allPassengerDetailsInputSuccess, setAllPassengerDetailsInputSuccess] =
    useState(false);
  const [travelPlanSelected, setTravelPlanSelected] = useState(false);

  useEffect(() => {
    headingRef.current.scrollIntoView();
  }, [buyingForMultiple]);

  let interval;
  let baseProgress;

  const savePassport = async (passportFile, userId) => {
    let status = true;
    setLoading(true);
    interval && clearInterval(interval);
    interval = setInterval(() => {
      baseProgress++;
      if (isNaN(baseProgress)) {
        baseProgress = 10;
      }
      baseProgress < 95 && setProgress(baseProgress);
    }, 50);
    const formData = new FormData();

    formData.append('file', passportFile);
    formData.append('userId', userId);

    const reqHeaders = { ...headers };
    delete reqHeaders['Content-Type'];

    try {
      const res = await fetch(`${baseUrl}/users/savePassport`, {
        method: 'POST',
        headers: reqHeaders,
        body: formData
      });

      const data = await res.json();

      if (data.error || data.errors) throw new Error('message: ', data.message);
      else setUploadState('success'); // passport has been sent to backend
    } catch (error) {
      // toast.error('An error occurred, please try again');
      setUploadState('failure');
      status = false;
      setError(true);
      setLoading(false);
    }

    clearInterval(interval);
    interval = setInterval(() => {
      baseProgress++;
      baseProgress <= 100 && setProgress(baseProgress);
      if (baseProgress >= 110) {
        // setLoading(false);
        clearInterval(interval);
      }
    }, 50);
  };

  const saveInsuranceData = (
    start_date,
    end_date,
    duration,
    destination,
    policyHolder,
    insuredData,
    insuranceData
  ) => {
    let data = {
      duration,
      destination,
      idRegProduct: insuranceData?.idRegProduct || null,
      terms: 1,
      start_date: formateMafreDate(start_date),
      end_date: formateMafreDate(end_date),
      policyHolder,
      insuredData,
      deliveryDate: start_date
    };
    setInsuranceData(data);
  };

  const saveUserAndProceed = async () => {
    console.log('saved user and proceeding...');
    const allInsurers = [];

    const passenger = getPassenger(
      passengerDetails,
      travelDetails,
      isMinor,
      nextOfKinDetails,
      getAge
    );

    allInsurers.push(passenger);

    let policyHolder = passenger;
    const guardian = getGuardian(
      guardianDetails,
      travelDetails,
      isMinor,
      nextOfKinDetails,
      getAge
    );

    if (guardianHeight.initial > 0 || isMinor) {
      if (isMinor) policyHolder = guardian;
      allInsurers.push(guardian);
    }

    const attaches = allInsurers.filter(insurer => {
      return !insurer.is_holder;
    });

    let passengerData = JSON.parse(JSON.stringify(passenger));
    passengerData['attaches'] = attaches;
    passengerData['guardian'] = guardian;

    passengerData['next_of_kin'] = {
      nok_name: nextOfKinDetails.name || '',
      nok_relationship: nextOfKinDetails.relationship || '',
      nok_phone: nextOfKinDetails.phoneNumber || '',
      nok_email: nextOfKinDetails.email || '',
      nok_address: nextOfKinDetails.address || ''
    };

    saveInsuranceData(
      travelDetails.startDate,
      travelDetails.endDate,
      travelDetails.duration,
      travelDetails.destination,
      {
        ...policyHolder,
        date_of_birth: formateMafreDate(policyHolder.date_of_birth)
      },
      allInsurers.map(i => {
        i.date_of_birth = formateMafreDate(i.date_of_birth);
        return i;
      }),
      insuranceData
    );

    // if (travelDetailForm && travelDetailForm.travel_id) {
    //   profileData.travel_id = travelDetailForm.travel_id;
    // }

    setProfileData({ ...profileData, ...passengerData });
  };

  const handleSubmit = () => {
    // ignore if no fields have been filled (well, i'm kinda just checking some important fields)
    if (
      !passengerDetails.firstName &&
      !passengerDetails.lastName &&
      !travelDetails.startDate &&
      !travelDetails.endDate &&
      !travelDetails.purpose
    ) {
      return;
    }
    // if email address, address and phone number are missing in passenger details, use user profile data

    if (user?.profile) {
      if (!passengerDetails.email) {
        setPassengerDetails({
          ...passengerDetails,
          email: user.profile?.email || ''
        });
      }
      if (!passengerDetails.phoneNumber) {
        setPassengerDetails({
          ...passengerDetails,
          phoneNumber: user.profile?.phone || ''
        });
      }
      if (!passengerDetails.address) {
        setPassengerDetails({
          ...passengerDetails,
          address: user.profile?.address || ''
        });
      }
    }

    if (!passengerDetails.name) {
      setPassengerDetails({
        ...passengerDetails,
        name: passengerDetails?.firstName
      });
    }

    saveUserAndProceed();

    // passport upload
    if (
      file &&
      Object.keys(travelDetailForm).length &&
      travelDetailForm.user_id
    ) {
      savePassport(file, travelDetailForm.user_id);
    }
    setFormSubmitted(true);
  };

  if (submit) {
    // ! bulk transaction
    handleSubmit();
  }

  const allFieldsSuccess =
    allTravelDetailsInputSuccess &&
    allPassengerDetailsInputSuccess &&
    travelPlanSelected &&
    file;

  if (allFieldsSuccess) {
    setFieldsSuccess(true);
  } else {
    setFieldsSuccess(false);
  }

  return (
    <div className=''>
      <div>
        <div className='d-flex justify-content-between align-items-center'>
          <h1 className='py-3' ref={headingRef}>
            Bulk transaction
          </h1>
          <Back />
        </div>
        <h2 className='py-3'>Fill in customer details below</h2>

        <div
          className='border rounded p-4 d-flex flex-column justify-content-between gap-5'
          style={{ boxShadow: '3px 4px 3px 2px #676767' }}
        >
          <TravelDetails
            setAllTravelDetailsInputSuccess={setAllTravelDetailsInputSuccess}
          />

          <PassengerDetails
            setAllPassengerDetailsInputSuccess={
              setAllPassengerDetailsInputSuccess
            }
          />

          <GuardianDetails />

          <PassportUpload />

          <Referral />

          <Plan
            setTravelPlanSelected={setTravelPlanSelected}
            insurancePackage={insurancePackage}
          />
        </div>
      </div>

      {displayPopUpForm &&
        createPortal(<EditModal formIndex={formIndex} />, document.body)}
    </div>
  );
};

export default AllInOneForm;
