export default function getPassenger(
  passengerDetails,
  travelDetails,
  isMinor,
  nextOfKinDetails,
  getAge
) {
  return {
    name: passengerDetails.firstName,
    first_name: passengerDetails.firstName,
    last_name: passengerDetails.lastName,
    other_names: passengerDetails.otherNames,
    gender: passengerDetails.gender || 'N/A', //TODO: add to ui
    email: passengerDetails.email,
    phone: passengerDetails.phoneNumber,
    passport_no: passengerDetails.passportNumber,
    destination: travelDetails.destination,
    title: passengerDetails.title || '',
    age: getAge(passengerDetails.dateOfBirth),
    date_of_birth: passengerDetails.dateOfBirth,
    medical_cond: 'N/A',
    address: passengerDetails.address,
    nok_name: nextOfKinDetails.name,
    nok_relationship: nextOfKinDetails.relationship,
    nok_phone: nextOfKinDetails.phoneNumber,
    nok_email: nextOfKinDetails.email,
    nok_address: nextOfKinDetails.address,
    is_holder: nextOfKinDetails.isPolicyHolder && !isMinor,
    no_of_days: travelDetails.duration,
    purpose: travelDetails.purpose,
    from_date: travelDetails.startDate,
    to_date: travelDetails.endDate
  };
}
