import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  quotesPageState,
  guardianDetailsState,
  travelDetailsState,
  nextOfKinDetailsState,
  passengerDetailsState,
  travelDetailFormState
} from '../../../recoil/atoms';
import QuotesForms from './quotes.forms';
import useLoginUser from '../../../hooks/useLoginUser';
import { useParams } from 'react-router';
import { baseUrl } from '../../../constants';
import { toast } from 'react-toastify';
import RetryLoader from '../../../components/commons/retryLoader';

const Quotes = () => {
  const [, setActivePage] = useRecoilState(quotesPageState);
  const { updateMapKey, headers } = useLoginUser();

  const resetForms = () => {
    setGuardianDetails({});
    setNextOfKinDetails({});
    setPassengerDetails({});
    setTravelDetails({});
    setTravelDetailForm({});
  };

  const [, setGuardianDetails] = useRecoilState(guardianDetailsState);
  const [, setTravelDetails] = useRecoilState(travelDetailsState);
  const [, setNextOfKinDetails] = useRecoilState(nextOfKinDetailsState);
  const [, setPassengerDetails] = useRecoilState(passengerDetailsState);
  const [, setTravelDetailForm] = useRecoilState(travelDetailFormState);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [travel, setTravel] = useState(null);

  const { subpath: user_travel } = useParams();
  const ids = (user_travel || '').split('-');
  const user_id = ids[0] || '';
  const travel_id = ids[1] || '';

  const fetchTravel = () => {
    setLoading(true);
    fetch(`${baseUrl}/users/travel/${user_id}/view/${travel_id}`, { headers })
      .then(response => response.json())
      .then(trv => {
        const travel = trv.data;
        if (travel?.user?.profile) {
          travel.user.profile.next_of_kin = JSON.parse(
            travel.user.profile.next_of_kin
          );
        }

        const payment =
          travel?.payments && travel?.payments.length
            ? travel?.payments[0]
            : {};
        setTravel(travel);
        setTravelDetailForm({
          destination: travel?.destination || '',
          purpose: travel?.purpose || '',
          startDate: travel?.from_date || '',
          endDate: travel?.to_date || '',
          product: travel?.product || '',
          travel_id: travel?.id,
          mapfre_certificate_id: payment.mapfre_certificate_id,
          payment_id: payment.id
        });

        setTravelDetails({
          destination: travel?.destination || '',
          purpose: travel?.purpose || '',
          startDate: travel?.from_date || '',
          endDate: travel?.to_date || '',
          product: travel?.product || '',
          travel_id: travel?.id,
          mapfre_certificate_id: payment.mapfre_certificate_id,
          payment_id: payment.id
        });
        setGuardianDetails({
          guardianFirstName: travel?.user?.profile?.guardian?.first_name || '',
          guardianLastName: travel?.user?.profile?.guardian?.last_name || '',
          guardianOtherName: travel?.user?.profile?.guardian?.other_names || '',
          gender: travel?.user?.profile?.guardian?.gender || '',
          guardianAddress: travel?.user?.profile?.guardian?.address || '',
          guardianPhoneNumber: travel?.user?.profile?.guardian?.phone || '',
          guardianEmail: travel?.user?.profile?.guardian?.email || '',
          guardianPassportNumber:
            travel?.user?.profile?.guardian?.passport_no || '',
          title: travel?.user?.profile?.guardian?.title || '',
          guardianDateOfBirth:
            travel?.user?.profile?.guardian?.date_of_birth || ''
        });
        setNextOfKinDetails({
          name: travel?.user?.profile?.next_of_kin?.nok_name || '',
          relationship:
            travel?.user?.profile?.next_of_kin?.nok_relationship || '',
          phoneNumber: travel?.user?.profile?.next_of_kin?.nok_phone || '',
          email: travel?.user?.profile?.next_of_kin?.nok_email || '',
          address: travel?.user?.profile?.next_of_kin?.nok_address || ''
        });
        setPassengerDetails({
          title: travel?.user?.profile?.title || '',
          firstName: travel?.user?.name || '',
          lastName: travel?.user?.profile?.last_name || '',
          email: travel?.user?.email || '',
          phoneNumber: travel?.user?.phone || '',
          otherNames: travel?.user?.profile?.other_names || '',
          dateOfBirth: travel?.user?.profile?.date_of_birth || '',
          gender: travel?.user?.profile?.gender || '',
          passportNumber: travel?.user?.profile?.passport_no || '',
          address: travel?.user?.profile?.address || ''
        });
        setError(false);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        // nav('/404', { replace: true });
        setError(true);
        setLoading(false);
        toast.error(err.message || 'Travel not found');
      });
  };

  useEffect(() => {
    updateMapKey();
    setActivePage(0);
    fetchTravel();
    return resetForms;
    // eslint-disable-next-line
  }, []);
  return (
    <RetryLoader
      retry={fetchTravel}
      loading={loading && !travel}
      error={error}
      fullscreen={false}
    >
      <section className='quotes__main'>
        <QuotesForms />
      </section>
    </RetryLoader>
  );
};

export default Quotes;
