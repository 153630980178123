import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { Modal, Button } from 'react-bootstrap';
import {
  passengerDetailsState,
  clearFieldsState,
  allDataState,
  formIndexState,
  allPassengersState,
  fileState,
  passportUrlState,
  travelDetailsState,
  insuranceDataState,
  nextOfKinDetailsState,
  guardianDetailsState,
  profileInformationState,
  policyHolderState,
  selectedProductState,
  headerState,
  priceInformationState,
  profileDataState,
  travelDetailFormState,
  productXmlDataState,
  priceDataState
} from '../../../../recoil/atoms';
import { baseUrl, flutterwaveKey, GibbsApiKey } from '../../../../constants';
import { getAge, getRandomString } from '../../../../utilities';
import { useNavigate } from 'react-router';
import useLoginUser from '../../../../hooks/useLoginUser';
import buildGibsProfileData from '../../Batch/helpers/buildGibsProfileData';
import Details from './components/Details';
import Passport from './components/Passport';
import useGetPolicyHolder from './helpers/useGetPolicyHolder';
import usePassenger from './helpers/usePassenger';
import formateMafreDate from '../../../../helpers/formateMafreDate';
import useGetProductXml from './helpers/useGetProductXml';
import getPassenger from './helpers/getPassenger';
import useGetPrices from './helpers/useGetPrices';
import useGetGuardian from './helpers/useGetGuardian';
import Sidebar from '../../../../components/commons/Sidebar';
import List from '../../../../components/commons/List';
import Back from '../../../../components/commons/Back';

const PassengerDetails = () => {
  const nav = useNavigate();
  const headingRef = useRef();
  const { updateMapKey } = useLoginUser();

  const [allPassengers, setAllPassengers] = useRecoilState(allPassengersState);
  const [allData, setAllData] = useRecoilState(allDataState);
  const [file, setFile] = useRecoilState(fileState);
  const [travelDetails] = useRecoilState(travelDetailsState);
  const [insuranceData, setInsuranceData] = useRecoilState(insuranceDataState);
  const [nextOfKinDetails] = useRecoilState(nextOfKinDetailsState);
  const [profileInformation] = useRecoilState(profileInformationState);
  const [selectedProduct] = useRecoilState(selectedProductState);
  const [headers] = useRecoilState(headerState);
  const [priceInfo, setPriceInfo] = useRecoilState(priceInformationState);
  const [profileData, setProfileData] = useRecoilState(profileDataState);
  const [travelDetailForm, setTravelDetailForm] = useRecoilState(
    travelDetailFormState
  );
  const [passengerDetails, setPassengerDetails] = useRecoilState(
    passengerDetailsState
  );
  const [productXmlData, setProductXmlData] =
    useRecoilState(productXmlDataState);
  const [priceData, setPriceData] = useRecoilState(priceDataState);

  const [allInsuredData, setAllInsuredData] = useState([]);
  const [isMinor, setIsMinor] = useState(false);
  const [guardianHeight, setGuardianHeight] = useState({});
  const [coverDetails, setCoverDetails] = useState(null);
  const [allDetailsInputSuccess, setAllDetailsInputSuccess] = useState(false);
  const [passportUploadSuccess, setPassportUploadSuccess] = useState(false);
  const [clickNavigate, setClickNavigate] = useState(false);

  const [gibbsResponse, setGibbsResponse] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [payment, setPayment] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');

  const allFieldsSuccess = allDetailsInputSuccess && passportUploadSuccess;

  // for decision modal -> asks user if they want to pay now or send payment link to customer
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => {
    console.log('opened modal');
    setShowModal(true);
  };
  const handleClose = () => {
    console.log('closed modal');
    setShowModal(false);
  };

  const passenger = usePassenger();
  const getPolicyHolder = useGetPolicyHolder();
  const guardian = useGetGuardian();

  let pxd = useGetProductXml(insuranceData);
  let gp = useGetPrices(pxd);

  useEffect(() => {
    if (pxd && Object.keys(pxd).length) {
      if (!pxd?.error) {
        setProductXmlData(pxd);
        setCoverDetails(pxd?.cover_details || {});
      }
    }

    if (gp && Object.keys(gp).length) {
      if (!gp?.isError) {
        setPriceData(gp);
        setPriceInfo(gp?.root?.premiumBreakdown?.startPrice);
      }
    }
  }, [pxd, gp]);

  useEffect(() => {
    if (clickNavigate) {
      console.log('clicked navigate');
      if (gp?.isError) {
        console.log('gp error');
        setTimeout(() => {
          // setIsLoading(false);
          setError(gp?.error);
        }, 5000);
      }
      if (priceData && Object.keys(priceData).length) {
        console.log('got priceData');
        setError('');
        handleShow();
        console.log('showModal: ', showModal);
      }
    }
  }, [priceData, clickNavigate, gp]);

  useEffect(() => {
    headingRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const handleClick = () => {
    // add current passenger details to list of all passenger details
    setAllPassengers([
      ...allPassengers,
      {
        passengerDetails,
        passport: file
      }
    ]);
    // reset state
    setPassengerDetails(passengerDetailsState);
    setFile(null);
    setAllDetailsInputSuccess(false);
    setPassportUploadSuccess(false);
  };

  const saveInsuranceData = (
    start_date,
    end_date,
    duration,
    destination,
    policyHolder,
    insuredData,
    idRegProduct
  ) => {
    let data = {
      duration,
      destination,
      idRegProduct: insuranceData?.idRegProduct || idRegProduct,
      terms: 1,
      start_date: formateMafreDate(start_date),
      end_date: formateMafreDate(end_date),
      policyHolder,
      insuredData,
      deliveryDate: start_date
    };
    setInsuranceData(data);
  };

  const saveTravelAndProceed = async (
    profileData,
    product,
    passengerDetails,
    nextOfKinDetails
  ) => {
    try {
      const res = await fetch(`${baseUrl}/users/create`, {
        method: 'POST',
        body: JSON.stringify({
          ...profileData,
          product: product.dsProduct
        }),
        headers
      });

      const data = await res.json();

      if (data.user_id && data.travel_id) {
        let travelDetailForm = {
          customer_name:
            passengerDetails.firstName + ' ' + passengerDetails.lastName,
          user_id: data.user_id,
          travel_id: data.travel_id,
          email: passengerDetails.email,
          phone: passengerDetails.phoneNumber,
          from_date: travelDetails.startDate,
          to_date: travelDetails.endDate,
          duration: travelDetails.duration,
          age: getAge(passengerDetails.dateOfBirth),
          destination: travelDetails.destination,
          marketer: nextOfKinDetails?.marketer || 'N/A',
          gibssTransactionData: buildGibsProfileData(
            profileData,
            GibbsApiKey,
            product
          )
        };

        return travelDetailForm;
      } else {
        return data.message;
      }
    } catch (error) {
      console.log('error: ', error);
      return error;
    }
  };

  const handleProceed = async () => {
    // setIsLoading(true);
    // add current passenger details to list of all passenger details
    let updatedAllPassengers = [
      ...allPassengers,
      {
        passengerDetails,
        passport: file
      }
    ];
    setAllPassengers(updatedAllPassengers);
    // reset state
    setPassengerDetails({});
    setFile(null);

    const allInsurers = [];
    // map through allPassengers
    updatedAllPassengers.map(({ passengerDetails }) => {
      allInsurers.push(
        getPassenger(passengerDetails, travelDetails, nextOfKinDetails, isMinor)
      );
    });

    console.log('allInsurers: ', allInsurers);

    const attaches = allInsurers.filter(insurer => !insurer.is_holder);

    let passengerData = JSON.parse(JSON.stringify(passenger));
    passengerData['attaches'] = attaches;
    if (guardian) passengerData['guardian'] = guardian;

    passengerData['next_of_kin'] = {
      nok_name: nextOfKinDetails?.name,
      nok_relationship: nextOfKinDetails?.relationship,
      nok_phone: nextOfKinDetails?.phoneNumber,
      nok_email: nextOfKinDetails?.email,
      nok_address: nextOfKinDetails?.address
    };

    saveInsuranceData(
      travelDetails.startDate,
      travelDetails.endDate,
      travelDetails.duration,
      travelDetails.destination,
      getPolicyHolder,
      allInsurers,
      selectedProduct.cd
    );

    let updatedProfileData = {
      ...getPolicyHolder,
      ...profileData,
      ...passengerData
    };
    console.log('updatedProfileData: ', updatedProfileData);
    setProfileData(updatedProfileData);

    // create account for policyHolder
    const data = await saveTravelAndProceed(
      updatedProfileData,
      selectedProduct,
      passengerDetails,
      null
    );
    //! TO-DO: add error-handling for data from saveTravelAndProceed
    if (data?.error) {
      toast.error('An error occured');
      return;
    }
    setProfileData({ ...profileData, travel_id: data?.travel_id });
    setTravelDetailForm(data);

    setClickNavigate(true);
  };

  const config = {
    public_key: flutterwaveKey,
    tx_ref: getRandomString(3) + '-' + Date.now(),
    amount: parseFloat(gp?.root?.premiumBreakdown?.startPrice),
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd,banktransfer',
    customer: {
      email: travelDetailForm.email,
      phonenumber: travelDetailForm.phone,
      name: travelDetailForm.customer_name
    },
    customizations: {
      title: `Insurance payment ${
        selectedProduct.dsProduct ? 'for' + selectedProduct.dsProduct : ''
      }`,
      description: `Insurance payment ${
        selectedProduct.dsProduct ? 'for' + selectedProduct.dsProduct : ''
      } on CHI Plc`,
      logo: 'https://chiplc.com/wp-content/uploads/2019/09/cropped-New-CHI-logo-1.png'
    }
  };
  // console.log("config: ", config)

  const handleFlutterPayment = useFlutterwave(config);

  const handlePayNow = () => {
    // generate payment link
    const handlePay = () => {
      // setIsLoading(true);
      handleFlutterPayment({
        callback: response => {
          closePaymentModal(); // this will close the modal programmatically
          console.log('response: ', response);
          setIsLoading(true);
          setLoadingText('Checking flutterwave response...');
          if (response.status === 'successful') {
            console.log('payment successful');
            // setPaid(true);
            setPaymentInfo(response);
            console.log('calling save payment...');
            savePayment(response);
          } else {
            toast.error('Payment unsuccessful');
            console.error(response);
            setIsLoading(false);
            setIsError(true);
            setError('Payment Unsuccessful');
          }
        },
        onClose: e => {
          console.info(e, 'closed');
          // setIsLoading(false);
        }
      });
    };
    // redirect to payment link
    handlePay();

    const savePayment = async response => {
      console.log('called savePayment!');
      setLoadingText('Saving payment...');
      const flutterwavePayment = response || paymentInfo;
      if (!flutterwavePayment) return;
      // setIsLoading(true);
      const paymentData = {
        travel_id: travelDetailForm.travel_id,
        amount: flutterwavePayment.amount,
        payment_type: 'Online',
        premium: gp?.root?.premiumBreakdown?.startPrice,
        paystack_ref: flutterwavePayment.flw_ref,
        trxref: flutterwavePayment.tx_ref,
        status: flutterwavePayment.status,
        product_name: selectedProduct.dsProduct
      };
      console.log('paymentData: ', paymentData);

      try {
        const res = await Promise.all([
          fetch(`${baseUrl}/users/travel/payment`, {
            method: 'POST',
            body: JSON.stringify(paymentData),
            headers
          }),
          updateMapKey()
        ]);

        let data = await res[0].json();
        console.log('data from /users/travel/payment: ', data);
        if (!data.error && !data.errors) {
          setPayment(data);
          console.log('caling issueCertificate...');
          issueCertificate(true, data);
        } else {
          setIsLoading(false);
          setIsError('Error from /users/travel/payment');
        }
      } catch (error) {
        console.error(error);
        setIsError(true);
        setError(error);
        setIsLoading(false);
      }
    };

    const issueCertificate = async (hasPaid = false, paymentRequest) => {
      console.log('called issueCertificate');
      setLoadingText('Issuing certificate...');
      const pmt = paymentRequest || payment;
      // setIsLoading(true);
      if (!payment && !hasPaid) {
        return savePayment(paymentInfo);
      }
      console.log('certificate: ', certificate);
      if (certificate) {
        console.log('calling storeOnGibbs');
        return storeOnGibbs(certificate, pmt);
      }

      console.log('pxd(product xml data): ', pxd);
      const xml = pxd;

      try {
        if (!xml || !xml.data) {
          throw new Error("Couldn't load xml");
        }
        const res = await fetch(`${baseUrl}/product-quotes`, {
          method: 'POST',
          body: JSON.stringify({
            action: 'issuing',
            xml: xml.data,
            xml_raw: xml.xml
          }),
          headers
        });

        let data = await res.json();
        console.log('data: ', data);

        if (data.root.idRegContrato) {
          setCertificate(data.root);
          console.log('calling storeOnGibbs...');
          storeOnGibbs(data.root, pmt);
        } else {
          console.error(data);
          setIsLoading(false);
          setIsError(true);
          setError('Error while storing on Gibbs');
        }
      } catch (error) {
        console.error(error);
        setIsError(true);
        setError(error);
      }
    };

    const storeOnGibbs = async (certificate, _payment) => {
      console.log('called storeOnGibbs');
      setLoadingText('Storing on Gibbs...');
      if (gibbsResponse) {
        return completePayment(gibbsResponse, _payment, certificate);
      }
      const data = JSON.parse(
        JSON.stringify(
          buildGibsProfileData(
            profileData, //is this updated?
            GibbsApiKey,
            selectedProduct
          )
        )
      );
      data.Map_PolicyNo = certificate?.numContrato || '';
      data.Payment_Ref = _payment?.tx_ref || 'manual';
      data.MarketerID = travelDetailForm.marketer.MarketerID || '27033277';
      data.MarketerName =
        travelDetailForm.marketer.full_name || 'BOLAJI AKINMORIN';
      data.Title = travelDetailForm.Title || 'N/A';
      data.Premium = gp?.root?.premiumBreakdown?.startPrice;

      console.log('data in storeOnGibbs: ', data);

      try {
        const res = await fetch(`${baseUrl}/complete-transaction`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers
        });

        let response = await res.json();
        console.log('response: ', response);
        if (response?.error || response?.errors) {
          console.log('error: ', error);
          setIsLoading(false);
          setIsError(true);
          setError(error);
        } else {
          console.error(response);
          setGibbsResponse(response);
          completePayment(response, _payment, certificate);
        }
      } catch (error) {
        console.error(error);
        setIsError(true);
        setError(error);
        setIsLoading(false);
      }
    };

    const completePayment = async (response, payment, certificate) => {
      console.log('called completePayment!');
      setLoadingText('Completing Payment...');
      let chiData = response?.BuyTravelInsuranceResult?.TravelID;

      const paymentData = {
        certificate_url: certificate?.file || '',
        chi_certificate_no: chiData?.CertificateNos || 'Not Available',
        chi_invoice_no: chiData?.InvoiceID || 'Not Available',
        chi_policy_number: chiData?.PolicyNumber || 'Not Available',
        chi_response_status: chiData?.StatusmSG || 'Not Available',
        id: payment?.data?.id,
        mapfre_certificate_id: certificate?.idRegContrato || '',
        mapfre_policy_number: certificate?.numContrato || ''
      };
      console.log('paymentData: ', paymentData);

      try {
        const res = await fetch(`${baseUrl}/users/travel/payments`, {
          method: 'POST',
          body: JSON.stringify(paymentData),
          headers
        });
        let dt = await res.json();
        console.log('response from /users/travel/payments: ', dt);
        setLoadingText('Navigating to success page');
        nav(
          `/quotes/success/${travelDetailForm.travel_id}/${payment?.data?.id}`
        );
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        setIsError(true);
        setError(error);
      }
    };
  };

  if (isLoading) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100dvh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '2rem'
        }}
      >
        <Spinner color='primary' animation='border' role='status' />
        {loadingText && <h1 style={{ fontSize: '2rem' }}>{loadingText}</h1>}
      </div>
    );
  }

  if (isError) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100dvh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <h1>Oops. An error occured.</h1>
        {error && <p>{error}</p>}
      </div>
    );
  }

  return (
    <div className='w-100' ref={headingRef}>
      <div className='d-flex justify-content-between align-items-center'>
        <h1 className='p-4' style={{ fontWeight: '600', fontSize: '3rem' }}>
          Passenger Details
        </h1>
        <Back />
      </div>
      <div className='passenger-details__container'>
        <Details setAllDetailsInputSuccess={setAllDetailsInputSuccess} />

        <Passport setPassportUploadSuccess={setPassportUploadSuccess} />

        <div className='d-flex gap-3 justify-content-between py-3'>
          {/* add more passenger details */}
          <button
            className={
              allFieldsSuccess ? 'button-secondary active' : 'inactive'
            }
            onClick={handleClick}
            disabled={!allFieldsSuccess}
          >
            Add another passenger's details
          </button>

          <button
            className={allFieldsSuccess ? 'button-primary active' : 'inactive'}
            onClick={handleProceed}
            disabled={!allFieldsSuccess}
          >
            Proceed
          </button>
        </div>

        {/* <Modal show={showModal} onHide={handleClose}> */}
        <Modal show={showModal}>
          <Modal.Header closeButton>
            <Modal.Title>Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Pay now or send payment link to Policy Holder.
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={() => nav('../payment')}>
              Send Payment Link
            </Button>
            <Button variant='secondary' onClick={handlePayNow}>
              Pay now
            </Button>
          </Modal.Footer>
        </Modal>

        {!!allPassengers.length && <List />}
      </div>
    </div>
  );
};

export default PassengerDetails;
