import React from 'react';

export default function UserDetailPassport({ user }) {


    return (
        <div className='profile__information'>
            <h3 className='profile__information__title mt-4 mb-4'>User Passport</h3>

            <div>
                <div className="col-sm-6">
                    <img alt="passport" src={user?.profile?.passport_image || "https://blog.nscsports.org/wp-content/uploads/2014/10/default-img.gif"} className="img-fluid img-thumbnail" />
                </div>
            </div>

        </div>
    )
}
