import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { baseUrl } from '../../../constants';
import { tasksState, tasksStatusState } from '../../../recoil/atoms';
import localforage from 'localforage';

const useTasks = () => {
  const [status, setStatus] = useState(tasksStatusState);
  const [tasks, setTasks] = useRecoilState(tasksState);
  const { loading, error } = status;

  useEffect(() => {
    getTasks();
    // eslint-disable-next-line
  }, []);

  const getTasks = async () => {
    setStatus({ ...status, loading: true });
    let cacheData = await localforage.getItem('all-tasks');

    if (cacheData) {
      setTasks(cacheData);
      setStatus({ error: false, loading: false });
    }

    fetch(`${baseUrl}/tasks`)
      .then(tasks => tasks.json())
      .then(tasks => {
        if (tasks.error || tasks.errors) throw new Error(tasks.message);
        localforage.setItem('all-tasks', tasks.data);
        setTasks(tasks.data);
        setStatus({ error: false, loading: false });
      })
      .catch(err => {
        console.error(err);
        setStatus({ error: true, loading: false });
      });
  };

  return { tasks, loading, getTasks, error };
};

export default useTasks;
