import React from 'react';
// import { Link } from 'react-router-dom';
import twitter from '../../assets/svg/twitter.svg';
import linkedIn from '../../assets/svg/linkedin.svg';

const Footer = () => {
  const getInTouch = e => {
    e.preventDefault();
    e.stopPropagation();
    if (window.Tawk_API && window.Tawk_API.maximize) {
      window.Tawk_API.maximize();
    }
  };

  return (
    <footer className='footer'>
      <div className='footer__top'>
        <ul className='footer__top__list'>
          <li className='footer__top__item'>Company</li>
          <li className='footer__top__item'>
            <span>About us</span>
          </li>
          <li className='footer__top__item'>
            <a href='/' onClick={getInTouch}>
              Get in touch
            </a>
          </li>
        </ul>

        <ul className='footer__top__list'>
          <li className='footer__top__item'>Legal</li>
          <li className='footer__top__item'>
            <span>Privacy Policy</span>
          </li>
          <li className='footer__top__item'>
            <span>Terms and Conditions</span>
          </li>
        </ul>
      </div>
      <div className='footer__bottom'>
        <span>
          &copy; {new Date().getFullYear()} CHI Plc. All Rights Reserved
        </span>

        <div className='footer__bottom__socials'>
          <span>
            <img src={twitter} alt='twitter' />
          </span>
          <span>
            <img src={linkedIn} alt='linkedIn' />
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
