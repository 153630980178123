import React from 'react';
// import { useRecoilState } from 'recoil';
// import {
//   buyInsuranceScreenState,
//   selectedPlanState
// } from '../../../../recoil/atoms';
import CustomDropdown from '../../../../components/custom-dropdown';
import { packages } from '../../../../constants';
// import { toCurrency } from '../../../../utilities';

import InsurancePackage from './package';

const InsurancePlan = ({ heading }) => {
  return (
    <section className=''>
      <h1 className='home__plans__heading mt-5'>{heading}</h1>
      {false && (
        <div className='home__plans__search'>
          <input type='text' placeholder='Search plan' />
          <CustomDropdown
            name='category'
            onSelect={selection => {}}
            identifier='category'
            data={[
              { category: 'Fashion' },
              { category: 'Gadgets' },
              { category: 'Education' },
              { category: 'Decoration' }
            ]}
            inputId={`category`}
            placeholder='Package'
            disable={false}
            showItems={true}
            value={{ category: null }}
          />
        </div>
      )}
      <div className='home__plans__table'>
        <h3 className='home__plans__table__heading home__plans__table__heading--1'>
          Packages <small>({packages.length} packages)</small>
        </h3>
        <h3 className='home__plans__table__heading home__plans__table__heading--2 hide-mobile'>
          Plans
        </h3>
        {packages.map(insurancePackage => (
          <InsurancePackage
            insurancePackage={insurancePackage}
            key={insurancePackage.name}
          />
        ))}
      </div>
    </section>
  );
};

export default InsurancePlan;
