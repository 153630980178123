import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../../assets/image/logo.png';
import { useRecoilState, useRecoilValue } from 'recoil';

import { currentLoginState } from '../../recoil/selectors';
import { appHeaderState } from '../../recoil/atoms';

const Header = () => {
  const authenticated = useRecoilValue(currentLoginState);
  const [menu, setMenu] = useRecoilState(appHeaderState);

  const nav = useNavigate();

  const getInTouch = e => {
    e.preventDefault();
    e.stopPropagation();
    if (window.Tawk_API && window.Tawk_API.maximize) {
      window.Tawk_API.maximize();
    }
  };

  const gotoPage = (e, str) => {
    e.stopPropagation();
    setMenu(false);
    nav(str);
  };

  return (
    <nav className='header my-3 container d-flex justify-content-between py-4'>
      <NavLink to='/'>
        <img className='header__logo' src={logo} alt='CHI' />
      </NavLink>

      <div className='header__mobile-toggle ' onClick={() => setMenu(!menu)}>
        <svg
          version='1.1'
          xmlns='http://www.w3.org/2000/svg'
          x='0px'
          y='0px'
          viewBox='0 0 512 512'
        >
          <g>
            <g>
              <path d='M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z' />
            </g>
          </g>
          <g>
            <g>
              <path d='M492,76H20C8.954,76,0,84.954,0,96s8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,76,492,76z' />
            </g>
          </g>
          <g>
            <g>
              <path d='M492,396H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20C512,404.954,503.046,396,492,396z' />
            </g>
          </g>
        </svg>
      </div>

      <ul className={`header__links ${menu ? 'header__links_show' : ''}`}>
        <li className='header__link' onClick={getInTouch}>
          <a href='/' onClick={getInTouch}>
            Get in touch
          </a>
        </li>
        {authenticated ? (
          <>
            <li
              onClick={e => gotoPage(e, '/dashboard')}
              className='header__link header__link__dashboard'
            >
              <NavLink to='/dashboard'>Dashboard</NavLink>
            </li>
          </>
        ) : (
          <>
            <li
              onClick={e => gotoPage(e, '/login')}
              className='header__link header__link__dashboard'
            >
              <NavLink to='/login'>Login</NavLink>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Header;
