import React, { useState, useCallback } from 'react';
import { Spinner, ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { baseUrl } from '../../../../constants';
import {
  headerState,
  quotesPageState,
  passportUrlState,
  travelDetailFormState
} from '../../../../recoil/atoms';
// import { firebase } from '../../../../utilities';
import upload from '../../../../assets/svg/upload.svg';

export default function PassportUpload() {
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [, setPassport] = useRecoilState(passportUrlState);
  const [headers] = useRecoilState(headerState);
  const [activePage, setActivePage] = useRecoilState(quotesPageState);
  const [travelDetailForm] = useRecoilState(travelDetailFormState);

  let interval;
  let baseProgress;

  const handleUpload = () => {
    setLoading(true);

    savePassport();

    // if (passport) {
    //   baseProgress = 0;
    //   interval = setInterval(() => {
    //     baseProgress++;
    //     baseProgress < 51 && setProgress(baseProgress + 1);
    //   }, 10);
    //   return savePassport({
    //     userId: travelDetailForm.user_id,
    //     url: passport
    //   });
    // }

    // const storage = firebase.storage();
    // const storageRef = storage.ref();
    // const uploadTask = storageRef.child('passports/' + file.name).put(file);

    // uploadTask.on(
    //   firebase.storage.TaskEvent.STATE_CHANGED,
    //   snapshot => {
    //     setProgress(
    //       Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 50
    //     );
    //   },
    //   error => {
    //     toast.error('An error occurred, please try again');
    //     setLoading(false);
    //     console.error(error);
    //   },
    //   () => {
    //     uploadTask.snapshot.ref.getDownloadURL().then(url => {
    //       baseProgress = 50;
    //       setPassport(url);
    //       savePassport({ userId: travelDetailForm.user_id, url });
    //     });
    //   }
    // );
  };

  const savePassport = async () => {
    let status = true;
    interval && clearInterval(interval);
    interval = setInterval(() => {
      baseProgress++;
      if (isNaN(baseProgress)) {
        baseProgress = 10;
      }
      baseProgress < 95 && setProgress(baseProgress);
    }, 50);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', travelDetailForm.user_id);

    const reqHeaders = { ...headers };
    delete reqHeaders['Content-Type'];

    try {
      const res = await fetch(`${baseUrl}/users/savePassport`, {
        method: 'POST',
        headers: reqHeaders,
        body: formData
      });

      const data = await res.json();
      if (data.error || data.errors) throw new Error(data.message);
    } catch (error) {
      toast.error('An error occurred, please try again');
      status = false;
      setError(true);
      setLoading(false);
    }
    clearInterval(interval);
    interval = setInterval(() => {
      baseProgress++;
      baseProgress <= 100 && setProgress(baseProgress);
      if (baseProgress >= 110) {
        setLoading(false);
        clearInterval(interval);
        if (status) {
          //redirect to pay
          setActivePage(activePage + 1);
        }
      }
    }, 50);
  };

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0] && acceptedFiles[0].size > 204800) {
      toast.error('Please select a file smaller than 200kb');
      return;
    }

    if (acceptedFiles[0] && !acceptedFiles[0].type.includes('image')) {
      toast.error('Please select an image file (jpeg, png, gif)');
      return;
    }
    setPassport(null);
    setFile(acceptedFiles[0]);
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className='quote-details container'>
      <h1 className='quote-details__heading'>
        Please upload a copy of your passport
      </h1>

      <div className='passport-upload' {...getRootProps()}>
        <div className='dropzone'>
          <input {...getInputProps()} accept='image/*' />
          {
            <div className='insurance-documents__upload'>
              <img src={upload} alt='upload' />
              <p className='mb-0'>
                Drag and drop the file here, or click to select file
              </p>
              <p className='mt-1'>
                <small>
                  <b>Note: </b>The file should smaller 200kb and must be an
                  image (jpeg,jpg,png,gif)
                </small>
              </p>
              <button className='button-rounded'>Upload</button>
            </div>
          }
        </div>
      </div>

      {file && (
        <div className='uploaded-file'>
          <img src={upload} alt='upload' width='40px' /> {file.name}
        </div>
      )}

      {loading && (
        <div className='mt-3'>
          <ProgressBar striped variant='primary' now={progress} />
        </div>
      )}

      <div className='quote-details__buttons'>
        <button
          type='button'
          className='button-rounded button-rounded--outline'
          onClick={() => activePage !== 0 && setActivePage(activePage - 1)}
        >
          Back
        </button>
        <button
          disabled={!file || loading || error}
          onClick={handleUpload}
          className='button-rounded'
        >
          {loading ? (
            <>
              <Spinner /> loading...
            </>
          ) : (
            'Proceed'
          )}
        </button>
      </div>
    </div>
  );
}
