import UserDetailsInformation from './user-details.information';
import UserDetailsPassport from './user-details.passport';
// import UserDetailsNextOfKin from './user-details.next-of-kin';
import { useMemo, useState } from 'react';
import useLoginUser from '../../../hooks/useLoginUser';
import { useEffect } from 'react';
import Travels from '../DashboardHome/travels';
import Certificates from '../DashboardHome/certificates';
import Payments from '../DashboardHome/payments';
import RetryLoader from '../../../components/commons/retryLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { baseUrl } from '../../../constants';
import { useRecoilValue } from 'recoil';
import { permissionuserDataState } from '../../../recoil/atoms';

const UserDetails = () => {
  const { subpath: user_id } = useParams();
  const nav = useNavigate();

  const { headers } = useLoginUser();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const permissions = useRecoilValue(permissionuserDataState);

  const fetchUser = () => {
    setLoading(true);
    fetch(`${baseUrl}/users/${user_id}`, { headers })
      .then(response => response.json())
      .then(user => {
        if (user.error || user.errors) throw new Error(user.message);

        if (user.data.profile?.next_of_kin) {
          try {
            user.data.profile.next_of_kin = JSON.parse(
              user.data.profile?.next_of_kin
            );
          } catch (error) {}
        }

        let certificates = user.data.travels.filter(tr => tr.mapfre_policy_no);
        let payments = user.data.travels.reduce(
          (all, tr) => [...tr.payments, ...all],
          []
        );

        certificates.forEach(tr => {
          if (tr.payments && tr.payments.reduce) {
            tr.payments = tr.payments.reduce(
              (acc, curr) =>
                curr.mapfre_policy_number === tr.mapfre_policy_no ? curr : acc,
              {}
            );
          }
        });

        user.data.certificates = certificates;
        user.data.payments = payments;

        setUser(user.data);
        setError(false);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setError(true);
        setLoading(false);
      });
  };

  const [activeTab, setActiveTab] = useState(0);
  const tabs = useMemo(
    () => [
      {
        title: 'Profile Information',
        component: <UserDetailsInformation user={user} />,
        show: true
      },
      // {
      //   title: 'Next of Kin Details',
      //   component: <UserDetailsNextOfKin user={user} />,
      //   show: true
      // },
      {
        title: 'Passport',
        component: <UserDetailsPassport user={user} />,
        show: true
      },
      {
        title: 'Travels',
        show: permissions.list_user_travels,
        component: (
          <Travels
            data={user?.travels || []}
            loading={loading}
            error={error}
            retry={fetchUser}
          />
        )
      },
      {
        title: 'Cerificates',
        show: permissions.list_user_travels,
        component: (
          <Certificates
            data={user?.certificates || []}
            loading={loading}
            error={error}
            retry={fetchUser}
          />
        )
      },
      {
        title: 'Payments',
        show: permissions.list_user_payments,
        component: (
          <Payments
            data={user?.payments || []}
            loading={loading}
            error={error}
            retry={fetchUser}
          />
        )
      }
    ],
    // eslint-disable-next-line
    [user, loading, error]
  );

  useEffect(() => {
    fetchUser();
    if (!user_id) {
      nav('/dashboard/users', { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='profile container dashboard-home'>
      <div className='profile__tabs'>
        <div className='profile__tabs__heading'>
          {tabs.map((tab, index) =>
            tab.show ? (
              <div
                className={`profile__tab ${
                  index === activeTab && 'profile__tab--active'
                }`}
                onClick={() => setActiveTab(index)}
                key={index}
              >
                {tab.title}
              </div>
            ) : (
              ''
            )
          )}
        </div>

        <div className='profile__tabs__body'>
          <RetryLoader
            retry={fetchUser}
            loading={loading}
            error={error}
            fullscreen={false}
          >
            {tabs[activeTab].component}
          </RetryLoader>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
