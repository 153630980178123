import { getRandomString } from '../utilities';

export default function generateConfig(details, price, product) {
  const url =
    (!process.env.NODE_ENV || process.env.NODE_ENV) === 'development'
      ? 'http://localhost:3000'
      : 'travel.chiplc.com';

  const name = details?.customer_name || details?.name;

  console.log('details: ', details);
  console.log('name: ', name);
  console.log('price: ', price);
  console.log('product: ', product);

  if (!details || !details.email || !name || !price || !product) {
    return { error: 'Missing necessary value(s).' };
  }

  return {
    tx_ref: getRandomString(3) + '-' + Date.now(),
    amount: parseFloat(price),
    currency: 'NGN',
    redirect_url: `${url}/certificate`,
    payment_options: 'card,mobilemoney,ussd,banktransfer',
    customer: {
      email: details.email,
      phonenumber: details.phone,
      name
    },
    customizations: {
      title: `Insurance payment ${product ? 'for ' + product : ''}`,
      description: `Insurance payment ${
        product ? 'for ' + product : ''
      } on CHI Plc`,
      logo: 'https://chiplc.com/wp-content/uploads/2019/09/cropped-New-CHI-logo-1.png'
    }
  };
}
