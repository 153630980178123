import React, { useState } from 'react';
import { useRecoilState } from 'recoil';

import {
  displayPopUpFormState,
  formIndexState,
  allDataState
} from '../../recoil/atoms';
import { toast } from 'react-toastify';

const List = ({ setBuyingForMultiple }) => {
  const [displayPopUpForm, setDisplayPopUpForm] = useRecoilState(
    displayPopUpFormState
  );
  const [formIndex, setFormIndex] = useRecoilState(formIndexState);
  const [allData, setAllData] = useRecoilState(allDataState);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleEdit = index => {
    setDisplayPopUpForm(true);
    setFormIndex(index);
  };

  const handleDelete = index => {
    const updatedAllData = allData?.filter((_, i) => i !== index);
    setAllData(updatedAllData);
    toast.info('Deleted successfully.');
    if (!updatedAllData?.length) setBuyingForMultiple(false);
  };

  return (
    <div className='list'>
      <table className='table rounded py-6'>
        <thead className='text-center' style={{ fontSize: '2rem' }}>
          <tr className='fs-1'>
            <th className='p-1 align-middle'>First Name</th>
            <th className='p-1 align-middle'>Last Name</th>
            <th className='p-1 align-middle'>Phone Number</th>
            <th className='p-1 align-middle'>Passport Number</th>
            <th className='p-1 align-middle'>Action</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '1.5rem' }}>
          {allData.map(({ passengerDetails }, index) => (
            <tr key={index} className='text-center'>
              <td className='p-3 align-middle'>
                {passengerDetails?.firstName}
              </td>
              <td className='p-3 align-middle'>{passengerDetails?.lastName}</td>
              <td className='p-3 align-middle'>
                {passengerDetails?.phoneNumber}
              </td>
              <td className='p-3 align-middle'>
                {passengerDetails?.passportNumber}
              </td>
              <td className='p-3 d-flex justify-content-between align-middle'>
                {!showConfirmation ? (
                  <>
                    <button
                      className='btn btn-success'
                      onClick={() => handleEdit(index)}
                      style={{
                        fontSize: '1.5rem'
                      }}
                    >
                      Edit
                    </button>

                    <button
                      className='btn btn-danger'
                      onClick={() => setShowConfirmation(true)}
                      style={{
                        fontSize: '1.5rem'
                      }}
                    >
                      Delete
                    </button>
                  </>
                ) : (
                  <div className='w-100 d-flex flex-column align-items-center'>
                    <em className='text-center'>Are you sure?</em>

                    <div className='w-100 d-flex align-items-center justify-content-around gap-5'>
                      <button
                        className='button-primary'
                        onClick={() => handleDelete(index)}
                      >
                        Yes
                      </button>
                      <button
                        className='button-secondary'
                        onClick={() => setShowConfirmation(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;
