import moment from 'moment';
import React, { useMemo, useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { useTable, usePagination, useSortBy } from 'react-table';
import RetryLoader from '../../../components/commons/retryLoader';
import { numberWithCommas } from '../../../utilities';
import PrintCertifcate from '../../Quotes/SuccessPage/print-certifcate';

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Certificates({ loading, error, data, retry }) {
  const columns = useMemo(
    () => [
      {
        Header: 'Amount',
        accessor: d => numberWithCommas(d?.amount)
      },
      {
        Header: 'Trans Ref',
        accessor: d => d?.trxref
      },
      {
        Header: 'Status',
        accessor: d => d?.status
      },
      {
        Header: 'Payment Type',
        accessor: d => d?.payment_type
      },
      {
        Header: 'Date Created',
        accessor: b => moment(b.created_at).format('DD/MM/YYYY hh:mmA')
      }
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = tableInstance;

  const pages = useMemo(() => {
    const items = [];
    items.push(
      <Pagination.Item
        disabled={!canPreviousPage}
        key='-1'
        onClick={previousPage}
      >
        Previous
      </Pagination.Item>
    );

    if (pageCount)
      items.push(
        <Pagination.Item
          active={0 === pageIndex}
          disabled={!canPreviousPage}
          key='0'
          onClick={() => gotoPage(0)}
        >
          1
        </Pagination.Item>
      );

    if (pageIndex - 1 > 1) items.push(<Pagination.Ellipsis />);

    for (let num = pageIndex - 1; num < pageIndex + 2; num++) {
      if (num >= 1 && num < pageCount - 1)
        items.push(
          <Pagination.Item
            key={num}
            active={num === pageIndex}
            onClick={() => gotoPage(num)}
          >
            {num + 1}
          </Pagination.Item>
        );
    }

    if (pageIndex + 3 < pageCount) items.push(<Pagination.Ellipsis />);

    if (pageCount > 1)
      items.push(
        <Pagination.Item
          active={pageCount - 1 === pageIndex}
          key={pageCount - 1}
          onClick={() => gotoPage(pageCount - 1)}
        >
          {pageCount}
        </Pagination.Item>
      );

    items.push(
      <Pagination.Item disabled={!canNextPage} key='+1' onClick={nextPage}>
        Next
      </Pagination.Item>
    );
    return items;
  }, [
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    pageIndex
  ]);

  const [print, setPrint] = useState(null);

  useEffect(() => {
    if (print) {
      setTimeout(() => {
        window.print();
        setPrint(false);
      }, 100);
    }
  }, [print]);

  return print ? (
    <PrintCertifcate data={print} watermarked={true} fullscreen={true} />
  ) : (
    <div className='dashboard-home__table table-responsive'>
      <RetryLoader
        retry={retry}
        loading={loading}
        error={error}
        fullscreen={false}
      >
        <ExcelFile
          element={
            <button className='float-right mb-4 display-6 py-2'>
              Download Excel
            </button>
          }
        >
          <ExcelSheet data={data} name='Payments'>
            <ExcelColumn label='Amount' value='amount' />
            <ExcelColumn label='Trans Ref' value='trxref' />
            <ExcelColumn label='Status' value='status' />
            <ExcelColumn label='Payment Type' value='payment_type' />
            <ExcelColumn
              label='Date Created'
              value={col => moment(col.created_at).format('DD/MM/YYYY hh:mmA')}
            />
          </ExcelSheet>
        </ExcelFile>
        <table className='table table-bordered' {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map(headerGroup => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map(column => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map(row => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map(cell => {
                        // Apply the cell props
                        let itemClassName =
                          cell.column.Header === 'Status'
                            ? cell.value
                              ? 'table-row-success'
                              : 'table-row-failed'
                            : '';
                        return (
                          <td
                            className={itemClassName}
                            {...cell.getCellProps()}
                          >
                            {cell.column.Header === 'Status' && !cell.value
                              ? 'Incomplete'
                              : cell.render('Cell')}
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>

        <div className='mt-2 mb-5'>
          <Pagination>{pages}</Pagination>
        </div>
      </RetryLoader>
    </div>
  );
}
