import React, { useMemo, useState, createRef } from 'react';
import { Pagination } from 'react-bootstrap';
import { useTable, usePagination, useSortBy } from 'react-table';
import RetryLoader from '../../../components/commons/retryLoader';
import ReactExport from 'react-export-excel';
import moment from 'moment';
import Search from '../../../components/commons/search';
import { savePDF } from '@progress/kendo-react-pdf';
import { Link } from 'react-router-dom';
import useLoginUser from '../../../hooks/useLoginUser';
import { permissionuserDataState } from '../../../recoil/atoms';
import { useRecoilValue } from 'recoil';
import useCountries from '../../../hooks/useCountries';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Travels({ loading, error, data, retry }) {
  const { user } = useLoginUser();
  const permissions = useRecoilValue(permissionuserDataState);
  const { getCountryName } = useCountries();
  const columns = useMemo(
    () => [
      {
        Header: 'Customer',
        accessor: d => d?.user?.name + ' ' + d?.user?.profile?.last_name
      },
      {
        Header: 'Phone',
        accessor: d => d?.user?.phone
      },
      {
        Header: 'Destination',
        accessor: d => getCountryName(d.destination) // accessor is the "key" in the data
      },
      {
        Header: 'Purpose',
        accessor: 'purpose'
      },
      {
        Header: 'Start Date',
        sortMethod: (a, b) => Date(a).getTime() - Date(b).getTime(),
        accessor: b => moment(b.from_date).format('DD/MM/YYYY')
      },
      {
        Header: 'End Date',
        sortMethod: (a, b) => Date(a).getTime() - Date(b).getTime(),
        accessor: b => moment(b.to_date).format('DD/MM/YYYY')
      },
      {
        Header: 'Policy No',
        accessor: d => d.mapfre_policy_no || ''
      },
      {
        Header: 'Date Created',
        sortMethod: (a, b) => Date(a).getTime() - Date(b).getTime(),
        accessor: b => moment(b.created_at).format('DD/MM/YYYY hh:mmA')
      }
    ],
    [getCountryName]
  );

  const [filteredData, setFilteredData] = useState([]);

  const tableInstance = useTable(
    {
      columns,
      data: filteredData
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = tableInstance;

  const pages = useMemo(() => {
    const items = [];
    items.push(
      <Pagination.Item
        disabled={!canPreviousPage}
        key='-1'
        onClick={previousPage}
      >
        Previous
      </Pagination.Item>
    );

    if (pageCount)
      items.push(
        <Pagination.Item
          active={0 === pageIndex}
          disabled={!canPreviousPage}
          key='-1-1-1'
          onClick={() => gotoPage(0)}
        >
          1
        </Pagination.Item>
      );

    if (pageIndex - 1 > 1) items.push(<Pagination.Ellipsis />);

    for (let num = pageIndex - 1; num < pageIndex + 2; num++) {
      if (num >= 1 && num < pageCount - 1)
        items.push(
          <Pagination.Item
            key={num}
            active={num === pageIndex}
            onClick={() => gotoPage(num)}
          >
            {num + 1}
          </Pagination.Item>
        );
    }

    if (pageIndex + 3 < pageCount) items.push(<Pagination.Ellipsis />);

    if (pageCount > 1)
      items.push(
        <Pagination.Item
          active={pageCount - 1 === pageIndex}
          key={pageCount + 2}
          onClick={() => gotoPage(pageCount - 1)}
        >
          {pageCount}
        </Pagination.Item>
      );

    items.push(
      <Pagination.Item disabled={!canNextPage} key='+1+1' onClick={nextPage}>
        Next
      </Pagination.Item>
    );
    return items;
  }, [
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    pageIndex
  ]);

  const ref = createRef();

  const exportPDF = () => {
    let element = ref.current;

    if (element !== null) {
      savePDF(element, {
        repeatHeaders: true,
        paperSize: 'A1',
        orientation: 'horizontal',
        margin: '0.5cm'
      });
    }
  };

  return (
    <div className='dashboard-home__table table-responsive'>
      <RetryLoader
        retry={retry}
        loading={loading}
        error={error}
        fullscreen={false}
      >
        <div className='d-flex justify-content-between align-items-center mb-5'>
          <Search
            className='my-0'
            data={data}
            setOutput={setFilteredData}
            fields={[
              {
                key: b =>
                  (b?.user?.name || '') +
                  ' ' +
                  (b?.user?.profile?.last_name || ''),
                name: 'name'
              },
              {
                key: b => b?.user?.phone || '',
                name: 'phone'
              },
              {
                key: b => b?.destination || '',
                name: 'destination'
              },
              {
                key: b => b?.purpose || '',
                name: 'purpose'
              },
              {
                key: b => b?.mapfre_policy_no || '',
                name: 'policy no'
              }
            ]}
          />
          <div className='d-flex'>
            <ExcelFile
              element={
                <button className='display-6 py-2'>Download Excel</button>
              }
            >
              <ExcelSheet data={filteredData} name='Travel Inquires'>
                <ExcelColumn
                  label='Other Names'
                  value={col => col?.user?.name}
                />
                <ExcelColumn
                  label='Surname'
                  value={col => col?.user?.profile?.last_name}
                />
                <ExcelColumn label='Email' value={col => col?.user?.email} />
                <ExcelColumn label='Phone' value={col => col?.user?.phone} />
                <ExcelColumn
                  label='Address'
                  value={col => col?.user?.profile?.address}
                />
                <ExcelColumn
                  label='Destination'
                  value={col => getCountryName(col.destination)}
                />
                <ExcelColumn label='Purpose' value='purpose' />
                <ExcelColumn label='Start Date' value='from_date' />
                <ExcelColumn label='End Date' value='to_date' />
                <ExcelColumn label='Policy Number' value='mapfre_policy_no' />
                <ExcelColumn
                  label='Date Created'
                  value={col =>
                    moment(col.created_at).format('DD/MM/YYYY hh:mmA')
                  }
                />
                <ExcelColumn label='Created By' value={col => col.creator} />
              </ExcelSheet>
            </ExcelFile>
            <button onClick={exportPDF} className='display-6 py-2'>
              Download pdf
            </button>
          </div>
        </div>
        <table className='table table-bordered' {...getTableProps()} ref={ref}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, i) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, j) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={j}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row, k) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()} key={row?.original?.id || k}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, l) => {
                        return (
                          <td className={''} {...cell.getCellProps()} key={l}>
                            {cell.column.Header === 'Policy No' &&
                            !cell.value ? (
                              <span>
                                <span className='text-danger'>Inquiry</span>
                                {user?.id === cell.row.original?.user?.id &&
                                  cell.row.original?.user?.id && (
                                    <Link
                                      to={`/dashboard/continue-buy-insurance/${cell.row.original?.user?.id}-${cell.row.original?.id}`}
                                    >
                                      <button>Buy</button>
                                    </Link>
                                  )}
                                {permissions.create_user_travel_payment && (
                                  <Link
                                    to={`/dashboard/continue-buy-insurance-offline/${cell.row.original?.user?.id}-${cell.row.original?.id}`}
                                  >
                                    <button>Buy as Staff</button>
                                  </Link>
                                )}
                              </span>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>

        <div className='mt-2 mb-5'>
          <Pagination>{pages}</Pagination>
        </div>
      </RetryLoader>
    </div>
  );
}
