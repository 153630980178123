export const schemas = {
  name: {
    required: true,
    maxLength: 20,
    label: 'Other Names'
  },
  description: {
    required: true,
    minLength: 5,
    label: 'Description'
  },
  tasks: {
    required: false,
    label: 'Permissions'
  }
};
