import travellers from '../../../assets/svg/travellers.svg';
import successIcon from '../../../assets/svg/success-icon.svg';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { baseUrl } from '../../../constants';
import { Spinner } from 'react-bootstrap';
import Retry from '../../../components/commons/retry';
import PrintCertifcate from './print-certifcate';

const SuccessPage = () => {
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [print, setPrint] = useState(false);

  const { travel_id, payment_id } = useParams();

  const printReciept = () => {
    setPrint(true);
  };

  useEffect(() => {
    if (print) {
      setTimeout(() => {
        window.print();
        setPrint(false);
      }, 100);
    }
  }, [print]);

  const getDetails = () => {
    setLoading(true);
    fetch(`${baseUrl}/users/travel/${travel_id}/payment/${payment_id}`)
      .then(response => response.json())
      .then(response => {
        if (response.error || response.errors)
          throw new Error(response.message);
        if (response.data.payments && response.data.payments.reduce) {
          response.data.payments = response.data.payments.reduce(
            (acc, curr) =>
              parseInt(curr.id) === parseInt(payment_id) ? curr : acc,
            {}
          );
        }
        setData(response.data);
        setError(false);
        setLoading(false);
      })
      .catch(err => {
        toast.error(err.message);
        console.error(err);
        setError(true);
        setLoading(false);
      });
  };
  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, []);
  return print ? (
    <PrintCertifcate data={data} watermarked={true} />
  ) : loading || error ? (
    <div className='app__loader'>
      {loading ? (
        <Spinner color='primary' animation='border' role='status' />
      ) : (
        <Retry
          action={getDetails}
          msg='An error occurred while fetch certificate information, please try again'
        />
      )}
    </div>
  ) : (
    <div className='success-page'>
      <div className='success-page__travellers'>
        <img src={travellers} alt='travellers' />
      </div>
      <div className='success-page__texts py-5'>
        <img src={successIcon} alt='Success' />
        <p>
          Congratulations! you insurance purchase was successful.
          <div>
            <a href={data?.payments?.certificate_url} download>
              <button className='button-rounded mt-2 mr-2 py-2'>
                Download Certificate
              </button>
            </a>
            <button onClick={printReciept} className='button-rounded mt-2 py-2'>
              Print Reciept
            </button>
          </div>
          <PrintCertifcate data={data} watermarked={true} />
          <div className='card p-2 my-3 py-3 bg-primary-light'>
            <div className='card-title'>
              <h3 className='text-primary w-600 mt-3'>Important Message!!!</h3>
            </div>

            <div className='card-body'>
              *Please note that the letter addressed to the embassy can be found
              in the 3rd page of the policy document***
            </div>
          </div>
        </p>
      </div>
    </div>
  );
};

export default SuccessPage;
