import { useRecoilState } from 'recoil';
import {
  guardianDetailsState,
  nextOfKinDetailsState,
  travelDetailsState
} from '../../../../../recoil/atoms';
import { useState } from 'react';
import { getAge } from '../../../../../utilities';

export default function useGetGuardian() {
  const [guardianDetails] = useRecoilState(guardianDetailsState);
  const [travelDetails] = useRecoilState(travelDetailsState);
  const [nextOfKinDetails] = useRecoilState(nextOfKinDetailsState);

  const [isMinor] = useState(false);

  return {
    name: guardianDetails?.guardianFirstName,
    first_name: guardianDetails?.guardianFirstName,
    last_name: guardianDetails?.guardianLastName,
    other_names: guardianDetails?.guardianOtherName,
    gender: guardianDetails?.gender || 'N/A',
    address: guardianDetails?.guardianAddress,
    phone: guardianDetails?.guardianPhoneNumber,
    email: guardianDetails?.guardianEmail,
    passport_no: guardianDetails?.guardianPassportNumber,
    title: guardianDetails?.title || '',
    age: getAge(guardianDetails?.guardianDateOfBirth),
    date_of_birth: guardianDetails?.guardianDateOfBirth,
    is_holder: isMinor || !nextOfKinDetails.isPolicyHolder,
    no_of_days: travelDetails?.duration,
    purpose: travelDetails?.purpose,
    from_date: travelDetails?.startDate,
    to_date: travelDetails?.endDate
  };
}
