import { useEffect, useState } from 'react';
import { baseUrl } from '../../../../../constants';
import { useRecoilValue } from 'recoil';
import { headerState } from '../../../../../recoil/atoms';

export default function useGetPrices(productXml) {
  const headers = useRecoilValue(headerState);
  const [localPriceData, setLocalPriceData] = useState({});
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fn() {
      try {
        const res = await fetch(`${baseUrl}/product-quotes`, {
          method: 'POST',
          body: JSON.stringify({
            action: 'getPrices',
            xml: productXml.data,
            xml_raw: productXml.xml
          }),
          headers
        });

        let data = await res.json();
        if (data.error || data.errors) {
          throw new Error('Unable to generate price');
        }

        setLocalPriceData(data);
      } catch (error) {
        console.error(error);
        setIsError(true);
        setError(error);
      }
    }

    if (
      !productXml ||
      !Object.keys(productXml).length ||
      productXml.error ||
      !productXml.data
    ) {
      setIsError(true);
      setError("Couldn't load xml");
    } else {
      try {
        console.log('Got productXml');
        setIsError(false);
        setError('');
        fn();
      } catch (error) {
        setIsError(true);
        setError(error);
        console.log('error in getPrices: ', error);
        return null;
      }
    }
  }, [productXml, headers]);

  if (isError) return { isError, error };
  return localPriceData;
}
