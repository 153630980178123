const countries = [
  {
    idReg: '43',
    cd: 'AF',
    ds: 'AFGHANISTAN'
  },
  {
    idReg: '55',
    cd: 'AX',
    ds: 'ALAND ISLANDS (FINLAND)'
  },
  {
    idReg: '46',
    cd: 'AL',
    ds: 'ALBANIA'
  },
  {
    idReg: '9',
    cd: 'DZ',
    ds: 'ALGERIA'
  },
  {
    idReg: '51',
    cd: 'AS',
    ds: 'AMERICAN SAMOA (USA)'
  },
  {
    idReg: '41',
    cd: 'AD',
    ds: 'ANDORRA'
  },
  {
    idReg: '48',
    cd: 'AO',
    ds: 'ANGOLA'
  },
  {
    idReg: '45',
    cd: 'AI',
    ds: 'ANGUILLA (UK)'
  },
  {
    idReg: '332',
    cd: 'AQ',
    ds: 'ANTARCTICA'
  },
  {
    idReg: '44',
    cd: 'AG',
    ds: 'ANTIGUA AND BARBUDA'
  },
  {
    idReg: '22',
    cd: 'AR',
    ds: 'ARGENTINA'
  },
  {
    idReg: '47',
    cd: 'AM',
    ds: 'ARMENIA'
  },
  {
    idReg: '54',
    cd: 'AW',
    ds: 'ARUBA'
  },
  {
    idReg: '31',
    cd: 'AU',
    ds: 'AUSTRALIA'
  },
  {
    idReg: '52',
    cd: 'AT',
    ds: 'AUSTRIA'
  },
  {
    idReg: '56',
    cd: 'AZ',
    ds: 'AZERBAIJAN'
  },
  {
    idReg: '72',
    cd: 'BS',
    ds: 'BAHAMAS'
  },
  {
    idReg: '10',
    cd: 'BH',
    ds: 'BAHRAIN'
  },
  {
    idReg: '59',
    cd: 'BD',
    ds: 'BANGLADESH'
  },
  {
    idReg: '58',
    cd: 'BB',
    ds: 'BARBADOS'
  },
  {
    idReg: '76',
    cd: 'BY',
    ds: 'BELARUS'
  },
  {
    idReg: '6',
    cd: 'BE',
    ds: 'BELGIUM'
  },
  {
    idReg: '77',
    cd: 'BZ',
    ds: 'BELIZE'
  },
  {
    idReg: '65',
    cd: 'BJ',
    ds: 'BENIN'
  },
  {
    idReg: '67',
    cd: 'BM',
    ds: 'BERMUDA (UK)'
  },
  {
    idReg: '73',
    cd: 'BT',
    ds: 'BHUTAN'
  },
  {
    idReg: '69',
    cd: 'BO',
    ds: 'BOLIVIA'
  },
  {
    idReg: '57',
    cd: 'BA',
    ds: 'BOSNIA'
  },
  {
    idReg: '75',
    cd: 'BW',
    ds: 'BOTSWANA'
  },
  {
    idReg: '74',
    cd: 'BV',
    ds: 'BOUVET ISLAND (NORWAY)'
  },
  {
    idReg: '21',
    cd: 'BR',
    ds: 'BRAZIL'
  },
  {
    idReg: '143',
    cd: 'IO',
    ds: 'BRITISH INDIAN OCEAN TERRITORY (UK)'
  },
  {
    idReg: '68',
    cd: 'BN',
    ds: 'BRUNEI'
  },
  {
    idReg: '62',
    cd: 'BG',
    ds: 'BULGARIA'
  },
  {
    idReg: '61',
    cd: 'BF',
    ds: 'BURKINA FASO'
  },
  {
    idReg: '64',
    cd: 'BI',
    ds: 'BURUNDI'
  },
  {
    idReg: '154',
    cd: 'KH',
    ds: 'CAMBODIA'
  },
  {
    idReg: '87',
    cd: 'CM',
    ds: 'CAMEROON'
  },
  {
    idReg: '78',
    cd: 'CA',
    ds: 'CANADA'
  },
  {
    idReg: '91',
    cd: 'CV',
    ds: 'CAPE VERDE'
  },
  {
    idReg: '161',
    cd: 'KY',
    ds: 'CAYMAN'
  },
  {
    idReg: '81',
    cd: 'CF',
    ds: 'CENTRAL AFRICAN REPUBLIC'
  },
  {
    idReg: '252',
    cd: 'TD',
    ds: 'CHAD'
  },
  {
    idReg: '27',
    cd: 'CL',
    ds: 'CHILE'
  },
  {
    idReg: '11',
    cd: 'CN',
    ds: 'CHINA'
  },
  {
    idReg: '93',
    cd: 'CX',
    ds: 'CHRISTMAS ISLAND (AUSTRALIA)'
  },
  {
    idReg: '79',
    cd: 'CC',
    ds: 'COCOS (KEELING) ISLANDS (AUSTRALIA)'
  },
  {
    idReg: '3',
    cd: 'Co',
    ds: 'COLOMBIA'
  },
  {
    idReg: '156',
    cd: 'KM',
    ds: 'COMOROS'
  },
  {
    idReg: '82',
    cd: 'CG',
    ds: 'CONGO'
  },
  {
    idReg: '80',
    cd: 'CD',
    ds: 'CONGO (THE DEMOCRATIC REPUBLIC)'
  },
  {
    idReg: '85',
    cd: 'CK',
    ds: 'COOK ISLANDS (NEW ZEALAND)'
  },
  {
    idReg: '89',
    cd: 'CR',
    ds: 'COSTA RICA'
  },
  {
    idReg: '136',
    cd: 'HR',
    ds: 'CROATIA'
  },
  {
    idReg: '90',
    cd: 'CU',
    ds: 'CUBA'
  },
  {
    idReg: '94',
    cd: 'CY',
    ds: 'CYPRUS'
  },
  {
    idReg: '95',
    cd: 'CZ',
    ds: 'CZECH REPUBLIC'
  },
  {
    idReg: '98',
    cd: 'DK',
    ds: 'DENMARK'
  },
  {
    idReg: '97',
    cd: 'DJ',
    ds: 'DJIBOUTI'
  },
  {
    idReg: '99',
    cd: 'DM',
    ds: 'DOMINICA (UK)'
  },
  {
    idReg: '100',
    cd: 'DO',
    ds: 'DOMINICAN REPUBLIC'
  },
  {
    idReg: '35',
    cd: 'EC',
    ds: 'ECUADOR'
  },
  {
    idReg: '104',
    cd: 'EG',
    ds: 'EGYPT'
  },
  {
    idReg: '247',
    cd: 'SV',
    ds: 'EL SALVADOR'
  },
  {
    idReg: '126',
    cd: 'GQ',
    ds: 'EQUATORIAL GUINEA'
  },
  {
    idReg: '333',
    cd: 'ER',
    ds: 'ERITREA'
  },
  {
    idReg: '103',
    cd: 'EE',
    ds: 'ESTONIA'
  },
  {
    idReg: '108',
    cd: 'ET',
    ds: 'ETHIOPIA'
  },
  {
    idReg: '111',
    cd: 'FK',
    ds: 'FALKLAND ISLANDS (MALVINAS) (UK)'
  },
  {
    idReg: '113',
    cd: 'FO',
    ds: 'FAROE ISLANDS (DENMARK)'
  },
  {
    idReg: '110',
    cd: 'FJ',
    ds: 'FIJI'
  },
  {
    idReg: '109',
    cd: 'FI',
    ds: 'FINLAND'
  },
  {
    idReg: '18',
    cd: 'FR',
    ds: 'FRANCE'
  },
  {
    idReg: '118',
    cd: 'GF',
    ds: 'FRENCH GUIANA (FRANCE)'
  },
  {
    idReg: '212',
    cd: 'PF',
    ds: 'FRENCH POLYNESIA (FRANCE)'
  },
  {
    idReg: '253',
    cd: 'TF',
    ds: 'FRENCH SOUTHERN TERRITORIES (FRANCE)'
  },
  {
    idReg: '115',
    cd: 'GA',
    ds: 'GABON'
  },
  {
    idReg: '123',
    cd: 'GM',
    ds: 'GAMBIA'
  },
  {
    idReg: '117',
    cd: 'GE',
    ds: 'GEORGIA'
  },
  {
    idReg: '300',
    cd: 'GER',
    ds: 'GERMANY'
  },
  {
    idReg: '120',
    cd: 'GH',
    ds: 'GHANA'
  },
  {
    idReg: '121',
    cd: 'GI',
    ds: 'GIBRALTAR (UK)'
  },
  {
    idReg: '7',
    cd: 'GR',
    ds: 'GREECE'
  },
  {
    idReg: '122',
    cd: 'GL',
    ds: 'GREENLAND (DENMARK)'
  },
  {
    idReg: '116',
    cd: 'GD',
    ds: 'GRENADA'
  },
  {
    idReg: '125',
    cd: 'GP',
    ds: 'GUADELOUPE'
  },
  {
    idReg: '130',
    cd: 'GU',
    ds: 'GUAM (USA)'
  },
  {
    idReg: '36',
    cd: 'GT',
    ds: 'GUATEMALA'
  },
  {
    idReg: '119',
    cd: 'GG',
    ds: 'GUERNSEY (UK)'
  },
  {
    idReg: '131',
    cd: 'GW',
    ds: 'GUINEA-BISSAU'
  },
  {
    idReg: '132',
    cd: 'GY',
    ds: 'GUYANA'
  },
  {
    idReg: '137',
    cd: 'HT',
    ds: 'HAITI'
  },
  {
    idReg: '134',
    cd: 'HM',
    ds: 'HEARD ISLAND AND MCDONALD ISLANDS (AUSTRALIA)'
  },
  {
    idReg: '274',
    cd: 'VA',
    ds: 'HOLY SEE (VATICAN CITY STATE)'
  },
  {
    idReg: '135',
    cd: 'HN',
    ds: 'HONDURAS'
  },
  {
    idReg: '8',
    cd: 'HK',
    ds: 'HONG KONG'
  },
  {
    idReg: '15',
    cd: 'HU',
    ds: 'HUNGARY'
  },
  {
    idReg: '146',
    cd: 'IS',
    ds: 'ICELAND'
  },
  {
    idReg: '33',
    cd: 'IN',
    ds: 'INDIA'
  },
  {
    idReg: '139',
    cd: 'ID',
    ds: 'INDONESIA'
  },
  {
    idReg: '299',
    cd: 'IR',
    ds: 'IRAN'
  },
  {
    idReg: '144',
    cd: 'IQ',
    ds: 'IRAQ'
  },
  {
    idReg: '23',
    cd: 'IE',
    ds: 'IRELAND'
  },
  {
    idReg: '141',
    cd: 'IM',
    ds: 'ISLE OF MAN (UK)'
  },
  {
    idReg: '140',
    cd: 'IL',
    ds: 'ISRAEL'
  },
  {
    idReg: '20',
    cd: 'IT',
    ds: 'ITALY'
  },
  {
    idReg: '84',
    cd: 'CI',
    ds: 'IVORY COAST'
  },
  {
    idReg: '149',
    cd: 'JM',
    ds: 'JAMAICA'
  },
  {
    idReg: '29',
    cd: 'JP',
    ds: 'JAPAN'
  },
  {
    idReg: '148',
    cd: 'JE',
    ds: 'JERSEY (UK)'
  },
  {
    idReg: '12',
    cd: 'JO',
    ds: 'JORDAN'
  },
  {
    idReg: '162',
    cd: 'KZ',
    ds: 'KAZAKHSTAN'
  },
  {
    idReg: '152',
    cd: 'KE',
    ds: 'KENYA'
  },
  {
    idReg: '155',
    cd: 'KI',
    ds: 'KIRIBATI'
  },
  {
    idReg: '160',
    cd: 'KW',
    ds: 'KUWAIT'
  },
  {
    idReg: '153',
    cd: 'KG',
    ds: 'KYRGYZSTAN'
  },
  {
    idReg: '163',
    cd: 'LA',
    ds: 'LAO PDR'
  },
  {
    idReg: '172',
    cd: 'LV',
    ds: 'LATVIA'
  },
  {
    idReg: '164',
    cd: 'LB',
    ds: 'LEBANON'
  },
  {
    idReg: '169',
    cd: 'LS',
    ds: 'LESOTHO'
  },
  {
    idReg: '168',
    cd: 'LR',
    ds: 'LIBERIA'
  },
  {
    idReg: '173',
    cd: 'LY',
    ds: 'LIBYA'
  },
  {
    idReg: '166',
    cd: 'LI',
    ds: 'LIECHTENSTEIN'
  },
  {
    idReg: '170',
    cd: 'LT',
    ds: 'LITHUANIA'
  },
  {
    idReg: '171',
    cd: 'LU',
    ds: 'LUXEMBOURG'
  },
  {
    idReg: '185',
    cd: 'MO',
    ds: 'MACAO'
  },
  {
    idReg: '181',
    cd: 'MK',
    ds: 'MACEDONIA'
  },
  {
    idReg: '179',
    cd: 'MG',
    ds: 'MADAGASCAR'
  },
  {
    idReg: '193',
    cd: 'MW',
    ds: 'MALAWI'
  },
  {
    idReg: '195',
    cd: 'MY',
    ds: 'MALAYSIA'
  },
  {
    idReg: '192',
    cd: 'MV',
    ds: 'MALDIVES'
  },
  {
    idReg: '182',
    cd: 'ML',
    ds: 'MALI'
  },
  {
    idReg: '28',
    cd: 'MT',
    ds: 'MALTA'
  },
  {
    idReg: '180',
    cd: 'MH',
    ds: 'MARSHALL ISLANDS'
  },
  {
    idReg: '187',
    cd: 'MQ',
    ds: 'MARTINIQUE'
  },
  {
    idReg: '188',
    cd: 'MR',
    ds: 'MAURITANIA'
  },
  {
    idReg: '191',
    cd: 'MU',
    ds: 'MAURITIUS'
  },
  {
    idReg: '284',
    cd: 'YT',
    ds: 'MAYOTTE (FRANCE)'
  },
  {
    idReg: '14',
    cd: 'MX',
    ds: 'MEXICO'
  },
  {
    idReg: '112',
    cd: 'FM',
    ds: 'MICRONESIA'
  },
  {
    idReg: '176',
    cd: 'MD',
    ds: 'MOLDOVA'
  },
  {
    idReg: '175',
    cd: 'MC',
    ds: 'MONACO'
  },
  {
    idReg: '184',
    cd: 'MN',
    ds: 'MONGOLIA'
  },
  {
    idReg: '177',
    cd: 'ME',
    ds: 'MONTENEGRO'
  },
  {
    idReg: '189',
    cd: 'MS',
    ds: 'MONTSERRAT (UK)'
  },
  {
    idReg: '174',
    cd: 'MA',
    ds: 'MOROCCO'
  },
  {
    idReg: '196',
    cd: 'MZ',
    ds: 'MOZAMBIQUE'
  },
  {
    idReg: '337',
    cd: 'MLC',
    ds: 'MULTI-COUNTRY WITHIN COVERED ZONE'
  },
  {
    idReg: '183',
    cd: 'MM',
    ds: 'MYANMAR'
  },
  {
    idReg: '197',
    cd: 'NA',
    ds: 'NAMIBIA'
  },
  {
    idReg: '206',
    cd: 'NR',
    ds: 'NAURU'
  },
  {
    idReg: '205',
    cd: 'NP',
    ds: 'NEPAL'
  },
  {
    idReg: '203',
    cd: 'NL',
    ds: 'NETHERLANDS'
  },
  {
    idReg: '334',
    cd: 'AN',
    ds: 'NETHERLANDS ANTILLES'
  },
  {
    idReg: '198',
    cd: 'NC',
    ds: 'NEW CALEDONIA'
  },
  {
    idReg: '208',
    cd: 'NZ',
    ds: 'NEW ZEALAND'
  },
  {
    idReg: '289',
    cd: 'NI',
    ds: 'NICARAGUA'
  },
  {
    idReg: '199',
    cd: 'NE',
    ds: 'NIGER'
  },
  {
    idReg: '201',
    cd: 'NG',
    ds: 'NIGERIA'
  },
  {
    idReg: '207',
    cd: 'NU',
    ds: 'NIUE (NEW ZEALAND)'
  },
  {
    idReg: '200',
    cd: 'NF',
    ds: 'NORFOLK ISLAND (AUSTRALIA)'
  },
  {
    idReg: '158',
    cd: 'KP',
    ds: 'NORTH KOREA'
  },
  {
    idReg: '186',
    cd: 'MP',
    ds: 'NORTHERN'
  },
  {
    idReg: '204',
    cd: 'NO',
    ds: 'NORWAY'
  },
  {
    idReg: '209',
    cd: 'OM',
    ds: 'OMAN'
  },
  {
    idReg: '215',
    cd: 'PK',
    ds: 'PAKISTAN'
  },
  {
    idReg: '222',
    cd: 'PW',
    ds: 'PALAU'
  },
  {
    idReg: '220',
    cd: 'PS',
    ds: 'PALESTINIAN (OCCUPIED TERRITORY)'
  },
  {
    idReg: '34',
    cd: 'PA',
    ds: 'PANAMA'
  },
  {
    idReg: '213',
    cd: 'PG',
    ds: 'PAPUA NEW GUINEA'
  },
  {
    idReg: '223',
    cd: 'PY',
    ds: 'PARAGUAY'
  },
  {
    idReg: '211',
    cd: 'PE',
    ds: 'PERU'
  },
  {
    idReg: '32',
    cd: 'PH',
    ds: 'PHILIPPINES'
  },
  {
    idReg: '218',
    cd: 'PN',
    ds: 'PITCAIRN'
  },
  {
    idReg: '216',
    cd: 'PL',
    ds: 'POLAND'
  },
  {
    idReg: '16',
    cd: 'PT',
    ds: 'PORTUGAL'
  },
  {
    idReg: '219',
    cd: 'PR',
    ds: 'PUERTO RICO (USA)'
  },
  {
    idReg: '224',
    cd: 'QA',
    ds: 'QATAR'
  },
  {
    idReg: '124',
    cd: 'GN',
    ds: 'REPUBLIC OF GUINEA (CONAKRY)'
  },
  {
    idReg: '225',
    cd: 'RE',
    ds: 'REUNION'
  },
  {
    idReg: '226',
    cd: 'RO',
    ds: 'ROMANIA'
  },
  {
    idReg: '25',
    cd: 'RU',
    ds: 'RUSSIAN FEDERATION'
  },
  {
    idReg: '229',
    cd: 'RW',
    ds: 'RWANDA'
  },
  {
    idReg: '66',
    cd: 'BL',
    ds: 'SAINT BARTHÉLEMY (FRANCE)'
  },
  {
    idReg: '236',
    cd: 'SH',
    ds: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA (UK)'
  },
  {
    idReg: '157',
    cd: 'KN',
    ds: 'SAINT KITTS AND NEVIS'
  },
  {
    idReg: '165',
    cd: 'LC',
    ds: 'SAINT LUCIA'
  },
  {
    idReg: '178',
    cd: 'MF',
    ds: 'SAINT MARTIN (FRANCE)'
  },
  {
    idReg: '217',
    cd: 'PM',
    ds: 'SAINT PIERRE AND MIQUELON (FRANCE)'
  },
  {
    idReg: '275',
    cd: 'VC',
    ds: 'SAINT VINCENT AND THE GRENADINES'
  },
  {
    idReg: '282',
    cd: 'WS',
    ds: 'SAMOA'
  },
  {
    idReg: '241',
    cd: 'SM',
    ds: 'SAN MARINO'
  },
  {
    idReg: '246',
    cd: 'ST',
    ds: 'SAO TOME AND PRINCIPE'
  },
  {
    idReg: '290',
    cd: 'SA',
    ds: 'SAUDI ARABIA'
  },
  {
    idReg: '242',
    cd: 'SN',
    ds: 'SENEGAL'
  },
  {
    idReg: '227',
    cd: 'RS',
    ds: 'SERBIA'
  },
  {
    idReg: '232',
    cd: 'SC',
    ds: 'SEYCHELLES'
  },
  {
    idReg: '240',
    cd: 'SL',
    ds: 'SIERRA LEONE'
  },
  {
    idReg: '235',
    cd: 'SG',
    ds: 'SINGAPORE'
  },
  {
    idReg: '239',
    cd: 'SK',
    ds: 'SLOVAKIA'
  },
  {
    idReg: '237',
    cd: 'SI',
    ds: 'SLOVENIA'
  },
  {
    idReg: '231',
    cd: 'SB',
    ds: 'SOLOMON ISLANDS'
  },
  {
    idReg: '243',
    cd: 'SO',
    ds: 'SOMALIA'
  },
  {
    idReg: '285',
    cd: 'ZA',
    ds: 'SOUTH AFRICA'
  },
  {
    idReg: '128',
    cd: 'GS',
    ds: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS (UK)'
  },
  {
    idReg: '159',
    cd: 'KR',
    ds: 'SOUTH KOREA'
  },
  {
    idReg: '245',
    cd: 'SS',
    ds: 'SOUTH SUDAN'
  },
  {
    idReg: '107',
    cd: 'ES',
    ds: 'SPAIN'
  },
  {
    idReg: '167',
    cd: 'LK',
    ds: 'SRI LANKA'
  },
  {
    idReg: '233',
    cd: 'SD',
    ds: 'SUDAN'
  },
  {
    idReg: '244',
    cd: 'SR',
    ds: 'SURINAME'
  },
  {
    idReg: '238',
    cd: 'SJ',
    ds: 'SVALBARD AND JAN MAYEN (NORWAY)'
  },
  {
    idReg: '250',
    cd: 'SZ',
    ds: 'SWAZILAND'
  },
  {
    idReg: '37',
    cd: 'SE',
    ds: 'SWEDEN'
  },
  {
    idReg: '83',
    cd: 'CH',
    ds: 'SWITZERLAND'
  },
  {
    idReg: '249',
    cd: 'SY',
    ds: 'SYRIA'
  },
  {
    idReg: '265',
    cd: 'TW',
    ds: 'TAIWAN (CHINA)'
  },
  {
    idReg: '256',
    cd: 'TJ',
    ds: 'TAJIKISTAN'
  },
  {
    idReg: '266',
    cd: 'TZ',
    ds: 'TANZANIA'
  },
  {
    idReg: '255',
    cd: 'TH',
    ds: 'THAILAND'
  },
  {
    idReg: '258',
    cd: 'TL',
    ds: 'TIMOR-LESTE'
  },
  {
    idReg: '254',
    cd: 'TG',
    ds: 'TOGO'
  },
  {
    idReg: '257',
    cd: 'TK',
    ds: 'TOKELAU (NEW ZEALAND)'
  },
  {
    idReg: '261',
    cd: 'TO',
    ds: 'TONGA'
  },
  {
    idReg: '263',
    cd: 'TT',
    ds: 'TRINIDAD AND TOBAGO'
  },
  {
    idReg: '26',
    cd: 'TN',
    ds: 'TUNISIA'
  },
  {
    idReg: '335',
    cd: 'TR',
    ds: 'TURKEY'
  },
  {
    idReg: '259',
    cd: 'TM',
    ds: 'TURKMENISTAN'
  },
  {
    idReg: '251',
    cd: 'TC',
    ds: 'TURKS AND CAICOS ISLANDS (UK)'
  },
  {
    idReg: '264',
    cd: 'TV',
    ds: 'TUVALU'
  },
  {
    idReg: '268',
    cd: 'UG',
    ds: 'UGANDA'
  },
  {
    idReg: '267',
    cd: 'UA',
    ds: 'UKRAINE'
  },
  {
    idReg: '42',
    cd: 'AE',
    ds: 'UNITED ARAB EMIRATES'
  },
  {
    idReg: '269',
    cd: 'GB',
    ds: 'UNITED KINGDOM (UK)'
  },
  {
    idReg: '271',
    cd: 'USA',
    ds: 'UNITED STATES (USA)'
  },
  {
    idReg: '270',
    cd: 'UM',
    ds: 'UNITED STATES MINOR OUTLYING ISLANDS (USA)'
  },
  {
    idReg: '30',
    cd: 'UY',
    ds: 'URUGUAY'
  },
  {
    idReg: '273',
    cd: 'UZ',
    ds: 'UZBEKISTAN'
  },
  {
    idReg: '280',
    cd: 'VU',
    ds: 'VANUATU'
  },
  {
    idReg: '5',
    cd: 'VE',
    ds: 'VENEZUELA'
  },
  {
    idReg: '279',
    cd: 'VN',
    ds: 'VIET NAM'
  },
  {
    idReg: '277',
    cd: 'VG',
    ds: 'VIRGIN ISLANDS (UK)'
  },
  {
    idReg: '278',
    cd: 'VI',
    ds: 'VIRGIN ISLANDS (USA)'
  },
  {
    idReg: '281',
    cd: 'WF',
    ds: 'WALLIS AND FUTUNA (FRANCE)'
  },
  {
    idReg: '105',
    cd: 'EH',
    ds: 'WESTERN'
  },
  {
    idReg: '283',
    cd: 'YE',
    ds: 'YEMEN'
  },
  {
    idReg: '336',
    cd: 'ZM',
    ds: 'ZAMBIA'
  },
  {
    idReg: '287',
    cd: 'ZW',
    ds: 'ZIMBABWE'
  }
];

export default countries;
