import { Outlet } from 'react-router-dom';
import Sidebar from '../../../components/commons/Sidebar';
import { Suspense } from 'react';
import { Spinner } from 'react-bootstrap';

const Batch = () => {
  return (
    <div className='batch'>
      <Sidebar />

      <div className='px-3'>
        <Suspense
          fallback={
            <Spinner color='primary' animation='border' role='status' />
          }
        >
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};

export default Batch;
