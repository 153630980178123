import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  checkValidations,
  ErrorMessage
} from '../../../../utilities/use-validation';
import {
  nextOfKinDetailsState,
  passengerDetailsState,
  guardianDetailsState,
  insuranceDataState,
  clearFieldsState,
  isMinorState,
  guardianHeightState
} from '../../../../recoil/atoms';
import { useRecoilState } from 'recoil';
import { getAge } from '../../../../utilities';

import plusIcon from '../../../../assets/svg/plus.svg';
import collapseIcon from '../../../../assets/svg/collapse.svg';
import CustomDropdown from '../../../../components/custom-dropdown';
import { genders, titles, relationships } from '../../../../constants';
import { guardianSchema } from '../../NextOfKinDetails/next-of-kin.schema';
import getPassenger from '../helpers/getPassenger';
import getGuardian from '../helpers/getGuardian';

const GuardianDetails = () => {
  const [nextOfKinDetails, setNextOfKinDetails] = useRecoilState(
    nextOfKinDetailsState
  );
  const [passengerDetails, setPassengerDetails] = useRecoilState(
    passengerDetailsState
  );
  const [guardianDetails, setGuardianDetails] =
    useRecoilState(guardianDetailsState);
  const [insuranceData, setInsuranceData] = useRecoilState(insuranceDataState);
  const [clearFields] = useRecoilState(clearFieldsState);
  const [isMinor, setIsMinor] = useRecoilState(isMinorState);

  const [errors, setErrors] = useState({});
  const [guardianHeight, setGuardianHeight] =
    useRecoilState(guardianHeightState);

  const guardianRef = useRef();
  const showGuardian = guardianHeight.initial > 0 || isMinor;

  // useEffect(() => {
  //   if (callSaveUserAndProceed) saveUserAndProceed();
  // }, [callSaveUserAndProceed]);

  useEffect(() => {
    const guardian = guardianRef.current.clientHeight + 25;
    setGuardianHeight({ initial: 0, new: guardian });
  }, [isMinor]);

  useEffect(() => {
    if (isMinor) {
      setNextOfKinDetails({
        ...nextOfKinDetails,
        isPolicyHolder: !isMinor
      });
      setTimeout(handleToggle, 100);
    }
    // eslint-disable-next-line
  }, [isMinor]);

  useEffect(() => {
    const newIsMinor = getAge(passengerDetails.dateOfBirth) < 18;
    setIsMinor(newIsMinor);
  }, [passengerDetails.dateOfBirth]);

  useEffect(() => {
    if (clearFields) {
      setGuardianDetails({
        firstName: '',
        lastName: '',
        gender: '',
        title: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        passportNumber: '',
        address: '',
        relationship: ''
      });
      setIsMinor(false);
      setInsuranceData({});
    }
  }, [clearFields]);

  const handleValidations = (data, field) => {
    const schemas =
      guardianHeight.initial > 0 || isMinor ? { ...{}, ...guardianSchema } : {};
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleToggle = () => {
    if (guardianHeight.initial > 0) {
      setGuardianHeight({ initial: 0, new: guardianHeight.initial });
    } else {
      setGuardianHeight({ initial: guardianHeight.new });
    }
  };

  const handleChange = (name, value) => {
    const data = { ...guardianDetails, [name]: value };
    // const { success } = handleValidations(data, name);

    // if (success)
    setGuardianDetails(data);
  };

  return (
    <div>
      <div className='quote-details__options col-sm-12 quote-details__options--1'>
        <span onClick={() => !isMinor && handleToggle()}>
          {guardianHeight.initial > 0 ? (
            <span style={{ color: 'var(--color-primary)' }}>
              {'Remove guardian'}
            </span>
          ) : isMinor ? (
            'You are quite young, please add a guardian'
          ) : (
            'I want to include a guardian'
          )}
        </span>
        <img
          src={guardianHeight.initial > 0 ? collapseIcon : plusIcon}
          alt='expand'
          onClick={handleToggle}
          style={{ opacity: isMinor ? '0.5' : '1' }}
        />
      </div>

      <div
        className='quote-details__options__guardian row m-0'
        ref={guardianRef}
        style={{
          height: guardianHeight.initial,
          visibility:
            !isMinor && !(guardianHeight.initial > 0) ? 'collapse' : 'visible'
        }}
      >
        <div className='col-sm-6 mt-sm-3 mt-5'>
          <label htmlFor='firstName'>
            First Name <span className='text-danger'> *</span>
          </label>
          <input
            name='firstName'
            value={guardianDetails.firstName}
            onChange={event =>
              handleChange(event.target.name, event.target.value)
            }
            type='text'
            id='guardianFirstName'
            placeholder='Enter guardian first name'
            readOnly={!showGuardian}
          />
          <ErrorMessage
            message={errors.firstName && errors.firstName.message}
          />
        </div>

        <div className='col-sm-6 mt-sm-3 mt-5'>
          <label htmlFor='lastName'>
            Surname <span className='text-danger'> *</span>
          </label>
          <input
            name='lastName'
            value={guardianDetails.lastName}
            onChange={event =>
              handleChange(event.target.name, event.target.value)
            }
            type='text'
            id='guardianLastName'
            placeholder='Enter guardian surname'
            readOnly={!showGuardian}
          />
          <ErrorMessage message={errors.lastName && errors.lastName.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='gender'>
            Gender <span className='text-danger'> *</span>
          </label>
          <CustomDropdown
            name='gender'
            onSelect={selection => {
              handleChange('gender', selection.gender);
            }}
            identifier='gender'
            data={genders}
            inputId={`guardianGender`}
            placeholder='Pick your gender'
            disable={false}
            showItems={true}
            value={{ gender: guardianDetails.gender }}
          />
          <ErrorMessage message={errors.gender && errors.gender.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='title'>Title</label>
          <CustomDropdown
            name='title'
            onSelect={selection => {
              handleChange('title', selection.title);
            }}
            identifier='title'
            data={titles}
            inputId={`guardianTitle`}
            placeholder='Pick your title'
            disable={false}
            showItems={true}
            value={{ title: guardianDetails.title }}
          />
          <ErrorMessage message={errors.title && errors.title.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='email'>
            Email <span className='text-danger'> *</span>
          </label>
          <input
            name='email'
            value={guardianDetails.email}
            onChange={event =>
              handleChange(event.target.name, event.target.value)
            }
            id='guardianEmail'
            type='email'
            placeholder='Enter guardian email'
            readOnly={!showGuardian}
          />
          <ErrorMessage message={errors.email && errors.email.message} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='phoneNumber'>
            Phone Number <span className='text-danger'> *</span>
          </label>
          <input
            name='phoneNumber'
            value={guardianDetails.phoneNumber}
            onChange={event =>
              handleChange(
                event.target.name,
                event.target.value.replace(/[^0-9.]/g, '')
              )
            }
            id='guardianPhone'
            type='phone'
            placeholder='Enter guardian phone'
            readOnly={!showGuardian}
          />
          <ErrorMessage
            message={errors.phoneNumber && errors.phoneNumber.message}
          />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='dateOfBirth'>
            Date of Birth <span className='text-danger'> *</span>
          </label>
          <input
            max={moment().subtract(18, 'years').format('YYYY-DD-MM')}
            name='dateOfBirth'
            value={guardianDetails.dateOfBirth}
            onChange={event =>
              handleChange(event.target.name, event.target.value)
            }
            id='guardianDateOfBirth'
            type='date'
            placeholder='enter guardian address'
            readOnly={!showGuardian}
          />
          <ErrorMessage
            message={errors.dateOfBirth && errors.dateOfBirth.message}
          />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='passportNumber'>
            Passport Number <span className='text-danger'> *</span>
          </label>
          <input
            name='passportNumber'
            value={guardianDetails.passportNumber}
            onChange={event =>
              handleChange(event.target.name, event.target.value)
            }
            id='guardianPassport'
            type='text'
            readOnly={!showGuardian}
          />
          <ErrorMessage
            message={errors.passportNumber && errors.passportNumber.message}
          />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='address'>
            Address <span className='text-danger'> *</span>
          </label>
          <input
            name='address'
            value={guardianDetails.address}
            onChange={event =>
              handleChange(event.target.name, event.target.value)
            }
            id='guardianAddress'
            type='address'
            placeholder='enter guardian address'
            readOnly={!showGuardian}
          />
          <ErrorMessage message={errors.address && errors.address.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='guardian-relationship'>
            Relationship <span className='text-danger'> *</span>
          </label>
          <CustomDropdown
            name='guardian-relationship'
            onSelect={selection => {
              handleChange('relationship', selection.relationship);
            }}
            identifier='relationship'
            data={relationships}
            inputId={`guardian-relationship`}
            placeholder='Pick relationship'
            disable={false}
            showItems={true}
            value={{ relationship: guardianDetails.relationship }}
            readOnly={!showGuardian}
          />

          <div className='mb-4'>
            <ErrorMessage
              message={errors.relationship && errors.relationship.message}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuardianDetails;
