import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const url =
    (!process.env.NODE_ENV || process.env.NODE_ENV) === 'development'
      ? 'localhost:3000/dashboard/index'
      : 'https://travelinsurance.com';

  return (
    <nav
      className='w-100 d-flex justify-content-between align-items-center p-4'
      style={{ background: '#ececec' }}
    >
      <Link
        to={url}
        style={{
          fontWeight: '500',
          fontSize: '1.5rem',
          color: '#9b2743',
          textDecoration: 'inherit'
        }}
      >
        Travel Insurance
      </Link>
      <Link
        to={url}
        style={{
          fontWeight: '500',
          fontSize: '1.5rem',
          color: '#9b2743',
          textDecoration: 'inherit'
        }}
      >
        Home
      </Link>
    </nav>
  );
};

export default Header;
