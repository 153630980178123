import React, { useState, useEffect } from 'react';
import { baseUrl } from '../../../../constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';

const Certificate = () => {
  const nav = useNavigate();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [errorSubtext, setErrorSubtext] = useState('');
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [webhookLoading, setWebhookLoading] = useState(false);
  const [webhookSuccess, setWebhookSuccess] = useState(false);

  const location = useLocation();
  const regex = /[?&]([^=]+)=([^&]+)/g;
  let match;
  const queryParams = {};

  while ((match = regex.exec(location.search)) !== null) {
    const key = match[1];
    const value = match[2];
    queryParams[key] = value;
  }

  const callWebhook = async val => {
    const res = await fetch(`${baseUrl}/flw-webhook`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(val)
    });
    return res.json();
  };

  const confirmPayment = async ref => {
    const res = await fetch(`${baseUrl}/agents/travel/verify-payment/${ref}`, {
      method: 'POST'
    });
    return res.json();
  };

  const getCertificate = async id => {
    const res = await fetch(`${baseUrl}/agents/travel/certificate/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (res?.status === 500) {
      return { data: res.statusText };
    }

    return res.json();
  };

  const { status, tx_ref, transaction_id } = queryParams;

  useEffect(() => {
    if (status === 'successful') {
      const confirm = async () => {
        try {
          const data = await confirmPayment(tx_ref);

          if (data?.status === 'success') {
            setLoading(false);
            setPaymentConfirmed(true);
            setIsSuccess(true);
            // call endpoint to update DB
            const res = await callWebhook(data);
            setWebhookLoading(true);
            if (res) {
              setWebhookLoading(false);
              if (res?.statusCode === 200) {
                //! TODO: confirm current implementation behaviour i.e. this process must succeed before certificate generation can happen.
                setWebhookSuccess(true);
              } else {
                setWebhookSuccess(false);
              }
            }
          } else {
            setIsError(true);
            setError('Unable to confirm payment.');
            setErrorSubtext(
              'Please retry payment using the payment link that was sent to your email.'
            );
          }
        } catch (error) {
          console.log('error: ', error);
          setError(error);
        }
      };
      confirm();
    } else {
      setIsError(true);
      setError('Payment unsuccessful');
    }
  }, [status, tx_ref]);

  const handleClick = val => {
    const res = getCertificate(val);
    setLoading(true);

    res.then(data => {
      setLoading(false);
      setIsSuccess(false);
      const { successful, certificate_url } = data;
      if (successful) {
        nav(`${certificate_url}`, { replace: true });
        //! TODO: implement this page -> nav(`/quotes/isSuccess/${travel_id}/${payment?.data?.id}`);
      } else {
        setIsError(true);
        setError('Certificate generation failed');
        setErrorSubtext('Refresh page to retry.');
      }
    });
  };

  return (
    <div
      className='w-100 d-flex flex-column align-items-center justify-content-between'
      style={{ height: '100dvh' }}
    >
      <Header />

      {loading && !paymentConfirmed && (
        <div className='d-flex flex-column justify-content-center align-items-center gap-6'>
          <Spinner animation='border' />
          <p className='display-2'>Verifying payment...</p>
        </div>
      )}

      {isSuccess && (
        <div className='d-flex flex-column justify-content-center align-items-center gap-6'>
          <h1 className='display-2 text-center'>
            {paymentConfirmed
              ? loading
                ? 'Generating certificate...'
                : 'Payment confirmed successfully!'
              : 'Confirm payment.'}
          </h1>

          {!loading && (
            <button
              className={`${
                webhookLoading || !webhookSuccess || loading
                  ? 'inactive'
                  : 'button-primary'
              }`}
              onClick={() => handleClick(tx_ref)}
              disabled={webhookLoading || !webhookSuccess || loading}
            >
              Get Certificate
            </button>
          )}
        </div>
      )}

      {isError && (
        <div className='d-flex flex-column justify-content-center align-items-center gap-6'>
          <h1 className='display-2 text-center'>{error}</h1>
          {errorSubtext && <h2 className='display-4'>{errorSubtext}</h2>}
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Certificate;
