import React from "react";
import { Link } from "react-router-dom";
import Robot from "./../../assets/svg/robot.svg";

const NotFound = ({ heading = "Nothing here", body = "Come back later" }) => (
  <div className="notfound">
    <figure>
      <img src={Robot} alt="not found" className="img" />
    </figure>

    <div className="notfound__texts">
      <h1 className="notfound__heading">{heading}</h1>

      <h3>{body}</h3>
      <div className="button">
        <Link to="/">
          <button className="submit-button">Back To Home</button>
        </Link>
      </div>
    </div>
  </div>
);

export default NotFound;
