import packages from './packages';
import quotesSteps from './quotes-steps';
import countries from './countries';
import relationships from './relationships';
import titles from './titles';
import genders from './genders';
import getProductDescription from './getProductDescription';

const baseUrl =
  (process.env.NODE_ENV || process.env.NODE_ENV) === 'development'
    ? 'http://127.0.0.1:8000/api'
    : 'https://travelapi.chiplc.com/api';

const subDirectory = '/';

const GibbsApiKey = 'tvRek_u3Yv39-63TtG';
const GibbsSubriskID = 'ECN';
const subProductTypeID = '8';

const flutterwaveKey =
  (process.env.NODE_ENV || process.env.NODE_ENV) === 'development'
    ? 'FLWPUBK_TEST-fe234025a8c37fbf14d24c5f33e248e7-X'
    : 'FLWPUBK-d9d52d459a7b8577add4ab47c5f15bf4-X';

export {
  packages,
  quotesSteps,
  baseUrl,
  subDirectory,
  GibbsApiKey,
  subProductTypeID,
  GibbsSubriskID,
  countries,
  relationships,
  getProductDescription,
  flutterwaveKey,
  titles,
  genders
};
