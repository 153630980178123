import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  checkValidations,
  ErrorMessage
} from '../../../utilities/use-validation';
import { schemas } from './profile-password.schema';
import useLoginUser from '../../../hooks/useLoginUser';
import { baseUrl } from '../../../constants';

const ChangePassword = () => {
  const { headers } = useLoginUser();

  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);

  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleChange = event => {
    const { name, value } = event.target;
    const data = { ...form, [name]: value };
    setForm(data);
    handleValidations(data, name);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { success } = handleValidations({
      ...form
    });

    if (success) {
      changePassword();
    }
  };

  const changePassword = async () => {
    setLoading(true);

    try {
      const res = await fetch(`${baseUrl}/update-password`, {
        method: 'POST',
        headers,
        body: JSON.stringify(form)
      });

      const data = await res.json();
      if (data.error || data.errors) throw new Error(data.message);
      toast.success('password updated successfully!!');
      setForm({});
    } catch (error) {
      toast.error(error.message);
      console.error(error);
    }
    setLoading(false);
  };
  return (
    <div className='profile__information'>
      <h3 className='profile__information__title mt-4'>Update Password</h3>

      <form action='' className='row d-block mt-5' onSubmit={handleSubmit}>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='old_password'>Current Password</label>
          <input
            name='old_password'
            value={form.old_password || ''}
            onChange={handleChange}
            type='password'
            id='old_password'
            placeholder='Enter Current Password'
          />
          <ErrorMessage
            message={errors.old_password && errors.old_password.message}
          />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='password'>Password</label>
          <input
            name='password'
            value={form.password || ''}
            onChange={handleChange}
            type='password'
            id='password'
            placeholder='Enter Password'
          />
          <ErrorMessage message={errors.password && errors.password.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='password_confirmation'>Confirm Password</label>
          <input
            name='password_confirmation'
            value={form.password_confirmation || ''}
            onChange={handleChange}
            type='password'
            id='password_confirmation'
            placeholder='Enter Confirm Password'
          />
          <ErrorMessage
            message={
              errors.password_confirmation &&
              errors.password_confirmation.message
            }
          />
        </div>

        <div className='quote-details__buttons mt-5 col-sm-12'>
          <button disabled={loading} className='button-rounded'>
            {loading ? (
              <span>
                <Spinner />
                loading...
              </span>
            ) : (
              'Update'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
