const relationships = [
    { relationship: "Father" },
    { relationship: "Mother" },
    { relationship: "Husband" },
    { relationship: "Wife" },
    { relationship: "Sibling" },
    { relationship: "Aunty" },
    { relationship: "Cousin" },
    { relationship: "Uncle" },
    { relationship: "Grand Father" },
    { relationship: "Grand Mother" },
    { relationship: "Spouse" },
    { relationship: "Friend" },
    { relationship: "Colleague" },
    { relationship: "Others" },
];

export default relationships;