import { useRef, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import plusIcon from '../../../../assets/svg/plus.svg';
import collapseIcon from '../../../../assets/svg/collapse.svg';
import checkIcon from '../../../../assets/svg/check.svg';
import { selectedPlanState, userTypeState } from '../../../../recoil/atoms';

const InsurancePackage = ({ insurancePackage, isUser }) => {
  const nav = useNavigate();
  const packageRef = useRef();
  const [packageHeight, setPackageHeight] = useState({});
  const [, setSelectedPlan] = useRecoilState(selectedPlanState);
  const [userType] = useRecoilState(userTypeState);

  useEffect(() => {
    const guardian = packageRef.current.clientHeight;
    setPackageHeight({
      initial: 0,
      new: guardian + (insurancePackage?.plans?.length || 4) * 5
    });
    // eslint-disable-next-line
  }, []);

  const handlePackageToggle = () => {
    if (packageHeight.initial > 0) {
      setPackageHeight({ initial: 0, new: packageHeight.initial });
    } else {
      setPackageHeight({ initial: packageHeight.new });
    }
  };

  let route = '';
  const getRoute = (iPackage, iPlan) => {
    if (userType === 'customer') {
      // if logged-in user is a customer, redirect here (shorter-flow form)
      route = `/get-quotes/${iPackage}/${iPlan}`;
    } else {
      // if there's no logged-in user or logged-in user is an agent, redirect here (longer-flow form)
      // this is because agents input different data each time they're buying insurance cause they're buying on behalf of different customers
      route = `/inquiry/${iPackage}/${iPlan}`;
    }
    return route;
  };

  return (
    <div className='home__plans__table__plan' onClick={handlePackageToggle}>
      <span className='home__plans__table__plan__name'>
        {insurancePackage.name}
      </span>
      <div className='home__plans__table__plan__count '>
        <span className='hide-mobile'>
          {insurancePackage.plans.length} plans
        </span>
        <img
          src={packageHeight.initial > 0 ? collapseIcon : plusIcon}
          alt='close'
        />
      </div>
      <ul
        className='home__plans__table__plan__details'
        ref={packageRef}
        style={{
          height: packageHeight.initial
        }}
      >
        <p className='home__plans__table__plan__details__description'>
          {insurancePackage.description}
        </p>

        {insurancePackage.plans.map(plan => (
          <li onClick={e => e.stopPropagation()} key={plan.name}>
            <div>
              <p>
                <img src={checkIcon} alt='' />
                {plan.name} ({`${plan.minDays} - ${plan.maxDays} Days`})
              </p>
              <p>{plan.description}</p>
            </div>
            <button
              onClick={() => {
                setSelectedPlan({ package: insurancePackage, plan });
                const route = getRoute(insurancePackage.name, plan.name);
                nav(encodeURI(route));
              }}
            >
              Buy Now
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InsurancePackage;
