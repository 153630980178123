import { baseUrl } from '../../../constants';
import useLoginUser from '../../../hooks/useLoginUser';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import RetryLoader from '../../../components/commons/retryLoader';
import RolesTable from './roles.table';
import RolesCreateProfile from './roles.create-profile';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  rolesState,
  editRoleDataState,
  createRoleDataState,
  permissionuserDataState
} from '../../../recoil/atoms';
import useTasks from '../Tasks/useTasks';
import localforage from 'localforage';

const RolesIndex = () => {
  const { user, headers } = useLoginUser();
  const nav = useNavigate();
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [error, setError] = useState(false);
  const [roles, setRoles] = useRecoilState(rolesState);
  const { tasks } = useTasks();
  const [editRoleData, setEditRoleData] = useRecoilState(editRoleDataState);
  const [createRoleData, setCreateRoleData] =
    useRecoilState(createRoleDataState);

  const [showCreateRole, setShowCreateRole] = useState(false);

  const fetchRoles = async () => {
    setLoading(true);
    let cacheData = await localforage.getItem('all-roles');

    if (cacheData) {
      setRoles(cacheData);
      setLoading(false);
      setError(false);
    }

    fetch(`${baseUrl}/roles`)
      .then(roles => roles.json())
      .then(roles => {
        if (roles.error || roles.errors) throw new Error(roles.message);
        localforage.setItem('all-roles', roles.data);
        setRoles(roles.data);
        setLoading(false);
        setError(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        setError(true);
      });
  };

  const createRole = () => {
    setCreateRoleData({ ...createRoleData, loading: true });
    const data = { ...createRoleData, loading: undefined };
    fetch(`${baseUrl}/roles`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers
    })
      .then(roles => roles.json())
      .then(roles => {
        if (roles.error || roles.errors) throw new Error(roles.message);
        setCreateRoleData({});
        fetchRoles();
        setShowCreateRole(false);
        toast.success('New Role Created');
      })
      .catch(err => {
        console.error(err);
        toast.error('An error occurred while saving, please try again');
        setCreateRoleData({ ...createRoleData, loading: false });
      });
  };

  const updateRole = role_id => {
    setEditRoleData({ ...editRoleData, loading: true });
    const data = { ...editRoleData, loading: undefined };
    fetch(`${baseUrl}/roles/${role_id}`, {
      method: 'POST',
      body: JSON.stringify({ ...data, _method: 'PUT' }),
      headers
    })
      .then(roles => roles.json())
      .then(roles => {
        if (roles.error || roles.errors) throw new Error(roles.message);
        setEditRoleData({});
        fetchRoles();
        toast.success('Changes saved');
      })
      .catch(err => {
        console.error(err);
        toast.error('An error occurred while saving, please try again');
        setEditRoleData({ ...editRoleData, loading: false });
      });
  };

  const removeRole = role_id => {
    setDeleteLoading(true);
    fetch(`${baseUrl}/roles/${role_id}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ _method: 'DELETE' })
    })
      .then(roles => roles.json())
      .then(roles => {
        if (roles.error || roles.errors) throw new Error(roles.message);
        fetchRoles();
        toast.success('Role Deleted');
        setDeleteLoading(false);
      })
      .catch(err => {
        console.error(err);
        toast.error('An error occurred while deleting, please try again');
        setDeleteLoading(false);
      });
  };

  const activateRole = role => {
    setDeleteLoading(true);
    fetch(`${baseUrl}/roles/toggle/${role.id}`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ ...role, enabled: !role.enabled, _method: 'PUT' })
    })
      .then(roles => roles.json())
      .then(roles => {
        if (roles.error || roles.errors) throw new Error(roles.message);
        fetchRoles();
        toast.success('Role Status Updated');
        setDeleteLoading(false);
      })
      .catch(err => {
        console.error(err);
        toast.error('An error occurred while deleting, please try again');
        setDeleteLoading(false);
      });
  };

  useEffect(() => {
    if (!user?.is_super_admin) {
      nav('/dashboard/index', { replace: true });
    }
    fetchRoles();
    // eslint-disable-next-line
  }, []);

  const permissions = useRecoilValue(permissionuserDataState);

  return (
    <div className='dashboard-users container'>
      <RetryLoader
        retry={fetchRoles}
        loading={loading}
        error={error}
        fullscreen={false}
      >
        <div className='dashboard-users__tabs'>
          <div className='dashboard-users__tabs__heading'>
            <div
              className={`dashboard-users__tab dashboard-users__tab--active`}
            >
              Roles
            </div>
            <div className='dashboard-users__create'>
              {permissions.create_role && (
                <button
                  className='button'
                  onClick={() => setShowCreateRole(true)}
                >
                  Create Role
                </button>
              )}
            </div>
          </div>

          {showCreateRole && (
            <RolesCreateProfile
              hide={() => setShowCreateRole(false)}
              save={createRole}
              tasks={tasks}
            />
          )}

          <div className='dashboard-users__tabs__body'>
            <RolesTable
              data={roles}
              updateRole={updateRole}
              removeRole={removeRole}
              deleteLoading={deleteLoading}
              activateRole={activateRole}
              tasks={tasks}
            />
          </div>
        </div>
      </RetryLoader>
    </div>
  );
};

export default RolesIndex;
