import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/commons/header';
import Footer from '../../components/commons/footer';
import welcome from '../../assets/svg/welcome.svg';
import InsurancePlan from '../Dashboard/BuyInsurance/InsurancePlan/insurance-plan';

const HomePage = () => {
  const packagesRef = React.useRef();
  const nav = useNavigate();

  return (
    <>
      <Header />
      <div className='home container'>
        <section className='home__welcome row'>
          <div className='home__welcome__text col-md-6'>
            <h1 className='home__welcome__text__heading '>
              Welcome to our travel insurance portal
            </h1>
            <p className='home__welcome__text__sub-heading'>
              Consolidated Hallmark Insurance (CHI) Plc is a leading general
              business insurance company determined to change the way Nigerians
              see insurance. With the help of our people and technology, we
              ensure we don’t break your heart when you need us most because our
              primary objective is to deliver exceptional service to you, our
              customer. We are here to reduce anxiety and cater to your needs.
              We have got you covered.
            </p>

            <div className='home__welcome__button-wrapper'>
              <button
                className='button-primary button-primary1--1'
                onClick={() =>
                  packagesRef.current.scrollIntoView({ behavior: 'smooth' })
                }
              >
                See Plans
              </button>
              <button
                className='button-secondary'
                onClick={() => nav('/login')}
              >
                Buy as agent
              </button>
            </div>
          </div>
          <div className='home__welcome__images col-md-6'>
            <img src={welcome} alt='welcome' />
          </div>
          <div ref={packagesRef} id='see-plans' className='mt-5 mb-2' />
        </section>
        <InsurancePlan heading='Select your travel insurance plan' />
        <section className='home__news-letter'>
          <h1 className='home__news-letter__heading'>Newsletter</h1>
          <p className='home__news-letter__sub-heading'>
            Enter your email to get latest updates. No spam, we promise
          </p>
          <div className='home__news-letter__input'>
            <input type='text' placeholder='Enter your email' />
            <button className='button-primary'>Signup</button>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
