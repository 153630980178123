import React from 'react'
import { Spinner } from 'react-bootstrap'
import Retry from './retry'

export default function RetryLoader({ retry, loading, error, children, fullscreen }) {

    return (
        (loading || error ?
            <div className={`retry__loader ${fullscreen ? 'retry__loader_fullscreen' : ''}`}>
                {
                    loading
                        ? <Spinner color="primary" animation="border" role="status" />
                        : <Retry
                            action={retry}
                            fullscreen={false}
                            msg="An error occurred while fetch certificate information, please try again"
                        />
                }
            </div> :
            children
        )
    )
}
