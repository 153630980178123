import { baseUrl } from '../../../constants';
import useLoginUser from '../../../hooks/useLoginUser';
import { toast } from 'react-toastify';
import { useState, useEffect, useMemo } from 'react';
import RetryLoader from '../../../components/commons/retryLoader';
import UsersTable from './users.table';
import UsersCreateProfile from './users.create-profile';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  rolesState,
  createUserDataState,
  permissionuserDataState
} from '../../../recoil/atoms';
import localforage from 'localforage';
import Search from '../../../components/commons/search';

const UsersIndex = () => {
  const { user, headers } = useLoginUser();
  const nav = useNavigate();

  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(false);
  // eslint-disable-next-line
  const [roles, setRoles] = useRecoilState(rolesState);
  const [createUserData, setCreateUserData] =
    useRecoilState(createUserDataState);

  const [showCreateUser, setShowCreateUser] = useState(false);

  const fetchUsers = async () => {
    setLoading(true);
    let cacheData = await localforage.getItem('all-users');

    if (cacheData) {
      setUsers(cacheData);
      setLoading(false);
    }

    fetch(`${baseUrl}/users`)
      .then(users => users.json())
      .then(users => {
        if (users.error || user.errors) throw new Error(users.message);
        localforage.setItem('all-users', users.data);
        setUsers(users.data);
        setLoading(false);
        setError(false);
      })
      .catch(err => {
        toast.error('An error occurred, please try again');
        setError(true);
        setLoading(false);
        console.error(err);
      });
  };

  const permissions = useRecoilValue(permissionuserDataState);

  const fetchRoles = async () => {
    let cacheData = await localforage.getItem('all-roles');

    if (cacheData) {
      setRoles(cacheData);
    }

    fetch(`${baseUrl}/roles`)
      .then(roles => roles.json())
      .then(roles => {
        if (roles.error || roles.errors) throw new Error(roles.message);
        localforage.setItem('all-roles', roles.data);
        setRoles(roles.data);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const createUser = () => {
    setCreateUserData({ ...createUserData, loading: true });
    const data = { ...createUserData, loading: undefined };
    fetch(`${baseUrl}/users`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers
    })
      .then(user => user.json())
      .then(user => {
        if (user.error || user.errors) throw new Error(user.message);
        setCreateUserData({});
        localforage.setItem('all-users', [user.data, ...users]);
        fetchUsers();
        setShowCreateUser(false);
        toast.success('User Created Successfully');
      })
      .catch(err => {
        console.error(err);
        toast.error(
          err.message || 'An error occurred while saving, please try again'
        );
        setCreateUserData({ ...createUserData, loading: false });
      });
  };

  useEffect(() => {
    if (!user?.is_super_admin) {
      nav('/dashboard/index', { replace: true });
    }
    fetchUsers();
    fetchRoles();
    // eslint-disable-next-line
  }, []);

  const searchFields = useMemo(() => ['name', 'email'], []);

  const [filteredUsers, setFilteredUsers] = useState([]);

  // const search

  return (
    <div className='dashboard-users container'>
      <RetryLoader
        retry={fetchUsers}
        loading={loading}
        error={error}
        fullscreen={false}
      >
        <div className='dashboard-users__tabs'>
          <div className='dashboard-users__tabs__heading'>
            <div
              className={`dashboard-users__tab dashboard-users__tab--active`}
            >
              Users
            </div>
            <div className='dashboard-users__create'>
              {permissions.create_user && (
                <button
                  className='button'
                  onClick={() => setShowCreateUser(true)}
                >
                  Create User
                </button>
              )}
            </div>
          </div>

          <Search
            data={users}
            setOutput={setFilteredUsers}
            fields={searchFields}
            filter='user_type'
            filterLabel='User Type'
            filterOptions={{
              customer: 'Customer',
              staff: 'Staff'
            }}
          />

          {showCreateUser && (
            <UsersCreateProfile
              hide={() => setShowCreateUser(false)}
              save={createUser}
            />
          )}

          <div className='dashboard-users__tabs__body'>
            <UsersTable data={filteredUsers} refresh={fetchUsers} />
          </div>
        </div>
      </RetryLoader>
    </div>
  );
};

export default UsersIndex;
