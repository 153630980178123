import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import localforage from 'localforage';
import { toast } from 'react-toastify';

import { baseUrl, subProductTypeID } from '../constants';
import useLoginUser from './useLoginUser';
import { headerState } from '../recoil/atoms';
import InitCountries from '../constants/countries';

export default function useCountries() {
  const [countries, setCountries] = useState(InitCountries);
  const [countriesMap, setCountriesMap] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [headers] = useRecoilState(headerState);

  const { updateMapKey } = useLoginUser();

  useEffect(() => {
    setCountriesMap(
      countries.reduce((acc, country) => ({ ...acc, [country.cd]: country }))
    );
  }, [countries]);

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  const fetchFromCache = async () => {
    const data = await localforage.getItem('mapfre-countries');
    if (!data) return { countries: null, reload: true };
    return {
      countries: data.countries,
      reload: Math.abs(moment(data.createdAt).diff(moment(), 'days')) > 3
    };
  };

  const getCountryName = useCallback(
    name => {
      return countriesMap[name]?.ds || name;
    },
    [countriesMap]
  );

  const getCountries = async (alertError = false) => {
    setLoading(true);
    const { countries, reload } = await fetchFromCache();
    if (!reload) setCountries(countries);
    if (countries && countries.length) setLoading(false);
    if (reload) {
      try {
        let res = await fetch(`${baseUrl}/countries-available`, {
          method: 'POST',
          body: JSON.stringify({
            effectDate: moment().format('DD/MM/YYYY'),
            subProductTypeID
          }),
          headers
        });

        let data = await res.json();
        if (data?.root?.country) {
          setCountries(data?.root?.country);
          localforage.setItem('mapfre-countries', {
            countries: data?.root?.country,
            createdAt: moment().toString()
          });
          setError(false);
        } else {
          throw new Error('Invalid country');
        }
        setLoading(false);
      } catch (error) {
        updateMapKey();
        if (alertError) {
          toast.error('An error occurred, please try again');
          setError(true);
        }
      }
    }
    setLoading(false);
  };

  return {
    countries,
    loading,
    error,
    getCountries,
    countriesMap,
    getCountryName
  };
}
