import React from 'react';
import Sidebar from '../../../components/commons/Sidebar';
import { Outlet } from 'react-router-dom';

const Group = () => {
  return (
    <div className='group'>
      <Sidebar />

      <div className='group__main'>
        <Outlet />
      </div>
    </div>
  );
};

export default Group;
