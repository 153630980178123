import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { policyHolderState } from '../../../../recoil/atoms';
import Policy from './components/Policy';
import Travel from './components/Travel';
import Plan from './components/Plan';
import { Spinner } from 'react-bootstrap';
import Back from '../../../../components/commons/Back';

const TravelDetails = () => {
  const nav = useNavigate();
  const headingRef = useRef();
  const {
    state: { insurancePackage }
  } = useLocation();

  const [policyHolder, setPolicyHolder] = useRecoilState(policyHolderState);
  const [allTravelInputSuccess, setAllTravelInputSuccess] = useState(false);
  const [allPolicyInputSuccess, setAllPolicyInputSuccess] = useState(false);
  const [travelPlanSelected, setTravelPlanSelected] = useState(false);
  const [navLoading, setNavLoading] = useState(false);

  const allFieldsSuccess =
    allTravelInputSuccess && allPolicyInputSuccess && travelPlanSelected;

  const handleClick = () => {
    if (!allFieldsSuccess) {
      return;
    }
    // add the name field to policyHolder
    setPolicyHolder({
      ...policyHolder,
      name: `${policyHolder?.firstName} ${policyHolder.lastName}`
    });
    setNavLoading(true);
    setTimeout(() => {
      setNavLoading(false);
      nav('../passenger-details');
    }, 1000);
  };

  useEffect(() => {
    headingRef.current.scrollIntoView({ behavior: 'smooth' });
    console.log('state: ', insurancePackage);
  }, []);

  return (
    <div className='travel-details' ref={headingRef}>
      <div className='d-flex justify-content-between align-items-center'>
        <h1 className='' style={{ fontWeight: '600', fontSize: '3rem' }}>
          Travel Details
        </h1>
        <Back />
      </div>

      <div className='travel-details__container'>
        {/* policy holder details */}
        <Policy setAllPolicyInputSuccess={setAllPolicyInputSuccess} />

        {/* travel details */}
        <Travel setAllTravelInputSuccess={setAllTravelInputSuccess} />

        {/* select plan */}
        <Plan
          setTravelPlanSelected={setTravelPlanSelected}
          insurancePackage={insurancePackage}
        />

        <button
          className={`${
            allFieldsSuccess ? 'button-primary active' : 'inactive'
          } my-5 button`}
          onClick={handleClick}
          disabled={!allFieldsSuccess}
        >
          {navLoading ? (
            <Spinner color='primary' animation='border' role='status' />
          ) : (
            'Proceed'
          )}
        </button>
      </div>
    </div>
  );
};

export default TravelDetails;
