import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';

export default function UsersActiveProfile({
  user,
  hide,
  activateUser,
  loading
}) {
  const [reason, setReason] = useState('');
  return (
    <Modal
      show={user}
      onHide={hide}
      centered
      backdrop={loading ? 'static' : true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {!user?.enabled ? 'Activate' : 'Suspend'} {user?.name || 'User'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {!user?.enabled ? (
            <h4 className='px-2 py-3 text-danger'>
              Are your sure you want to enable disable
            </h4>
          ) : (
            <div>
              <h4 className='px-2 py-3 text-danger'>
                Why do you want to Suspend this User
              </h4>
              <div className='col-sm-12 p-0'>
                <textarea onChange={e => setReason(e.target.value)}>
                  {reason}
                </textarea>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='button-rounded button-rounded--outline'
          disabled={loading}
          onClick={hide}
        >
          Close
        </button>
        <button
          className='button-rounded main'
          onClick={() => activateUser(user, reason)}
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Update'}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
