export const productDescriptionMaps = {
  ECO4: {
    description:
      'Our Economy Plan is a basic cover and covers for all countries. This plan is suitable for travelers within the African sub regions, having a medical cover limit is $15,000.00',
    title: 'Economy Plan',
    name: 'Economy Plan',
    minDays: 1,
    maxDays: 730
  },
  FAMI6: {
    description:
      'Family holiday? Protect your family with our travel plan that covers overseas medical & more. This plan is cost-effective & caters for entire families travelling together. We offer half price for underage children and group discounts with a medical cover limit of $50,000.00.',
    title: 'Family Plan',
    name: 'Family Plan',
    minDays: 1,
    maxDays: 730
  },
  PEAR12: {
    description:
      'Our Pearl Plan covers for all countries except USA, Australia, Japan, and Canada with a medical cover limit of $50,000.00.',
    title: 'Pearl Plan',
    name: 'Pearl Plan',
    minDays: 1,
    maxDays: 730
  },
  PILB25: {
    description:
      'When considering Pilgrimage, it is important to plan properly for possessions, luggage and most of all, health. Our customized pilgrimage plan covers you! It is your ticket to a worry-free travel with a medical cover limit of $10,000 to $25,000. Protect your travel investment at an affordable group rate. Get Started',
    title: 'Pilgrimage Basic Plan',
    name: 'Pilgrimage Basic Plan',
    minDays: 1,
    maxDays: 45
  },
  PILX19: {
    description:
      'When considering Pilgrimage, it is important to plan properly for possessions, luggage and most of all, health. Our customized pilgrimage plan covers you! It is your ticket to a worry-free travel with a medical cover limit of $10,000 to $25,000. Protect your travel investment at an affordable group rate. Get Started',
    title: 'Pilgrimage Extra Plan',
    name: 'Pilgrimage Extra Plan',
    minDays: 1,
    maxDays: 45
  },
  PILP21: {
    description:
      'When considering Pilgrimage, it is important to plan properly for possessions, luggage and most of all, health. Our customized pilgrimage plan covers you! It is your ticket to a worry-free travel with a medical cover limit of $10,000 to $25,000. Protect your travel investment at an affordable group rate. Get Started',
    title: 'Pilgrimage Plus Plan',
    name: 'Pilgrimage Plus Plan',
    minDays: 1,
    maxDays: 45
  },
  SCH18: {
    description:
      'Our Europe Plan cover for all European countries and European states with a medical cover limit of EUR30,000.00',
    title: 'Europe Plan',
    name: 'Europe Plan',
    minDays: 1,
    maxDays: 730
  },
  SCCHNI: {
    description:
      'Our Schengen Only Plan cover for all Schengen countries and Schengen states with a medical cover limit of EUR30,000.00',
    title: 'Schengen Plan',
    name: 'Schengen Plan',
    minDays: 1,
    maxDays: 730
  },
  STUB5: {
    description:
      'When traveling for study abroad, students must protect themselves from the unexpected. Having our student insurance plan protects you from large financial losses that can occur from accidents or illness. With a medical cover limit of $60,000 to $100,000.',
    title: 'Student Classic Plan',
    name: 'Student Classic Plan',
    minDays: 1,
    maxDays: 365
  },
  STUEC: {
    description:
      'When traveling for study abroad, students must protect themselves from the unexpected. Having our student insurance plan protects you from large financial losses that can occur from accidents or illness. With a medical cover limit of $60,000 to $100,000.',
    title: 'Student Economy Plan',
    name: 'Student Economy Plan',
    minDays: 1,
    maxDays: 365
  },
  STUP8: {
    description:
      'When traveling for study abroad, students must protect themselves from the unexpected. Having our student insurance plan protects you from large financial losses that can occur from accidents or illness. With a medical cover limit of $60,000 to $100,000.',
    title: 'Student Premium Plan',
    name: 'Student Premium Plan',
    minDays: 1,
    maxDays: 365
  },
  TRAV10: {
    description:
      'Our Traveller Plan is a premium cover that provides coverage for all countries except USA, Australia, Japan, and Canada. With a medical cover limit of $150,000.00',
    title: 'Traveller Plan',
    name: 'Traveller Plan',
    minDays: 1,
    maxDays: 730
  }
};

export default function getProductDescription(product) {
  const key = product['dsSuffix'];

  return productDescriptionMaps[key];
}
