import React, { useEffect, useState } from 'react';
import Header from '../../components/commons/header';
import { checkValidations, ErrorMessage } from '../../utilities/use-validation';
import { schemas } from './login.schema';

import { useRecoilState } from 'recoil';
import { loginState, userTypeState } from '../../recoil/atoms';

import { baseUrl } from '../../constants';

import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import useLoginUser from '../../hooks/useLoginUser';
import { Link, useNavigate } from 'react-router-dom';
import localforage from 'localforage';
// import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import endpoints from '../../requests/endpoints';

const Login = () => {
  const nav = useNavigate();
  const [state, setState] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [login] = useRecoilState(loginState);
  const [, setUserType] = useRecoilState(userTypeState);

  const { updateLogin } = useLoginUser();

  // const loginUser = async () => {
  //   try {
  //     let res = await axios.post(endpoints.login.url);
  //     console.log('res: ', res);
  //     return res;
  //   } catch (error) {
  //     console.log('error: ', error);
  //   }
  // };

  // const mutation = useMutation({
  //   mutationFn: loginUser
  // });

  useEffect(() => {
    if (login) {
      if (login.first_login) {
        nav('/first-change-password');
      } else {
        nav('/dashboard');
      }
    }
    // eslint-disable-next-line
  }, [login]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${baseUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(state)
      });

      const res = await response.json();
      if (res?.errors) {
        toast.error(res.message);
        setState({ ...state, password: '' });
        return;
      }
      if (res?.error) {
        toast.error(res.error);
        setState({ ...state, password: '' });
        return;
      }

      if (res?.user?.user_type === 'staff') {
        setUserType('staff');
      }

      setLoading(false);
      updateLogin(res);
      toast.success('Login successful, Welcome');
      localforage.setItem('loginState', res);
      return;
    } catch (error) {
      toast.error(error || 'Incorrect email and/or password');
      setLoading(false);
    }

    // mutation.mutate(userDetails);

    login();
  };

  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });

    setErrors(errors);
    return { success };
  };

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...state, [name]: value };
    setState({ ...state, [name]: value });
    handleValidations(data, name);
  };

  return (
    <div className='login-page mb-5'>
      <Header />
      <form className='background login' onSubmit={handleSubmit}>
        <h1>Login</h1>
        <hr />

        <p>Welcome back. Please login to your account here.</p>

        <>
          <div className='formGroup2 mt-5 mb-4'>
            <input
              type='email'
              className='formGroup2__input'
              placeholder='Email'
              name='email'
              value={state.email}
              disabled={loading}
              noValidate
              autoComplete='false'
              autofill='false'
              onChange={handleChange}
            />
            <div className='mt-2'>
              <ErrorMessage message={errors?.email && errors.email.message} />
            </div>
          </div>
          <div className='formGroup2'>
            <div className='d-flex align-items-center'>
              <input
                type={showPassword ? 'text' : 'password'}
                className='formGroup2__input'
                placeholder='Password'
                name='password'
                value={state.password}
                disabled={loading}
                noValidate
                autoComplete='false'
                autofill='false'
                onChange={handleChange}
              />
              <div
                onClick={togglePasswordVisibility}
                className='formGroup3__input'
              >
                {/* {showPassword ? (
                  <FaRegEye
                    className='mr-2'
                    style={{ fontSize: '13px', cursor: 'pointer' }}
                  />
                ) : (
                  <FaRegEyeSlash
                    className='mr-2'
                    style={{ fontSize: '13px', cursor: 'pointer' }}
                  />
                )} */}
              </div>
            </div>

            <div className='mt-2'>
              <ErrorMessage
                message={errors?.password && errors.password.message}
              />
            </div>
          </div>
        </>

        <button disabled={loading} className='login__button mb-5'>
          {loading ? <Spinner animation='border' /> : 'Login'}
        </button>

        <Link to='/forgot-password'>Forgot password?</Link>
      </form>
    </div>
  );
};
export default Login;
