import { productDescriptionMaps } from './getProductDescription';

// const defaultPlans = [
//   {
//     name: 'Traveller',
//     description:
//       'This plan is suitable for travelers within the African sub regions. Cover limit is $15,000.00',
//     cover: 30000,
//     benefits: [
//       {
//         type: 'Medical',
//         list: [
//           {
//             description: 'Emergency dental care',
//             premiumLimit: 2000,
//             classicLimit: 1000
//           }
//         ]
//       },
//       {
//         type: 'Baggage',
//         list: [
//           {
//             description: 'Emergency dental care',
//             premiumLimit: 2000,
//             classicLimit: 1000
//           }
//         ]
//       }
//     ]
//   },
//   {
//     name: 'Economy',
//     description:
//       'This plan is suitable for travelers within the African sub regions. Cover limit is $15,000.00',
//     cover: 40000,
//     benefits: [
//       {
//         type: 'Medical',
//         list: [
//           {
//             description: 'Emergency dental care',
//             premiumLimit: 2000,
//             classicLimit: 1000
//           }
//         ]
//       },
//       {
//         type: 'Baggage',
//         list: [
//           {
//             description: 'Emergency dental care',
//             premiumLimit: 2000,
//             classicLimit: 1000
//           }
//         ]
//       }
//     ]
//   },
//   {
//     name: 'Europe',
//     description:
//       'This plan is suitable for travelers within the African sub regions. Cover limit is $15,000.00',
//     cover: 60000,
//     benefits: [
//       {
//         type: 'Medical',
//         list: [
//           {
//             description: 'Emergency dental care',
//             premiumLimit: 2000,
//             classicLimit: 1000
//           }
//         ]
//       },
//       {
//         type: 'Baggage',
//         list: [
//           {
//             description: 'Emergency dental care',
//             premiumLimit: 2000,
//             classicLimit: 1000
//           }
//         ]
//       }
//     ]
//   },
//   {
//     name: 'Pearl',
//     description:
//       'This plan is suitable for travelers within the African sub regions. Cover limit is $15,000.00',
//     cover: 30000,
//     benefits: [
//       {
//         type: 'Medical',
//         list: [
//           {
//             description: 'Emergency dental care',
//             premiumLimit: 2000,
//             classicLimit: 1000
//           }
//         ]
//       },
//       {
//         type: 'Baggage',
//         list: [
//           {
//             description: 'Emergency dental care',
//             premiumLimit: 2000,
//             classicLimit: 1000
//           }
//         ]
//       }
//     ]
//   }
// ];

const packages = [
  {
    id: 1,
    value: 'Student',
    name: 'Student',
    description:
      'For us this is just the beginning. We sustained by belief that the',
    coverLimit: 4,
    plans: [
      productDescriptionMaps.STUB5,
      productDescriptionMaps.STUEC,
      productDescriptionMaps.STUP8
    ]
  },
  {
    id: 2,
    value: 'Pilgrimage',
    name: 'Pilgrimage',
    description:
      'For us this is just the beginning. We sustained by belief that the',
    coverLimit: 4,
    plans: [
      productDescriptionMaps.PILB25,
      productDescriptionMaps.PILP21,
      productDescriptionMaps.PILX19
    ]
  },
  {
    id: 3,
    value: 'Schengen',
    name: 'Schengen',
    description:
      'For us this is just the beginning. We sustained by belief that the',
    coverLimit: 4,
    plans: [productDescriptionMaps.SCH18]
  },
  {
    id: 4,
    value: 'Worldwide',
    name: 'Worldwide',
    description:
      'For us this is just the beginning. We sustained by belief that the',
    coverLimit: 4,
    plans: [
      productDescriptionMaps.FAMI6,
      productDescriptionMaps.ECO4,
      productDescriptionMaps.PEAR12,
      productDescriptionMaps.TRAV10
    ]
  }
];

export default packages;
