import { useRecoilState } from 'recoil';
import {
  buyInsuranceScreenState,
  guardianDetailsState,
  travelDetailsState,
  nextOfKinDetailsState,
  passengerDetailsState,
  travelDetailFormState
} from '../../../recoil/atoms';

import TravelDetails from './TravelDetails/travel-details';
import InsuranceDetails from './InsuranceDetails/insurance-details';
import InsurancePayment from './InsurancePayment/insurance-payment';
import SelectPlan from './SelectPlan/select-plan';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const BuyInsurance = () => {
  const { subpath: purpose } = useParams();
  const nav = useNavigate();

  // eslint-disable-next-line
  const [guardianDetails, setGuardianDetails] =
    useRecoilState(guardianDetailsState);
  // eslint-disable-next-line
  const [travelDetails, setTravelDetails] = useRecoilState(travelDetailsState);
  // eslint-disable-next-line
  const [nextOfKinDetails, setNextOfKinDetails] = useRecoilState(
    nextOfKinDetailsState
  );
  // eslint-disable-next-line
  const [passengerDetails, setPassengerDetails] = useRecoilState(
    passengerDetailsState
  );
  // eslint-disable-next-line
  const [travelDetailForm, setTravelDetailForm] = useRecoilState(
    travelDetailFormState
  );

  const resetForms = () => {
    setGuardianDetails({});
    setNextOfKinDetails({});
    setPassengerDetails({});
    setTravelDetails({});
    setTravelDetailForm({});
    setActiveScreen(0);
  };

  useEffect(() => {
    if (!purpose) {
      nav('/dashboard/buy-insurance', { replace: true });
    }

    return resetForms;
    // eslint-disable-next-line
  }, []);

  const screens = [
    <TravelDetails />,
    <SelectPlan />,
    <InsuranceDetails />,
    <InsurancePayment />
  ];
  const [activeScreen, setActiveScreen] = useRecoilState(
    buyInsuranceScreenState
  );

  return <div className='buy-insurance container'>{screens[activeScreen]}</div>;
};

export default BuyInsurance;
