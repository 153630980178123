import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import {
  ErrorMessage,
  checkValidations
} from '../../../utilities/use-validation';
// import CustomDropdown from '../../../components/custom-dropdown';
import { createUserDataState, rolesState } from '../../../recoil/atoms';
import { useRecoilState } from 'recoil';
import { schemas as defaultSchemas } from './users.edit-profile-schema';
import { useEffect } from 'react';

export default function UsersCreateProfile({ hide, save }) {
  const [userData, setUserData] = useRecoilState(createUserDataState);
  const [roles] = useRecoilState(rolesState);
  const [errors, setErrors] = useState({});
  const [schemas, setSchemas] = useState(defaultSchemas);

  useEffect(() => {
    if (userData?.user_type === 'staff') {
      setSchemas({
        ...defaultSchemas,
        role_id: {
          required: true,
          label: 'Role'
        }
      });
    } else {
      setSchemas(defaultSchemas);
    }
  }, [userData]);

  useEffect(() => {
    setUserData({
      name: '',
      last_name: '',
      other_names: '',
      email: '',
      phone: '',
      user_type: '',
      role_id: ''
    });
    setErrors({});
    return () => {
      setUserData({});
      setErrors({});
    };
    // eslint-disable-next-line
  }, []);

  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleChange = event => {
    const { name, value } = event.target;
    const data = { ...userData, [name]: value };
    setUserData(data);
    handleValidations(data, name);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { success } = handleValidations({
      ...userData
    });

    if (success) {
      save();
    }
  };

  return (
    <Modal
      show={true}
      onHide={hide}
      centered
      backdrop={userData.loading ? 'static' : true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Create User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form action='' className=' px-3' onSubmit={handleSubmit}>
            <div className='col-sm-12 mt-3'>
              <label htmlFor='first-name'>Other Names</label>
              <input
                name='name'
                value={userData.name}
                onChange={handleChange}
                type='name'
                id='first-name'
                placeholder='Enter Other Names'
              />
              <ErrorMessage message={errors.name && errors.name.message} />
            </div>
            <div className='col-sm-12 mt-3'>
              <label htmlFor='last-name'>Surname</label>
              <input
                name='last_name'
                value={userData.last_name}
                onChange={handleChange}
                type='name'
                id='last-name'
                placeholder='Enter surname'
              />
              <ErrorMessage
                message={errors.last_name && errors.last_name.message}
              />
            </div>
            {/* <div className='col-sm-12 mt-3'>
              <label htmlFor='other-names'>Other Names</label>
              <input
                name='other_names'
                value={userData.other_names}
                onChange={handleChange}
                type='name'
                id='other-names'
                placeholder='Enter other name'
              />
              <ErrorMessage
                message={errors.other_names && errors.other_names.message}
              />
            </div> */}
            <div className='col-sm-12 mt-3'>
              <label htmlFor='email'>Email</label>
              <input
                name='email'
                value={userData.email}
                onChange={handleChange}
                id='email'
                type='email'
                placeholder='Enter email'
              />
              <ErrorMessage message={errors.email && errors.email.message} />
            </div>
            <div className='col-sm-12 mt-3 mb-3'>
              <label htmlFor='phone'>Phone Number</label>
              <input
                name='phone'
                value={userData.phone}
                onChange={e => {
                  e.target.value = e.target.value
                    .replace(/[^0-9+]/g, '')
                    .replace(/[+%]/g, function (match, offset, all) {
                      return match === '+'
                        ? all.indexOf('+') === offset
                          ? '+'
                          : ''
                        : '';
                    });
                  handleChange(e);
                }}
                id='phone'
                type='phone'
                placeholder='Enter phone number'
              />
              <ErrorMessage message={errors.phone && errors.phone.message} />
            </div>
            <div className='col-sm-12 mt-3'>
              <label htmlFor='address'>Address</label>
              <input
                name='address'
                value={userData.address}
                onChange={handleChange}
                id='address'
                type='text'
                placeholder='Enter address'
              />
              <ErrorMessage
                message={errors.address && errors.address.message}
              />
            </div>
            <div className='col-sm-12 mt-3'>
              <label htmlFor='phone'>User Type</label>
              <select name='user_type' onChange={handleChange}>
                <option value='' className='text-muted'>
                  Select User Type..
                </option>
                <option
                  value='customer'
                  selected={userData.user_type === 'customer'}
                >
                  Customer
                </option>
                <option value='staff' selected={userData.user_type === 'staff'}>
                  Staff
                </option>
              </select>
              <ErrorMessage
                message={errors.user_type && errors.user_type.message}
              />
            </div>
            {userData.user_type === 'staff' ? (
              <div className='col-sm-12 mt-3'>
                <label htmlFor='phone'>Role</label>
                <select name='role_id' onChange={handleChange}>
                  <option value='' className='text-muted'>
                    Select Role..
                  </option>
                  {roles &&
                    roles.map &&
                    roles
                      .filter(r => r.enabled)
                      .map(role => (
                        <option
                          key={role.id}
                          value={role.id}
                          selected={userData.role_id === role.id}
                        >
                          {role.name}
                        </option>
                      ))}
                </select>
                <ErrorMessage
                  message={errors.role_id && errors.role_id.message}
                />
              </div>
            ) : (
              ''
            )}
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='button-rounded button-rounded--outline'
          disabled={userData.loading}
          onClick={hide}
        >
          Close
        </button>
        <button
          className='button-rounded main'
          onClick={handleSubmit}
          disabled={userData.loading}
        >
          {userData.loading ? 'Saving...' : 'Save'}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
