import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { genders, titles, relationships } from '../../../constants';
import {
  quotesPageState,
  passengerDetailsState,
  fileState,
  userDataState,
  profileDataState,
  guardianDetailsState,
  nextOfKinDetailsState,
  travelDetailsState,
  insuranceDataState
} from '../../../recoil/atoms';
import {
  checkValidations,
  ErrorMessage
} from '../../../utilities/use-validation';
import { schemas } from './passenger-details.schema';
import { guardianSchema } from '../NextOfKinDetails/next-of-kin.schema';

import CustomDropdown from '../../../components/custom-dropdown';
import PassportUpload from '../PassportUpload/passport-upload';
import { toast } from 'react-toastify';
import { getAge } from '../../../utilities';
import plusIcon from '../../../assets/svg/plus.svg';
import collapseIcon from '../../../assets/svg/collapse.svg';

const PassengerDetails = () => {
  const [errors, setErrors] = useState({});
  const [guardianHeight, setGuardianHeight] = useState({});
  const [otherTitle, setOtherTitle] = useState(false);
  const [isMinor, setIsMinor] = useState(false);
  const [hasGuardian, setHasGuardian] = useState(false);

  const [activePage, setActivePage] = useRecoilState(quotesPageState);
  const [passengerDetails, setPassengerDetails] = useRecoilState(
    passengerDetailsState
  );
  const [file, setFile] = useRecoilState(fileState);
  const [user] = useRecoilState(userDataState);
  const [profileData, setProfileData] = useRecoilState(profileDataState);
  const [guardianDetails, setGuardianDetails] =
    useRecoilState(guardianDetailsState);
  const [nextOfKinDetails, setNextOfKinDetails] = useRecoilState(
    nextOfKinDetailsState
  );
  const [travelDetails] = useRecoilState(travelDetailsState);
  const [insuranceData, setInsuranceData] = useRecoilState(insuranceDataState);

  const guardianRef = useRef();
  const showGuardian = guardianHeight.initial > 0 || isMinor;

  useEffect(() => {
    if (
      passengerDetails.title &&
      (passengerDetails.title === 'others' ||
        !titles.find(t => t.title === passengerDetails.title))
    ) {
      setOtherTitle(true);
      if (passengerDetails.title === 'others') {
        setPassengerDetails({ ...passengerDetails, title: '' });
      }
    }
    // eslint-disable-next-line
  }, [passengerDetails?.title]);

  useEffect(() => {
    const guardian = guardianRef.current.clientHeight + 25;
    setGuardianHeight({ initial: 0, new: guardian });
  }, [isMinor]);

  useEffect(() => {
    if (isMinor) {
      setNextOfKinDetails({
        ...nextOfKinDetails,
        isPolicyHolder: !isMinor
      });
      setTimeout(handleGuardianToggle, 100);
    }
    // eslint-disable-next-line
  }, [isMinor]);

  useEffect(() => {
    const newIsMinor = getAge(passengerDetails.dateOfBirth) < 18;
    setIsMinor(newIsMinor);
  }, [passengerDetails.dateOfBirth]);

  const getPassenger = () => {
    return {
      name: passengerDetails.firstName,
      first_name: passengerDetails.firstName,
      last_name: passengerDetails.lastName,
      other_names: passengerDetails.otherNames,
      gender: passengerDetails.gender || 'N/A', //TODO: add to ui
      email: passengerDetails.email,
      phone: passengerDetails.phoneNumber,
      passport_no: passengerDetails.passportNumber,
      destination: travelDetails.destination,
      title: passengerDetails.title || '',
      age: getAge(passengerDetails.dateOfBirth),
      date_of_birth: passengerDetails.dateOfBirth,
      medical_cond: 'N/A',
      address: passengerDetails.address,
      nok_name: nextOfKinDetails.name,
      nok_relationship: nextOfKinDetails.relationship,
      nok_phone: nextOfKinDetails.phoneNumber,
      nok_email: nextOfKinDetails.email,
      nok_address: nextOfKinDetails.address,
      is_holder: nextOfKinDetails.isPolicyHolder && !isMinor,
      no_of_days: travelDetails.duration,
      purpose: travelDetails.purpose,
      from_date: travelDetails.startDate,
      to_date: travelDetails.endDate
    };
  };

  const getGuardian = () => ({
    name: guardianDetails.guardianFirstName,
    first_name: guardianDetails.guardianFirstName,
    last_name: guardianDetails.guardianLastName,
    other_names: guardianDetails.guardianOtherName,
    gender: guardianDetails.gender || 'N/A',
    address: guardianDetails.guardianAddress,
    phone: guardianDetails.guardianPhoneNumber,
    email: guardianDetails.guardianEmail,
    passport_no: guardianDetails.guardianPassportNumber,
    title: guardianDetails.title || '',
    age: getAge(guardianDetails.guardianDateOfBirth),
    date_of_birth: guardianDetails.guardianDateOfBirth,
    is_holder: isMinor || !nextOfKinDetails.isPolicyHolder,
    no_of_days: travelDetails.duration,
    purpose: travelDetails.purpose,
    from_date: travelDetails.startDate,
    to_date: travelDetails.endDate
  });

  const handleGuardianToggle = () => {
    setHasGuardian(!hasGuardian);
    if (guardianHeight.initial > 0) {
      setGuardianHeight({ initial: 0, new: guardianHeight.initial });
    } else {
      setGuardianHeight({ initial: guardianHeight.new });
    }
  };

  const handleValidations = (data, field) => {
    let { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });

    setErrors(errors);
    return { success };
  };

  const handleGuardianValidations = (data, field) => {
    const schemas =
      guardianHeight.initial > 0 || isMinor ? { ...{}, ...guardianSchema } : {};
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setPassengerDetails({ ...passengerDetails, [name]: value });
  };

  const handleChangeDropdown = (name, value) => {
    setPassengerDetails({
      ...passengerDetails,
      [name]: value
    });
    if (name === 'startDate') {
      setPassengerDetails({
        ...passengerDetails,
        endDate: '' //! TO-DO: confirm that this field was updated correctly
      });
    }
  };

  const handleGuardianChange = (name, value) => {
    setGuardianDetails({ ...guardianDetails, [name]: value });
    // handleGuardianValidations(data, name);
  };

  const formateMafreDate = date => {
    if (date) return date.split('-').reverse().join('/');
  };

  const saveInsuranceData = (
    start_date,
    end_date,
    duration,
    destination,
    policyHolder,
    insuredData
  ) => {
    let data = {
      duration,
      destination,
      idRegProduct: insuranceData?.idRegProduct || null,
      terms: 1,
      start_date: formateMafreDate(start_date),
      end_date: formateMafreDate(end_date),
      policyHolder,
      insuredData,
      deliveryDate: start_date
    };
    setInsuranceData(data);
  };

  const saveUserAndProceed = async () => {
    const allInsurers = [];

    const passenger = getPassenger();
    allInsurers.push(passenger);

    let policyHolder = passenger;
    const guardian = getGuardian();

    if (guardianHeight.initial > 0 || isMinor) {
      if (isMinor) policyHolder = guardian;
      allInsurers.push(guardian);
    }

    const attaches = allInsurers.filter(insurer => !insurer.is_holder);

    let passengerData = JSON.parse(JSON.stringify(passenger)); // this clones the passenger object and assigns the clone to passengerData
    passengerData['attaches'] = attaches;
    passengerData['guardian'] = guardian;

    passengerData['next_of_kin'] = {
      nok_name: nextOfKinDetails.name,
      nok_relationship: nextOfKinDetails.relationship,
      nok_phone: nextOfKinDetails.phoneNumber,
      nok_email: nextOfKinDetails.email,
      nok_address: nextOfKinDetails.address
    };

    saveInsuranceData(
      travelDetails.startDate,
      travelDetails.endDate,
      travelDetails.duration,
      travelDetails.destination,
      {
        ...policyHolder,
        date_of_birth: formateMafreDate(policyHolder.date_of_birth)
      },
      allInsurers.map(i => {
        i.date_of_birth = formateMafreDate(i.date_of_birth);
        return i;
      })
    );
    setProfileData({ ...profileData, ...passengerData });
  };

  const handleSubmit = event => {
    event.preventDefault();
    const passengerValidationResult = handleValidations({
      ...passengerDetails
    });

    if (passengerValidationResult.success) {
      // check if passengerDetails has email address, address and phone number
      // if any of these fields is missing,
      // get user profile data and add it to passengerDetails

      //this can only be done when user is logged in
      if (user && user.profile) {
        if (!passengerDetails.email) {
          setPassengerDetails({
            ...passengerDetails,
            email: user.profile?.email || ''
          });
        }
        if (!passengerDetails.phoneNumber) {
          setPassengerDetails({
            ...passengerDetails,
            phoneNumber: user.profile?.phone || ''
          });
        }
        if (!passengerDetails.address) {
          setPassengerDetails({
            ...passengerDetails,
            address: user.profile?.address || ''
          });
        }
      }

      if (!passengerDetails.name) {
        setPassengerDetails({
          ...passengerDetails,
          name: passengerDetails?.firstName
        });
      }

      setProfileData({
        ...profileData,
        ...passengerDetails
      });
      saveUserAndProceed();

      // run validation for guardian info?
      const guardianData =
        guardianHeight.initial > 0 || isMinor
          ? { ...nextOfKinDetails, ...guardianDetails }
          : nextOfKinDetails;

      const guardianValidationResult = handleGuardianValidations({
        ...guardianData
      });
      if (guardianValidationResult.error) {
        toast.error('An error occurred while validating the guardian details');
      }

      if (file) {
        toast.success('Passport uploaded successfully');
        let errorCount = 0;
        // if isMinor and guardian details not added, don't navigate to next page
        if (isMinor && !hasGuardian) {
          toast.error("You're quite young. Please include a guardian.");
          return;
        }
        if (hasGuardian) {
          // check that guardian details has all fields completed
          for (const key in guardianDetails) {
            if (key === 'title') continue;
            else if (!guardianDetails[key]) errorCount++;
          }
          if (errorCount > 1) {
            toast.error('Please fill all the fields in the guardian section.');
            return;
          }
        }
        if ((isMinor && hasGuardian && errorCount <= 1) || !isMinor) {
          setActivePage(activePage + 1);
        }
      } else {
        toast.error('File upload failed. Did you upload a passport?');
      }
    }
  };

  return (
    <div className='quote-details container'>
      <h1 className='quote-details__heading'>We need some details about you</h1>

      <form action='' className='row mt-5' onSubmit={handleSubmit}>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='first-name'>
            First Name <span className='text-danger'> *</span>
          </label>
          <input
            name='firstName'
            value={passengerDetails.firstName}
            onChange={handleChange}
            type='name'
            id='first-name'
            placeholder='Enter First Name'
          />
          <ErrorMessage
            message={errors.firstName && errors.firstName.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='last-name'>
            Surname <span className='text-danger'> *</span>
          </label>
          <input
            name='lastName'
            value={passengerDetails.lastName}
            onChange={handleChange}
            type='text'
            id='last-name'
            placeholder='Enter surname'
          />
          <ErrorMessage message={errors.lastName && errors.lastName.message} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='date-of-birth'>
            Date of Birth<span className='text-danger'> *</span>
          </label>
          <input
            name='dateOfBirth'
            value={passengerDetails.dateOfBirth}
            onChange={handleChange}
            id='date-of-birth'
            type='date'
            max={moment().format('YYYY-MM-DD')}
          />
          <ErrorMessage
            message={errors.dateOfBirth && errors.dateOfBirth.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='gender'>
            Gender <span className='text-danger'> *</span>
          </label>
          <CustomDropdown
            name='gender'
            onSelect={selection => {
              handleChangeDropdown('gender', selection.gender);
            }}
            identifier='gender'
            data={genders}
            inputId={`gender`}
            placeholder='Pick your gender'
            disable={false}
            showItems={true}
            value={{ gender: passengerDetails.gender }}
          />
          <ErrorMessage message={errors.gender && errors.gender.message} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='title'>Title</label>
          {!otherTitle ? (
            <CustomDropdown
              name='title'
              onSelect={selection => {
                handleChangeDropdown('title', selection.title);
              }}
              identifier='title'
              data={titles}
              inputId={`title`}
              placeholder='Pick your title'
              disable={false}
              showItems={true}
              value={{ title: passengerDetails.title }}
            />
          ) : (
            <input
              name='title'
              value={passengerDetails.title}
              onChange={handleChange}
              id='title'
              type='text'
              placeholder='Please enter title'
            />
          )}
          <ErrorMessage message={errors.title && errors.title.message} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='email'>Email</label>
          <input
            name='email'
            value={passengerDetails.email}
            onChange={handleChange}
            id='email'
            type='email'
            placeholder='Enter email'
          />
          <ErrorMessage message={errors.email && errors.email.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>Phone Number</label>
          <input
            name='phoneNumber'
            value={passengerDetails.phoneNumber}
            onChange={e => {
              e.target.value = e.target.value
                .replace(/[^0-9+]/g, '')
                .replace(/[+%]/g, function (match, offset, all) {
                  return match === '+'
                    ? all.indexOf('+') === offset
                      ? '+'
                      : ''
                    : '';
                });
              handleChange(e);
            }}
            id='phone'
            type='phone'
          />
          <ErrorMessage
            message={errors.phoneNumber && errors.phoneNumber.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='passport'>
            Passport Number<span className='text-danger'> *</span>
          </label>
          <input
            name='passportNumber'
            value={passengerDetails.passportNumber}
            onChange={handleChange}
            id='passport'
            type='passport'
          />
          <ErrorMessage
            message={errors.passportNumber && errors.passportNumber.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>Address</label>
          <input
            name='address'
            value={passengerDetails.address}
            onChange={handleChange}
            id='address'
            type='address'
          />
          <ErrorMessage message={errors.address && errors.address.message} />
        </div>

        <div className='col-sm-6'>
          <PassportUpload />
        </div>

        {/* Start Of The Gaurdian Section */}
        <div className='quote-details__options col-sm-12 quote-details__options--1'>
          <span onClick={() => !isMinor && handleGuardianToggle()}>
            {guardianHeight.initial > 0 ? (
              <span style={{ color: 'var(--color-primary)' }}>
                {'Remove guardian'}
              </span>
            ) : isMinor ? (
              'You are quite young, please add a guardian'
            ) : (
              'I want to include a guardian'
            )}
          </span>
          <img
            src={guardianHeight.initial > 0 ? collapseIcon : plusIcon}
            alt='expand'
            onClick={handleGuardianToggle}
            style={{ opacity: isMinor ? '0.5' : '1' }}
          />
        </div>

        <div
          className='quote-details__options__guardian row m-0'
          ref={guardianRef}
          style={{
            height: guardianHeight,
            visibility:
              !isMinor && !(guardianHeight.initial > 0) ? 'collapse' : 'visible'
          }}
        >
          <div className='col-sm-6 mt-sm-3 mt-5'>
            <label htmlFor='firstName'>
              First Name <span className='text-danger'> *</span>
            </label>
            <input
              name='firstName'
              value={guardianDetails.firstName}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              type='text'
              id='guardianFirstName'
              placeholder='Enter guardian first name'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={errors.firstName && errors.firstName.message}
            />
          </div>

          <div className='col-sm-6 mt-sm-3 mt-5'>
            <label htmlFor='lastName'>
              Surname <span className='text-danger'> *</span>
            </label>
            <input
              name='lastName'
              value={guardianDetails.lastName}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              type='text'
              id='guardianLastName'
              placeholder='Enter guardian surname'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={errors.lastName && errors.lastName.message}
            />
          </div>

          <div className='col-sm-6 mt-3'>
            <label htmlFor='gender'>
              Gender <span className='text-danger'> *</span>
            </label>
            <CustomDropdown
              name='gender'
              onSelect={selection => {
                handleGuardianChange('gender', selection.gender);
              }}
              identifier='gender'
              data={genders}
              inputId={`guardianGender`}
              placeholder='Pick your gender'
              disable={false}
              showItems={true}
              value={{ gender: guardianDetails.gender }}
            />
            <ErrorMessage message={errors.gender && errors.gender.message} />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='title'>Title</label>
            <CustomDropdown
              name='title'
              onSelect={selection => {
                handleGuardianChange('title', selection.title);
              }}
              identifier='title'
              data={titles}
              inputId={`guardianTitle`}
              placeholder='Pick your title'
              disable={false}
              showItems={true}
              value={{ title: guardianDetails.title }}
            />
            <ErrorMessage message={errors.title && errors.title.message} />
          </div>

          <div className='col-sm-6 mt-3'>
            <label htmlFor='email'>
              Email <span className='text-danger'> *</span>
            </label>
            <input
              name='email'
              value={guardianDetails.email}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              id='guardianEmail'
              type='email'
              placeholder='Enter guardian email'
              readOnly={!showGuardian}
            />
            <ErrorMessage message={errors.email && errors.email.message} />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='phoneNumber'>
              Phone Number <span className='text-danger'> *</span>
            </label>
            <input
              name='phoneNumber'
              value={guardianDetails.phoneNumber}
              onChange={event =>
                handleGuardianChange(
                  event.target.name,
                  event.target.value.replace(/[^0-9.]/g, '')
                )
              }
              id='guardianPhone'
              type='phone'
              placeholder='Enter guardian phone'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={errors.phoneNumber && errors.phoneNumber.message}
            />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='dateOfBirth'>
              Date of Birth <span className='text-danger'> *</span>
            </label>
            <input
              max={moment().subtract(18, 'years').format('YYYY-DD-MM')}
              name='dateOfBirth'
              value={guardianDetails.dateOfBirth}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              id='guardianDateOfBirth'
              type='date'
              placeholder='enter guardian address'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={errors.dateOfBirth && errors.dateOfBirth.message}
            />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='passportNumber'>
              Passport Number <span className='text-danger'> *</span>
            </label>
            <input
              name='passportNumber'
              value={guardianDetails.passportNumber}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              id='guardianPassport'
              type='text'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={errors.passportNumber && errors.passportNumber.message}
            />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='address'>
              Address <span className='text-danger'> *</span>
            </label>
            <input
              name='address'
              value={guardianDetails.address}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              id='guardianAddress'
              type='address'
              placeholder='enter guardian address'
              readOnly={!showGuardian}
            />
            <ErrorMessage message={errors.address && errors.address.message} />
          </div>

          <div className='col-sm-6 mt-3'>
            <label htmlFor='guardian-relationship'>
              Relationship <span className='text-danger'> *</span>
            </label>
            <CustomDropdown
              name='guardian-relationship'
              onSelect={selection => {
                handleGuardianChange('relationship', selection.relationship);
              }}
              identifier='relationship'
              data={relationships}
              inputId={`guardian-relationship`}
              placeholder='Pick relationship'
              disable={false}
              showItems={true}
              value={{ relationship: guardianDetails.relationship }}
              readOnly={!showGuardian}
            />

            <div className='mb-4'>
              <ErrorMessage
                message={errors.relationship && errors.relationship.message}
              />
            </div>
          </div>
        </div>

        <div className='quote-details__buttons col-sm-12 mt-5'>
          <button
            type='button'
            className='button-rounded button-rounded--outline'
            onClick={() => activePage !== 0 && setActivePage(0)}
          >
            Back
          </button>
          <button className='button-rounded' type='submit'>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default PassengerDetails;
