import { useRecoilState } from 'recoil';
import {
  nextOfKinDetailsState,
  policyHolderState,
  travelDetailsState
} from '../../../../../recoil/atoms';
import formateMafreDate from '../../../../../helpers/formateMafreDate';
import { getAge } from '../../../../../utilities';

export default function useGetPolicyHolder() {
  const [policyHolder] = useRecoilState(policyHolderState);
  const [travelDetails] = useRecoilState(travelDetailsState);
  const [nextOfKinDetails] = useRecoilState(nextOfKinDetailsState);

  return {
    name: `${policyHolder?.firstName} ${policyHolder?.lastName}`,
    email: policyHolder?.email,
    phone: policyHolder?.phoneNumber,
    address: policyHolder?.address,
    destination: travelDetails.destination,
    no_of_days: travelDetails.duration,
    purpose: travelDetails.purpose,
    from_date: travelDetails.startDate,
    to_date: travelDetails.endDate,
    first_name: policyHolder?.firstName,
    last_name: policyHolder?.lastName,
    other_names: policyHolder?.otherNames,
    gender: policyHolder?.gender || 'N/A',
    passport_no: policyHolder?.passportNumber,
    age: getAge(policyHolder?.dateOfBirth),
    date_of_birth: formateMafreDate(policyHolder?.dateOfBirth),
    title: policyHolder?.title || '',
    medical_cond: 'N/A',
    //! ---------------- TO-DO: find out if these info are necessary
    nok_name: nextOfKinDetails?.name || '',
    nok_relationship: nextOfKinDetails?.relationship || '',
    nok_phone: nextOfKinDetails?.phoneNumber || '',
    nok_email: nextOfKinDetails?.email || '',
    nok_address: nextOfKinDetails?.address || '',
    is_holder: nextOfKinDetails?.isPolicyHolder || ''
    //! -----------------
  };
}
