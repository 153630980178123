import React from 'react';
import { useState, useEffect } from 'react';

export default function Search({
  data,
  fields,
  setOutput,
  filter,
  filterLabel,
  filterOptions,
  className
}) {
  const [textInput, setTextInput] = useState('');
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [data]);

  const search = () => {
    let filteredData = [...data];
    if (textInput && fields && fields.length) {
      filteredData = filteredData.filter(d => {
        let valid = false;
        for (let i = 0; i < fields.length; i++) {
          let field = '';
          if (typeof fields[i] === 'string') {
            field = d[fields[i]];
          }

          if (typeof fields[i] === 'object') {
            if (typeof fields[i].key === 'string') {
              field = d[fields[i].key];
            }

            if (typeof fields[i].key === 'function') {
              console.debug('compute', fields[i].key(d));
              field = fields[i].key(d);
            }
          }

          field = field || '';

          if (
            field.toString().toLowerCase().includes(textInput.toLowerCase())
          ) {
            valid = true;
            break;
          }
        }
        return valid;
      });
    }

    if (filter) {
      filteredData = filteredData.filter(d => d[filter].includes(filterValue));
    }
    return setOutput(filteredData);
  };

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [filterValue]);

  return (
    <>
      <div className={'searchable ' + className}>
        <input onChange={e => setTextInput(e.target.value)} />
        <button onClick={search}>Search</button>
      </div>
      {filter && (
        <div className={'searchable ' + className}>
          <div className=''>
            <label>Filter By {filterLabel}</label>
            <select
              onChange={e => setFilterValue(e.target.value)}
              className='form-control'
            >
              <option value=''>All</option>
              {Object.keys(filterOptions).map(opt => (
                <option value={opt}>{filterOptions[opt]}</option>
              ))}
            </select>
          </div>
        </div>
      )}
    </>
  );
}
