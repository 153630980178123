import React from 'react';
import { useNavigate } from 'react-router-dom';

const Back = () => {
  const nav = useNavigate();

  return (
    <p
      onClick={() => nav(-1)}
      className='align-self-end'
      style={{ fontSize: '1.5rem', cursor: 'pointer', color: 'blue' }}
    >
      Back
    </p>
  );
};

export default Back;
