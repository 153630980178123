import InsurancePlan from './InsurancePlan/insurance-plan';

const BuyInsurance = () => {
  return (
    <div className='buy-insurance container'>
      <InsurancePlan heading='Please choose your plan' />
    </div>
  );
};

export default BuyInsurance;
