export const schemas = {
  firstName: {
    required: true,
    label: 'Other Names'
  },
  lastName: {
    required: true,
    label: 'Surname'
  },
  otherNames: {
    required: false,
    label: 'Surname'
  },
  dateOfBirth: {
    required: true,
    label: 'Date of Birth'
  },
  email: {
    required: true,
    inputType: 'email',
    label: 'Email'
  },
  phoneNumber: {
    required: true,
    maxLength: 15,
    minLength: 9,
    maxWords: 1,
    label: 'Phone Number'
  },
  address: {
    required: true,
    maxLength: 45,
    label: 'Address'
  },
  passportNumber: {
    required: true,
    minLength: 3,
    maxLength: 20,
    label: 'Passport Number'
  },
  gender: {
    required: true,
    label: 'Gender'
  },
  title: {
    required: false,
    label: 'Title'
  }
};
