import React from 'react';
import {
  AiFillTwitterSquare,
  AiOutlineFacebook,
  AiOutlineLinkedin,
  AiOutlineInstagram
} from 'react-icons/ai';

const Footer = () => {
  return (
    <div className='certificate-container__footer'>
      <div className='footer-copyright'>
        <p>&copy; {new Date().getFullYear()} CHI Plc. All Rights Reserved</p>
      </div>

      <div className='footer-container'>
        <ul className='footer-links'>
          <li>
            <a href='https://twitter.com/MyChiplc/'>
              <AiFillTwitterSquare />
            </a>
          </li>
          <li>
            <a href='https://facebook.com/mychiplc'>
              <AiOutlineFacebook />
            </a>
          </li>
          <li>
            <a href='https://www.linkedin.com/company/consolidated-hallmark-insurance-plc/'>
              <AiOutlineLinkedin />
            </a>
          </li>
          <li>
            <a href='https://instagram.com/mychiplc?igshid=YzAwZjE1ZTI0Zg=='>
              <AiOutlineInstagram />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
