import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

import {
  travelDetailsState,
  passengerDetailsState,
  guardianDetailsState,
  clearFieldsState,
  formSubmittedState,
  allDataState,
  fileState,
  selectedProductState,
  insuranceDataState,
  profileDataState,
  finalSubmitState,
  headerState,
  travelDetailFormState,
  nextOfKinDetailsState
} from '../../../recoil/atoms';
import List from '../../../components/commons/List';
import AllInOneForm from './AllInOneForm';
import buildGibsProfileData from './helpers/buildGibsProfileData';
import { baseUrl, GibbsApiKey } from '../../../constants';
import { getAge } from '../../../utilities';

const BatchHome = () => {
  const nav = useNavigate();
  const {
    state: { insurancePackage }
  } = useLocation();

  const [travelDetails] = useRecoilState(travelDetailsState);
  const [passengerDetails] = useRecoilState(passengerDetailsState);
  const [guardianDetails] = useRecoilState(guardianDetailsState);
  const [, setClearFields] = useRecoilState(clearFieldsState);
  const [formSubmitted, setFormSubmitted] = useRecoilState(formSubmittedState);
  const [file] = useRecoilState(fileState);
  const [selectedProduct] = useRecoilState(selectedProductState);
  const [allData, setAllData] = useRecoilState(allDataState);
  const [insuranceData] = useRecoilState(insuranceDataState);
  const [profileData, setProfileData] = useRecoilState(profileDataState);
  const [finalSubmit, setFinalSubmit] = useRecoilState(finalSubmitState);
  const [headers] = useRecoilState(headerState);

  const [, setTravelDetailForm] = useRecoilState(travelDetailFormState);
  const [nextOfKinDetails, setNextOfKinDetails] = useRecoilState(
    nextOfKinDetailsState
  );
  const [updating, setUpdating] = useState(false);
  const [hasBeenAdded, setHasBeenAdded] = useState({});
  const [loading, setLoading] = useState(false);

  const [fieldsSuccess, setFieldsSuccess] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [buyingForMultiple, setBuyingForMultiple] = useState(false);

  const updateState = () => {
    let key = passengerDetails?.passportNumber;

    if (key && !hasBeenAdded[key]) {
      console.log('performing state update...');
      setUpdating(true);

      let currentData = {
        travelDetails,
        passengerDetails,
        guardianDetails,
        file,
        selectedProduct,
        insuranceData,
        profileData,
        nextOfKinDetails,
        price: 0
      };
      console.log('currentData: ', currentData);
      //! TO-DO: check that currentData has non-empty values
      setAllData([...allData, currentData]);
      setHasBeenAdded({
        ...hasBeenAdded,
        [key]: true
      });

      setTimeout(() => {
        setUpdating(false);
      }, 2000);

      return [...allData, currentData];
    } else {
      // no currentData (form wasn't filled)
      if (allData?.length && allData[0]?.passportNumber) {
        // there is at least one user, so proceed
      } else {
        // no data, throw error
        toast.error('No data. Please fill form.');
        // throw new Error('No data. Please fill form.');
      }
    }
    console.log(
      `User: ${passengerDetails?.firstName} ${passengerDetails?.lastName} with passport number: ${passengerDetails?.passportNumber} has been previously added to state.`
    );
  };

  useEffect(() => {
    if (allData?.length) {
      console.log('allData: ', allData);
      setBuyingForMultiple(true);
      toast.info(
        'Check list at bottom of page to edit or delete previous data.'
      );
    }
  }, [allData]);

  const saveTravelAndProceed = async (
    profileData,
    product,
    passengerDetails,
    nextOfKinDetails
  ) => {
    console.log('nextOfKinDetails: ', nextOfKinDetails);

    try {
      const res = await fetch(`${baseUrl}/users/create`, {
        method: 'POST',
        body: JSON.stringify({
          ...profileData,
          product: product.dsProduct
        }),
        headers
      });

      const data = await res.json();

      if (data.user_id && data.travel_id) {
        let travelDetailForm = {
          customer_name:
            passengerDetails.firstName + ' ' + passengerDetails.lastName,
          user_id: data.user_id,
          travel_id: data.travel_id,
          email: passengerDetails.email,
          phone: passengerDetails.phoneNumber,
          from_date: travelDetails.startDate,
          to_date: travelDetails.endDate,
          duration: travelDetails.duration,
          age: getAge(passengerDetails.dateOfBirth),
          destination: travelDetails.destination,
          marketer: nextOfKinDetails?.marketer || 'N/A',
          gibssTransactionData: buildGibsProfileData(
            profileData,
            GibbsApiKey,
            product
          )
        };

        return travelDetailForm;
      } else {
        throw data.message;
      }
    } catch (error) {
      console.log('error: ', error);
      return;
    }
  };

  useEffect(() => {
    if (formSubmitted) {
      const updatedAllData = updateState();

      // reset state values
      setClearFields(true);
      setSubmit(false);
      setFormSubmitted(false);

      if (finalSubmit) {
        // ! create accounts for all customers
        if (updatedAllData?.length) {
          const fetch = async arr => {
            if (!arr.length) {
              throw new Error('allData is empty');
            } else {
              return Promise.allSettled(
                arr.map(
                  async ({
                    profileData,
                    selectedProduct,
                    passengerDetails,
                    nextOfKinDetails
                  }) => {
                    try {
                      const response = await saveTravelAndProceed(
                        profileData,
                        selectedProduct,
                        passengerDetails,
                        nextOfKinDetails
                      );
                      return response;
                    } catch (error) {
                      throw error;
                    }
                  }
                )
              );
            }
          };

          fetch(updatedAllData)
            .then(data => {
              data.map((el, id) => {
                if (el.status === 'fulfilled') {
                  const { value } = el;

                  setAllData(prevData =>
                    prevData.map((dt, indx) => {
                      if (indx === id) {
                        return { ...dt, travelDetailForm: value };
                      }
                      return dt;
                    })
                  );
                }
                if (el.status === 'rejected') {
                  throw new Error(el.reason);
                }
              });
            })
            .catch(err => {
              console.log('error during account creation: ', err);
              toast.error(err);
              return;
            });

          // reset state value
          setFinalSubmit(false);

          //!TO-DO: check that allData has been collated correctly before navigating to next screen

          // if (createdCount !== allData.length) {
          // toast.error('Not all customers have had their accounts created.');
          // } else {
          if (!updating) {
            setLoading(true);
            setTimeout(() => {
              setLoading(false);
              nav('product-details');
            }, 3000);
          }
          // }
        }
      }
    }
  }, [allData, formSubmitted, finalSubmit]);

  const handleMultiple = () => {
    setSubmit(true);
    setBuyingForMultiple(true); // displays list of userData at bottom of page
  };

  const handleSubmit = () => {
    setFormSubmitted(true);
    setSubmit(true); // trigger bulkSubmit in AllInOneForm
    setFinalSubmit(true);
  };

  return (
    <div className='w-100 d-flex flex-column justify-content-between p-3'>
      <AllInOneForm
        submit={submit}
        setFieldsSuccess={setFieldsSuccess}
        buyingForMultiple={buyingForMultiple}
        insurancePackage={insurancePackage}
      />

      <div className='d-flex flex-column justify-content-between gap-6'>
        <div className='d-flex justify-content-between my-5'>
          <button
            className={
              fieldsSuccess
                ? 'button-rounded button-rounded--outline'
                : 'inactive'
            }
            onClick={handleMultiple}
            disabled={!fieldsSuccess}
          >
            Buy More
          </button>

          <button
            className={fieldsSuccess ? 'button-rounded active' : 'inactive'}
            onClick={handleSubmit}
            disabled={!fieldsSuccess}
          >
            Submit
          </button>
        </div>

        {buyingForMultiple && (
          <List setBuyingForMultiple={setBuyingForMultiple} />
        )}
      </div>
    </div>
  );
};

export default BatchHome;
