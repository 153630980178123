import { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { quotesPageState, travelDetailsState } from '../../../../recoil/atoms';
import CustomDropdown from '../../../../components/custom-dropdown';
import {
  checkValidations,
  ErrorMessage
} from '../../../../utilities/use-validation';
import { schemas } from './travel-details.schema';
import moment from 'moment';
import { packages } from '../../../../constants';
import { useNavigate } from 'react-router-dom';
import useCountries from '../../../../hooks/useCountries';

const TravelDetails = () => {
  const nav = useNavigate();
  const [travelDetails, setTravelDetails] = useRecoilState(travelDetailsState);
  const [activePage, setActivePage] = useRecoilState(quotesPageState);
  const [errors, setErrors] = useState({});
  const { countries } = useCountries();
  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleChange = (name, value) => {
    const data = { ...travelDetails, [name]: value };
    if (name === 'startDate') data.endDate = '';
    setTravelDetails(data);
    handleValidations(data, name);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { success } = handleValidations({
      ...travelDetails
    });

    if (success) {
      setActivePage(activePage + 1);
    }
  };

  useEffect(() => {
    getDateDifference(travelDetails.startDate, travelDetails.endDate);
    // eslint-disable-next-line
  }, [travelDetails.startDate, travelDetails.endDate]);

  const getDateDifference = (startDate, endDate) => {
    const difference = Math.floor(
      (new Date(endDate) - new Date(startDate)) / 86400000
    );
    handleChange('duration', difference + 1);
  };

  return (
    <div className='quote-details container'>
      <h1 className='quote-details__heading'>
        Please give us some info about your travel
      </h1>

      <form action='' className='row mt-5' onSubmit={handleSubmit}>
        <div className='col-sm-6'>
          <label htmlFor='destination'>
            Destination <span className='text-danger'> *</span>
          </label>
          <CustomDropdown
            name='destination'
            onSelect={selection => {
              handleChange('destination', selection.cd);
            }}
            identifier='ds'
            data={countries}
            inputId={`destination`}
            placeholder='Pick your destination'
            disable={false}
            showItems={true}
            value={{ destination: travelDetails.destination }}
          />
          <ErrorMessage
            message={errors.destination && errors.destination.message}
          />
        </div>

        <div className='col-sm-6'>
          <label htmlFor='purpose'>
            Purpose <span className='text-danger'> *</span>
          </label>
          <CustomDropdown
            name='purpose'
            onSelect={selection => {
              handleChange('purpose', selection.value);
            }}
            identifier='name'
            data={packages}
            inputId={`purpose`}
            placeholder='Pick your purpose'
            disable={false}
            showItems={true}
            value={{ name: travelDetails.purpose }}
          />
          <ErrorMessage message={errors.purpose && errors.purpose.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='destination'>
            Start Date <span className='text-danger'> *</span>
          </label>
          <input
            type='date'
            name='startDate'
            min={moment().format('YYYY-MM-DD')}
            value={travelDetails.startDate}
            onChange={event => handleChange('startDate', event.target.value)}
          />
          <ErrorMessage
            message={errors.startDate && errors.startDate.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='destination'>
            End Date <span className='text-danger'> *</span>
          </label>
          <input
            type='date'
            name='endDate'
            min={moment().format('YYYY-MM-DD')}
            value={travelDetails.endDate}
            onChange={event => handleChange('endDate', event.target.value)}
          />
          <ErrorMessage message={errors.endDate && errors.endDate.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='destination'>Duration</label>
          <input
            type='text'
            readOnly
            disabled={
              !(!isNaN(travelDetails.duration) && travelDetails.duration)
            }
            value={
              !isNaN(travelDetails.duration) && travelDetails.duration
                ? `${travelDetails.duration} day(s)`
                : 'Set start and end date'
            }
          />
          <ErrorMessage
            message={
              travelDetails.startDate &&
              travelDetails.endDate &&
              travelDetails.duration <= 0 &&
              'End date should be later than start date'
            }
          />
        </div>

        <div className='quote-details__buttons  col-sm-12 mt-5'>
          <button
            type='button'
            className='button-rounded button-rounded--outline'
            onClick={() =>
              activePage !== 0 ? setActivePage(activePage - 1) : nav(-1)
            }
          >
            Back
          </button>
          <button className='button-rounded'>Next</button>
        </div>
      </form>
    </div>
  );
};

export default TravelDetails;
