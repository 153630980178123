import moment from 'moment';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { genders, titles } from '../../../../constants';
import {
  quotesPageState,
  passengerDetailsState
} from '../../../../recoil/atoms';
import {
  checkValidations,
  ErrorMessage
} from '../../../../utilities/use-validation';
import { schemas } from './passenger-details.schema';
import CustomDropdown from '../../../../components/custom-dropdown';

const PassengerDetails = () => {
  const [activePage, setActivePage] = useRecoilState(quotesPageState);
  const [errors, setErrors] = useState({});
  const [passengerDetails, setPassengerDetails] = useRecoilState(
    passengerDetailsState
  );

  const handleValidations = (data, field) => {
    let { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });

    setErrors(errors);
    return { success };
  };

  const handleChange = event => {
    const { name, value } = event.target;
    const data = { ...passengerDetails, [name]: value };
    setPassengerDetails(data);
    handleValidations(data, name);
  };

  const handleChangeDropdown = (name, value) => {
    const data = { ...passengerDetails, [name]: value };
    if (name === 'startDate') data.endDate = '';
    setPassengerDetails(data);
    handleValidations(data, name);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { success } = handleValidations({
      ...passengerDetails
    });

    if (success) {
      setActivePage(activePage + 1);
    }
  };

  const [otherTitle, setOtherTitle] = useState(false);
  useEffect(() => {
    if (
      passengerDetails.title &&
      (passengerDetails.title === 'others' ||
        !titles.find(t => t.title === passengerDetails.title))
    ) {
      setOtherTitle(true);
      if (passengerDetails.title === 'others') {
        setPassengerDetails({ ...passengerDetails, title: '' });
      }
    }
    // eslint-disable-next-line
  }, [passengerDetails?.title]);

  return (
    <div className='quote-details container'>
      <h1 className='quote-details__heading'>We need some details about you</h1>

      <form action='' className='row mt-5' onSubmit={handleSubmit}>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='first-name'>
          Other Names <span className='text-danger'> *</span>
          </label>
          <input
            name='firstName'
            value={passengerDetails.firstName}
            onChange={handleChange}
            type='name'
            id='first-name'
            placeholder='Enter Other Names'
          />
          <ErrorMessage
            message={errors.firstName && errors.firstName.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='last-name'>
            Surname <span className='text-danger'> *</span>
          </label>
          <input
            name='lastName'
            value={passengerDetails.lastName}
            onChange={handleChange}
            type='name'
            id='last-name'
            placeholder='Enter surname'
          />
          <ErrorMessage message={errors.lastName && errors.lastName.message} />
        </div>
        {/* <div className='col-sm-6 mt-3'>
          <label htmlFor='other-names'>Other Names </label>
          <input
            name='otherNames'
            value={passengerDetails.otherNames}
            onChange={handleChange}
            type='name'
            id='other-names'
            placeholder='Enter other names'
          />
          <ErrorMessage
            message={errors.otherNames && errors.otherNames.message}
          />
        </div> */}
        <div className='col-sm-6 mt-3'>
          <label htmlFor='date-of-birth'>
            Date of Birth <span className='text-danger'> *</span>
          </label>
          <input
            name='dateOfBirth'
            value={passengerDetails.dateOfBirth}
            onChange={handleChange}
            id='date-of-birth'
            type='date'
            max={moment().format('YYYY-MM-DD')}
          />
          <ErrorMessage
            message={errors.dateOfBirth && errors.dateOfBirth.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='gender'>
            Gender <span className='text-danger'> *</span>
          </label>
          <CustomDropdown
            name='gender'
            onSelect={selection => {
              handleChangeDropdown('gender', selection.gender);
            }}
            identifier='gender'
            data={genders}
            inputId={`gender`}
            placeholder='Pick your gender'
            disable={false}
            showItems={true}
            value={{ gender: passengerDetails.gender }}
          />
          <ErrorMessage message={errors.gender && errors.gender.message} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='title'>Title</label>
          {!otherTitle ? (
            <CustomDropdown
              name='title'
              onSelect={selection => {
                handleChangeDropdown('title', selection.title);
              }}
              identifier='title'
              data={titles}
              inputId={`title`}
              placeholder='Pick your title'
              disable={false}
              showItems={true}
              value={{ title: passengerDetails.title }}
            />
          ) : (
            <input
              name='title'
              value={passengerDetails.title}
              onChange={handleChange}
              id='title'
              type='text'
              placeholder='Please enter title'
            />
          )}
          <ErrorMessage message={errors.title && errors.title.message} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='email'>
            Email <span className='text-danger'> *</span>
          </label>
          <input
            name='email'
            value={passengerDetails.email}
            onChange={handleChange}
            id='email'
            type='email'
            placeholder='Enter email'
          />
          <ErrorMessage message={errors.email && errors.email.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>
            Phone Number <span className='text-danger'> *</span>
          </label>
          <input
            name='phoneNumber'
            value={passengerDetails.phoneNumber}
            onChange={e => {
              e.target.value = e.target.value
                .replace(/[^0-9+]/g, '')
                .replace(/[+%]/g, function (match, offset, all) {
                  return match === '+'
                    ? all.indexOf('+') === offset
                      ? '+'
                      : ''
                    : '';
                });
              handleChange(e);
            }}
            id='phone'
            type='phone'
          />
          <ErrorMessage
            message={errors.phoneNumber && errors.phoneNumber.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='passport'>
            Passport Number <span className='text-danger'> *</span>
          </label>
          <input
            name='passportNumber'
            value={passengerDetails.passportNumber}
            onChange={handleChange}
            id='passport'
            type='passport'
          />
          <ErrorMessage
            message={errors.passportNumber && errors.passportNumber.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>
            Address <span className='text-danger'> *</span>
          </label>
          <input
            name='address'
            value={passengerDetails.address}
            onChange={handleChange}
            id='address'
            type='address'
          />
          <ErrorMessage message={errors.address && errors.address.message} />
        </div>
        <div className='quote-details__buttons col-sm-12 mt-5'>
          <button
            type='button'
            className='button-rounded button-rounded--outline'
            onClick={() => activePage !== 0 && setActivePage(0)}
          >
            Back
          </button>
          <button className='button-rounded'>Next</button>
        </div>
      </form>
    </div>
  );
};

export default PassengerDetails;
