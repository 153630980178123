import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import moment from 'moment';
import {
  travelDetailsState,
  clearFieldsState,
  currentPackageState,
  currentPlanState,
  allDataState,
  formIndexState
} from '../../../../recoil/atoms';
import CustomDropdown from '../../../../components/custom-dropdown';
import { schemas } from '../../TravelDetails/travel-details.schema';

import {
  checkValidations,
  ErrorMessage
} from '../../../../utilities/use-validation';
import useCountries from '../../../../hooks/useCountries';
import { packages } from '../../../../constants';

const TravelDetails = ({ setAllTravelDetailsInputSuccess }) => {
  const [travelDetails, setTravelDetails] = useRecoilState(travelDetailsState);
  const [clearFields] = useRecoilState(clearFieldsState);
  const [currentPackage] = useRecoilState(currentPackageState);
  const [currentPlan] = useRecoilState(currentPlanState);
  const [allData] = useRecoilState(allDataState);
  const [formIndex] = useRecoilState(formIndexState);

  const [errors, setErrors] = useState({});
  const [successMap, setSuccessMap] = useState({});

  const { countries } = useCountries();

  const numberOfRequiredInputFields = 5;
  useEffect(() => {
    const trueKeys = Object.keys(successMap).filter(
      key => successMap[key] === true
    );

    if (trueKeys?.length)
      setAllTravelDetailsInputSuccess(
        trueKeys.length >= numberOfRequiredInputFields
      );
  }, [successMap]);

  useEffect(() => {
    getDateDifference(travelDetails.startDate, travelDetails.endDate);
    // eslint-disable-next-line
  }, [travelDetails.startDate, travelDetails.endDate]);

  useEffect(() => {
    handleChange('purpose', currentPackage);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (clearFields) {
      setTravelDetails({});
    }
  }, [clearFields]);

  const getDateDifference = (startDate, endDate) => {
    const difference = Math.floor(
      (new Date(endDate) - new Date(startDate)) / 86400000
    );
    handleChange('duration', difference + 1);
  };

  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleChange = (name, value) => {
    const data = { ...travelDetails, [name]: value };
    if (name === 'startDate') data.endDate = '';
    const { success } = handleValidations(data, name);
    setTravelDetails(data);
    setSuccessMap({ ...successMap, [name]: success });
  };

  return (
    <div className=''>
      <h1 className=''>Travel Details</h1>

      <div className='row'>
        <div className='col-sm-6'>
          <label htmlFor='destination'>
            Destination <span className='text-danger'> *</span>
          </label>
          <CustomDropdown
            name='destination'
            onSelect={selection => {
              handleChange('destination', selection.cd);
            }}
            identifier='ds'
            data={countries}
            inputId={`destination`}
            placeholder='Pick your destination'
            disable={false}
            showItems={true}
            value={{
              destination: travelDetails.destination || ''
            }}
          />
          <ErrorMessage
            message={errors.destination && errors.destination.message}
          />
        </div>

        <div className='col-sm-6'>
          <label htmlFor='purpose'>
            Purpose <span className='text-danger'> *</span>
          </label>
          <CustomDropdown
            name='purpose'
            onSelect={selection => handleChange('purpose', selection?.purpose)}
            identifier='purpose'
            data={[
              { purpose: packages[0]?.name },
              { purpose: packages[1]?.name },
              { purpose: packages[2]?.name },
              { purpose: packages[3]?.name }
            ]}
            inputId={`purpose`}
            placeholder='Purpose'
            disable={false}
            showItems={true}
            value={travelDetails?.purpose || ''}
          />
          <ErrorMessage message={errors.purpose && errors.purpose.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='startDate'>
            Start Date <span className='text-danger'> *</span>
          </label>
          <input
            type='date'
            name='startDate'
            min={moment().format('YYYY-MM-DD')}
            value={travelDetails.startDate || ''}
            onChange={event => handleChange('startDate', event.target.value)}
          />
          <ErrorMessage
            message={errors.startDate && errors.startDate.message}
          />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='destination'>
            End Date <span className='text-danger'> *</span>
          </label>
          <input
            type='date'
            name='endDate'
            min={moment().format('YYYY-MM-DD')}
            value={travelDetails.endDate || ''}
            onChange={event => handleChange('endDate', event.target.value)}
          />
          <ErrorMessage message={errors.endDate && errors.endDate.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='destination'>
            Duration <span className='text-danger'> *</span>
          </label>
          <input
            type='text'
            readOnly
            disabled={
              !(!isNaN(travelDetails.duration) && travelDetails.duration)
            }
            value={
              !isNaN(travelDetails.duration) && travelDetails.duration
                ? `${travelDetails.duration} day(s)`
                : 'Set start and end date'
            }
          />
          <ErrorMessage
            message={
              travelDetails.startDate &&
              travelDetails.endDate &&
              travelDetails.duration <= 0 &&
              'End date should be later than start date'
            }
          />
        </div>
      </div>
    </div>
  );
};

export default TravelDetails;
