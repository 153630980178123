import { useRef, useEffect, useState, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import {
  quotesPageState,
  nextOfKinDetailsState,
  guardianDetailsState,
  passengerDetailsState,
  travelDetailsState,
  insuranceDataState,
  profileDataState
} from '../../../../recoil/atoms';
import CustomDropdown from '../../../../components/custom-dropdown';
import plusIcon from '../../../../assets/svg/plus.svg';
import collapseIcon from '../../../../assets/svg/collapse.svg';
import {
  checkValidations,
  ErrorMessage
} from '../../../../utilities/use-validation';
import { getAge } from '../../../../utilities';
import { guardianSchema } from './next-of-kin.schema';
import moment from 'moment';
import { baseUrl, relationships, genders, titles } from '../../../../constants';
import { Spinner } from 'react-bootstrap';
import localforage from 'localforage';

const NextOfKinDetails = () => {
  const [activePage, setActivePage] = useRecoilState(quotesPageState);
  const [errors, setErrors] = useState({});
  const [marketers, setMarketers] = useState([]);
  const [guardianHeight, setGuardianHeight] = useState({});
  const [nextOfKinDetails, setNextOfKinDetails] = useRecoilState(
    nextOfKinDetailsState
  );
  const [, setProfileData_state] = useRecoilState(profileDataState);

  const [guardianDetails, setGuardianDetails] =
    useRecoilState(guardianDetailsState);
  // eslint-disable-next-line

  const [insuranceData, setInsuranceData] = useRecoilState(insuranceDataState);
  const guardianRef = useRef();

  const [passengerDetails] = useRecoilState(passengerDetailsState);
  const [travelDetails] = useRecoilState(travelDetailsState);

  const [loading, setLoading] = useState(false);

  const handleValidations = (data, field) => {
    const schemas = guardianHeight.initial > 0 || isMinor ? guardianSchema : {};
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleNextOfKinChange = (name, value) => {
    const data = { ...nextOfKinDetails, [name]: value };
    setNextOfKinDetails(data);
    handleValidations(data, name);
  };

  const handleGuardianChange = (name, value) => {
    const data = { ...guardianDetails, [name]: value };
    setGuardianDetails(data);
    handleValidations(data, name);
  };

  const handleGuardianToggle = () => {
    if (guardianHeight.initial > 0) {
      setGuardianHeight({ initial: 0, new: guardianHeight.initial });
    } else {
      setGuardianHeight({ initial: guardianHeight.new });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    const data =
      guardianHeight.initial > 0 || isMinor
        ? { ...nextOfKinDetails, ...guardianDetails }
        : nextOfKinDetails;

    const { success } = handleValidations(data);

    if (success) {
      saveUserAndProceed();
    }
  };

  // const buildGibsProfileData = profileData => {
  //   return {
  //     Apikey: GibbsApiKey,
  //     PolicyStartDate: profileData.from_date,
  //     PolicyEndDate: profileData.to_date,
  //     TravelPurpose: profileData.purpose,
  //     Destination: profileData.destination,
  //     ContactAddress: profileData.address,
  //     LastName: profileData.last_name,
  //     FirstName: profileData.first_name,
  //     Gender: profileData.gender,
  //     Title: profileData.title,
  //     PassportNo: profileData.passport_no,
  //     PhoneNo: profileData.phone,
  //     Email: profileData.email,
  //     DOB: profileData.date_of_birth,
  //     NoK_Name: profileData.next_of_kin.nok_name,
  //     NoK_Relationship: profileData.next_of_kin.nok_relationship,
  //     NoK_Address: profileData.next_of_kin.nok_address,
  //     NoK_Email: profileData.next_of_kin.nok_email,
  //     NoK_PhoneNo: profileData.next_of_kin.nok_phone,
  //     CoverType: product.dsProduct
  //   };
  // };

  const formateMafreDate = _date => {
    return _date.split('-').reverse().join('/');
  };

  const saveInsuranceData = (
    start_date,
    end_date,
    duration,
    destination,
    policyHolder,
    insuredData
  ) => {
    let data = {
      duration,
      destination,
      idRegProduct: insuranceData?.idRegProduct || null,
      terms: 1,
      start_date: formateMafreDate(start_date),
      end_date: formateMafreDate(end_date),
      policyHolder,
      insuredData,
      deliveryDate: start_date
    };
    setInsuranceData(data);
  };

  const getPassenger = () => {
    return {
      name: passengerDetails.firstName,
      first_name: passengerDetails.firstName,
      last_name: passengerDetails.lastName,
      other_names: passengerDetails.otherNames,
      gender: passengerDetails.gender || 'N/A', //TODO: add to ui
      email: passengerDetails.email,
      phone: passengerDetails.phoneNumber,
      passport_no: passengerDetails.passportNumber,
      destination: travelDetails.destination,
      title: passengerDetails.title || '',
      age: getAge(passengerDetails.dateOfBirth),
      date_of_birth: passengerDetails.dateOfBirth,
      medical_cond: 'N/A',
      address: passengerDetails.address,
      nok_name: nextOfKinDetails.name,
      nok_relationship: nextOfKinDetails.relationship,
      nok_phone: nextOfKinDetails.phoneNumber,
      nok_email: nextOfKinDetails.email,
      nok_address: nextOfKinDetails.address,
      is_holder: nextOfKinDetails.isPolicyHolder && !isMinor,
      no_of_days: travelDetails.duration,
      purpose: travelDetails.purpose,
      from_date: travelDetails.startDate,
      to_date: travelDetails.endDate
    };
  };

  const getGuardian = () => ({
    name: guardianDetails.guardianFirstName,
    first_name: guardianDetails.guardianFirstName,
    last_name: guardianDetails.guardianLastName,
    other_names: guardianDetails.guardianOtherName,
    gender: guardianDetails.gender || 'N/A',
    address: guardianDetails.guardianAddress,
    phone: guardianDetails.guardianPhoneNumber,
    email: guardianDetails.guardianEmail,
    passport_no: guardianDetails.guardianPassportNumber,
    title: guardianDetails.title || '',
    age: getAge(guardianDetails.guardianDateOfBirth),
    date_of_birth: guardianDetails.guardianDateOfBirth,
    is_holder: isMinor || !nextOfKinDetails.isPolicyHolder,
    no_of_days: travelDetails.duration,
    purpose: travelDetails.purpose,
    from_date: travelDetails.startDate,
    to_date: travelDetails.endDate
  });

  const saveUserAndProceed = async () => {
    setLoading(true);

    const allInsurers = [];

    const passenger = getPassenger();

    allInsurers.push(passenger);

    let policyHolder = passenger;
    const guardian = getGuardian();

    if (guardianHeight.initial > 0 || isMinor) {
      if (isMinor) policyHolder = guardian;
      allInsurers.push(guardian);
    }

    const attaches = allInsurers.filter(insurer => {
      return !insurer.is_holder;
    });

    let profileData = JSON.parse(JSON.stringify(passenger));
    profileData['attaches'] = attaches;
    profileData['guardian'] = guardian;
    profileData['next_of_kin'] = {
      nok_name: nextOfKinDetails.name,
      nok_relationship: nextOfKinDetails.relationship,
      nok_phone: nextOfKinDetails.phoneNumber,
      nok_email: nextOfKinDetails.email,
      nok_address: nextOfKinDetails.address
    };

    saveInsuranceData(
      travelDetails.startDate,
      travelDetails.endDate,
      travelDetails.duration,
      travelDetails.destination,
      {
        ...policyHolder,
        date_of_birth: formateMafreDate(policyHolder.date_of_birth)
      },
      allInsurers.map(i => {
        i.date_of_birth = formateMafreDate(i.date_of_birth);
        return i;
      })
    );

    // if (travelDetailForm && travelDetailForm.travel_id) {
    //   profileData.travel_id = travelDetailForm.travel_id;
    // }

    setProfileData_state(profileData);
    setActivePage(activePage + 1);

    // users/create
    // try {
    //   const res = await fetch(`${baseUrl}/users/create`, {
    //     method: 'POST',
    //     body: JSON.stringify(profileData),
    //     headers
    //   });

    //   const data = await res.json();

    //   if (data.user_id && data.travel_id) {
    //     let travelDetailForm = {
    //       customer_name:
    //         passengerDetails.firstName + ' ' + passengerDetails.lastName,
    //       user_id: data.user_id,
    //       travel_id: data.travel_id,
    //       email: passengerDetails.email,
    //       phone: passengerDetails.phoneNumber,
    //       from_date: travelDetails.startDate,
    //       to_date: travelDetails.endDate,
    //       duration: travelDetails.duration,
    //       age: getAge(guardianDetails.guardianDateOfBirth),
    //       destination: travelDetails.destination,
    //       marketer: nextOfKinDetails.marketer || 'N/A',
    //       gibssTransactionData: buildGibsProfileData(profileData)
    //     };

    //     setTravelDetailForm(travelDetailForm);
    //     setActivePage(activePage + 1);
    //   } else {
    //     toast.error('An error occurred, please try again');
    //   }
    // } catch (error) {
    //   console.error(error);
    //   toast.error('An error occurred, please try again');
    // }

    setLoading(false);
  };

  const isMinor = useMemo(
    () => getAge(passengerDetails.dateOfBirth) < 18,
    [passengerDetails.dateOfBirth]
  );

  const fetchMarketers = async () => {
    const marketers = localforage.getItem('marketers');
    if (marketers) setMarketers(marketers);
    const res = await fetch(`${baseUrl}/marketers`);
    const data = await res.json();
    if (res.status === 200) {
      data.Marketer.forEach(d => {
        d.full_name = `${d.FirstName} ${d.LastName}`;
      });
      setMarketers(data.Marketer);
      localforage.setItem('marketers', data.Marketer);
    }
  };

  useEffect(() => {
    if (isMinor) {
      setNextOfKinDetails({
        ...nextOfKinDetails,
        isPolicyHolder: !isMinor
      });
      setTimeout(handleGuardianToggle, 100);
    }
    // eslint-disable-next-line
  }, [isMinor]);

  useEffect(() => {
    const guardian = guardianRef.current.clientHeight + 25;
    setGuardianHeight({ initial: 0, new: guardian });
    fetchMarketers();
    document.querySelector('.quote-details__heading').scrollIntoView();
  }, [isMinor]);

  const showGuardian = guardianHeight.initial > 0 || isMinor;

  const [otherTitle, setOtherTitle] = useState(false);
  useEffect(() => {
    if (
      guardianDetails.title &&
      (guardianDetails.title === 'others' ||
        !titles.find(t => t.title === guardianDetails.title))
    ) {
      setOtherTitle(true);
      if (guardianDetails.title === 'others') {
        setGuardianDetails({ ...guardianDetails, title: '' });
      }
    }
    // eslint-disable-next-line
  }, [guardianDetails?.title]);

  return (
    <div className='quote-details container'>
      <h1 className='quote-details__heading'>Guardian Details</h1>

      <form action='' className='row mt-5' onSubmit={handleSubmit}>
        {/* <div className='col-sm-6 mt-3'>
          <label htmlFor='name'>
            Full Name <span className='text-danger'> *</span>
          </label>
          <input
            name='name'
            value={nextOfKinDetails.name}
            onChange={event =>
              handleNextOfKinChange(event.target.name, event.target.value)
            }
            type='name'
            id='name'
            placeholder='Enter next of kin name'
          />
          <ErrorMessage message={errors.name && errors.name.message} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='email'>
            Email <span className='text-danger'> *</span>
          </label>
          <input
            name='email'
            value={nextOfKinDetails.email}
            onChange={event =>
              handleNextOfKinChange(event.target.name, event.target.value)
            }
            id='email'
            type='email'
            placeholder='Enter next of kin email'
          />
          <ErrorMessage message={errors.email && errors.email.message} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>
            Phone Number <span className='text-danger'> *</span>
          </label>
          <input
            name='phoneNumber'
            value={nextOfKinDetails.phoneNumber}
            onChange={e => {
              e.target.value = e.target.value
                .replace(/[^0-9+]/g, '')
                .replace(/[+%]/g, function (match, offset, all) {
                  return match === '+'
                    ? all.indexOf('+') === offset
                      ? '+'
                      : ''
                    : '';
                });
              handleNextOfKinChange(e.target.name, e.target.value);
            }}
            id='phone'
            type='phone'
            placeholder='Enter next of kin phone'
          />
          <ErrorMessage
            message={errors.phoneNumber && errors.phoneNumber.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>
            Address <span className='text-danger'> *</span>
          </label>
          <input
            name='address'
            value={nextOfKinDetails.address}
            onChange={event =>
              handleNextOfKinChange(event.target.name, event.target.value)
            }
            id='address'
            type='address'
            placeholder='enter next of kin address'
          />
          <ErrorMessage message={errors.address && errors.address.message} />
        </div>
        <div className='col-sm-6 mt-3 mb-2'>
          <label htmlFor='relationship'>
            Relationship <span className='text-danger'> *</span>
          </label>
          <CustomDropdown
            name='relationship'
            onSelect={selection => {
              handleNextOfKinChange('relationship', selection.relationship);
            }}
            identifier='relationship'
            data={relationships}
            inputId={`relationship`}
            placeholder='Pick relationship'
            disable={false}
            showItems={true}
            value={{ relationship: nextOfKinDetails.relationship }}
          />
          <ErrorMessage
            message={errors.relationship && errors.relationship.message}
          />
        </div> */}
        <div className='quote-details__options col-sm-12 quote-details__options--1'>
          <span onClick={() => !isMinor && handleGuardianToggle()}>
            {guardianHeight.initial > 0 ? (
              <span style={{ color: 'var(--color-primary)' }}>
                {'Remove guardian'}
              </span>
            ) : isMinor ? (
              'You are quiet young, please add a guardian'
            ) : (
              'I want to include a guardian'
            )}
          </span>
          <img
            src={guardianHeight.initial > 0 ? collapseIcon : plusIcon}
            alt='expand'
            onClick={handleGuardianToggle}
            style={{ opacity: isMinor ? '0.5' : '1' }}
          />
        </div>

        <div
          className='quote-details__options__guardian row m-0'
          ref={guardianRef}
          style={{
            height: guardianHeight.initial,
            overflow: !isMinor && !(guardianHeight.initial > 0) ? 'hidden' : ''
          }}
        >
          <div className='col-sm-6 mt-sm-3 mt-5'>
            <label htmlFor='name'>
            Other Names <span className='text-danger'> *</span>
            </label>
            <input
              name='guardianFirstName'
              value={guardianDetails.guardianFirstName}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              type='name'
              id='name'
              placeholder='Enter Guardian Other Names'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={
                errors.guardianFirstName && errors.guardianFirstName.message
              }
            />
          </div>

          <div className='col-sm-6 mt-sm-3 mt-5'>
            <label htmlFor='name'>
              Surname <span className='text-danger'> *</span>
            </label>
            <input
              name='guardianLastName'
              value={guardianDetails.guardianLastName}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              type='name'
              id='name'
              placeholder='Enter guardian surname'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={
                errors.guardianLastName && errors.guardianLastName.message
              }
            />
          </div>

          {/* <div className='col-sm-6 mt-3'>
            <label htmlFor='name'>
              Other Name <span className='text-danger'> *</span>
            </label>
            <input
              name='guardianOtherName'
              value={guardianDetails.guardianOtherName}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              type='text'
              id='guardianOtherName'
              placeholder='Enter guardian other name'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={
                errors.guardianOtherName && errors.guardianOtherName.message
              }
            />
          </div> */}

          <div className='col-sm-6 mt-3'>
            <label htmlFor='gender'>
              Gender <span className='text-danger'> *</span>
            </label>
            <CustomDropdown
              name='gender'
              onSelect={selection => {
                handleGuardianChange('gender', selection.gender);
              }}
              identifier='gender'
              data={genders}
              inputId={`gender`}
              placeholder='Pick your gender'
              disable={false}
              showItems={true}
              value={{ gender: guardianDetails.gender }}
            />
            <ErrorMessage message={errors.gender && errors.gender.message} />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='title'>Title</label>
            {!otherTitle ? (
              <CustomDropdown
                name='title'
                onSelect={selection => {
                  handleGuardianChange('title', selection.title);
                }}
                identifier='title'
                data={titles}
                inputId={`title`}
                placeholder='Pick your title'
                disable={false}
                showItems={true}
                value={{ title: guardianDetails.title }}
              />
            ) : (
              <input
                name='title'
                value={guardianDetails.title}
                onChange={e => handleGuardianChange('title', e.target.value)}
                id='title'
                type='text'
                placeholder='Please enter title'
              />
            )}
            <ErrorMessage message={errors.title && errors.title.message} />
          </div>

          <div className='col-sm-6 mt-3'>
            <label htmlFor='email'>
              Email <span className='text-danger'> *</span>
            </label>
            <input
              name='guardianEmail'
              value={guardianDetails.guardianEmail}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              id='email'
              type='email'
              placeholder='Enter guardian email'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={errors.guardianEmail && errors.guardianEmail.message}
            />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='phone'>
              Phone Number <span className='text-danger'> *</span>
            </label>
            <input
              name='guardianPhoneNumber'
              value={guardianDetails.guardianPhoneNumber}
              onChange={event =>
                handleGuardianChange(
                  event.target.name,
                  event.target.value?.replace(/[^0-9.]/g, '')
                )
              }
              id='phone'
              type='phone'
              placeholder='Enter guardian phone'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={
                errors.guardianPhoneNumber && errors.guardianPhoneNumber.message
              }
            />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='phone'>
              Date of Birth <span className='text-danger'> *</span>
            </label>
            <input
              max={moment().subtract(18, 'years').format('YYYY-DD-MM')}
              name='guardianDateOfBirth'
              value={guardianDetails.guardianDateOfBirth}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              id='guardianDateOfBirth'
              type='date'
              placeholder='enter guardian address'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={
                errors.guardianDateOfBirth && errors.guardianDateOfBirth.message
              }
            />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='passport'>
              Passport Number <span className='text-danger'> *</span>
            </label>
            <input
              name='guardianPassportNumber'
              value={guardianDetails.guardianPassportNumber}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              id='passport'
              type='passport'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={
                errors.guardianPassportNumber &&
                errors.guardianPassportNumber.message
              }
            />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='phone'>
              Address <span className='text-danger'> *</span>
            </label>
            <input
              name='guardianAddress'
              value={guardianDetails.guardianAddress}
              onChange={event =>
                handleGuardianChange(event.target.name, event.target.value)
              }
              id='address'
              type='address'
              placeholder='enter guardian address'
              readOnly={!showGuardian}
            />
            <ErrorMessage
              message={errors.guardianAddress && errors.guardianAddress.message}
            />
          </div>

          <div className='col-sm-6 mt-3'>
            <label htmlFor='guardian-relationship'>
              Relationship <span className='text-danger'> *</span>
            </label>
            <CustomDropdown
              name='relationship'
              onSelect={selection => {
                handleGuardianChange(
                  'guardianRelationship',
                  selection.relationship
                );
              }}
              identifier='relationship'
              data={relationships}
              inputId={`guardian-relationship`}
              placeholder='Pick relationship'
              disable={false}
              showItems={true}
              value={{ relationship: guardianDetails.guardianRelationship }}
              readOnly={!showGuardian}
            />

            <div className='mb-4'>
              <ErrorMessage
                message={
                  errors.guardianRelationship &&
                  errors.guardianRelationship.message
                }
              />
            </div>
          </div>
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='referred'>I was referred by a marketer</label>
          <select
            onChange={e => {
              setNextOfKinDetails({
                ...nextOfKinDetails,
                isReferred: e.target.value
              });
            }}
            value={nextOfKinDetails.isReferred}
          >
            <option value='Internet'>Internet </option>
            <option value='Social Media'>Social Media </option>
            <option value='Friends/Associate'>Friends/Associate </option>
            <option value='Agency/Marketers'>Agency/Marketers </option>
          </select>
        </div>
        {nextOfKinDetails.isReferred === 'Agency/Marketers' && (
          <div className='col-sm-6 mt-3'>
            <label className='w-100' htmlFor='guardian-relationship'>
              Marketer
            </label>
            <CustomDropdown
              name='marketer'
              onSelect={selection => {
                handleNextOfKinChange('marketer', selection);
              }}
              identifier='full_name'
              data={marketers}
              inputId={`marketer`}
              placeholder='Pick marketer'
              disable={false}
              showItems={true}
              value={nextOfKinDetails.marketer}
            />

            <div className='mb-3'>
              <ErrorMessage
                message={errors.marketer && errors.marketer.message}
              />
            </div>
          </div>
        )}
        {false && (
          <div className='quote-details__options quote-details__options--3 mb-5'>
            <div className='checkboxGroup'>
              <input
                type='checkbox'
                id='policy-holder'
                checked={nextOfKinDetails.isPolicyHolder && !isMinor}
                onChange={event => {
                  const { checked } = event.target;
                  setNextOfKinDetails({
                    ...nextOfKinDetails,
                    isPolicyHolder: checked
                  });
                }}
              />
              <label htmlFor='policy-holder'>I am the policyholder</label>
            </div>

            <div className='checkboxGroup'>
              <input
                type='checkbox'
                id='travel-group'
                checked={nextOfKinDetails.isInGroup}
                onChange={event => {
                  const { checked } = event.target;
                  setNextOfKinDetails({
                    ...nextOfKinDetails,
                    isInGroup: checked
                  });
                }}
              />
              <label htmlFor='travel-group'>I am traveling in a group</label>
            </div>
          </div>
        )}
        <div className='quote-details__buttons col-sm-12 mt-5'>
          <button
            type='button'
            className='button-rounded button-rounded--outline'
            onClick={() => activePage !== 0 && setActivePage(activePage - 1)}
          >
            Back
          </button>
          <button disabled={loading} className='button-rounded'>
            {loading ? (
              <>
                <Spinner animation='border' /> Loading...
              </>
            ) : (
              'Save'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NextOfKinDetails;
