import alertIcon from '../../assets/svg/alert.svg';
import avatar from '../../assets/image/avatar.png';
// import chevron from '../../assets/svg/chevron.svg';
import { NavLink } from 'react-router-dom';
import useLoginUser from '../../hooks/useLoginUser';
import { useRecoilState } from 'recoil';
import { dashboardMenuState } from '../../recoil/atoms';

const DashboardHeader = ({ title }) => {
  const { login } = useLoginUser();

  // eslint-disable-next-line
  const [menu, setMenu] = useRecoilState(dashboardMenuState);

  return (
    <nav className='dashboard-header container my-3'>
      <div className='d-flex align-items-center'>
        <div
          className='dashboard-header__menu-toggle show-mobile'
          onClick={() => setMenu(true)}
        >
          <svg
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 512 512'
          >
            <g>
              <g>
                <path d='M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z' />
              </g>
            </g>
            <g>
              <g>
                <path d='M492,76H20C8.954,76,0,84.954,0,96s8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,76,492,76z' />
              </g>
            </g>
            <g>
              <g>
                <path d='M492,396H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20C512,404.954,503.046,396,492,396z' />
              </g>
            </g>
          </svg>
        </div>
        <NavLink to='/dashboard'>
          <h1 className='mb-0 dashboard-header__title'>{title}</h1>
        </NavLink>
      </div>

      <div className='dashboard-header__menu'>
        <img src={alertIcon} alt='alert' />

        <NavLink to='/dashboard/profile' style={{ display: 'contents' }}>
          <figure className='dashboard-header__menu__avatar'>
            {login?.avatar ? (
              <img src={avatar} alt='avatar' />
            ) : (
              <span className='text'>{login?.name[0]}</span>
            )}
          </figure>

          <span className='hide-mobile dashboard-header__menu__user'>
            {login?.name}
          </span>
          {/* <img src={chevron} alt='drop down' /> */}
        </NavLink>
      </div>
    </nav>
  );
};

export default DashboardHeader;
