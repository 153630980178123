export const schemas = {
    old_password: {
        required: true,
        label: 'Current Password'
    },
    password: {
        required: true,
        minLength: 6,
        label: 'Password'
    },
    password_confirmation: {
        required: true,
        minLength: 6,
        label: 'Confirm Password'
    },
};
