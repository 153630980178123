import React, { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

import {
  currentPackageState,
  travelDetailFormState,
  selectedProductState,
  policyHolderState,
  insuranceDataState,
  travelDetailsState,
  priceDataState
} from '../../../recoil/atoms';
import { baseUrl } from '../../../constants';
import Back from '../../../components/commons/Back';
import generateConfig from '../../../helpers/generateConfig';

export default function GroupPayment() {
  const [currentPackage] = useRecoilState(currentPackageState);
  // const [priceInfo] = useRecoilState(priceInformationState);
  const [travelDetails] = useRecoilState(travelDetailsState);
  const [travelDetailForm] = useRecoilState(travelDetailFormState);
  const [policyHolder] = useRecoilState(policyHolderState);
  const [product] = useRecoilState(selectedProductState);
  const [insuranceData] = useRecoilState(insuranceDataState);
  const [selectedProduct] = useRecoilState(selectedProductState);

  const priceData = useRecoilValue(priceDataState);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [retry, setRetry] = useState(false);
  const [success, setSuccess] = useState(false);
  const [config, setConfig] = useState({});

  const makePayment = async arr => {
    try {
      const res = await fetch(`${baseUrl}/users/travel/batch-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(arr)
      });
      if (res.status === 500) {
        return { data: res?.statusText };
      }
      return res.json();
    } catch (error) {
      console.log('error: ', error);
      return error;
    }
  };

  useEffect(() => {
    setLoading(true);

    const price = priceData?.root?.premiumBreakdown?.startPrice || '0.00';
    const conf = generateConfig(policyHolder, price, product);
    if (conf?.error) {
      console.log('error: ', conf.error);
      return;
    }
    //! Note: config is an array because the API expects an array of config objects
    const config = [
      {
        ...conf,
        insurance_data: insuranceData,
        travel_detail: travelDetailForm,
        product: selectedProduct?.dsProduct,
        price,
        purpose: travelDetails?.purpose
      }
    ];

    setConfig(config);

    try {
      setLoading(true);
      async function fn() {
        const data = await makePayment(config);
        console.log('data from makePayment: ', data);
        if (data) {
          if (data?.data === 'success') {
            console.log('result of batch payment operation: ', data);
            setSuccess(true);
          } else {
            console.log('data.data: ', data?.data);
            if (data?.data) toast.error(data.data);
            setIsError(true);
            setError('Payment failed. Please retry.'); //generic error message
          }
        } else {
          setIsError(true);
          setError('An error occurred.');
        }
      }

      fn();
    } catch (error) {
      console.log('error during batch payment: ', error);
      setIsError(true);
      setError(error);
      toast.error(error);
    } finally {
      setLoading(false);
    }
  }, [retry]);

  return (
    <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center batch-payment text-center'>
      <Back />

      {loading && <Spinner color='primary' animation='border' role='status' />}

      {isError && (
        <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center gap-4'>
          <p className='' style={{ fontSize: '2rem' }}>
            {error}
          </p>
          <button className='button-rounded' onClick={() => setRetry(true)}>
            Retry
          </button>
        </div>
      )}

      {success && (
        <main className='d-flex flex-column align-items-center justify-content-center gap-4'>
          <h1 className='py-4'>Successful Transaction</h1>
          <h2 className='py-4'>
            Payment links have been generated and sent to customers.
          </h2>
          <img
            className='w-50 h-55'
            src='https://einsurance.chiplc.com/assets/images/success-icon.png'
            alt='CHI Success Logo'
          />
        </main>
      )}
    </div>
  );
}
