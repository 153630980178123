import { useRecoilState } from 'recoil';
import {
  buyInsuranceScreenState,
  guardianDetailsState,
  travelDetailsState,
  nextOfKinDetailsState,
  passengerDetailsState,
  travelDetailFormState
} from '../../../recoil/atoms';

import TravelDetails from './TravelDetails/travel-details';
import InsuranceDetails from './InsuranceDetails/insurance-details';
import InsurancePayment from './InsurancePayment/insurance-payment';
import SelectPlan from './SelectPlan/select-plan';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import RetryLoader from '../../../components/commons/retryLoader';
import { toast } from 'react-toastify';
import { baseUrl } from '../../../constants';
import useLoginUser from '../../../hooks/useLoginUser';

const BuyInsurance = () => {
  const { updateMapKey, headers } = useLoginUser();

  const resetForms = () => {
    setGuardianDetails({});
    setNextOfKinDetails({});
    setPassengerDetails({});
    setTravelDetails({});
    setTravelDetailForm({});
  };

  const [, setGuardianDetails] = useRecoilState(guardianDetailsState);
  const [, setTravelDetails] = useRecoilState(travelDetailsState);
  const [, setNextOfKinDetails] = useRecoilState(nextOfKinDetailsState);
  const [, setPassengerDetails] = useRecoilState(passengerDetailsState);
  const [, setTravelDetailForm] = useRecoilState(travelDetailFormState);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [travel, setTravel] = useState(null);

  const { subpath: user_travel } = useParams();
  const ids = (user_travel || '').split('-');
  const user_id = ids[0] || '';
  const travel_id = ids[1] || '';

  const fetchTravel = () => {
    setLoading(true);
    fetch(`${baseUrl}/users/travel/${user_id}/view/${travel_id}`, { headers })
      .then(response => response.json())
      .then(trv => {
        const travel = trv.data;
        if (travel?.user?.profile) {
          travel.user.profile.next_of_kin = JSON.parse(
            travel.user.profile.next_of_kin
          );
        }
        setTravel(travel);
        setTravelDetailForm({
          destination: travel?.destination || '',
          purpose: travel?.purpose || '',
          travel_id: travel?.id,
          startDate: travel?.from_date,
          endDate: travel?.to_date
        });
        setTravelDetails({
          destination: travel?.destination || '',
          purpose: travel?.purpose || '',
          travel_id: travel?.id,
          startDate: travel?.from_date,
          endDate: travel?.to_date
        });
        setGuardianDetails({
          guardianFirstName: travel?.user?.profile?.guardian?.first_name || '',
          guardianLastName: travel?.user?.profile?.guardian?.last_name || '',
          guardianOtherName: travel?.user?.profile?.guardian?.other_names || '',
          gender: travel?.user?.profile?.guardian?.gender || '',
          guardianAddress: travel?.user?.profile?.guardian?.address || '',
          guardianPhoneNumber: travel?.user?.profile?.guardian?.phone || '',
          guardianEmail: travel?.user?.profile?.guardian?.email || '',
          guardianPassportNumber:
            travel?.user?.profile?.guardian?.passport_no || '',
          title: travel?.user?.profile?.guardian?.title || '',
          guardianDateOfBirth:
            travel?.user?.profile?.guardian?.date_of_birth || ''
        });
        setNextOfKinDetails({
          name: travel?.user?.profile?.next_of_kin?.nok_name || '',
          relationship:
            travel?.user?.profile?.next_of_kin?.nok_relationship || '',
          phoneNumber: travel?.user?.profile?.next_of_kin?.nok_phone || '',
          email: travel?.user?.profile?.next_of_kin?.nok_email || '',
          address: travel?.user?.profile?.next_of_kin?.nok_address || ''
        });
        setPassengerDetails({
          title: travel?.user?.profile?.title || '',
          firstName: travel?.user?.name || '',
          lastName: travel?.user?.profile?.last_name || '',
          email: travel?.user?.email || '',
          phoneNumber: travel?.user?.phone || '',
          otherNames: travel?.user?.profile?.other_names || '',
          dateOfBirth: travel?.user?.profile?.date_of_birth || '',
          gender: travel?.user?.profile?.gender || '',
          passportNumber: travel?.user?.profile?.passport_no || '',
          address: travel?.user?.profile?.address || ''
        });
        setError(false);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        // nav('/404', { replace: true });
        setError(true);
        setLoading(false);
        toast.error(err.message || 'Travel not found');
      });
  };

  useEffect(() => {
    updateMapKey();
    setActiveScreen(0);
    !travel && fetchTravel();
    return resetForms;
    // eslint-disable-next-line
  }, []);

  const screens = [
    <TravelDetails travel={travel} />,
    <SelectPlan />,
    <InsuranceDetails />,
    <InsurancePayment />
  ];
  const [activeScreen, setActiveScreen] = useRecoilState(
    buyInsuranceScreenState
  );

  return (
    <RetryLoader
      retry={fetchTravel}
      loading={loading && !travel}
      error={error}
      fullscreen={false}
    >
      <div className='buy-insurance container'>{screens[activeScreen]}</div>
    </RetryLoader>
  );
};

export default BuyInsurance;
