import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { baseUrl } from '../../../constants';
import useLoginUser from '../../../hooks/useLoginUser';
import { profileInformationState } from '../../../recoil/atoms';
// import avatar from '../../../assets/image/avatar.png';
import {
  checkValidations,
  ErrorMessage
} from '../../../utilities/use-validation';
import { schemas } from './profile-information.schema';

const ProfileInformation = () => {
  const { user, headers, fetchUser } = useLoginUser();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [profileInformation, setProfileInformation] = useRecoilState(
    profileInformationState
  );

  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleChange = event => {
    const { name, value } = event.target;
    const data = { ...profileInformation, [name]: value };
    setProfileInformation(data);
    handleValidations(data, name);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { success } = handleValidations({
      ...profileInformation
    });

    if (success) {
      save();
    }
  };

  useEffect(() => {
    const profile = {
      firstName: user.name,
      email: user.email,
      lastName: user?.profile?.last_name || '',
      otherNames: user?.profile?.other_names || '',
      passportNumber: user?.profile?.passport_no || '',
      address: user?.profile?.address || '',
      dateOfBirth: user?.profile?.date_of_birth || '',
      phoneNumber: user?.phone || ''
    };

    setProfileInformation(profile);
    // eslint-disable-next-line
  }, [user]);

  const save = () => {
    setLoading(true);
    const data = JSON.parse(JSON.stringify(user));
    data.profile = data.profile || {};
    data.name = profileInformation.firstName;
    data.profile.last_name = profileInformation.lastName;
    data.profile.other_names = profileInformation.otherNames;
    data.profile.passport_no = profileInformation.passportNumber;
    data.profile.address = profileInformation.address;
    data.profile.date_of_birth = profileInformation.dateOfBirth;
    data.phone = profileInformation.phoneNumber;

    fetch(`${baseUrl}/me`, {
      headers,
      method: 'POST',
      body: JSON.stringify({ ...data, _method: 'PUT' })
    })
      .then(res => res.json())
      .then(res => {
        if (res.error || res.errors) throw new Error(res.message);
        fetchUser();
        toast.success('Profile updated successfully');
        setProfileInformation({});
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        toast.error(err.message || 'Error updating profile');
        setLoading(false);
      });
  };

  return (
    <div className='profile__information'>
      {/* <h3 className='profile__information__title'>Your Photo</h3>

      <div className='profile__information__photo mt-2'>
        <img src={avatar} alt='avatar' />
        <div className='profile__information__photo__texts'>
          <h3>Upload your photo</h3>
          <h3>Your profile photo helps us identify you</h3>
        </div>
      </div> */}

      <h3 className='profile__information__title mt-4'>Personal Information</h3>

      <form action='' className='row mt-4' onSubmit={handleSubmit}>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='first-name'>First Name</label>
          <input
            name='firstName'
            value={profileInformation.firstName}
            onChange={handleChange}
            type='name'
            id='first-name'
            placeholder='Enter First Name'
          />
          <ErrorMessage
            message={errors.firstName && errors.firstName.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='last-name'>Surname</label>
          <input
            name='lastName'
            value={profileInformation.lastName}
            onChange={handleChange}
            type='name'
            id='last-name'
            placeholder='Enter surname'
          />
          <ErrorMessage message={errors.lastName && errors.lastName.message} />
        </div>
        {/* <div className='col-sm-6 mt-3'>
          <label htmlFor='other-names'>Other Names</label>
          <input
            name='otherNames'
            value={profileInformation.otherNames}
            onChange={handleChange}
            type='name'
            id='other-names'
            placeholder='Enter other names'
          />
          <ErrorMessage
            message={errors.otherNames && errors.otherNames.message}
          />
        </div> */}
        <div className='col-sm-6 mt-3'>
          <label htmlFor='date-of-birth'>Date of Birth</label>
          <input
            name='dateOfBirth'
            value={profileInformation.dateOfBirth}
            onChange={handleChange}
            id='date-of-birth'
            type='date'
          />
          <ErrorMessage
            message={errors.dateOfBirth && errors.dateOfBirth.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='email'>Email</label>
          <input
            name='email'
            value={profileInformation.email}
            onChange={handleChange}
            id='email'
            type='email'
            readOnly={true}
            placeholder='Enter email'
          />
          <ErrorMessage message={errors.email && errors.email.message} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>Phone Number</label>
          <input
            name='phoneNumber'
            value={profileInformation.phoneNumber}
            onChange={e => {
              e.target.value = e.target.value
                .replace(/[^0-9+]/g, '')
                .replace(/[+%]/g, function (match, offset, all) {
                  return match === '+'
                    ? all.indexOf('+') === offset
                      ? '+'
                      : ''
                    : '';
                });
              handleChange(e);
            }}
            id='phone'
            type='phone'
          />
          <ErrorMessage
            message={errors.phoneNumber && errors.phoneNumber.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>Passport Number</label>
          <input
            name='passportNumber'
            value={profileInformation.passportNumber}
            onChange={handleChange}
            id='passportNumber'
            type='passportNumber'
          />
          <ErrorMessage
            message={errors.passportNumber && errors.passportNumber.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>Address</label>
          <input
            name='address'
            value={profileInformation.address}
            onChange={handleChange}
            id='address'
            type='address'
          />
          <ErrorMessage message={errors.address && errors.address.message} />
        </div>
        <div className='quote-details__buttons col-sm-12 mt-5'>
          <button disabled={loading} className='button-rounded'>
            {loading ? 'Loading...' : 'Update'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProfileInformation;
