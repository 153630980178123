import React from 'react';
import logo from '../../../assets/image/logo.png';
import { Link } from 'react-router-dom';
import { numberWithCommas } from '../../../utilities';
import useCountries from '../../../hooks/useCountries';

export default function PrintCertifcate({ watermarked, data, fullscreen }) {
  const { getCountryName } = useCountries();
  return (
    <div
      className={
        'chi_certificate my-4 card p-2 py-3 ' + (fullscreen ? 'fullscreen' : '')
      }
    >
      {watermarked && (
        <div class='logo_watermark'>
          <img src={logo} alt='chi' />
        </div>
      )}
      <div>
        <Link to='/'>
          <img src={logo} alt='chi' />
        </Link>
      </div>
      <ul class='list-group list-group-flush'>
        <li class='list-group-item'>
          <h5 class='list-group-item-heading'>
            <b>Amount: </b>
            {numberWithCommas(data?.payments?.amount || 0)}
          </h5>
        </li>
        <li class='list-group-item'>
          <b>Payment Status: </b>
          <span
            class={`badge bg-${
              data?.payments?.status === 'successful' ? 'success' : 'danger'
            }`}
          >
            {data?.payments?.status || 'Unknown'}
          </span>
        </li>

        <li class='list-group-item'>
          <b>Payment Type: </b>
          {data?.payments?.payment_type}
        </li>
        <li class='list-group-item'>
          <b>Transaction Date: </b>
          {data?.payments?.created_at}
        </li>

        <li class='list-group-item'>
          <b>Policy No.: </b>
          {data?.payments?.mapfre_policy_number}
        </li>

        <li class='list-group-item'>
          <b>Issued Certificate: </b>
          {data?.payments?.chi_certificate_no}
        </li>

        <li class='list-group-item'>
          <b>Tracking No.: </b>
          {data?.payments?.chi_policy_number}
        </li>

        <li class='list-group-item'>
          <b>Batch No.: </b>
          {data?.payments?.chi_invoice_no}
        </li>
        <li class='list-group-item'>
          <b>Transaction Reference: </b>
          {data?.payments?.trxref}
        </li>
        <li class='list-group-item'>
          <b>Destination: </b>
          {getCountryName(data?.destination)}
        </li>
        <li class='list-group-item'>
          <b>Start Date: </b>
          {data?.from_date}
        </li>
        <li class='list-group-item'>
          <b>End Date: </b>
          {data?.to_date}
        </li>
        <li class='list-group-item'>
          <b>Total Duration: </b>
          {data?.no_of_days}
        </li>
      </ul>
    </div>
  );
}
