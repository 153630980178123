import React from 'react';
import { useRecoilState } from 'recoil';
import { quotesPageState } from '../../../recoil/atoms';
import TravelDetails from './TravelDetails/travel-details';
import PassengerDetails from './PassengerDetails/passenger-details';
import NextOfKinDetails from './NextOfKinDetails/next-of-kin-details';
import SelectPlan from './SelectPlan/select-plan';
import ProductDetails from './ProductDetails/product-details';
import PassportUpload from './PassportUpload/passport-upload';
import Payment from './Payment/payment';

const QuotesForms = () => {
  const quotePages = [
    <TravelDetails />,
    <PassengerDetails />,
    <NextOfKinDetails />,
    <SelectPlan />,
    <ProductDetails />,
    <PassportUpload />,
    <Payment />
  ];
  const [activePage] = useRecoilState(quotesPageState);
  return <div>{quotePages[activePage]}</div>;
};

export default QuotesForms;
