import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

import { allDataState, currentPackageState } from '../../../../recoil/atoms';
import { baseUrl } from '../../../../constants';
import { getRandomString } from '../../../../utilities';
import Back from '../../../../components/commons/Back';
import generateConfig from '../../../../helpers/generateConfig';

export default function Payment() {
  const [allData] = useRecoilState(allDataState);
  const [currentPackage] = useRecoilState(currentPackageState);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [configs, setConfigs] = useState([]);

  const makePayment = async arr => {
    const res = await fetch(`${baseUrl}/users/travel/batch-payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(arr)
    });
    return res.json();
  };

  useEffect(() => {
    setLoading(true);
    let configs = allData?.map(
      ({
        travelDetailForm,
        selectedProduct,
        price,
        insuranceData,
        travelDetails: { purpose }
      }) => {
        if (!travelDetailForm) {
          console.log(
            `Cannot generate config. Missing travelDetailForm. travelDetailForm: ${travelDetailForm}`
          );
          return null;
        }
        if (!selectedProduct) {
          console.log(
            `Cannot generate config. Missing selectedProduct. selectedProduct: ${selectedProduct}`
          );
          return null;
        }
        if (!price) {
          console.log(`Cannot generate config. Missing price. price: ${price}`);
          return null;
        }

        if (!purpose) {
          console.log(
            `Cannot generate config. Missing purpose. purpose: ${purpose}`
          );
          return null;
        }

        const conf = generateConfig(
          travelDetailForm,
          price,
          selectedProduct.dsProduct
        );
        if (conf?.error) {
          console.log('error: ', conf.error);
          return null;
        }
        return {
          ...conf,
          insurance_data: insuranceData,
          travel_detail: travelDetailForm,
          product: selectedProduct?.dsProduct,
          price,
          purpose
        };
      }
    );
    //! TO-DO: add check to ensure config is non-empty
    console.log('configs: ', configs);
    if (configs) {
      setConfigs(configs);
      try {
        makePayment(configs)
          .then(data => {
            console.log('result of batch payment operation: ', data);
            if (data?.data === 'success') {
              setSuccess(true);
            } else {
              setError(true);
              toast.error(data?.data);
              return;
            }
          })
          .catch(err => {
            console.log('error during batch payment: ', err);
            setError(true);
            toast.error(err);
          });
      } catch (error) {
        console.log('error during batch payment: ', error);
        setError(error);
        toast.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      toast.err('An error occurred');
      setError(true);
      return;
    }
  }, []);

  return (
    <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center batch-payment text-center'>
      <Back />

      {loading && <Spinner color='primary' animation='border' role='status' />}

      {error && (
        <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center gap-4'>
          <p style={{ fontSize: '2rem' }}>An error occurred. Please retry.</p>
        </div>
      )}

      {success && (
        <main className='d-flex flex-column align-items-center justify-content-center gap-4'>
          <h1 className='py-4'>Successful Transaction</h1>
          <h2 className='py-4'>
            Payment links have been generated and sent to customers.
          </h2>
          <img
            className='w-50 h-55'
            src='https://einsurance.chiplc.com/assets/images/success-icon.png'
            alt='CHI Success Logo'
          />
        </main>
      )}
    </div>
  );
}
