import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import moment from 'moment';
import CustomDropdown from '../../../../../components/custom-dropdown';
import { countries } from '../../../../../constants';
import { travelDetailsState } from '../../../../../recoil/atoms';
import {
  ErrorMessage,
  checkValidations
} from '../../../../../utilities/use-validation';
import { packages } from '../../../../../constants';
import { schemas } from '../../../TravelDetails/travel-details.schema';

const Travel = ({ setAllTravelInputSuccess }) => {
  const [travelDetails, setTravelDetails] = useRecoilState(travelDetailsState);
  const [errors, setErrors] = useState({});
  const [successMap, setSuccessMap] = useState({});

  const numberOfRequiredInputFields = 5;
  useEffect(() => {
    const trueCount = Object.keys(successMap).filter(
      key => successMap[key] === true
    );
    setAllTravelInputSuccess(trueCount.length >= numberOfRequiredInputFields);
  }, [successMap]);

  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleChange = (name, value) => {
    const data = { ...travelDetails, [name]: value };
    if (name === 'startDate') data.endDate = '';
    const { success } = handleValidations(data, name);
    setTravelDetails(data);
    setSuccessMap({ ...successMap, [name]: success });
  };

  const getDateDifference = (startDate, endDate) => {
    const difference = Math.floor(
      (new Date(endDate) - new Date(startDate)) / 86400000
    );
    handleChange('duration', difference + 1);
  };

  useMemo(
    () => getDateDifference(travelDetails.startDate, travelDetails.endDate),
    [travelDetails.startDate, travelDetails.endDate]
  );

  return (
    <div className='row py-3'>
      <h2 className='col-sm-12 py-3 heading'>
        Fill in the travel details for this group.
      </h2>

      <div className='col-sm-6 py-3'>
        <label>
          Destination <span className='text-danger'> *</span>
        </label>
        <CustomDropdown
          name='destination'
          onSelect={selection => {
            handleChange('destination', selection.cd);
          }}
          identifier='ds'
          data={countries}
          inputId={`destination`}
          placeholder='Pick your destination'
          disable={false}
          showItems={true}
          value={{
            destination: travelDetails.destination || ''
          }}
        />
        <ErrorMessage
          message={errors.destination && errors.destination.message}
        />
      </div>

      <div className='col-sm-6 py-3'>
        <label>
          Purpose <span className='text-danger'> *</span>
        </label>
        <CustomDropdown
          name='purpose'
          onSelect={selection => handleChange('purpose', selection?.purpose)}
          identifier='purpose'
          data={[
            { purpose: packages[0]?.name },
            { purpose: packages[1]?.name },
            { purpose: packages[2]?.name },
            { purpose: packages[3]?.name }
          ]}
          inputId={`purpose`}
          placeholder='Purpose'
          disable={false}
          showItems={true}
          value={travelDetails?.purpose || ''}
        />
        <ErrorMessage message={errors.purpose && errors.purpose.message} />
      </div>

      <div className='col-sm-6 py-3'>
        <label htmlFor='startDate'>
          Start Date <span className='text-danger'> *</span>
        </label>
        <input
          type='date'
          name='startDate'
          id='startDate'
          min={moment().format('YYYY-MM-DD')}
          value={travelDetails.startDate || ''}
          onChange={event => handleChange('startDate', event.target.value)}
        />
        <ErrorMessage message={errors.startDate && errors.startDate.message} />
      </div>

      <div className='col-sm-6 py-3'>
        <label htmlFor='destinaendDatetion'>
          End Date <span className='text-danger'> *</span>
        </label>
        <input
          type='date'
          name='endDate'
          id='endDate'
          min={moment().format('YYYY-MM-DD')}
          value={travelDetails.endDate || ''}
          onChange={event => handleChange('endDate', event.target.value)}
        />
        <ErrorMessage message={errors.endDate && errors.endDate.message} />
      </div>

      <div className='col-sm-6 py-3'>
        <label htmlFor='duration'>
          Duration <span className='text-danger'> *</span>
        </label>
        <input
          type='text'
          name='duration'
          id='duration'
          readOnly
          disabled={!(!isNaN(travelDetails.duration) && travelDetails.duration)}
          value={
            !isNaN(travelDetails.duration) && travelDetails.duration
              ? `${travelDetails.duration} day(s)`
              : 'Set start and end date'
          }
        />
        <ErrorMessage
          message={
            travelDetails.startDate &&
            travelDetails.endDate &&
            travelDetails.duration <= 0 &&
            'End date should be later than start date'
          }
        />
      </div>

      <div className='col-sm-12 py-3'>
        {travelDetails.duration ? (
          <h3 className='text-muted px-0'>
            Current planned trip length: {travelDetails.duration} Days
          </h3>
        ) : undefined}
      </div>
    </div>
  );
};

export default Travel;
