import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import {
  ErrorMessage,
  checkValidations
} from '../../../utilities/use-validation';
// import CustomDropdown from '../../../components/custom-dropdown';
import { editRoleDataState } from '../../../recoil/atoms';
import { useRecoilState } from 'recoil';
import { schemas } from './roles.edit-profile-schema';
import { useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function RolesEditProfile({
  role,
  hide,
  save,
  tasks,
  addPermission
}) {
  const [roleData, setRoleData] = useRecoilState(editRoleDataState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setRoleData({
      name: role?.name || '',
      description: role?.description || '',
      tasks: role?.tasks ? role?.tasks.map(t => t.id) : []
    });
    setErrors({});
    return () => {
      setRoleData({});
      setErrors({});
    };
    // eslint-disable-next-line
  }, [role]);

  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleChange = event => {
    const { name, value } = event.target;
    const data = { ...roleData, [name]: value };
    setRoleData(data);
    handleValidations(data, name);
  };

  const handleSubmit = event => {
    event.preventDefault();
    const { success } = handleValidations({
      ...roleData
    });

    if (success) {
      save(role.id);
    }
  };

  const handleSelect = e => {
    const data = {
      ...roleData,
      tasks: e.map(t => t.id)
    };
    setRoleData(data);
    handleValidations(data, 'tasks');
  };

  return (
    <Modal
      show={role}
      onHide={hide}
      centered
      backdrop={roleData.loading ? 'static' : true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit {role?.name || 'Role'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <form action='' className='row px-3' onSubmit={handleSubmit}>
            <div className='col-sm-12 mt-3'>
              <label htmlFor='first-name'> Name</label>
              <input
                name='name'
                value={roleData.name}
                onChange={handleChange}
                type='name'
                id='-name'
                placeholder='Enter  name'
              />
              <ErrorMessage message={errors.name && errors.name.message} />
            </div>
            <div className='col-sm-12 mt-3'>
              <label htmlFor='description'>Description</label>
              <textarea
                name='description'
                value={roleData.description}
                onChange={handleChange}
                type='description'
                id='description'
                placeholder='Enter first description'
              />
              <ErrorMessage
                message={errors.description && errors.description.message}
              />
            </div>
            <div className='col-sm-12 mt-3 mb-5'>
              <label htmlFor='description'>Permissions</label>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                defaultValue={role?.tasks || []}
                options={tasks}
                isMulti
                className={'react-select'}
                onChange={handleSelect}
                isDisabled={!addPermission}
              />
              <ErrorMessage
                message={errors.description && errors.description.message}
              />
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='button-rounded button-rounded--outline'
          disabled={roleData.loading}
          onClick={hide}
        >
          Close
        </button>
        <button
          className='button-rounded main'
          onClick={handleSubmit}
          disabled={roleData.loading}
        >
          {roleData.loading ? 'Saving...' : 'Save'}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
