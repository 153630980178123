import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import {
  passengerDetailsState,
  clearFieldsState,
  allDataState,
  formIndexState
} from '../../../../recoil/atoms';
import CustomDropdown from '../../../../components/custom-dropdown';
import {
  checkValidations,
  ErrorMessage
} from '../../../../utilities/use-validation';
import { genders, titles, relationships } from '../../../../constants';
import { schemas } from '../../PassengerDetails/passenger-details.schema';

const PassengerDetails = ({ setAllPassengerDetailsInputSuccess }) => {
  const [passengerDetails, setPassengerDetails] = useRecoilState(
    passengerDetailsState
  );
  const [clearFields] = useRecoilState(clearFieldsState);

  const [errors, setErrors] = useState({});
  const [otherTitle, setOtherTitle] = useState(false);
  const [successMap, setSuccessMap] = useState({});

  useEffect(() => {
    if (clearFields) {
      setPassengerDetails({});
    }
  }, [clearFields]);

  const numberOfRequiredInputFields = 5;
  const notRequiredKeys = ['title', 'phoneNumber', 'email', 'address'];
  useEffect(() => {
    const trueKeys = Object.keys(successMap).filter(
      key => successMap[key] === true && !notRequiredKeys.includes(key)
    );

    if (trueKeys?.length)
      setAllPassengerDetailsInputSuccess(
        trueKeys.length >= numberOfRequiredInputFields
      );
  }, [successMap]);

  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleChange = event => {
    const { name, value } = event.target;
    const data = { ...passengerDetails, [name]: value };
    const { success } = handleValidations(data, name);
    setPassengerDetails(data);
    setSuccessMap({ ...successMap, [name]: success });
  };

  const handleChangeDropdown = (name, value) => {
    const data = {
      ...passengerDetails,
      [name]: value
    };
    const { success } = handleValidations(data, name);
    setPassengerDetails(data);
    setSuccessMap({ ...successMap, [name]: success });
    if (name === 'startDate') {
      setPassengerDetails({
        ...passengerDetails,
        endDate: ''
      });
    }
  };

  return (
    <div className='py-3'>
      <h1 className=''>Passenger Details</h1>

      <div className='row'>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='first-name'>
            First Name <span className='text-danger'> *</span>
          </label>
          <input
            name='firstName'
            value={
              passengerDetails.firstName ||
              // allData[formIndex]?.passengerDetails.firstName ||
              ''
            }
            onChange={handleChange}
            type='name'
            id='first-name'
            placeholder='Enter First Name'
          />
          <ErrorMessage
            message={errors.firstName && errors.firstName.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='last-name'>
            Surname <span className='text-danger'> *</span>
          </label>
          <input
            name='lastName'
            value={
              passengerDetails.lastName ||
              // allData[formIndex]?.passengerDetails.lastName ||
              ''
            }
            onChange={handleChange}
            type='text'
            id='last-name'
            placeholder='Enter surname'
          />
          <ErrorMessage message={errors.lastName && errors.lastName.message} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='date-of-birth'>
            Date of Birth<span className='text-danger'> *</span>
          </label>
          <input
            name='dateOfBirth'
            value={
              passengerDetails.dateOfBirth ||
              // allData[formIndex]?.passengerDetails.dateOfBirth ||
              ''
            }
            onChange={handleChange}
            id='date-of-birth'
            type='date'
            max={moment().format('YYYY-MM-DD')}
          />
          <ErrorMessage
            message={errors.dateOfBirth && errors.dateOfBirth.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='gender'>
            Gender <span className='text-danger'> *</span>
          </label>
          <CustomDropdown
            name='gender'
            onSelect={selection => {
              handleChangeDropdown('gender', selection.gender);
            }}
            identifier='gender'
            data={genders}
            inputId={`gender`}
            placeholder='Pick your gender'
            disable={false}
            showItems={true}
            value={{
              gender:
                passengerDetails.gender ||
                // allData[formIndex]?.passengerDetails.gender ||
                ''
            }}
          />
          <ErrorMessage message={errors.gender && errors.gender.message} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='title'>Title</label>
          {!otherTitle ? (
            <CustomDropdown
              name='title'
              onSelect={selection => {
                handleChangeDropdown('title', selection.title);
              }}
              identifier='title'
              data={titles}
              inputId={`title`}
              placeholder='Pick your title'
              disable={false}
              showItems={true}
              value={{
                title:
                  passengerDetails.title ||
                  // allData[formIndex]?.passengerDetails.title ||
                  ''
              }}
            />
          ) : (
            <input
              name='title'
              value={passengerDetails.title}
              //   onChange={handleChange}
              id='title'
              type='text'
              placeholder='Please enter title'
            />
          )}
          <ErrorMessage message={errors.title && errors.title.message} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='email'>Email</label>
          <input
            name='email'
            value={
              passengerDetails.email ||
              // allData[formIndex]?.passengerDetails.email ||
              ''
            }
            onChange={handleChange}
            id='email'
            type='email'
            placeholder='Enter email'
          />
          <ErrorMessage message={errors.email && errors.email.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>Phone Number</label>
          <input
            name='phoneNumber'
            value={
              passengerDetails.phoneNumber ||
              // allData[formIndex]?.passengerDetails.phoneNumber ||
              ''
            }
            onChange={e => {
              e.target.value = e.target.value
                .replace(/[^0-9+]/g, '')
                .replace(/[+%]/g, function (match, offset, all) {
                  return match === '+'
                    ? all.indexOf('+') === offset
                      ? '+'
                      : ''
                    : '';
                });
              handleChange(e);
            }}
            id='phone'
            type='phone'
          />
          <ErrorMessage
            message={errors.phoneNumber && errors.phoneNumber.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='passport'>
            Passport Number<span className='text-danger'> *</span>
          </label>
          <input
            name='passportNumber'
            value={
              passengerDetails.passportNumber ||
              // allData[formIndex]?.passengerDetails.passportNumber ||
              ''
            }
            onChange={handleChange}
            id='passport'
            type='passport'
          />
          <ErrorMessage
            message={errors.passportNumber && errors.passportNumber.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>Address</label>
          <input
            name='address'
            value={
              passengerDetails.address ||
              // allData[formIndex]?.passengerDetails.address ||
              ''
            }
            onChange={handleChange}
            id='address'
            type='address'
          />
          <ErrorMessage message={errors.address && errors.address.message} />
        </div>
      </div>
    </div>
  );
};

export default PassengerDetails;
