import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import localforage from 'localforage';
import { nextOfKinDetailsState, isMinorState } from '../../../../recoil/atoms';
import CustomDropdown from '../../../../components/custom-dropdown';
import {
  ErrorMessage,
  checkValidations
} from '../../../../utilities/use-validation';
import { guardianSchema } from '../../NextOfKinDetails/next-of-kin.schema';
import { baseUrl } from '../../../../constants';

const Referral = () => {
  const [nextOfKinDetails, setNextOfKinDetails] = useRecoilState(
    nextOfKinDetailsState
  );
  const [isMinor] = useRecoilState(isMinorState);

  const [marketers, setMarketers] = useState([]);
  const [guardianHeight] = useState({});
  const [errors, setErrors] = useState({});

  const fetchMarketers = async () => {
    const marketers = await localforage.getItem('marketers');
    if (marketers) setMarketers(marketers);
    const res = await fetch(`${baseUrl}/marketers`);
    const data = await res.json();
    if (res.status === 200) {
      data.Marketer.forEach(d => {
        d.full_name = `${d.FirstName} ${d.LastName}`;
      });
      setMarketers(data.Marketer);
      localforage.setItem('marketers', data.Marketer);
    }
  };

  useEffect(() => {
    fetchMarketers();
  }, []);

  const handleValidations = (data, field) => {
    const schemas =
      guardianHeight.initial > 0 || isMinor ? { ...{}, ...guardianSchema } : {};
    console.log('schemas: ', schemas);
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleNextOfKinChange = (name, value) => {
    const data = { ...nextOfKinDetails, [name]: value };
    handleValidations(data, name);
    setNextOfKinDetails(data);
  };

  return (
    <div className='w-100'>
      {/* add markerer referral here */}
      <div className=''>
        <h1>Referral</h1>

        <label htmlFor='referred' style={{ fontSize: '1.5rem' }}>
          {!nextOfKinDetails.isReferred
            ? 'I was referred by Internet'
            : `I was referred by ${nextOfKinDetails.isReferred}`}
        </label>

        <div className='w-100 d-flex flex-column gap-3 justify-content-between align-middle'>
          <div className='col-sm-6'>
            <select
              onChange={e => {
                setNextOfKinDetails({
                  ...nextOfKinDetails,
                  isReferred: e.target.value
                });
              }}
              value={nextOfKinDetails.isReferred}
            >
              <option value='Internet'>Internet </option>
              <option value='Social Media'>Social Media </option>
              <option value='Friends/Associate'>Friends/Associate </option>
              <option value='Agency/Marketers'>Agency/Marketers </option>
            </select>
          </div>

          {nextOfKinDetails.isReferred === 'Agency/Marketers' && (
            <div className='col-sm-6'>
              <label className='w-100' htmlFor='guardian-relationship'>
                Marketer
              </label>
              <CustomDropdown
                name='marketer'
                onSelect={selection => {
                  handleNextOfKinChange('marketer', selection);
                }}
                identifier='full_name'
                data={marketers}
                inputId={`marketer`}
                placeholder='Pick marketer'
                disable={false}
                showItems={true}
                value={nextOfKinDetails.marketer}
              />

              <div className='mb-3'>
                <ErrorMessage
                  message={errors.marketer && errors.marketer.message}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Referral;
