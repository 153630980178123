import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  quotesPageState,
  headerState,
  priceInformationState,
  travelDetailFormState,
  selectedProductState,
  insuranceDataState
} from '../../../../recoil/atoms';

import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { flutterwaveKey, baseUrl } from '../../../../constants';
import { toast } from 'react-toastify';
import useLoginUser from '../../../../hooks/useLoginUser';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Retry from '../../../../components/commons/retry';
import { getRandomString, numberWithCommas } from '../../../../utilities';

export default function Payment() {
  const nav = useNavigate();
  const [activePage, setActivePage] = useRecoilState(quotesPageState);
  const [headers] = useRecoilState(headerState);
  const [priceInfo] = useRecoilState(priceInformationState);
  const [travelDetailForm] = useRecoilState(travelDetailFormState);
  const [insuranceData] = useRecoilState(insuranceDataState);
  const [product] = useRecoilState(selectedProductState);
  const { updateMapKey } = useLoginUser();
  const [paid, setPaid] = useState(false);
  const [error, setError] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const [gibbsResponse, setGibbsResponse] = useState(false);
  const [payment, setPayment] = useState(false);
  const [loading, setLoading] = useState(false);

  const price = priceInfo?.root?.premiumBreakdown?.startPrice || '0.00';

  const config = {
    public_key: flutterwaveKey,
    tx_ref: getRandomString(3) + '-' + Date.now(),
    amount: parseFloat(price),
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: travelDetailForm.email,
      phonenumber: travelDetailForm.phone,
      name: travelDetailForm.customer_name
    },
    customizations: {
      title: `Insurance payment ${
        product.dsProduct ? 'for' + product.dsProduct : ''
      }`,
      description: `Insurance payment ${
        product.dsProduct ? 'for' + product.dsProduct : ''
      } on CHI Plc`,
      logo: 'https://chiplc.com/wp-content/uploads/2019/09/cropped-New-CHI-logo-1.png'
    }
  };

  const getProductXml = async () => {
    let data;
    try {
      const res = await fetch(`${baseUrl}/products-xml-formatted`, {
        method: 'POST',
        headers,
        body: JSON.stringify(insuranceData)
      });

      data = await res.json();
      if (data.error || data.errors) {
        throw new Error('Missing Token');
      }
    } catch (error) {
      console.error(error);
      updateMapKey();
    }

    return data;
  };

  const handleFlutterPayment = useFlutterwave(config);

  const handlePay = () => {
    setLoading(true);
    handleFlutterPayment({
      callback: response => {
        closePaymentModal(); // this will close the modal programmatically
        if (response.status === 'successful') {
          setPaid(response);
          savePayment(response);
        } else {
          toast.error('Payment unsuccessful');
          console.error(response);
          setLoading(false);
        }
      },
      onClose: e => {
        console.info(e, 'closed');
        setLoading(false);
      }
    });
  };

  const savePayment = async response => {
    const flutterwavePayment = response || paid;
    if (!flutterwavePayment) return;
    setLoading(true);
    const paymentData = {
      travel_id: travelDetailForm.travel_id,
      amount: flutterwavePayment.amount,
      payment_type: 'staff',
      premium: price,
      paystack_ref: flutterwavePayment.flw_ref,
      trxref: flutterwavePayment.tx_ref,
      status: flutterwavePayment.status,
      product_name: product.dsProduct
    };

    try {
      const res = await Promise.all([
        fetch(`${baseUrl}/users/travel/payment`, {
          method: 'POST',
          body: JSON.stringify(paymentData),
          headers
        }),
        updateMapKey()
      ]);

      let data = await res[0].json();

      if (!data.error && !data.errors) {
        setPayment(data);
        issueCertificate(true, flutterwavePayment);
      } else {
        setError(true);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const issueCertificate = async (hasPaid = false, paymentRequest) => {
    const pmt = paymentRequest || payment;
    setLoading(true);
    if (!payment && !hasPaid) {
      return savePayment(paid);
    }

    if (certificate) {
      return storeOnGibbs(certificate, pmt);
    }

    const xml = await getProductXml();

    try {
      if (!xml || !xml.data) {
        throw new Error("Couldn't load xml");
      }
      const res = await fetch(`${baseUrl}/product-quotes`, {
        method: 'POST',
        body: JSON.stringify({
          action: 'issuing',
          xml: xml.data,
          xml_raw: xml.xml
        }),
        headers
      });

      let data = await res.json();

      if (data.root.idRegContrato) {
        setCertificate(data.root);
        storeOnGibbs(data.root, pmt);
      } else {
        console.error(data);
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError(true);
    }
  };

  const storeOnGibbs = async (certificate, _payment) => {
    if (gibbsResponse) {
      return completePayment(gibbsResponse, payment, certificate);
    }

    const data = JSON.parse(
      JSON.stringify(travelDetailForm.gibssTransactionData)
    );
    data.Map_PolicyNo = certificate?.numContrato || '';
    data.Payment_Ref = _payment?.tx_ref || 'manual';
    data.MarketerID = travelDetailForm.marketer.MarketerID || '27033277';
    data.MarketerName =
      travelDetailForm.marketer.full_name || 'BOLAJI AKINMORIN';
    data.Title = travelDetailForm.Title || 'N/A';
    data.Premium = price;

    try {
      const res = await fetch(`${baseUrl}/complete-transaction`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers
      });

      let response = await res.json();

      if (response.error || response.errors) {
        setError(true);
        setLoading(false);
      } else {
        setGibbsResponse(response);
        completePayment(response, payment, certificate);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setError(true);
    }
  };

  const completePayment = async (response, payment, certificate) => {
    let chiData = response?.BuyTravelInsuranceResult?.TravelID;
    const paymentData = {
      certificate_url: certificate?.file || '',
      chi_certificate_no: chiData?.CertificateNos || 'Not Available',
      chi_invoice_no: chiData?.InvoiceID || 'Not Available',
      chi_policy_number: chiData?.PolicyNumber || 'Not Available',
      chi_response_status: chiData?.StatusmSG || 'Not Available',
      id: payment?.data?.id,
      mapfre_certificate_id: certificate?.idRegContrato || '',
      mapfre_policy_number: certificate?.numContrato || ''
    };

    try {
      const res = await fetch(`${baseUrl}/users/travel/payments`, {
        method: 'POST',
        body: JSON.stringify(paymentData),
        headers
      });

      const response = await res.json();

      if (response.error || response.errors) {
        setError(true);
        setLoading(false);
      } else {
        nav(
          `/quotes/success/${travelDetailForm.travel_id}/${payment?.data?.id}`
        );
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className='quote-details insurance-payment pt-5'>
      <div className='insurance-payment__card'>
        <div className='text-center insurance-payment__card__top'>
          <svg
            width='81'
            height='60'
            viewBox='0 0 81 60'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M70.9758 37.5H29.7523L30.6727 41.25H68.419C70.5848 41.25 72.19 42.9259 71.71 44.6858L70.9342 47.5307C73.5626 48.5939 75.375 50.8392 75.375 53.4375C75.375 57.094 71.7864 60.052 67.3841 59.9993C63.1903 59.949 59.7412 57.1128 59.628 53.6188C59.5661 51.7101 60.4837 49.9802 61.9909 48.7499H32.5091C33.9684 49.9412 34.875 51.6007 34.875 53.4375C34.875 57.1656 31.1445 60.1677 26.6245 59.9927C22.6111 59.8374 19.347 57.1351 19.1361 53.7915C18.9733 51.2094 20.6037 48.9335 23.0808 47.7463L13.2023 7.5H3.375C1.51102 7.5 0 6.24082 0 4.6875V2.8125C0 1.25918 1.51102 0 3.375 0H17.7931C19.3964 0 20.7783 0.939961 21.0997 2.24883L22.3886 7.5H77.6236C79.7894 7.5 81.3946 9.1759 80.9147 10.9358L74.2669 35.3108C73.9177 36.5913 72.5517 37.5 70.9758 37.5ZM56.676 22.5H50.625V15.4687C50.625 14.6921 49.8694 14.0625 48.9375 14.0625H45.5625C44.6306 14.0625 43.875 14.6921 43.875 15.4687V22.5H37.8241C36.3206 22.5 35.5677 24.0148 36.6309 24.9006L46.0568 32.7555C46.7158 33.3047 47.7842 33.3047 48.4434 32.7555L57.8693 24.9006C58.9323 24.0148 58.1794 22.5 56.676 22.5Z'
              fill='#9C1C34'
            />
          </svg>
          <p>Make Payment</p>
        </div>

        <div className='insurance-payment__card__description'>
          <span>Product:</span>
          <span>{product.dsProduct}</span>
        </div>
        <div className='insurance-payment__card__description'>
          <span>Travel Purpose:</span>
          <span>{travelDetailForm.gibssTransactionData?.TravelPurpose}</span>
        </div>
        <div className='insurance-payment__card__description'>
          <span>Amount: </span>
          <span>₦{numberWithCommas(price)}</span>
        </div>

        {!paid && (
          <div onClick={handlePay} className='insurance-payment__card__button'>
            Pay with Rave
          </div>
        )}
      </div>

      {!paid && !loading && (
        <div className='quote-details__buttons justify-content-center'>
          <button
            type='button'
            className='button-rounded button-rounded--outline'
            onClick={() => activePage !== 0 && setActivePage(activePage - 1)}
          >
            Back
          </button>
        </div>
      )}

      {(paid || error) && (
        <div>
          <div className='quote-details__loader my-2'>
            {loading ? (
              <div className='mt-3'>
                <div className='my-2' style={{ fontSize: '1.2rem' }}>
                  Please wait while we process your certificate, this may take a
                  few minutes. <br />
                  Do not close the browser or leave this page.
                </div>
                <Spinner color='primary' animation='border' role='status' />
              </div>
            ) : (
              <Retry action={issueCertificate} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
