import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import upload from '../../../../../assets/svg/upload.svg';
import { useRecoilState } from 'recoil';
import { fileState, passportUrlState } from '../../../../../recoil/atoms';
import { toast } from 'react-toastify';

const Passport = ({ setPassportUploadSuccess }) => {
  const [, setPassport] = useRecoilState(passportUrlState);
  const [file, setFile] = useRecoilState(fileState);

  const onDrop = useCallback(acceptedFiles => {
    setPassport(null);
    if (acceptedFiles[0] && acceptedFiles[0].size > 2097152) {
      toast.error('Please select a file smaller than 2Mb');
      return;
    }

    if (acceptedFiles[0] && !acceptedFiles[0].type.includes('image')) {
      toast.error('Please select an image file (jpeg, png, gif)');
      return;
    }

    setFile(acceptedFiles[0]);
    setPassportUploadSuccess(true);
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className='passport'>
      <div className='passport__heading'>
        <h1>Passport Upload</h1>
        <span className='text-danger'> *</span>
      </div>
      <div className='passport-upload' {...getRootProps()}>
        <div className='dropzone'>
          <input {...getInputProps()} accept='image/*' />
          {
            <div className='insurance-documents__upload'>
              <img src={upload} alt='upload' />
              <div className='upload-text'>
                <p className='mb-0 '>
                  Drag and drop the file here, or click to select file
                </p>
                <p className='mt-1'>
                  <small>
                    <b>Note: </b>The file should be smaller 2MB and must be an
                    image (jpeg,jpg,png,gif)
                  </small>
                </p>
                <button
                  className='button-rounded upload-button mb-4 '
                  onClick={e => e.preventDefault()}
                >
                  {file ? 'Change' : 'Upload'}
                </button>
              </div>
            </div>
          }
        </div>
      </div>
      {file && (
        <div className='passport__file'>
          <div className='passport__file__group'>
            <img src={upload} alt='upload' width='40px' />
            <p>{file.name}</p>
          </div>
          <p
            onClick={() => setPassportUploadSuccess(false)}
            className='passport__text'
          >
            Delete
          </p>
        </div>
      )}
      {/* 
      {loading && (
        <div className='mt-3'>
          <ProgressBar striped variant='primary' now={progress} />
        </div>
      )} 
    */}
    </div>
  );
};

export default Passport;
