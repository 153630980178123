import React, { useEffect, useState } from 'react';
import Header from '../../components/commons/header';
import { checkValidations, ErrorMessage } from '../../utilities/use-validation';

import { useRecoilState } from 'recoil';
import { loginState } from '../../recoil/atoms';

import { baseUrl } from '../../constants';

import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const nav = useNavigate();

  const [state, setState] = React.useState({
    email: '',
    password: '',
    invalidError: false,
    formErrors: {
      email: '',
      password: ''
    }
  });

  const [loading, setLoading] = useState(false);

  const [login] = useRecoilState(loginState);

  useEffect(() => {
    if (login) nav('/dashboard');
  });

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);
    const userDetails = {
      email: state.email
    };
    const { success } = handleValidations(userDetails);

    if (success) {
      fetch(`${baseUrl}/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(userDetails)
      })
        .then(res => res.json())
        .then(res => {
          if (res.error || res.errors) {
            toast.error('Incorrect email');
          } else {
            toast.success('Reset instructions have been sent to your mail');
          }
          setLoading(false);
        })
        .catch(error => {
          toast.error('An error occurred, please try again');
          console.error(error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas: {
        email: {
          required: true,
          inputType: 'email',
          label: 'Email'
        }
      },
      data,
      field: field
    });

    setState({ ...data, formErrors: errors });
    return { success };
  };

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...state, [name]: value };
    setState({ ...state, [name]: value });
    handleValidations(data, name);
  };

  return (
    <div className='login-page mb-5'>
      <Header />
      <form className='background login' onSubmit={handleSubmit}>
        <h1>Forgot Password</h1>
        <hr />

        <p>
          Enter your Email and instructions will be sent to you!. If you did not
          forget your password
          <Link to='/login'>
            {' '}
            <span> Login here?</span>
          </Link>
        </p>

        <div className='formGroup2 mt-5 mb-4'>
          <input
            type='email'
            className='formGroup2__input'
            placeholder='Email'
            name='email'
            noValidate
            autoComplete='false'
            autofill='false'
            onChange={handleChange}
          />
          <div className='mt-2'>
            <ErrorMessage
              message={state.formErrors.email && state.formErrors.email.message}
            />
          </div>
        </div>

        <button disabled={loading} className='login__button mt-b'>
          {loading ? (
            <>
              <Spinner animation='border' /> Loading ...
            </>
          ) : (
            'Send Request'
          )}
        </button>
      </form>
    </div>
  );
};
export default ForgotPassword;
