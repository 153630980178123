import React, { useEffect, useState } from 'react';
import Header from '../../components/commons/header';
import { checkValidations, ErrorMessage } from '../../utilities/use-validation';

import { useRecoilState } from 'recoil';
import { loginState } from '../../recoil/atoms';

import { baseUrl } from '../../constants';

import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import useLoginUser from '../../hooks/useLoginUser';
import localforage from 'localforage';

const FirstChangePassword = props => {
  const { headers, updateLogin } = useLoginUser();
  const nav = useNavigate();
  const [state, setState] = React.useState({
    password: '',
    password_confirmation: '',
    formErrors: {
      password: '',
      password_confirmation: ''
    }
  });

  const [loading, setLoading] = useState(false);

  const [login] = useRecoilState(loginState);

  useEffect(() => {
    if (!login) nav('/login');
  });

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);
    const userDetails = {
      password: state.password,
      password_confirmation: state.password_confirmation
    };
    const { success } = handleValidations(userDetails);

    if (state.password !== state.password_confirmation) {
      setState({
        ...state,
        formErrors: {
          ...state.formErrors,
          password_confirmation: {
            success: false,
            input: state.password_confirmation,
            message: 'Password does not match'
          }
        }
      });
    } else {
      setState({
        ...state,
        formErrors: { ...state.formErrors, password_confirmation: '' }
      });
    }

    if (!success || state.password !== state.password_confirmation) {
      setLoading(false);
      return;
    }

    fetch(`${baseUrl}/update-password`, {
      method: 'POST',
      headers,
      body: JSON.stringify(userDetails)
    })
      .then(res => res.json())
      .then(async res => {
        if (res.error || res.errors) {
          toast.error(res.message);
          setLoading(false);
          return;
        }
        toast.success('Password change successfully, proceed to dashboard');
        let loginState = await localforage.getItem('loginState');
        if (loginState) {
          await updateLogin(loginState);
        }
        nav('/dashboard');
      })
      .catch(error => {
        toast.error('An error occurred, please try again');
        console.error(error);
        setLoading(false);
      });
  };
  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas: {
        password: {
          required: true,
          minLength: 5,
          maxLength: 20,
          label: 'Password'
        },
        password_confirmation: {
          required: true,
          minLength: 5,
          maxLength: 20,
          label: 'Password Confirm'
        }
      },
      data,
      field: field
    });

    setState({ ...data, formErrors: errors });
    return { success };
  };

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...state, [name]: value };
    setState({ ...state, [name]: value });
    handleValidations(data, name);
  };

  return (
    <div className='login-page mb-5'>
      <Header />
      <form className='background login' onSubmit={handleSubmit}>
        <h1>Change Password</h1>
        <hr />

        <p>Please change your password to secure your account better</p>

        <div className='formGroup2 mt-5 mb-4'>
          <input
            type='password'
            className='formGroup2__input'
            placeholder='Enter password'
            name='password'
            noValidate
            autoComplete='false'
            onChange={handleChange}
          />
          <div className='mt-2'>
            <ErrorMessage
              message={
                state.formErrors.password && state.formErrors.password.message
              }
            />
          </div>
        </div>

        <div className='formGroup2 mt-5 mb-4'>
          <input
            type='password'
            className='formGroup2__input'
            placeholder='Confirm  password'
            name='password_confirmation'
            noValidate
            autoComplete='false'
            onChange={handleChange}
          />
          <div className='mt-2'>
            <ErrorMessage
              message={
                state.formErrors.password_confirmation &&
                state.formErrors.password_confirmation.message
              }
            />
          </div>
        </div>

        <button disabled={loading} className='login__button mt-b'>
          {loading ? (
            <>
              <Spinner animation='border' /> Loading ...
            </>
          ) : (
            'Change Password'
          )}
        </button>
      </form>
    </div>
  );
};
export default FirstChangePassword;
