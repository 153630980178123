const titles = [
  { title: 'Mr.' },
  { title: 'Mrs.' },
  { title: 'Miss.' },
  { title: 'Dr.' },
  { title: 'Hon.' },
  { title: 'others' }
];

export default titles;
