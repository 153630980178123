import React, { Fragment } from 'react';
import { Spinner } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { getProductDescription } from '../../../../constants';
import {
  selectedProductState,
  quotesPageState,
  travelDetailsState
} from '../../../../recoil/atoms';
import useProducts from '../../../../hooks/useProducts';

export default function SelectPlan() {
  const { products, loading, error, getProducts } = useProducts();
  const [travelDetails] = useRecoilState(travelDetailsState);

  const [, setSelectedProduct] = useRecoilState(selectedProductState);
  const [activePage, setActivePage] = useRecoilState(quotesPageState);

  const buyNow = product => {
    setSelectedProduct(product);
    setActivePage(activePage + 1);
  };

  return (
    <div className='quote-details  container quote-details__select_plan'>
      {loading || error ? (
        <div className='quote-details__loader quote-details__overlay'>
          {loading ? (
            <Spinner color='primary' animation='border' role='status' />
          ) : (
            <div>
              <p className='text-muted'>
                An error occurred while loading the products
                <br /> please try again.
              </p>
              <br />
              <button onClick={getProducts}>
                <svg
                  version='1.1'
                  id='Capa_1'
                  xmlns='http://www.w3.org/2000/svg'
                  x='0px'
                  y='0px'
                  viewBox='0 0 489.533 489.533'
                >
                  <path
                    d='M268.175,488.161c98.2-11,176.9-89.5,188.1-187.7c14.7-128.4-85.1-237.7-210.2-239.1v-57.6c0-3.2-4-4.9-6.7-2.9
                                        l-118.6,87.1c-2,1.5-2,4.4,0,5.9l118.6,87.1c2.7,2,6.7,0.2,6.7-2.9v-57.5c87.9,1.4,158.3,76.2,152.3,165.6
                                        c-5.1,76.9-67.8,139.3-144.7,144.2c-81.5,5.2-150.8-53-163.2-130c-2.3-14.3-14.8-24.7-29.2-24.7c-17.9,0-31.9,15.9-29.1,33.6
                                        C49.575,418.961,150.875,501.261,268.175,488.161z'
                  />
                </svg>
                Reload
              </button>
            </div>
          )}
        </div>
      ) : (
        <Fragment>
          <h1 className='quote-details__heading px-2'>
            Please Select an Insurance Plan
          </h1>
          <h4 className='px-2 mt-3 text-muted'>
            Current planned trip lenght: {travelDetails.duration} Days
          </h4>

          <div className='row mt-5'>
            {products && products.length ? (
              products.map((product, i) => {
                const productDesc = getProductDescription(product);
                return (
                  <div key={i} className='col-sm-6'>
                    <div className='card quote-details__card'>
                      <div className='card-header'>
                        <div className='card-title'>
                          {productDesc?.title} (
                          {`${productDesc?.minDays} - ${productDesc?.maxDays} Days`}
                          )
                        </div>
                      </div>
                      <div className='card-body'>
                        {productDesc?.description}
                      </div>
                      <div className='card-footer'>
                        {!(
                          productDesc?.minDays < travelDetails?.duration &&
                          travelDetails?.duration > productDesc?.maxDays
                        ) ? (
                          <button
                            disabled={
                              productDesc?.minDays < travelDetails?.duration &&
                              travelDetails?.duration > productDesc?.maxDays
                            }
                            onClick={() => buyNow(product)}
                          >
                            Buy Now
                          </button>
                        ) : (
                          <div className='alert  alert-warning p-2'>
                            <p style={{ fontSize: '1.4rem' }} className='m-0'>
                              Travel Details does not qualify for this Plan
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h4 className='text-muted'>No products available</h4>
            )}
            <div className='quote-details__button px-5 col-sm-12'>
              <button
                type='button'
                className='button-rounded button-rounded--outline'
                onClick={() =>
                  activePage !== 0 && setActivePage(activePage - 1)
                }
              >
                Back
              </button>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}
