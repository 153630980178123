export const dashboardMenu = {
  index: {
    title: 'Dashboard',
    route: 'index',
    icon: (
      <svg
        width='25'
        height='20'
        viewBox='0 0 25 20'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M12.5 0C5.59635 0 0 5.59635 0 12.5C0 14.7917 0.61849 16.9384 1.69531 18.7847C1.9388 19.2023 2.40278 19.4444 2.88628 19.4444H22.1137C22.5972 19.4444 23.0612 19.2023 23.3047 18.7847C24.3815 16.9384 25 14.7917 25 12.5C25 5.59635 19.4036 0 12.5 0ZM12.5 2.77778C13.1385 2.77778 13.6536 3.21745 13.816 3.80425C13.7678 3.90234 13.7014 3.98785 13.6662 4.09375L13.2661 5.2947C13.0434 5.44618 12.7899 5.55555 12.5004 5.55555C11.7335 5.55555 11.1115 4.93359 11.1115 4.16667C11.1115 3.39974 11.7331 2.77778 12.5 2.77778ZM4.16667 15.2778C3.39974 15.2778 2.77778 14.6558 2.77778 13.8889C2.77778 13.122 3.39974 12.5 4.16667 12.5C4.93359 12.5 5.55556 13.122 5.55556 13.8889C5.55556 14.6558 4.93359 15.2778 4.16667 15.2778ZM6.25 8.33333C5.48307 8.33333 4.86111 7.71137 4.86111 6.94444C4.86111 6.17752 5.48307 5.55555 6.25 5.55555C7.01693 5.55555 7.63889 6.17752 7.63889 6.94444C7.63889 7.71137 7.01693 8.33333 6.25 8.33333ZM16.9605 5.19054L14.2986 13.1766C14.8928 13.6862 15.2778 14.4332 15.2778 15.2778C15.2778 15.7865 15.1311 16.2565 14.8924 16.6667H10.1076C9.86892 16.2565 9.72222 15.7865 9.72222 15.2778C9.72222 13.8047 10.8724 12.6115 12.322 12.5178L14.9844 4.53125C15.1654 3.98611 15.7539 3.68707 16.3021 3.87283C16.8477 4.05469 17.1419 4.64496 16.9605 5.19054ZM17.5968 7.67318L18.2704 5.65278C18.421 5.59679 18.5799 5.55599 18.75 5.55599C19.5169 5.55599 20.1389 6.17795 20.1389 6.94488C20.1389 7.7118 19.5169 8.33377 18.75 8.33377C18.2561 8.33333 17.8433 8.0612 17.5968 7.67318ZM20.8333 15.2778C20.0664 15.2778 19.4444 14.6558 19.4444 13.8889C19.4444 13.122 20.0664 12.5 20.8333 12.5C21.6003 12.5 22.2222 13.122 22.2222 13.8889C22.2222 14.6558 21.6003 15.2778 20.8333 15.2778Z' />
      </svg>
    )
  },
  'buy-insurance-offline': {
    title: 'Buy As Staff',
    route: 'buy-insurance-offline',
    permission: 'create_user_travel',
    icon: (
      <svg
        width='25'
        height='23'
        viewBox='0 0 25 23'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M20.8332 8.33333H15.8727L11.3111 0.349826C11.2503 0.243536 11.1624 0.155204 11.0565 0.0937728C10.9505 0.0323416 10.8302 -7.67912e-06 10.7078 1.36733e-09L7.8649 1.36733e-09C7.40353 1.36733e-09 7.07063 0.441406 7.19737 0.884983L9.32541 8.33333H4.861L2.986 5.83333C2.85492 5.65842 2.64876 5.55556 2.43044 5.55556H0.694763C0.24294 5.55556 -0.0886568 5.98003 0.0211523 6.4184L1.38877 11.1111L0.0211523 15.8038C-0.0886568 16.2422 0.24294 16.6667 0.694763 16.6667H2.43044C2.64919 16.6667 2.85492 16.5638 2.986 16.3889L4.861 13.8889H9.32541L7.19737 21.3368C7.07063 21.7804 7.40353 22.2222 7.8649 22.2222H10.7078C10.9569 22.2222 11.187 22.0885 11.3106 21.8724L15.8727 13.8889H20.8332C22.3675 13.8889 24.9999 12.6454 24.9999 11.1111C24.9999 9.57682 22.3675 8.33333 20.8332 8.33333Z' />
      </svg>
    )
  },
  'buy-insurance': {
    title: 'Buy Insurance',
    route: 'buy-insurance',
    icon: (
      <svg
        width='25'
        height='23'
        viewBox='0 0 25 23'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M20.8332 8.33333H15.8727L11.3111 0.349826C11.2503 0.243536 11.1624 0.155204 11.0565 0.0937728C10.9505 0.0323416 10.8302 -7.67912e-06 10.7078 1.36733e-09L7.8649 1.36733e-09C7.40353 1.36733e-09 7.07063 0.441406 7.19737 0.884983L9.32541 8.33333H4.861L2.986 5.83333C2.85492 5.65842 2.64876 5.55556 2.43044 5.55556H0.694763C0.24294 5.55556 -0.0886568 5.98003 0.0211523 6.4184L1.38877 11.1111L0.0211523 15.8038C-0.0886568 16.2422 0.24294 16.6667 0.694763 16.6667H2.43044C2.64919 16.6667 2.85492 16.5638 2.986 16.3889L4.861 13.8889H9.32541L7.19737 21.3368C7.07063 21.7804 7.40353 22.2222 7.8649 22.2222H10.7078C10.9569 22.2222 11.187 22.0885 11.3106 21.8724L15.8727 13.8889H20.8332C22.3675 13.8889 24.9999 12.6454 24.9999 11.1111C24.9999 9.57682 22.3675 8.33333 20.8332 8.33333Z' />
      </svg>
    )
  },
  profile: {
    title: 'Profile',
    route: 'profile',
    icon: (
      <svg
        width='25'
        height='25'
        viewBox='0 0 25 25'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M12.1094 0C5.41992 0 0 5.41992 0 12.1094C0 18.7988 5.41992 24.2188 12.1094 24.2188C18.7988 24.2188 24.2188 18.7988 24.2188 12.1094C24.2188 5.41992 18.7988 0 12.1094 0ZM12.1094 4.6875C14.4824 4.6875 16.4062 6.61133 16.4062 8.98438C16.4062 11.3574 14.4824 13.2812 12.1094 13.2812C9.73633 13.2812 7.8125 11.3574 7.8125 8.98438C7.8125 6.61133 9.73633 4.6875 12.1094 4.6875ZM12.1094 21.4844C9.24316 21.4844 6.6748 20.1855 4.95605 18.1543C5.87402 16.4258 7.6709 15.2344 9.76562 15.2344C9.88281 15.2344 10 15.2539 10.1123 15.2881C10.7471 15.4932 11.4111 15.625 12.1094 15.625C12.8076 15.625 13.4766 15.4932 14.1064 15.2881C14.2188 15.2539 14.3359 15.2344 14.4531 15.2344C16.5479 15.2344 18.3447 16.4258 19.2627 18.1543C17.5439 20.1855 14.9756 21.4844 12.1094 21.4844Z' />
      </svg>
    )
  },
  'process-insurance': {
    title: 'Buy Insurance',
    route: 'buy-insurance',
    hidden: true,
    icon: (
      <svg
        width='25'
        height='25'
        viewBox='0 0 25 25'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M12.1094 0C5.41992 0 0 5.41992 0 12.1094C0 18.7988 5.41992 24.2188 12.1094 24.2188C18.7988 24.2188 24.2188 18.7988 24.2188 12.1094C24.2188 5.41992 18.7988 0 12.1094 0ZM12.1094 4.6875C14.4824 4.6875 16.4062 6.61133 16.4062 8.98438C16.4062 11.3574 14.4824 13.2812 12.1094 13.2812C9.73633 13.2812 7.8125 11.3574 7.8125 8.98438C7.8125 6.61133 9.73633 4.6875 12.1094 4.6875ZM12.1094 21.4844C9.24316 21.4844 6.6748 20.1855 4.95605 18.1543C5.87402 16.4258 7.6709 15.2344 9.76562 15.2344C9.88281 15.2344 10 15.2539 10.1123 15.2881C10.7471 15.4932 11.4111 15.625 12.1094 15.625C12.8076 15.625 13.4766 15.4932 14.1064 15.2881C14.2188 15.2539 14.3359 15.2344 14.4531 15.2344C16.5479 15.2344 18.3447 16.4258 19.2627 18.1543C17.5439 20.1855 14.9756 21.4844 12.1094 21.4844Z' />
      </svg>
    )
  },
  users: {
    title: 'Users',
    route: 'users',
    permission: 'list_users',
    admin: true,
    icon: (
      <svg
        width='25'
        height='25'
        viewBox='0 0 25 25'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M12.1094 0C5.41992 0 0 5.41992 0 12.1094C0 18.7988 5.41992 24.2188 12.1094 24.2188C18.7988 24.2188 24.2188 18.7988 24.2188 12.1094C24.2188 5.41992 18.7988 0 12.1094 0ZM12.1094 4.6875C14.4824 4.6875 16.4062 6.61133 16.4062 8.98438C16.4062 11.3574 14.4824 13.2812 12.1094 13.2812C9.73633 13.2812 7.8125 11.3574 7.8125 8.98438C7.8125 6.61133 9.73633 4.6875 12.1094 4.6875ZM12.1094 21.4844C9.24316 21.4844 6.6748 20.1855 4.95605 18.1543C5.87402 16.4258 7.6709 15.2344 9.76562 15.2344C9.88281 15.2344 10 15.2539 10.1123 15.2881C10.7471 15.4932 11.4111 15.625 12.1094 15.625C12.8076 15.625 13.4766 15.4932 14.1064 15.2881C14.2188 15.2539 14.3359 15.2344 14.4531 15.2344C16.5479 15.2344 18.3447 16.4258 19.2627 18.1543C17.5439 20.1855 14.9756 21.4844 12.1094 21.4844Z' />
      </svg>
    )
  },
  roles: {
    title: 'Roles',
    route: 'roles',
    permission: 'list_roles',
    admin: true,
    icon: (
      <svg
        height='25'
        viewBox='0 0 512 512'
        width='25'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g>
          <path d='m255 307.5c-21.234 0-39.068 14.788-43.774 34.603 27.478 13.865 60.058 13.872 87.549 0-4.707-19.815-22.541-34.603-43.775-34.603z' />
          <path
            id='XMLID_2189_'
            d='m255 157.5c-53.762 0-97.5 43.738-97.5 97.5 0 26.729 10.814 50.978 28.295 68.609 5.995-14.307 16.308-26.363 29.292-34.56-7.839-9.175-12.587-21.065-12.587-34.049 0-28.949 23.551-52.5 52.5-52.5 28.948 0 52.5 23.551 52.5 52.5 0 12.984-4.749 24.874-12.587 34.049 12.985 8.197 23.297 20.253 29.292 34.56 17.48-17.63 28.295-41.879 28.295-68.609 0-53.762-43.738-97.5-97.5-97.5z'
          />
          <circle id='XMLID_2266_' cx='255' cy='255' r='22.5' />
          <path
            id='XMLID_2274_'
            d='m510 297.707v-85.414l-67.57-11.262c-4.035-14.043-9.643-27.561-16.75-40.382l39.831-55.763-60.397-60.397-55.763 39.831c-12.819-7.106-26.34-12.714-40.382-16.75l-11.262-67.57h-85.414l-11.262 67.57c-14.042 4.036-27.563 9.645-40.382 16.75l-55.763-39.831-60.397 60.397 39.831 55.763c-7.106 12.82-12.715 26.34-16.75 40.382l-67.57 11.262v85.414l67.57 11.262c4.036 14.042 9.644 27.563 16.75 40.382l-39.831 55.763 60.397 60.397 55.763-39.831c12.821 7.107 26.339 12.715 40.382 16.75l11.262 67.57h85.414l11.262-67.57c14.042-4.035 27.563-9.644 40.382-16.75l55.763 39.831 60.397-60.397-39.831-55.763c7.106-12.819 12.715-26.34 16.75-40.382zm-255 84.793c-70.304 0-127.5-57.196-127.5-127.5s57.196-127.5 127.5-127.5 127.5 57.196 127.5 127.5-57.196 127.5-127.5 127.5z'
          />
        </g>
      </svg>
    )
  },
  permissions: {
    title: 'Permissions',
    route: 'permissions',
    permission: 'list_tasks',
    icon: (
      <svg
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        height='25'
        width='25'
        viewBox='0 0 512 512'
      >
        <g>
          <g>
            <path d='M500.6,212.6l-59.9-14.7c-3.3-10.5-7.5-20.7-12.6-30.6l30.6-51c3.6-6,2.7-13.5-2.1-18.3L414,55.4c-4.8-4.8-12.3-5.7-18.3-2.1l-51,30.6c-9.9-5.1-20.1-9.3-30.6-12.6l-14.4-59.9C297.9,4.8,291.9,0,285,0h-60c-6.9,0-12.9,4.8-14.7,11.4l-14.4,59.9c-10.5,3.3-20.7,7.5-30.6,12.6l-51-30.6c-6-3.6-13.5-2.7-18.3,2.1L53.4,98c-4.8,4.8-5.7,12.3-2.1,18.3l30.6,51c-5.1,9.9-9.3,20.1-12.6,30.6l-57.9,14.7C4.8,214.1,0,220.1,0,227v60c0,6.9,4.8,12.9,11.4,14.4l57.9,14.7c3.3,10.5,7.5,20.7,12.6,30.6l-30.6,51c-3.6,6-2.7,13.5,2.1,18.3L96,458.6c4.8,4.8,12.3,5.7,18.3,2.1l51-30.6c9.9,5.1,20.1,9.3,30.6,12.6l14.4,57.9c1.8,6.6,7.8,11.4,14.7,11.4h60c6.9,0,12.9-4.8,14.7-11.4l14.4-57.9c10.5-3.3,20.7-7.5,30.6-12.6l51,30.6c6,3.6,13.5,2.7,18.3-2.1l42.6-42.6c4.8-4.8,5.7-12.3,2.1-18.3l-30.6-51c5.1-9.9,9.3-20.1,12.6-30.6l59.9-14.7c6.6-1.5,11.4-7.5,11.4-14.4v-60C512,220.1,507.2,214.1,500.6,212.6z M255,332c-41.4,0-75-33.6-75-75c0-41.4,33.6-75,75-75c41.4,0,75,33.6,75,75C330,298.4,296.4,332,255,332z' />
          </g>
        </g>
      </svg>
    )
  },
  reports: {
    title: 'Reports',
    route: 'report',
    // permission: 'list_tasks',
    admin: true,
    icon: (
      <svg
        height='25'
        viewBox='0 0 512 512'
        width='25'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g>
          <path d='m497 482h-467v-467c0-8.291-6.709-15-15-15s-15 6.709-15 15v482c0 8.291 6.709 15 15 15h482c8.291 0 15-6.709 15-15s-6.709-15-15-15z' />
          <path d='m75 452h61c8.291 0 15-6.709 15-15v-91c0-8.291-6.709-15-15-15h-61c-8.291 0-15 6.709-15 15v91c0 8.291 6.709 15 15 15z' />
          <path d='m196 271c-8.291 0-15 6.709-15 15v151c0 8.291 6.709 15 15 15h60c8.291 0 15-6.709 15-15v-151c0-8.291-6.709-15-15-15z' />
          <path d='m316 211c-8.291 0-15 6.709-15 15v211c0 8.291 6.709 15 15 15h60c8.291 0 15-6.709 15-15v-211c0-8.291-6.709-15-15-15z' />
          <path d='m497 151h-61c-8.291 0-15 6.709-15 15v271c0 8.291 6.709 15 15 15h61c8.291 0 15-6.709 15-15v-271c0-8.291-6.709-15-15-15z' />
          <path d='m61.787 263.104c3.959 7.359 13.109 9.994 20.317 6.108l349.287-187.538-8.809 17.617c-3.706 7.412-.703 16.421 6.709 20.127 7.48 3.713 16.436.652 20.127-6.709l30-60c2.329-4.644 2.08-10.166-.659-14.59s-7.559-7.119-12.759-7.119h-75c-8.291 0-15 6.709-15 15s6.709 15 15 15h15.5l-338.604 181.787c-7.295 3.941-10.035 13.037-6.109 20.317z' />
        </g>
      </svg>
    )
  },
  logout: {
    title: 'Logout',
    icon: (
      <svg
        height='25'
        viewBox='0 -10 490 490'
        width='25'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='m474.667969 251h-309.335938c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h309.335938c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0' />
        <path d='m250.667969 336.332031c-4.097657 0-8.191407-1.554687-11.308594-4.691406l-85.332031-85.332031c-6.25-6.253906-6.25-16.386719 0-22.636719l85.332031-85.332031c6.25-6.25 16.382813-6.25 22.636719 0 6.25 6.25 6.25 16.382812 0 22.632812l-74.027344 74.027344 74.027344 74.027344c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.234375 4.671875-11.328125 4.671875zm0 0' />
        <path d='m234.667969 469.667969c-129.386719 0-234.667969-105.28125-234.667969-234.667969s105.28125-234.667969 234.667969-234.667969c97.085937 0 182.804687 58.410157 218.410156 148.824219 3.242187 8.210938-.8125 17.492188-9.023437 20.753906-8.214844 3.203125-17.496094-.789062-20.757813-9.042968-30.742187-78.082032-104.789063-128.535157-188.628906-128.535157-111.746094 0-202.667969 90.925781-202.667969 202.667969s90.921875 202.667969 202.667969 202.667969c83.839843 0 157.886719-50.453125 188.628906-128.511719 3.242187-8.257812 12.523437-12.246094 20.757813-9.046875 8.210937 3.242187 12.265624 12.542969 9.023437 20.757813-35.605469 90.390624-121.324219 148.800781-218.410156 148.800781zm0 0' />
      </svg>
    )
  }
};
