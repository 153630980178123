import { Modal } from 'react-bootstrap';
import React from 'react';

export default function RolesActiveProfile({
  role,
  hide,
  activateRole,
  loading
}) {
  return (
    <Modal
      show={role}
      onHide={hide}
      centered
      backdrop={loading ? 'static' : true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {role?.enabled ? 'Enable' : 'Disable'} {role?.name || 'Role'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h4 className='px-2 py-3 text-danger'>
            Are your sure you want to {!role?.enabled ? 'enable' : 'disable'}{' '}
            {role?.name || 'Role'}?
          </h4>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className='button-rounded button-rounded--outline'
          disabled={loading}
          onClick={hide}
        >
          Close
        </button>
        <button
          className='button-rounded main'
          onClick={() => activateRole(role)}
          disabled={loading}
        >
          {loading ? 'Updating...' : 'Update'}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
