export default function buildGibsProfileData(
  profileData,
  GibbsApiKey,
  product
) {
  return {
    Apikey: GibbsApiKey,
    PolicyStartDate: profileData.from_date,
    PolicyEndDate: profileData.to_date,
    TravelPurpose: profileData.purpose,
    Destination: profileData.destination,
    ContactAddress: profileData.address,
    LastName: profileData.last_name,
    FirstName: profileData.first_name,
    Gender: profileData.gender,
    Title: profileData.title,
    PassportNo: profileData.passport_no,
    PhoneNo: profileData.phone,
    Email: profileData.email,
    DOB: profileData.date_of_birth,
    NoK_Name: profileData?.next_of_kin?.nok_name || "",
    NoK_Relationship: profileData?.next_of_kin?.nok_relationship || "",
    NoK_Address: profileData?.next_of_kin?.nok_address || "",
    NoK_Email: profileData?.next_of_kin?.nok_email || "",
    NoK_PhoneNo: profileData?.next_of_kin?.nok_phone || "",
    CoverType: product.dsProduct
  };
}
