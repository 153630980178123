export const schemas = {
  password: {
    required: true,
    minLength: 5,
    maxLength: 20,
    label: 'Password'
  },
  email: {
    required: true,
    inputType: 'email',
    label: 'Email'
  }
};
