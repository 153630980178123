import React, { Fragment, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './config/protected-route';
import Home from './pages/Home/home.index';
import Dashboard from './pages/Dashboard/dashboard.index';
import NotFound from './pages/NotFound/notfound';
import Quotes from './pages/Quotes/quotes.index';
import QuotesSuccessPage from './pages/Quotes/SuccessPage/success-page';
import Login from './pages/Auth/login';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import useLoginUser from './hooks/useLoginUser';
import { Spinner } from 'react-bootstrap';
import ForgotPassword from './pages/Auth/forgot-password';
import Retry from './components/commons/retry';

import ChangePassword from './pages/Auth/change-password';
import { useIdleTimer } from 'react-idle-timer';
import FirstChangePassword from './pages/Auth/first-change-password';
import ModalLogin from './pages/Auth/modal-login';
import localforage from 'localforage';
import InBetween from './pages/Quotes/InBetween';
import Batch from './pages/Quotes/Batch/Batch';
import ProductDetails from './pages/Quotes/Batch/components/ProductDetails';
import BatchHome from './pages/Quotes/Batch/BatchHome';
import { Payment } from './pages/Quotes/Batch/components';
import Certificate from './pages/Quotes/Batch/components/certificate';
import Group from './pages/Quotes/Group';
import TravelDetails from './pages/Quotes/Group/TravelDetails';
import PassengerDetails from './pages/Quotes/Group/PassengerDetails';
import GroupPayment from './pages/Quotes/Group/GroupPayment';

const TIMEOUT_TIME = 15;

function App() {
  const { updateLogin, user, logoutUser } = useLoginUser();
  const [loading, setLoading] = useState(true);
  const [showTimeOut, setShowTimeOut] = useState(false);

  const handleOnIdle = async () => {
    let loginState = await localforage.getItem('loginState');
    if (loginState) {
      if (showTimeOut) return;
      localforage.removeItem('loginState');
      setShowTimeOut(true);
    }
  };

  const handleOnActive = () => {
    localforage.setItem('loginStateLastActiveTime', getLastActiveTime());
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * TIMEOUT_TIME,
    onIdle: handleOnIdle,
    onAction: handleOnActive,
    debounce: 5000,
    crossTab: true
  });

  const init = async () => {
    let loginState = await localforage.getItem('loginState');

    const loginStateLastActiveTime = parseInt(
      await localforage.getItem('loginStateLastActiveTime')
    );

    //if last active is older than 15min, logout
    if (
      loginState &&
      loginStateLastActiveTime + 1000 * 60 * TIMEOUT_TIME < new Date().getTime()
    ) {
      return logoutUser();
    }

    if (loginState) {
      await updateLogin(loginState);
    }

    setTimeout(() => {
      setLoading(false);
      if (window.Tawk_API) {
        window.Tawk_API.visitor = {
          name: loginState?.user?.name,
          email: loginState?.user?.email
        };
      }
    }, 500);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='App'>
      {showTimeOut && (
        <ModalLogin
          close={() => setShowTimeOut(false)}
          email={user?.email}
          setShowTimeOut
        />
      )}
      <Fragment>
        <div>
          {loading ? (
            <div className='app__loader'>
              <Spinner color='primary' animation='border' role='status' />
            </div>
          ) : !user ? (
            <div>
              <Retry
                action={init}
                msg={
                  <span>
                    An error occurred while fetching user data
                    <br /> please try again.
                  </span>
                }
              />
            </div>
          ) : (
            <Routes>
              <Route index element={<Home />} />
              <Route path='/certificate' element={<Certificate />} />
              <Route path='/inquiry/:package/:class' element={<InBetween />} />
              <Route path='/get-quotes/:package/:class' element={<Quotes />} />
              <Route path='/quotes'>
                <Route path='batch' element={<Batch />}>
                  <Route index element={<BatchHome />} />
                  <Route path='product-details' element={<ProductDetails />} />
                  <Route path='payment' element={<Payment />} />
                </Route>
                <Route path='group' element={<Group />}>
                  <Route path='travel-details' element={<TravelDetails />} />
                  <Route
                    path='passenger-details'
                    element={<PassengerDetails />}
                  />
                  <Route path='payment' element={<GroupPayment />} />
                </Route>
                <Route
                  path='success/:travel_id/:payment_id'
                  element={<QuotesSuccessPage />}
                />
              </Route>
              <Route path='/login' element={<Login />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/change-password' element={<ChangePassword />} />
              <Route
                path='/first-change-password'
                element={<FirstChangePassword />}
              />
              {/* <Route element={<ProtectedRoute />}> */}
              <Route
                path='/dashboard/:path?/:subpath?/:var?'
                element={<Dashboard />}
              />
              {/* </Route> */}

              <Route element={<NotFound heading='Page not found' />} />
            </Routes>
          )}
          <ToastContainer />
        </div>
      </Fragment>
    </div>
  );
}

export default App;
