import React, { useState, useCallback, useEffect } from 'react';
import { Spinner, ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { baseUrl } from '../../../constants';
import {
  headerState,
  quotesPageState,
  passportUrlState,
  travelDetailFormState,
  uploadState,
  fileState
} from '../../../recoil/atoms';
// import { firebase } from '../../../utilities';
import upload from '../../../assets/svg/upload.svg';

export default function PassportUpload() {
  const [file, setFile] = useRecoilState(fileState);
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [, setPassport] = useRecoilState(passportUrlState);
  const [headers] = useRecoilState(headerState);
  const [activePage, setActivePage] = useRecoilState(quotesPageState);
  const [travelDetailForm] = useRecoilState(travelDetailFormState);
  const [, setUploadState] = useRecoilState(uploadState);

  let interval;
  let baseProgress;
  useEffect(() => {
    if (
      file &&
      Object.keys(travelDetailForm).length &&
      travelDetailForm.user_id
    ) {
      savePassport(file, travelDetailForm.user_id);
    }
  }, [file, travelDetailForm, travelDetailForm.user_id]);

  const savePassport = async (passportFile, userId) => {
    let status = true;
    setLoading(true);
    interval && clearInterval(interval);
    interval = setInterval(() => {
      baseProgress++;
      if (isNaN(baseProgress)) {
        baseProgress = 10;
      }
      baseProgress < 95 && setProgress(baseProgress);
    }, 50);
    const formData = new FormData();

    formData.append('file', passportFile);
    formData.append('userId', userId);

    const reqHeaders = { ...headers };
    delete reqHeaders['Content-Type'];

    try {
      const res = await fetch(`${baseUrl}/users/savePassport`, {
        method: 'POST',
        headers: reqHeaders,
        body: formData
      });

      const data = await res.json();

      if (data.error || data.errors) throw new Error('message: ', data.message);
      else setUploadState('success'); // passport has been sent to backend
    } catch (error) {
      // toast.error('An error occurred, please try again');
      setUploadState('failure');
      status = false;
      setError(true);
      setLoading(false);
    }

    clearInterval(interval);
    interval = setInterval(() => {
      baseProgress++;
      baseProgress <= 100 && setProgress(baseProgress);
      if (baseProgress >= 110) {
        // setLoading(false);
        clearInterval(interval);
      }
    }, 50);
  };

  const onDrop = useCallback(acceptedFiles => {
    setPassport(null);
    if (acceptedFiles[0] && acceptedFiles[0].size > 2097152) {
      toast.error('Please select a file smaller than 2Mb');
      return;
    }

    if (acceptedFiles[0] && !acceptedFiles[0].type.includes('image')) {
      toast.error('Please select an image file (jpeg, png, gif)');
      return;
    }

    setFile(acceptedFiles[0]);
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className=''>
      <div className='passport-upload' {...getRootProps()}>
        <div className='dropzone'>
          <input {...getInputProps()} accept='image/*' />
          {
            <div className='insurance-documents__upload'>
              <img src={upload} alt='upload' />
              <div className='upload-text'>
                <p className='mb-0 '>
                  Drag and drop or click to select file
                </p>
                <p className='mt-1'>
                  <small>
                    <b>Note: </b>The file should be smaller 2MB and must be an
                    image (jpeg,jpg,png,gif)
                  </small>
                </p>
                <button
                  className='button-rounded upload-button  '
                  onClick={e => e.preventDefault()}
                >
                  Upload
                </button>
              </div>
            </div>
          }
        </div>
      </div>

      {file && (
        <div className='uploaded-file'>
          <img src={upload} alt='upload' width='40px' /> {file.name}
        </div>
      )}

      {loading && (
        <div className='mt-3'>
          <ProgressBar striped variant='primary' now={progress} />
        </div>
      )}
    </div>
  );
}
