import { selector } from "recoil";
import { loginState, headerState } from "./atoms";

const currentLoginState = selector({
  key: "currentLoginState",
  get: ({ get }) => {
    return get(loginState);
  },
});

const getHeaderState = selector({
  key: "getHeaderState",
  get: ({ get }) => {
    return get(headerState);
  },
});

export { currentLoginState, getHeaderState };
