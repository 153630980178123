import React from 'react';
import { useRecoilState } from 'recoil';
import { quotesPageState } from '../../recoil/atoms';
import TravelDetails from './TravelDetails/travel-details';
import PassengerDetails from './PassengerDetails/passenger-details';
import SelectPlan from './SelectPlan/select-plan';
import ProductDetails from './ProductDetails/product-details';

const QuotesForms = () => {
  const quotePages = [
    <TravelDetails />,
    <PassengerDetails />,
    <SelectPlan />,
    <ProductDetails />
  ];
  const [activePage] = useRecoilState(quotesPageState);
  return <div>{quotePages[activePage]}</div>;
};

export default QuotesForms;
