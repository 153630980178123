export const schemas = {
  name: {
    required: true,
    maxLength: 20,
    label: 'Other Names'
  },
  last_name: {
    required: true,
    maxLength: 20,
    label: 'Surname'
  },
  other_names: {
    required: false,
    maxLength: 20,
    label: 'Other Name'
  },
  email: {
    required: true,
    inputType: 'email',
    label: 'Email'
  },
  address: {
    required: false,
    label: 'Address'
  },
  phone: {
    required: true,
    maxLength: 15,
    minLength: 9,
    maxWords: 1,
    label: 'Phone Number'
  },
  user_type: {
    required: true,
    label: 'User Type'
  },
  role_id: {
    required: false,
    label: 'Role'
  }
};
