import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate, useParams, NavLink } from 'react-router-dom';

import {
  dashboardMenuState,
  permissionuserDataState
} from '../../recoil/atoms';

import logo from '../../assets/image/logo.png';
import { dashboardMenu } from '../../pages/Dashboard/dashboard-menu';
import useLoginUser from '../../hooks/useLoginUser';

const Sidebar = () => {
  const nav = useNavigate();
  const { path } = useParams();
  const { logoutUser, user } = useLoginUser();

  const [menu, setMenu] = useRecoilState(dashboardMenuState);
  const permissions = useRecoilValue(permissionuserDataState);

  return (
    <div className=''>
      {menu && (
        <div className='sidebar_toggle' onClick={() => setMenu(false)}>
          &times;
        </div>
      )}

      <div className={`sidebar ${menu ? 'sidebar_show' : ''}`}>
        <NavLink to='/dashboard/index'>
          <img src={logo} alt='CHI' />
        </NavLink>

        <ul className='sidebar__links'>
          {Object.values(dashboardMenu).map(menu =>
            (!menu.admin || (menu.admin && user.is_super_admin)) &&
            (!menu.permission || permissions[menu.permission]) &&
            !menu.hidden ? (
              <li
                key={menu.title}
                className={menu.route === path ? 'active' : ''}
                onClick={() => {
                  if (menu.title === 'Logout') return logoutUser();
                  nav(`/dashboard/${menu.route}`);
                  setMenu(false);
                }}
              >
                {menu.icon}

                <span>{menu.title}</span>
              </li>
            ) : (
              ''
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
