import React from 'react';
import { useRecoilState } from 'recoil';
import { quotesPageState } from '../../../recoil/atoms';
import TravelDetails from './TravelDetails/travel-details';
import PassengerDetails from './PassengerDetails/passenger-details';
import NextOfKinDetails from './NextOfKinDetails/next-of-kin-details';

const QuotesForms = () => {
  const quotePages = [
    <TravelDetails />,
    <PassengerDetails />,
    <NextOfKinDetails />
  ];
  const [activePage] = useRecoilState(quotesPageState);
  return <div>{quotePages[activePage]}</div>;
};

export default QuotesForms;
