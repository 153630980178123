import { useState, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import {
  buyInsuranceScreenState,
  travelDetailsState,
  insuranceDataState,
  guardianDetailsState,
  // selectedProfileTabState,
  profileDataState
} from '../../../../recoil/atoms';
import CustomDropdown from '../../../../components/custom-dropdown';
import {
  checkValidations,
  ErrorMessage
} from '../../../../utilities/use-validation';
import { schemas as travelSchema } from './travel-details.schema';
import moment from 'moment';
import { genders, titles, relationships } from '../../../../constants';
import { useNavigate } from 'react-router-dom';
import useLoginUser from '../../../../hooks/useLoginUser';
import { getAge } from '../../../../utilities';

import { guardianSchema } from '../../../Quotes/NextOfKinDetails/next-of-kin.schema';

import useCountries from '../../../../hooks/useCountries';

const TravelDetails = ({ travel }) => {
  const nav = useNavigate();
  const [travelDetails, setTravelDetails] = useRecoilState(travelDetailsState);
  const [activePage, setActivePage] = useRecoilState(buyInsuranceScreenState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { countries } = useCountries();

  const { updateMapKey } = useLoginUser();

  const [insuranceData, setInsuranceData] = useRecoilState(insuranceDataState);
  const [guardianDetails, setGuardianDetails] =
    useRecoilState(guardianDetailsState);
  const [, setProfileData_state] = useRecoilState(profileDataState);

  const handleValidations = (data, field) => {
    const schemas = isMinor
      ? { ...travelSchema, ...guardianSchema }
      : travelSchema;

    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleChange = (name, value) => {
    const data = { ...travelDetails, [name]: value };
    if (name === 'startDate') data.endDate = '';
    setTravelDetails(data);
    handleValidations(data, name);
  };

  const handleGuardianChange = (name, value) => {
    const data = { ...guardianDetails, [name]: value };
    setGuardianDetails(data);
    handleValidations(data, name);
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const data = isMinor
      ? { ...travelDetails, ...guardianDetails }
      : travelDetails;

    const { success } = handleValidations(data);

    if (success) {
      saveUserAndProceed();
    }
  };

  useEffect(() => {
    getDateDifference(travelDetails.startDate, travelDetails.endDate);
    // eslint-disable-next-line
  }, [travelDetails.startDate, travelDetails.endDate]);

  const isMinor = useMemo(
    () => getAge(travel?.user?.profile?.date_of_birth) < 18,
    [travel]
  );

  const formateMafreDate = _date => {
    return _date.split('-').reverse().join('/');
  };

  const saveInsuranceData = (
    start_date,
    end_date,
    duration,
    destination,
    policyHolder,
    insuredData
  ) => {
    let data = {
      duration,
      destination,
      idRegProduct: insuranceData?.idRegProduct || null,
      terms: 1,
      start_date: formateMafreDate(start_date),
      end_date: formateMafreDate(end_date),
      policyHolder,
      insuredData,
      deliveryDate: start_date
    };
    setInsuranceData(data);
  };

  const getPassenger = () => {
    return {
      name: travel?.user?.name,
      first_name: travel?.user?.name,
      last_name: travel?.user?.profile?.last_name,
      other_names: travel?.user?.profile?.other_names,
      gender: travel?.user?.profile?.gender || 'N/A', //TODO: add to ui
      email: travel?.user?.email,
      phone: travel?.user?.phone,
      passport_no: travel?.user?.profile?.passport_no,
      destination: travelDetails.destination,
      title: travel?.user?.title || '',
      age: getAge(travel?.user?.profile?.date_of_birth),
      date_of_birth: travel?.user?.profile?.date_of_birth,
      medical_cond: 'N/A',
      address: travel?.user?.profile?.address,
      nok_name: travel?.user?.profile?.next_of_kin?.nok_name || '',
      nok_relationship:
        travel?.user?.profile?.next_of_kin?.nok_relationship || '',
      nok_phone: travel?.user?.profile?.next_of_kin?.nok_phone || '',
      nok_email: travel?.user?.profile?.next_of_kin?.nok_email || '',
      nok_address: travel?.user?.profile?.next_of_kin?.nok_address || '',
      is_holder: !isMinor,
      no_of_days: travelDetails.duration,
      purpose: travelDetails.purpose,
      from_date: travelDetails.startDate,
      to_date: travelDetails.endDate,
      travel_id: travel?.id
    };
  };

  const getGuardian = () => ({
    name: guardianDetails.guardianFirstName,
    first_name: guardianDetails.guardianFirstName,
    last_name: guardianDetails.guardianLastName,
    other_names: guardianDetails.guardianOtherName,
    gender: guardianDetails.gender || 'N/A',
    address: guardianDetails.guardianAddress,
    phone: guardianDetails.guardianPhoneNumber,
    email: guardianDetails.guardianEmail,
    passport_no: guardianDetails.guardianPassportNumber,
    title: guardianDetails.title || '',
    age: getAge(guardianDetails.guardianDateOfBirth),
    date_of_birth: guardianDetails.guardianDateOfBirth,
    is_holder: isMinor,
    no_of_days: travelDetails.duration,
    purpose: travelDetails.purpose,
    from_date: travelDetails.startDate,
    to_date: travelDetails.endDate
  });

  const saveUserAndProceed = async () => {
    setLoading(true);
    const allInsurers = [];

    const passenger = getPassenger();

    allInsurers.push(passenger);

    let policyHolder = passenger;
    const guardian = getGuardian();
    if (isMinor) {
      if (isMinor) policyHolder = guardian;
      allInsurers.push(guardian);
    }

    let profileData = JSON.parse(JSON.stringify(passenger));
    profileData['attaches'] = [];
    profileData['guardian'] = guardian;

    profileData['next_of_kin'] = {
      nok_name: passenger.nok_name,
      nok_relationship: passenger.nok_relationship,
      nok_phone: passenger.nok_phone,
      nok_email: passenger.nok_email,
      nok_address: passenger.nok_address
    };

    saveInsuranceData(
      travelDetails.startDate,
      travelDetails.endDate,
      travelDetails.duration,
      travelDetails.destination,
      {
        ...policyHolder,
        date_of_birth: formateMafreDate(policyHolder.date_of_birth)
      },
      allInsurers.map(i => {
        i.date_of_birth = formateMafreDate(i.date_of_birth);
        return i;
      })
    );

    setProfileData_state(profileData);

    setActivePage(activePage + 1);

    setLoading(false);
  };

  const getDateDifference = (startDate, endDate) => {
    const difference = Math.floor(
      (new Date(endDate) - new Date(startDate)) / 86400000
    );
    handleChange('duration', difference + 1);
  };

  useEffect(() => {
    handleChange('purpose', travel?.purpose);
    updateMapKey();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='buy-insurance__details'>
      <h2 className='quote-details__heading mt-5'>
        Please give us some info about your travel
      </h2>

      <form action='' className='row mt-5' onSubmit={handleSubmit}>
        <div className='col-sm-6'>
          <label htmlFor='destination'>
            Destination <span className='text-danger'> *</span>
          </label>
          <CustomDropdown
            name='destination'
            onSelect={selection => {
              handleChange('destination', selection.cd);
            }}
            identifier='ds'
            data={countries}
            inputId={`destination`}
            placeholder='Pick your destination'
            disable={false}
            showItems={true}
            value={
              countries.find(
                country => country.cd === travelDetails.destination
              ) || {}
            }
          />
          <ErrorMessage
            message={errors.destination && errors.destination.message}
          />
        </div>

        <div className='col-sm-6'>
          <label htmlFor='purpose'>
            purpose <span className='text-danger'> *</span>
          </label>
          <input
            type='text'
            readOnly
            value={travelDetails.purpose || travel?.purpose}
            onChange={() => null}
          />
          <ErrorMessage message={errors.purpose && errors.purpose.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='destination'>
            Start Date <span className='text-danger'> *</span>
          </label>
          <input
            type='date'
            name='startDate'
            min={moment().format('YYYY-MM-DD')}
            value={travelDetails.startDate}
            onChange={event => handleChange('startDate', event.target.value)}
          />
          <ErrorMessage
            message={errors.startDate && errors.startDate.message}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='destination'>
            End Date <span className='text-danger'> *</span>
          </label>
          <input
            type='date'
            name='endDate'
            min={moment().format('YYYY-MM-DD')}
            value={travelDetails.endDate}
            onChange={event => handleChange('endDate', event.target.value)}
          />
          <ErrorMessage message={errors.endDate && errors.endDate.message} />
        </div>

        <div className='col-sm-6 mt-3'>
          <label htmlFor='destination'>Duration</label>
          <input
            type='text'
            readOnly
            disabled={
              !(!isNaN(travelDetails.duration) && travelDetails.duration)
            }
            value={
              !isNaN(travelDetails.duration) && travelDetails.duration
                ? `${travelDetails.duration} day(s)`
                : 'Set start and end date'
            }
          />
          <ErrorMessage
            message={
              travelDetails.startDate &&
              travelDetails.endDate &&
              travelDetails.duration <= 0 &&
              'End date should be later than start date'
            }
          />
        </div>

        {isMinor && (
          <div className='quote-details__options--1 col-sm-12'>
            <div className='my-2 mb-4'>
              <span className='text-primary'>
                You are quiet young, please add a guardian
              </span>
            </div>

            <div className='quote-details__options__guardian row'>
              <div className='col-sm-6 mt-3'>
                <label htmlFor='name'>Other Names</label>
                <input
                  name='guardianFirstName'
                  value={guardianDetails.guardianFirstName}
                  onChange={event =>
                    handleGuardianChange(event.target.name, event.target.value)
                  }
                  type='name'
                  id='name'
                  placeholder='Enter guardian Other Names'
                  readOnly={!isMinor}
                />
                <ErrorMessage
                  message={
                    errors.guardianFirstName && errors.guardianFirstName.message
                  }
                />
              </div>

              <div className='col-sm-6 mt-3'>
                <label htmlFor='name'>Surname</label>
                <input
                  name='guardianLastName'
                  value={guardianDetails.guardianLastName}
                  onChange={event =>
                    handleGuardianChange(event.target.name, event.target.value)
                  }
                  type='name'
                  id='name'
                  placeholder='Enter guardian surname'
                  readOnly={!isMinor}
                />
                <ErrorMessage
                  message={
                    errors.guardianLastName && errors.guardianLastName.message
                  }
                />
              </div>

              {/* <div className='col-sm-6 mt-3'>
                <label htmlFor='name'>Other Name</label>
                <input
                  name='guardianOtherName'
                  value={guardianDetails.guardianOtherName}
                  onChange={event =>
                    handleGuardianChange(event.target.name, event.target.value)
                  }
                  type='text'
                  id='guardianOtherName'
                  placeholder='Enter guardian other name'
                  readOnly={!isMinor}
                />
                <ErrorMessage
                  message={
                    errors.guardianOtherName && errors.guardianOtherName.message
                  }
                />
              </div> */}

              <div className='col-sm-6 mt-3'>
                <label htmlFor='gender'>Gender</label>
                <CustomDropdown
                  name='gender'
                  onSelect={selection => {
                    handleGuardianChange('gender', selection.gender);
                  }}
                  identifier='gender'
                  data={genders}
                  inputId={`gender`}
                  placeholder='Pick your gender'
                  disable={false}
                  showItems={true}
                  value={{ gender: guardianDetails.gender }}
                />
                <ErrorMessage
                  message={errors.gender && errors.gender.message}
                />
              </div>
              <div className='col-sm-6 mt-3'>
                <label htmlFor='title'>Title</label>
                <CustomDropdown
                  name='title'
                  onSelect={selection => {
                    handleGuardianChange('title', selection.title);
                  }}
                  identifier='title'
                  data={titles}
                  inputId={`title`}
                  placeholder='Pick your title'
                  disable={false}
                  showItems={true}
                  value={{ title: guardianDetails.title }}
                />
                <ErrorMessage message={errors.title && errors.title.message} />
              </div>

              <div className='col-sm-6 mt-3'>
                <label htmlFor='email'>Email</label>
                <input
                  name='guardianEmail'
                  value={guardianDetails.guardianEmail}
                  onChange={event =>
                    handleGuardianChange(event.target.name, event.target.value)
                  }
                  id='email'
                  type='email'
                  placeholder='Enter guardian email'
                  readOnly={!isMinor}
                />
                <ErrorMessage
                  message={errors.guardianEmail && errors.guardianEmail.message}
                />
              </div>
              <div className='col-sm-6 mt-3'>
                <label htmlFor='phone'>Phone Number</label>
                <input
                  name='guardianPhoneNumber'
                  value={guardianDetails.guardianPhoneNumber}
                  onChange={event =>
                    handleGuardianChange(event.target.name, event.target.value)
                  }
                  id='phone'
                  type='phone'
                  placeholder='Enter guardian phone'
                  readOnly={!isMinor}
                />
                <ErrorMessage
                  message={
                    errors.guardianPhoneNumber &&
                    errors.guardianPhoneNumber.message
                  }
                />
              </div>
              <div className='col-sm-6 mt-3'>
                <label htmlFor='phone'>Date of Birth</label>
                <input
                  max={moment().subtract(18, 'years').format('YYYY-DD-MM')}
                  name='guardianDateOfBirth'
                  value={guardianDetails.guardianDateOfBirth}
                  onChange={event =>
                    handleGuardianChange(event.target.name, event.target.value)
                  }
                  id='guardianDateOfBirth'
                  type='date'
                  placeholder='enter guardian address'
                  readOnly={!isMinor}
                />
                <ErrorMessage
                  message={
                    errors.guardianDateOfBirth &&
                    errors.guardianDateOfBirth.message
                  }
                />
              </div>
              <div className='col-sm-6 mt-3'>
                <label htmlFor='passport'>Passport Number</label>
                <input
                  name='guardianPassportNumber'
                  value={guardianDetails.guardianPassportNumber}
                  onChange={event =>
                    handleGuardianChange(event.target.name, event.target.value)
                  }
                  id='passport'
                  type='passport'
                  readOnly={!isMinor}
                />
                <ErrorMessage
                  message={
                    errors.guardianPassportNumber &&
                    errors.guardianPassportNumber.message
                  }
                />
              </div>
              <div className='col-sm-6 mt-3'>
                <label htmlFor='phone'>Address</label>
                <input
                  name='guardianAddress'
                  value={guardianDetails.guardianAddress}
                  onChange={event =>
                    handleGuardianChange(event.target.name, event.target.value)
                  }
                  id='address'
                  type='address'
                  placeholder='enter guardian address'
                  readOnly={!isMinor}
                />
                <ErrorMessage
                  message={
                    errors.guardianAddress && errors.guardianAddress.message
                  }
                />
              </div>

              <div className='col-sm-6 mt-3'>
                <label htmlFor='guardian-relationship'>Relationship</label>
                <CustomDropdown
                  name='relationship'
                  onSelect={selection => {
                    handleGuardianChange(
                      'guardianRelationship',
                      selection.relationship
                    );
                  }}
                  identifier='relationship'
                  data={relationships}
                  inputId={`guardian-relationship`}
                  placeholder='Pick relationship'
                  disable={false}
                  showItems={true}
                  value={guardianDetails.relationship}
                  readOnly={!isMinor}
                />

                <div className='mb-4'>
                  <ErrorMessage
                    message={
                      errors.guardianRelationship &&
                      errors.guardianRelationship.message
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='quote-details__buttons col-sm-12 mt-5'>
          <button
            type='button'
            className='button-rounded button-rounded--outline'
            onClick={() =>
              activePage !== 0 ? setActivePage(activePage - 1) : nav(-1)
            }
          >
            Back
          </button>
          <button className='button-rounded' disabled={loading}>
            {loading ? 'Saving...' : 'Next'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TravelDetails;
