import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import moment from 'moment';

import {
  ErrorMessage,
  checkValidations
} from '../../../../../utilities/use-validation';
import CustomDropdown from '../../../../../components/custom-dropdown';
import { schemas } from '../../../PassengerDetails/passenger-details.schema';
import { genders, titles } from '../../../../../constants';
import { passengerDetailsState } from '../../../../../recoil/atoms';

const Details = ({ setAllDetailsInputSuccess }) => {
  const [passengerDetails, setPassengerDetails] = useRecoilState(
    passengerDetailsState
  );

  const [errors, setErrors] = useState({});
  const [otherTitle, setOtherTitle] = useState(false);
  const [successMap, setSuccessMap] = useState({});

  const numberOfRequiredInputFields = 5;
  const notRequiredKeys = ['title', 'phoneNumber', 'email', 'address'];
  useEffect(() => {
    const trueKeys = Object.keys(successMap).filter(
      key => successMap[key] === true && !notRequiredKeys.includes(key)
    );

    if (trueKeys && trueKeys.length)
      setAllDetailsInputSuccess(trueKeys.length >= numberOfRequiredInputFields);
  }, [successMap]);

  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleChange = event => {
    const { name, value } = event.target;
    const data = { ...passengerDetails, [name]: value };
    const { success } = handleValidations(data, name);
    setPassengerDetails(data);
    setSuccessMap({ ...successMap, [name]: success });
  };

  const handleChangeDropdown = (name, value) => {
    if (name === 'startDate') {
      setPassengerDetails({
        ...passengerDetails,
        endDate: ''
      });
    }
    const data = { ...passengerDetails, [name]: value };
    const { success } = handleValidations(data, name);
    setPassengerDetails(data);
    setSuccessMap({ ...successMap, [name]: success });
  };

  return (
    <div className='row'>
      <div className='col-sm-6 mt-3'>
        <label htmlFor='first-name'>
          First Name <span className='text-danger'> *</span>
        </label>
        <input
          name='firstName'
          value={passengerDetails.firstName || ''}
          onChange={handleChange}
          type='name'
          id='first-name'
          placeholder='Enter First Name'
        />
        <ErrorMessage message={errors.firstName && errors.firstName.message} />
      </div>
      <div className='col-sm-6 mt-3'>
        <label htmlFor='last-name'>
          Surname <span className='text-danger'> *</span>
        </label>
        <input
          name='lastName'
          value={passengerDetails.lastName || ''}
          onChange={handleChange}
          type='text'
          id='last-name'
          placeholder='Enter surname'
        />
        <ErrorMessage message={errors.lastName && errors.lastName.message} />
      </div>
      <div className='col-sm-6 mt-3'>
        <label htmlFor='date-of-birth'>
          Date of Birth<span className='text-danger'> *</span>
        </label>
        <input
          name='dateOfBirth'
          value={passengerDetails.dateOfBirth || ''}
          onChange={handleChange}
          id='date-of-birth'
          type='date'
          max={moment().format('YYYY-MM-DD')}
        />
        <ErrorMessage
          message={errors.dateOfBirth && errors.dateOfBirth.message}
        />
      </div>
      <div className='col-sm-6 mt-3'>
        <label htmlFor='gender'>
          Gender <span className='text-danger'> *</span>
        </label>
        <CustomDropdown
          name='gender'
          onSelect={selection => {
            handleChangeDropdown('gender', selection.gender);
          }}
          identifier='gender'
          data={genders}
          inputId={`gender`}
          placeholder='Pick your gender'
          disable={false}
          showItems={true}
          value={{
            gender: passengerDetails.gender || ''
          }}
        />
        <ErrorMessage message={errors.gender && errors.gender.message} />
      </div>
      <div className='col-sm-6 mt-3'>
        <label htmlFor='title'>Title</label>
        {!otherTitle ? (
          <CustomDropdown
            name='title'
            onSelect={selection => {
              handleChangeDropdown('title', selection.title);
            }}
            identifier='title'
            data={titles}
            inputId={`title`}
            placeholder='Pick your title'
            disable={false}
            showItems={true}
            value={{
              title: passengerDetails.title || ''
            }}
          />
        ) : (
          <input
            name='title'
            value={passengerDetails.title}
            //   onChange={handleChange}
            id='title'
            type='text'
            placeholder='Please enter title'
          />
        )}
        <ErrorMessage message={errors.title && errors.title.message} />
      </div>
      <div className='col-sm-6 mt-3'>
        <label htmlFor='email'>Email</label>
        <input
          name='email'
          value={passengerDetails.email || ''}
          onChange={handleChange}
          id='email'
          type='email'
          placeholder='Enter email'
        />
        <ErrorMessage message={errors.email && errors.email.message} />
      </div>

      <div className='col-sm-6 mt-3'>
        <label htmlFor='phone'>Phone Number</label>
        <input
          name='phoneNumber'
          value={passengerDetails.phoneNumber || ''}
          onChange={e => {
            e.target.value = e.target.value
              .replace(/[^0-9+]/g, '')
              .replace(/[+%]/g, function (match, offset, all) {
                return match === '+'
                  ? all.indexOf('+') === offset
                    ? '+'
                    : ''
                  : '';
              });
            handleChange(e);
          }}
          id='phone'
          type='phone'
        />
        <ErrorMessage
          message={errors.phoneNumber && errors.phoneNumber.message}
        />
      </div>
      <div className='col-sm-6 mt-3'>
        <label htmlFor='passport'>
          Passport Number<span className='text-danger'> *</span>
        </label>
        <input
          name='passportNumber'
          value={passengerDetails.passportNumber || ''}
          onChange={handleChange}
          id='passport'
          type='passport'
        />
        <ErrorMessage
          message={errors.passportNumber && errors.passportNumber.message}
        />
      </div>
      <div className='col-sm-6 mt-3'>
        <label htmlFor='phone'>Address</label>
        <input
          name='address'
          value={passengerDetails.address || ''}
          onChange={handleChange}
          id='address'
          type='address'
        />
        <ErrorMessage message={errors.address && errors.address.message} />
      </div>

      {/* add passport */}
    </div>
  );
};

export default Details;
