import React, { useEffect, useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import moment from 'moment';
import { allDataState, displayPopUpFormState } from '../../../../recoil/atoms';
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { Spinner } from 'react-bootstrap';

import CustomDropdown from '../../../../components/custom-dropdown';
import {
  checkValidations,
  ErrorMessage
} from '../../../../utilities/use-validation';
import {
  travelDetailsState,
  clearFieldsState,
  currentPackageState,
  currentPlanState,
  formIndexState,
  passengerDetailsState,
  passportUrlState,
  fileState,
  selectedProductState
} from '../../../../recoil/atoms';
import { schemas } from '../../TravelDetails/travel-details.schema';
import useCountries from '../../../../hooks/useCountries';
import useProducts from '../../../../hooks/useProducts';
import { genders, titles, relationships } from '../../../../constants';
import upload from '../../../../assets/svg/upload.svg';
import { getProductDescription } from '../../../../constants';

const EditModal = () => {
  const [allData, setAllData] = useRecoilState(allDataState);
  const [displayPopUpForm, setDisplayPopUpForm] = useRecoilState(
    displayPopUpFormState
  );
  const [travelDetails, setTravelDetails] = useRecoilState(travelDetailsState);
  const [currentPackage] = useRecoilState(currentPackageState);
  const [passengerDetails, setPassengerDetails] = useRecoilState(
    passengerDetailsState
  );
  const [, setPassport] = useRecoilState(passportUrlState);
  const [file, setFile] = useRecoilState(fileState);
  const [formIndex, setFormIndex] = useRecoilState(formIndexState);
  const [selectedProduct, setSelectedProduct] =
    useRecoilState(selectedProductState);

  const [otherTitle, setOtherTitle] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPlans, setShowPlans] = useState(true);

  const { countries } = useCountries();

  useEffect(() => console.log('formIndex: ', formIndex), [formIndex]);
  useEffect(() => console.log('allData: ', allData), [allData]);

  const { products, loading, error, getProducts } = useProducts();

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0] && acceptedFiles[0].size > 2097152) {
      toast.error('Please select a file smaller than 2Mb');
      return;
    }

    if (acceptedFiles[0] && !acceptedFiles[0].type.includes('image')) {
      toast.error('Please select an image file (jpeg, png, gif)');
      return;
    }

    let updatedAllData = allData.map((data, index) => {
      if (index === formIndex) {
        return {
          ...data,
          file: acceptedFiles[0]
        };
      }
    });
    setAllData(updatedAllData);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const closePopUp = () => {
    setDisplayPopUpForm(false);
  };

  const buyNow = product => {
    let updatedAllData = allData.map((data, index) => {
      if (index === formIndex) {
        return {
          ...data,
          selectedProduct: product
        };
      }
    });
    setAllData(updatedAllData);
    setShowPlans(false);
  };

  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const handleTravelDetailsChange = (name, value) => {
    const data = { ...travelDetails, [name]: value };
    if (name === 'startDate') data.endDate = '';
    // handleValidations(data, name);

    let updatedAllData = allData.map((data, index) => {
      if (index === formIndex) {
        return {
          ...data,
          travelDetails: {
            ...data.travelDetails,
            [name]: value
          }
        };
      }
    });
    setAllData(updatedAllData);
  };

  const handlePassengerDetailsChange = event => {
    const { name, value } = event.target;
    const data = { ...passengerDetails, [name]: value };
    if (name === 'startDate') data.endDate = '';
    // handleValidations(data, name);

    let updatedAllData = allData.map((data, index) => {
      if (index === formIndex) {
        return {
          ...data,
          passengerDetails: {
            ...data.passengerDetails,
            [name]: value
          }
        };
      }
    });
    setAllData(updatedAllData);
  };

  const handleChangePassengerDetailsDropdown = (name, value) => {
    setPassengerDetails({
      ...passengerDetails,
      [name]: value
    });
    if (name === 'startDate') {
      setPassengerDetails({
        ...passengerDetails,
        endDate: ''
      });
    }
  };

  return (
    <div className='modal'>
      <h1 className='modal__close' onClick={closePopUp}>
        <AiOutlineClose />
      </h1>

      <div className='modal__content'>
        <h1 style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>Edit Form</h1>

        <h2 style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
          Travel Details
        </h2>

        {/* travel details */}
        <div className='row'>
          <div className='col-sm-6'>
            <label htmlFor='destination'>
              Destination <span className='text-danger'> *</span>
            </label>
            <CustomDropdown
              name='destination'
              onSelect={selection => {
                handleTravelDetailsChange('destination', selection.cd);
              }}
              identifier='ds'
              data={countries}
              inputId={`destination`}
              placeholder='Pick your destination'
              disable={false}
              showItems={true}
              value={{
                destination:
                  allData[formIndex]?.travelDetails?.destination || ''
              }}
            />
            <ErrorMessage
              message={errors.destination && errors.destination.message}
            />
          </div>

          <div className='col-sm-6'>
            <label htmlFor='purpose'>
              purpose <span className='text-danger'> *</span>
            </label>
            <input
              type='text'
              readOnly
              value={
                currentPackage ||
                // allData[formIndex]?.travelDetails?.currentPackage ||
                ''
              }
              onChange={() => null}
            />
            <ErrorMessage message={errors.purpose && errors.purpose.message} />
          </div>

          <div className='col-sm-6 mt-3'>
            <label htmlFor='startDate'>
              Start Date <span className='text-danger'> *</span>
            </label>
            <input
              type='date'
              name='startDate'
              min={moment().format('YYYY-MM-DD')}
              value={allData[formIndex]?.travelDetails?.startDate || ''}
              onChange={event =>
                handleTravelDetailsChange('startDate', event.target.value)
              }
            />
            <ErrorMessage
              message={errors.startDate && errors.startDate.message}
            />
          </div>

          <div className='col-sm-6 mt-3'>
            <label htmlFor='destination'>
              End Date <span className='text-danger'> *</span>
            </label>
            <input
              type='date'
              name='endDate'
              min={moment().format('YYYY-MM-DD')}
              value={allData[formIndex]?.travelDetails?.endDate || ''}
              onChange={event =>
                handleTravelDetailsChange('endDate', event.target.value)
              }
            />
            <ErrorMessage message={errors.endDate && errors.endDate.message} />
          </div>

          <div className='col-sm-6 mt-3'>
            <label htmlFor='destination'>
              Duration <span className='text-danger'> *</span>
            </label>
            <input
              type='text'
              readOnly
              disabled={
                !(!isNaN(travelDetails.duration) && travelDetails.duration)
              }
              value={
                !isNaN(travelDetails.duration) && travelDetails.duration
                  ? `${travelDetails.duration} day(s)`
                  : 'Set start and end date'
              }
            />
            <ErrorMessage
              message={
                travelDetails.startDate &&
                travelDetails.endDate &&
                travelDetails.duration <= 0 &&
                'End date should be later than start date'
              }
            />
          </div>
        </div>

        {/* passenger details */}
        <h2 style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
          Passenger Details
        </h2>

        <div className='row'>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='first-name'>
              First Name <span className='text-danger'> *</span>
            </label>
            <input
              name='firstName'
              value={allData[formIndex]?.passengerDetails.firstName || ''}
              onChange={handlePassengerDetailsChange}
              type='name'
              id='first-name'
              placeholder='Enter First Name'
            />
            <ErrorMessage
              message={errors.firstName && errors.firstName.message}
            />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='last-name'>
              Surname <span className='text-danger'> *</span>
            </label>
            <input
              name='lastName'
              value={allData[formIndex]?.passengerDetails.lastName || ''}
              onChange={handlePassengerDetailsChange}
              type='text'
              id='last-name'
              placeholder='Enter surname'
            />
            <ErrorMessage
              message={errors.lastName && errors.lastName.message}
            />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='date-of-birth'>
              Date of Birth<span className='text-danger'> *</span>
            </label>
            <input
              name='dateOfBirth'
              value={allData[formIndex]?.passengerDetails.dateOfBirth || ''}
              onChange={handlePassengerDetailsChange}
              id='date-of-birth'
              type='date'
              max={moment().format('YYYY-MM-DD')}
            />
            <ErrorMessage
              message={errors.dateOfBirth && errors.dateOfBirth.message}
            />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='gender'>
              Gender <span className='text-danger'> *</span>
            </label>
            <CustomDropdown
              name='gender'
              onSelect={selection => {
                handleChangePassengerDetailsDropdown(
                  'gender',
                  selection.gender
                );
              }}
              identifier='gender'
              data={genders}
              inputId={`gender`}
              placeholder='Pick your gender'
              disable={false}
              showItems={true}
              value={{
                gender: allData[formIndex]?.passengerDetails.gender || ''
              }}
            />
            <ErrorMessage message={errors.gender && errors.gender.message} />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='title'>Title</label>
            {!otherTitle ? (
              <CustomDropdown
                name='title'
                onSelect={selection => {
                  handleChangePassengerDetailsDropdown(
                    'title',
                    selection.title
                  );
                }}
                identifier='title'
                data={titles}
                inputId={`title`}
                placeholder='Pick your title'
                disable={false}
                showItems={true}
                value={{
                  title: allData[formIndex]?.passengerDetails.title || ''
                }}
              />
            ) : (
              <input
                name='title'
                value={allData[formIndex]?.passengerDetails.title}
                //   onChange={handleChange}
                id='title'
                type='text'
                placeholder='Please enter title'
              />
            )}
            <ErrorMessage message={errors.title && errors.title.message} />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='email'>Email</label>
            <input
              name='email'
              value={allData[formIndex]?.passengerDetails.email || ''}
              onChange={handlePassengerDetailsChange}
              id='email'
              type='email'
              placeholder='Enter email'
            />
            <ErrorMessage message={errors.email && errors.email.message} />
          </div>

          <div className='col-sm-6 mt-3'>
            <label htmlFor='phone'>Phone Number</label>
            <input
              name='phoneNumber'
              value={allData[formIndex]?.passengerDetails.phoneNumber || ''}
              onChange={e => {
                e.target.value = e.target.value
                  .replace(/[^0-9+]/g, '')
                  .replace(/[+%]/g, function (match, offset, all) {
                    return match === '+'
                      ? all.indexOf('+') === offset
                        ? '+'
                        : ''
                      : '';
                  });
                handlePassengerDetailsChange(e);
              }}
              id='phone'
              type='phone'
            />
            <ErrorMessage
              message={errors.phoneNumber && errors.phoneNumber.message}
            />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='passport'>
              Passport Number<span className='text-danger'> *</span>
            </label>
            <input
              name='passportNumber'
              value={allData[formIndex]?.passengerDetails.passportNumber || ''}
              onChange={handlePassengerDetailsChange}
              id='passport'
              type='passport'
            />
            <ErrorMessage
              message={errors.passportNumber && errors.passportNumber.message}
            />
          </div>
          <div className='col-sm-6 mt-3'>
            <label htmlFor='phone'>Address</label>
            <input
              name='address'
              value={allData[formIndex]?.passengerDetails.address || ''}
              onChange={handlePassengerDetailsChange}
              id='address'
              type='address'
            />
            <ErrorMessage message={errors.address && errors.address.message} />
          </div>
        </div>

        {/* passport */}
        <h2>Replace passport image</h2>
        <p>{`Current passport filename : ${allData[formIndex]?.file['name']}`}</p>

        <div
          className='passport-upload'
          {...getRootProps()}
          style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
        >
          <div className='dropzone'>
            <input {...getInputProps()} accept='image/*' />
            {
              <div className='insurance-documents__upload'>
                <img src={upload} alt='upload' />
                <div className='upload-text'>
                  <p className='mb-0 '>
                    Drag and drop the file here, or click to select file
                  </p>
                  <p className='mt-1'>
                    <small>
                      <b>Note: </b>The file should be smaller 2MB and must be an
                      image (jpeg,jpg,png,gif)
                    </small>
                  </p>
                  <button
                    className='button-rounded upload-button mb-4 '
                    onClick={e => e.preventDefault()}
                  >
                    Upload
                  </button>
                </div>
              </div>
            }
          </div>
        </div>

        {allData[formIndex]?.file && (
          <div className='uploaded-file'>
            <img src={upload} alt='upload' width='40px' />{' '}
            {allData[formIndex]?.file?.name}
          </div>
        )}

        {/* select plan */}
        <div className='quote-details  quote-details__select_plan'>
          {loading || error ? (
            <div className='quote-details__loader quote-details__overlay'>
              {loading ? (
                <Spinner color='primary' animation='border' role='status' />
              ) : (
                <div>
                  <p className='text-muted'>
                    An error occurred while loading the products
                    <br /> please try again.
                  </p>
                  <br />
                  <button onClick={getProducts}>
                    <svg
                      version='1.1'
                      id='Capa_1'
                      xmlns='http://www.w3.org/2000/svg'
                      x='0px'
                      y='0px'
                      viewBox='0 0 489.533 489.533'
                    >
                      <path
                        d='M268.175,488.161c98.2-11,176.9-89.5,188.1-187.7c14.7-128.4-85.1-237.7-210.2-239.1v-57.6c0-3.2-4-4.9-6.7-2.9
                                        l-118.6,87.1c-2,1.5-2,4.4,0,5.9l118.6,87.1c2.7,2,6.7,0.2,6.7-2.9v-57.5c87.9,1.4,158.3,76.2,152.3,165.6
                                        c-5.1,76.9-67.8,139.3-144.7,144.2c-81.5,5.2-150.8-53-163.2-130c-2.3-14.3-14.8-24.7-29.2-24.7c-17.9,0-31.9,15.9-29.1,33.6
                                        C49.575,418.961,150.875,501.261,268.175,488.161z'
                      />
                    </svg>
                    Reload
                  </button>
                </div>
              )}
            </div>
          ) : showPlans ? (
            <>
              <h1 className='quote-details__heading px-2'>
                Please Select an Insurance Plan
              </h1>
              <h4 className='px-2 mt-3 text-muted'>
                Current planned trip length: {travelDetails.duration} Days
              </h4>
              <div className='row mt-5'>
                {products && products.length ? (
                  products.map((product, i) => {
                    const productDesc = getProductDescription(product);
                    return (
                      <div key={i} className='col-sm-6'>
                        <div className='card quote-details__card'>
                          <div className='card-header'>
                            <div className='card-title'>
                              {productDesc?.title} (
                              {`${productDesc?.minDays} - ${productDesc?.maxDays} Days`}
                              )
                            </div>
                          </div>
                          <div className='card-body'>
                            {productDesc?.description}
                          </div>
                          <div className='card-footer'>
                            {!(
                              productDesc?.minDays < travelDetails?.duration &&
                              travelDetails?.duration > productDesc?.maxDays
                            ) ? (
                              <button
                                disabled={
                                  productDesc?.minDays <
                                    travelDetails?.duration &&
                                  travelDetails?.duration > productDesc?.maxDays
                                }
                                onClick={() => buyNow(product)}
                              >
                                Select plan
                              </button>
                            ) : (
                              <div className='alert  alert-warning p-2'>
                                <p
                                  style={{ fontSize: '1.4rem' }}
                                  className='m-0'
                                >
                                  Travel Details does not qualify for this Plan
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h4 className='text-muted'>No products available</h4>
                )}
              </div>
            </>
          ) : (
            <>
              <p>Selected product: {allData[formIndex]?.selectedProduct.ds}</p>
              <button
                className='button-primary'
                onClick={() => setShowPlans(true)}
              >
                Select another plan
              </button>
            </>
          )}
        </div>

        <button className='button-primary' onClick={closePopUp}>
          Done
        </button>
      </div>
    </div>
  );
};

export default EditModal;
