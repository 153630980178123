import useLoginUser from '../../../hooks/useLoginUser';
import { useEffect } from 'react';
import RetryLoader from '../../../components/commons/retryLoader';
import TasksTable from './tasks.table';
import { useNavigate } from 'react-router-dom';
import useTasks from './useTasks';

const TasksIndex = () => {
  const { user } = useLoginUser();
  const nav = useNavigate();

  const { tasks, loading, error, getTasks } = useTasks();
  useEffect(() => {
    if (!user?.is_super_admin) {
      nav('/dashboard/index', { replace: true });
    }
    // getTasks();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='dashboard-users container'>
      <RetryLoader
        retry={getTasks}
        loading={loading}
        error={error}
        fullscreen={false}
      >
        <div className='dashboard-users__tabs'>
          <div className='dashboard-users__tabs__heading'>
            <div
              className={`dashboard-users__tab dashboard-users__tab--active`}
            >
              Permissions
            </div>
          </div>

          <div className='dashboard-users__tabs__body'>
            <TasksTable data={tasks} />
          </div>
        </div>
      </RetryLoader>
    </div>
  );
};

export default TasksIndex;
