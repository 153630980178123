import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { baseUrl, GibbsApiKey } from '../../../constants';
import {
  headerState,
  quotesPageState,
  selectedProductState,
  priceInformationState,
  insuranceDataState,
  passengerDetailsState,
  travelDetailsState,
  profileDataState,
  guardianDetailsState,
  travelDetailFormState,
  nextOfKinDetailsState
} from '../../../recoil/atoms';
import useLoginUser from '../../../hooks/useLoginUser';
import { toast } from 'react-toastify';
import { getAge } from '../../../utilities';
import localforage from 'localforage';
import {
  checkValidations,
  ErrorMessage
} from '../../../utilities/use-validation';
import { guardianSchema } from '../NextOfKinDetails/next-of-kin.schema';
import CustomDropdown from '../../../components/custom-dropdown';
import Payment from '../Payment/payment';

export default function ProductDetails() {
  const [activePage, setActivePage] = useRecoilState(quotesPageState);
  const [headers] = useRecoilState(headerState);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [error, setError] = useState(false);
  const [insuranceData, setInsuranceData] = useRecoilState(insuranceDataState);
  const [priceInfo, setPriceInfo] = useRecoilState(priceInformationState);
  const [product] = useRecoilState(selectedProductState);
  const { updateMapKey } = useLoginUser();
  const [coverDetails, setCoverDetails] = useState(null);

  const [passengerDetails] = useRecoilState(passengerDetailsState);
  const [travelDetails] = useRecoilState(travelDetailsState);
  const [profileData, setProfileData] = useRecoilState(profileDataState);
  const [guardianDetails] = useRecoilState(guardianDetailsState);
  const [, setTravelDetailForm] = useRecoilState(travelDetailFormState);
  const [nextOfKinDetails, setNextOfKinDetails] = useRecoilState(
    nextOfKinDetailsState
  );
  const [marketers, setMarketers] = useState([]);
  const [guardianHeight, setGuardianHeight] = useState({});
  const [errors, setErrors] = useState({});
  const isMinor = useMemo(
    () => getAge(passengerDetails.dateOfBirth) < 18,
    [passengerDetails.dateOfBirth]
  );

  const [completeUserCreate, setCompleteUserCreate] = useState(false);

  const handleValidations = (data, field) => {
    const schemas =
      guardianHeight.initial > 0 || isMinor ? { ...{}, ...guardianSchema } : {};
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });
    setErrors(errors);
    return { success };
  };

  const getProductXml = async insuranceData => {
    let data;
    console.log('insurance data: ', insuranceData);
    try {
      const res = await fetch(`${baseUrl}/products-xml-formatted`, {
        method: 'POST',
        headers,
        body: JSON.stringify(insuranceData)
      });

      data = await res.json();
      if (data.error || data.errors) {
        throw new Error('Missing Token');
      }
    } catch (error) {
      console.error(error);
      setError(true);
      updateMapKey();
    }
    setCoverDetails(data?.cover_details || {});
    return data;
  };

  const getPrices = async insuranceDataParam => {
    setLoading(true);
    const xml = await getProductXml(insuranceDataParam || insuranceData);
    try {
      if (!xml || !xml.data) {
        throw new Error("Couldn't load xml");
      }
      const res = await fetch(`${baseUrl}/product-quotes`, {
        method: 'POST',
        body: JSON.stringify({
          action: 'getPrices',
          xml: xml.data,
          xml_raw: xml.xml
        }),
        headers
      });

      let data = await res.json();
      if (data.error || data.errors) {
        throw new Error('Unable to generate price');
      }
      console.log('data in getPrices: ', data);
      setPriceInfo(data);
      setLoading(false);
      setError(false);
    } catch (error) {
      setError(true);
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    setInsuranceData({ ...insuranceData, idRegProduct: product.cd });
    getPrices({ ...insuranceData, idRegProduct: product.cd });
    // eslint-disable-next-line
  }, []);

  const buildGibsProfileData = (profileData, GibbsApiKey, product) => {
    return {
      Apikey: GibbsApiKey,
      PolicyStartDate: profileData.from_date,
      PolicyEndDate: profileData.to_date,
      TravelPurpose: profileData.purpose,
      Destination: profileData.destination,
      ContactAddress: profileData.address,
      LastName: profileData.last_name,
      FirstName: profileData.first_name,
      Gender: profileData.gender,
      Title: profileData.title,
      PassportNo: profileData.passport_no,
      PhoneNo: profileData.phone,
      Email: profileData.email,
      DOB: profileData.date_of_birth,
      NoK_Name: profileData.next_of_kin.nok_name,
      NoK_Relationship: profileData.next_of_kin.nok_relationship,
      NoK_Address: profileData.next_of_kin.nok_address,
      NoK_Email: profileData.next_of_kin.nok_email,
      NoK_PhoneNo: profileData.next_of_kin.nok_phone,
      CoverType: product.dsProduct
    };
  };

  const saveTravelAndProceed = async () => {
    setCreateLoading(true);
    try {
      const res = await fetch(`${baseUrl}/users/create`, {
        method: 'POST',
        body: JSON.stringify({
          ...profileData,
          product: product.dsProduct
        }),
        headers
      });

      const data = await res.json();

      if (data.user_id && data.travel_id) {
        let travelDetailForm = {
          customer_name:
            passengerDetails.firstName + ' ' + passengerDetails.lastName,
          user_id: data.user_id,
          travel_id: data.travel_id,
          email: passengerDetails.email,
          phone: passengerDetails.phoneNumber,
          from_date: travelDetails.startDate,
          to_date: travelDetails.endDate,
          duration: travelDetails.duration,
          age: getAge(guardianDetails.guardianDateOfBirth),
          destination: travelDetails.destination,
          marketer: nextOfKinDetails.marketer || 'N/A',
          gibssTransactionData: buildGibsProfileData(
            profileData,
            GibbsApiKey,
            product
          )
        };
        setProfileData({ ...profileData, travel_id: data.travel_id });
        setTravelDetailForm(travelDetailForm);
        setCompleteUserCreate(true);
      } else {
        toast.error('An error occurred, please try again');
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred, please try again');
    }

    setCreateLoading(false);
  };

  useEffect(() => {
    fetchMarketers();
  }, []);

  const fetchMarketers = async () => {
    const marketers = await localforage.getItem('marketers');
    if (marketers) setMarketers(marketers);
    const res = await fetch(`${baseUrl}/marketers`);
    const data = await res.json();
    if (res.status === 200) {
      data.Marketer.forEach(d => {
        d.full_name = `${d.FirstName} ${d.LastName}`;
      });
      setMarketers(data.Marketer);
      localforage.setItem('marketers', data.Marketer);
    }
  };

  const handleNextOfKinChange = (name, value) => {
    const data = { ...nextOfKinDetails, [name]: value };
    setNextOfKinDetails(data);
    // handleValidations(data, name);
  };

  const price = priceInfo?.root?.premiumBreakdown?.startPrice || '0.00';
  const totalInsured = priceInfo?.root?.premiumBreakdown?.numInsured || '1';

  return completeUserCreate ? (
    <Payment />
  ) : (
    <div className='quote-details  container quote-details__product_details'>
      {/* add markerer referral here */}
      <div className='col-sm-6 mt-3 referral'>
        <h1>Referral</h1>
        <label htmlFor='referred'>
          {nextOfKinDetails.isReferred === undefined
            ? 'I was referred by Internet'
            : `I was referred by ${nextOfKinDetails.isReferred}`}
        </label>

        <select
          onChange={e => {
            setNextOfKinDetails({
              ...nextOfKinDetails,
              isReferred: e.target.value
            });
          }}
          value={nextOfKinDetails.isReferred}
        >
          <option value='Internet'>Internet </option>
          <option value='Social Media'>Social Media </option>
          <option value='Friends/Associate'>Friends/Associate </option>
          <option value='Agency/Marketers'>Agency/Marketers </option>
        </select>
      </div>
      {nextOfKinDetails.isReferred === 'Agency/Marketers' && (
        <div className='col-sm-6 mt-3'>
          <label className='w-100' htmlFor='guardian-relationship'>
            Marketer
          </label>
          <CustomDropdown
            name='marketer'
            onSelect={selection => {
              handleNextOfKinChange('marketer', selection);
            }}
            identifier='full_name'
            data={marketers}
            inputId={`marketer`}
            placeholder='Pick marketer'
            disable={false}
            showItems={true}
            value={nextOfKinDetails.marketer}
          />

          <div className='mb-3'>
            <ErrorMessage
              message={errors.marketer && errors.marketer.message}
            />
          </div>
        </div>
      )}
      <h1 className='quote-details__heading'>
        Product Details and Price as below
      </h1>
      <div className='quote-details__product_details__main'>
        <div className='quote-details__product_details__main_name'>{`${product.dsProduct} PLAN`}</div>
        <div className='mb-4'>
          <small>{product.ds}</small>
        </div>
      </div>
      <div>
        {loading || error ? (
          <div className='quote-details__loader quote-details__product_loader'>
            {loading ? (
              <div className='mt-3'>
                <Spinner color='primary' animation='border' role='status' />
              </div>
            ) : (
              <div>
                <p className='text-muted'>
                  An error occurred while loading the products
                  <br /> please try again.
                </p>
                <br />
                <button onClick={() => getPrices()}>
                  <svg
                    version='1.1'
                    id='Capa_1'
                    xmlns='http://www.w3.org/2000/svg'
                    x='0px'
                    y='0px'
                    viewBox='0 0 489.533 489.533'
                  >
                    <path
                      d='M268.175,488.161c98.2-11,176.9-89.5,188.1-187.7c14.7-128.4-85.1-237.7-210.2-239.1v-57.6c0-3.2-4-4.9-6.7-2.9
                                        l-118.6,87.1c-2,1.5-2,4.4,0,5.9l118.6,87.1c2.7,2,6.7,0.2,6.7-2.9v-57.5c87.9,1.4,158.3,76.2,152.3,165.6
                                        c-5.1,76.9-67.8,139.3-144.7,144.2c-81.5,5.2-150.8-53-163.2-130c-2.3-14.3-14.8-24.7-29.2-24.7c-17.9,0-31.9,15.9-29.1,33.6
                                        C49.575,418.961,150.875,501.261,268.175,488.161z'
                    />
                  </svg>
                  Reload
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className='table-responsive'>
            <table className='table coverage_details'>
              <tbody>
                <tr>
                  <td colSpan='2' className='table-heading'>
                    PRODUCT DETAILS
                  </td>
                </tr>
                <tr>
                  <td>Maximum number of risks:</td>
                  <td>${product.numMaxRiesgos}</td>
                </tr>
                <tr>
                  <td>Maximum days before issuing (useful with claims):</td>
                  <td>{product.maxDiasAntes}</td>
                </tr>
                <tr>
                  <td>Maximum days after issuing (useful with claims):</td>
                  <td>{product.maxDiasDespues}</td>
                </tr>
                <tr>
                  <td>Insurer’s minimum age:</td>
                  <td>{product.edadMinimaAsegurado}</td>
                </tr>
                <tr>
                  <td>Insurer’s Maximum age:</td>
                  <td>{product.edadMaximaAsegurado}</td>
                </tr>
                <tr>
                  <td>Policy-holder’s minimum age:</td>
                  <td>{product.edadMinimaTomador}</td>
                </tr>
                <tr>
                  <td colSpan='2' className='table-heading pt-5'>
                    COVERAGE DETAILS
                  </td>
                </tr>
                <tr>
                  <td className='table-heading-condense'>BENEFITS</td>
                  <td className='table-heading-condense'>Limits</td>
                </tr>

                {coverDetails &&
                  Object.keys(coverDetails).map(detail => (
                    <>
                      <tr>
                        <td colSpan='2' className='table-heading'>
                          {detail}
                        </td>
                      </tr>
                      {coverDetails[detail].map(cover => (
                        <tr>
                          <td>{cover.name}</td>
                          <td>{cover.value}</td>
                        </tr>
                      ))}
                    </>
                  ))}
              </tbody>
            </table>
            <div className='quote-details__product_details_price '>
              <div>
                Number Insured: <span>{totalInsured}</span>
              </div>
              <div className='total-price'>
                Total Fee: <span>{price}</span>
              </div>
            </div>
          </div>
        )}

        {!parseFloat(price) && !loading && (
          <div className='alert  alert-warning my-4 p-4'>
            <p style={{ fontSize: '1.4rem' }} className='m-0'>
              This product is not available at moment. <br />
              Please try other products or try again later.
            </p>
          </div>
        )}

        <div className='quote-details__buttons'>
          <button
            type='button'
            className='button-rounded button-rounded--outline'
            onClick={() => activePage !== 0 && setActivePage(activePage - 1)}
          >
            Back
          </button>
          {!parseFloat(price) || loading || error ? (
            <></>
          ) : (
            <button
              onClick={saveTravelAndProceed}
              disabled={loading || error || createLoading || !parseFloat(price)}
              className='button-rounded'
            >
              {createLoading ? 'Saving...' : 'Proceed'}
            </button>
          )}
        </div>
        <br />
      </div>
    </div>
  );
}
