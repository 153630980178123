import React, { useState } from 'react';
import { checkValidations, ErrorMessage } from '../../utilities/use-validation';
import { schemas } from './login.schema';

import { baseUrl } from '../../constants';

import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import useLoginUser from '../../hooks/useLoginUser';
import localforage from 'localforage';

const ModalLogin = ({ close, email }) => {
  const [state, setState] = React.useState({
    email,
    password: '',
    invalidError: false,
    formErrors: {
      email: '',
      password: ''
    }
  });

  const [loading, setLoading] = useState(false);

  const { updateLogin } = useLoginUser();

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);
    const userDetails = {
      email: state.email,
      password: state.password
    };
    const { success } = handleValidations(userDetails);

    if (success) {
      fetch(`${baseUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(userDetails)
      })
        .then(res => res.json())
        .then(res => {
          if (res.error || res.errors) {
            toast.error(res.error || 'Incorrect email and/or password');
            setLoading(false);
            return;
          }
          updateLogin(res);
          toast.success('Login successful, Welcome Back');
          localforage.setItem('loginState', res);
          close();
          // nav('/dashboard');
        })
        .catch(error => {
          toast.error('An error occurred, please try again');
          console.error(error);
          setLoading(false);
        });
    }
  };

  const handleValidations = (data, field) => {
    const { errors, success } = checkValidations({
      schemas,
      data,
      field: field
    });

    setState({ ...data, formErrors: errors });
    return { success };
  };

  const handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    const data = { ...state, [name]: value };
    setState({ ...state, [name]: value });
    handleValidations(data, name);
  };

  return (
    <div className='session-login-modal'>
      <div className='session-login-modal-content'>
        <div className='session-login-modal-body'>
          <form className='my-4' onSubmit={handleSubmit}>
            <h1>Session Timeout</h1>
            <hr />

            <h3>Your Session timed out, Please login to continue.</h3>

            <div className='formGroup2 mt-5 mb-4'>
              <input
                type='email'
                className='formGroup2__input'
                placeholder='Email'
                name='email'
                noValidate
                value={email}
                autoComplete='false'
                autofill='false'
                onChange={handleChange}
                readOnly
              />
              <div className='mt-2'>
                <ErrorMessage
                  message={
                    state.formErrors.email && state.formErrors.email.message
                  }
                />
              </div>
            </div>
            <div className='formGroup2'>
              <input
                type='password'
                className='formGroup2__input'
                placeholder='Password'
                name='password'
                noValidate
                autoComplete='false'
                autofill='false'
                onChange={handleChange}
              />
              <div className='mt-2'>
                <ErrorMessage
                  message={
                    state.formErrors.password &&
                    state.formErrors.password.message
                  }
                />
              </div>
            </div>

            <button disabled={loading} className='login__button w-100'>
              {loading ? (
                <>
                  <Spinner animation='border' /> Loading ...
                </>
              ) : (
                'Login'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ModalLogin;
