import React, { useState, useCallback, useEffect } from 'react';
import { Spinner, ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { baseUrl } from '../../../../constants';
import {
  headerState,
  quotesPageState,
  passportUrlState,
  travelDetailFormState,
  uploadState,
  fileState,
  clearFieldsState
} from '../../../../recoil/atoms';
import upload from '../../../../assets/svg/upload.svg';

const PassportUpload = () => {
  const [file, setFile] = useRecoilState(fileState);
  const [, setPassport] = useRecoilState(passportUrlState);
  const [clearFields] = useRecoilState(clearFieldsState);

  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (clearFields) {
      setFile(null);
    }
  }, [clearFields]);

  const onDrop = useCallback(acceptedFiles => {
    setPassport(null);
    if (acceptedFiles[0] && acceptedFiles[0].size > 2097152) {
      toast.error('Please select a file smaller than 2Mb');
      return;
    }

    if (acceptedFiles[0] && !acceptedFiles[0].type.includes('image')) {
      toast.error('Please select an image file (jpeg, png, gif)');
      return;
    }

    setFile(acceptedFiles[0]);
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className='py-3'>
      <h1>Passport Upload</h1>

      <div className='passport-upload' {...getRootProps()}>
        <div className='dropzone'>
          <input {...getInputProps()} accept='image/*' />
          {
            <div className='insurance-documents__upload'>
              <img src={upload} alt='upload' />
              <div className='upload-text'>
                <p className='mb-0 '>
                  Drag and drop the file here, or click to select file
                </p>
                <p className='mt-1'>
                  <small>
                    <b>Note: </b>The file should be smaller 2MB and must be an
                    image (jpeg,jpg,png,gif)
                  </small>
                </p>
                <button
                  className='button-rounded upload-button mb-4 '
                  onClick={e => e.preventDefault()}
                >
                  Upload
                </button>
              </div>
            </div>
          }
        </div>
      </div>

      {file && (
        <div className='uploaded-file'>
          <img src={upload} alt='upload' width='40px' /> {file.name}
        </div>
      )}
      {/* 
      {loading && (
        <div className='mt-3'>
          <ProgressBar striped variant='primary' now={progress} />
        </div>
      )} 
      */}
    </div>
  );
};

export default PassportUpload;
