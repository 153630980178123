import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { currentPackageState, currentPlanState } from '../../recoil/atoms';
import { toast } from 'react-toastify';
import Sidebar from '../../components/commons/Sidebar';

const InBetween = () => {
  const nav = useNavigate();
  const { package: insurancePackage, class: plan } = useParams();
  const [, setCurrentPackage] = useRecoilState(currentPackageState);
  const [, setCurrentPlan] = useRecoilState(currentPlanState);
  const [multiple, setMultiple] = useState(false);

  useEffect(() => {
    setCurrentPackage(insurancePackage);
    setCurrentPlan(plan);
  }, []);

  return (
    <div className='in-between d-flex justify-content-between'>
      <div className='in-between__sidebar'>
        <Sidebar />
      </div>

      <div className='d-flex p-2 justify-content-center align-items-center in-between__main'>
        <div className='card text-center'>
          <div className='p-4 card-header d-flex align-items-center justify-content-between'>
            <h1 className='fs-1'>Buy Insurance</h1>
            <p
              onClick={() => nav(-1)}
              style={{ fontSize: '1.5rem', color: 'blue', cursor: 'pointer' }}
            >
              Cancel
            </p>
          </div>

          <div className='card-body px-5'>
            <h5 className='card-title'>Agent</h5>
            <p className='card-text in-between__text'>
              What type of transaction do you want to handle?
            </p>

            <div className='d-flex flex-column'>
              {multiple ? (
                <div className='py-3'>
                  <button
                    className='button-secondary py-3 mx-3 my-2'
                    onClick={() => {
                      nav('/quotes/group/travel-details', {
                        state: {
                          insurancePackage,
                          plan
                        }
                      });
                    }}
                  >
                    Group transaction
                  </button>
                  <button
                    className='button-primary py-3 mx-3 my-2'
                    onClick={() =>
                      nav('/quotes/batch', {
                        state: {
                          insurancePackage,
                          plan
                        }
                      })
                    }
                  >
                    Bulk transaction
                  </button>
                </div>
              ) : (
                <>
                  <button
                    onClick={() => setMultiple(true)}
                    className=' button-secondary py-3 my-2'
                  >
                    Multiple Transaction
                  </button>

                  <button
                    onClick={() =>
                      nav(`/get-quotes/${insurancePackage}/${plan}`)
                    }
                    className='button-primary py-3 my-2'
                  >
                    Single Transaction
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InBetween;
