export const schemas = {
  firstName: {
    required: true,
    maxLength: 20,
    label: 'Other Names'
  },
  lastName: {
    required: true,
    maxLength: 20,
    label: 'Surname'
  },
  otherNames: {
    required: false,
    maxLength: 20,
    label: 'Surname'
  },
  dateOfBirth: {
    required: true,
    label: 'Date of Birth'
  },
  // email: {
  //   required: true,
  //   inputType: 'email',
  //   label: 'Email'
  // },
  phoneNumber: {
    required: true,
    maxLength: 15,
    minLength: 9,
    maxWords: 1,
    label: 'Phone Number'
  },
  passportNumber: {
    required: true,
    maxLength: 15,
    minLength: 5,
    maxWords: 1,
    label: 'Passport Number'
  },
  address: {
    required: true,
    label: 'Address'
  }
};
