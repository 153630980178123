import moment from 'moment';
import React, { useMemo, useEffect, useState, createRef } from 'react';
import { Modal, Pagination, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTable, usePagination, useSortBy } from 'react-table';
import RetryLoader from '../../../components/commons/retryLoader';
import { numberWithCommas } from '../../../utilities';
import PrintCertifcate from '../../Quotes/SuccessPage/print-certifcate';
import ReactExport from 'react-export-excel';
import Search from '../../../components/commons/search';
import { baseUrl } from '../../../constants';
import { savePDF } from '@progress/kendo-react-pdf';
import { Link } from 'react-router-dom';
import { permissionuserDataState } from '../../../recoil/atoms';
import { useRecoilValue } from 'recoil';
import useCountries from '../../../hooks/useCountries';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Certificates({ loading, error, data, retry }) {
  const permissions = useRecoilValue(permissionuserDataState);
  const { getCountryName } = useCountries();
  const columns = useMemo(
    () => [
      {
        Header: 'Customer',
        accessor: d => d?.user?.name + ' ' + d?.user?.profile?.last_name
      },
      {
        Header: 'Destination',
        accessor: d => getCountryName(d.destination) // accessor is the "key" in the data
      },
      {
        Header: 'Amount',
        accessor: d => numberWithCommas(d?.payments?.amount)
      },
      {
        Header: 'Start Date',
        sortMethod: (a, b) => Date(a).getTime() - Date(b).getTime(),
        accessor: b => moment(b.from_date).format('DD/MM/YYYY')
      },
      {
        Header: 'End Date',
        sortMethod: (a, b) => Date(a).getTime() - Date(b).getTime(),
        accessor: b => moment(b.to_date).format('DD/MM/YYYY')
      },
      // {
      //   Header: 'Trans Ref',
      //   accessor: d => d?.payments?.trxref
      // },
      {
        Header: 'Certifcate No',
        accessor: d => d?.payments?.chi_certificate_no
      },
      {
        Header: 'Policy No',
        accessor: 'mapfre_policy_no'
      },
      {
        Header: 'Date Created',
        accessor: b => moment(b.created_at).format('DD/MM/YYYY hh:mmA')
        // orderByFn: (a, b) => Date(a.created_at).getTime() - Date(b.created_at).getTime()
      }
    ],
    [getCountryName]
  );
  const [filteredData, setFilteredData] = useState([]);

  const tableInstance = useTable(
    {
      columns,
      data: filteredData
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = tableInstance;

  const pages = useMemo(() => {
    const items = [];
    items.push(
      <Pagination.Item
        disabled={!canPreviousPage}
        key='-1'
        onClick={previousPage}
      >
        Previous
      </Pagination.Item>
    );

    if (pageCount)
      items.push(
        <Pagination.Item
          active={0 === pageIndex}
          disabled={!canPreviousPage}
          key='0'
          onClick={() => gotoPage(0)}
        >
          1
        </Pagination.Item>
      );

    if (pageIndex - 1 > 1) items.push(<Pagination.Ellipsis />);

    for (let num = pageIndex - 1; num < pageIndex + 2; num++) {
      if (num >= 1 && num < pageCount - 1)
        items.push(
          <Pagination.Item
            key={num}
            active={num === pageIndex}
            onClick={() => gotoPage(num)}
          >
            {num + 1}
          </Pagination.Item>
        );
    }

    if (pageIndex + 3 < pageCount) items.push(<Pagination.Ellipsis />);

    if (pageCount > 1)
      items.push(
        <Pagination.Item
          active={pageCount - 1 === pageIndex}
          key={pageCount - 1}
          onClick={() => gotoPage(pageCount - 1)}
        >
          {pageCount}
        </Pagination.Item>
      );

    items.push(
      <Pagination.Item disabled={!canNextPage} key='+1' onClick={nextPage}>
        Next
      </Pagination.Item>
    );
    return items;
  }, [
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    previousPage,
    nextPage,
    pageIndex
  ]);

  const [print, setPrint] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [reciept, setReciept] = useState(null);

  useEffect(() => {
    if (print) {
      setTimeout(() => {
        window.print();
        setPrint(false);
      }, 100);
    }
  }, [print]);

  const ref = createRef();

  const exportPDF = () => {
    let element = ref.current;

    if (element !== null) {
      savePDF(element, {
        repeatHeaders: true,
        paperSize: 'A1',
        orientation: 'horizontal',
        margin: '0.5cm'
      });
    }
  };

  return print ? (
    <PrintCertifcate data={print} watermarked={true} fullscreen={true} />
  ) : (
    <div className='dashboard-home__table table-responsive'>
      <RetryLoader
        retry={retry}
        loading={loading}
        error={error}
        fullscreen={false}
      >
        <div className='d-flex justify-content-between align-items-center mb-5'>
          <Search
            className='my-0'
            data={data}
            setOutput={setFilteredData}
            fields={[
              {
                key: b =>
                  (b?.user?.name || '') +
                  ' ' +
                  (b?.user?.profile?.last_name || ''),
                name: 'name'
              },
              {
                key: b => b?.destination || '',
                name: 'destination'
              },
              {
                key: b => b?.payments?.chi_certificate_no || '',
                name: 'certificate no'
              },
              {
                key: b => b?.mapfre_policy_no || '',
                name: 'policy no'
              }
            ]}
          />
          <div className='d-flex'>
            <ExcelFile
              element={
                <button className='mx-2 display-6 py-2'>Download Excel</button>
              }
            >
              <ExcelSheet data={filteredData} name='Certificates'>
                <ExcelColumn
                  label='Other Names'
                  value={col => col?.user?.name}
                />
                <ExcelColumn
                  label='Surname'
                  value={col => col?.user?.profile?.last_name}
                />
                <ExcelColumn label='Email' value={col => col?.user?.email} />
                <ExcelColumn label='Phone' value={col => col?.user?.phone} />
                <ExcelColumn
                  label='Address'
                  value={col => col?.user?.profile?.address}
                />
                <ExcelColumn
                  label='Destination'
                  value={col => getCountryName(col.destination)}
                />
                <ExcelColumn
                  label='Amount'
                  value={col => col?.payments?.amount}
                />
                <ExcelColumn label='Start Date' value='from_date' />
                <ExcelColumn label='End Date' value='to_date' />
                <ExcelColumn
                  label='Trans Ref'
                  value={col => col?.payments?.trxref}
                />
                <ExcelColumn
                  label='Certificate Number'
                  value={col => col?.payments?.chi_certificate_no}
                />
                <ExcelColumn label='Policy Number' value='mapfre_policy_no' />
                <ExcelColumn
                  label='Date Created'
                  value={col =>
                    moment(col.created_at).format('DD/MM/YYYY hh:mmA')
                  }
                />
                <ExcelColumn label='Created By' value={col => col.creator} />
              </ExcelSheet>
            </ExcelFile>
            <button onClick={exportPDF} className='display-6 py-2'>
              Download pdf
            </button>
          </div>
        </div>

        <table className='table table-bordered' {...getTableProps()} ref={ref}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup, l) => (
                // Apply the header row props
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={headerGroup.row?.id || l}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, i) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={i}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </th>
                    ))
                  }
                  <th>Actions</th>
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row, j) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()} key={row?.original?.id || j}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, k) => {
                        // Apply the cell props
                        let itemClassName =
                          cell.column.Header === 'Status'
                            ? cell.value
                              ? 'table-row-success'
                              : 'table-row-failed'
                            : '';
                        return (
                          <td
                            className={itemClassName}
                            {...cell.getCellProps()}
                            key={k}
                          >
                            {cell.column.Header === 'Status' && !cell.value
                              ? 'Incomplete'
                              : cell.render('Cell')}
                          </td>
                        );
                      })
                    }

                    <td>
                      <OverlayTrigger
                        placement='top'
                        overlay={props => (
                          <Tooltip id='' {...props}>
                            <span style={{ fontSize: '1.3rem' }}>
                              View Reciept
                            </span>
                          </Tooltip>
                        )}
                      >
                        <button
                          data-toggle='tooltip'
                          data-tooltip='View Reciept'
                          onClick={() => setReciept(row.original)}
                        >
                          <svg viewBox='0 0 24 24'>
                            <g>
                              <path
                                d='M16,0H8A5.006,5.006,0,0,0,3,5V23a1,1,0,0,0,1.564.825L6.67,22.386l2.106,1.439a1,1,0,0,0,1.13,0l2.1-1.439,2.1,1.439a1,1,0,0,0,1.131,0l2.1-1.438,2.1,1.437A1,1,0,0,0,21,23V5A5.006,5.006,0,0,0,16,0Zm3,21.1-1.1-.752a1,1,0,0,0-1.132,0l-2.1,1.439-2.1-1.439a1,1,0,0,0-1.131,0l-2.1,1.439-2.1-1.439a1,1,0,0,0-1.129,0L5,21.1V5A3,3,0,0,1,8,2h8a3,3,0,0,1,3,3Z'
                                fill='#9c1c34'
                                data-original='#000000'
                              />
                              <rect
                                x='7'
                                y='8'
                                width='10'
                                height='2'
                                rx='1'
                                fill='#9c1c34'
                                data-original='#000000'
                              />
                              <rect
                                x='7'
                                y='12'
                                width='8'
                                height='2'
                                rx='1'
                                fill='#9c1c34'
                                data-original='#000000'
                              />
                            </g>
                          </svg>
                        </button>
                      </OverlayTrigger>{' '}
                      <OverlayTrigger
                        placement='top'
                        overlay={props => (
                          <Tooltip id='' {...props}>
                            <span style={{ fontSize: '1.3rem' }}>
                              View Certificate
                            </span>
                          </Tooltip>
                        )}
                      >
                        <a
                          href={`${baseUrl}/print-certificate/${row?.original?.payments?.mapfre_certificate_id}`}
                          download
                          target='_blank'
                          rel='noreferrer'
                        >
                          <button
                            data-toggle='tooltip'
                            data-tooltip='View Certificate'
                            // onClick={() => setCertificate(row.original)}
                          >
                            <svg viewBox='0 0 24 24'>
                              <g>
                                <path
                                  d='m11 12a1 1 0 0 1 0 2h-3a1 1 0 0 1 0-2zm6-3a1 1 0 0 0 -1-1h-8a1 1 0 0 0 0 2h8a1 1 0 0 0 1-1zm-9-3h8a1 1 0 0 0 0-2h-8a1 1 0 0 0 0 2zm12 13.444v3.833a.721.721 0 0 1 -1.231.51l-.769-.768-.769.768a.721.721 0 0 1 -1.231-.51v-3.833a3.987 3.987 0 0 1 2-7.444 3.939 3.939 0 0 1 1 .142v-7.142a3 3 0 0 0 -3-3h-8a3 3 0 0 0 -3 3v12a3 3 0 0 0 3 3h5a1 1 0 0 1 0 2h-5a5.006 5.006 0 0 1 -5-5v-12a5.006 5.006 0 0 1 5-5h8a5.006 5.006 0 0 1 5 5v8.382a3.95 3.95 0 0 1 -1 6.062zm0-3.444a2 2 0 1 0 -2 2 2 2 0 0 0 2-2z'
                                  fill='#9c1c34'
                                  data-original='#000000'
                                />
                              </g>
                            </svg>
                          </button>
                        </a>
                      </OverlayTrigger>
                      {permissions.create_user_travel_payment && (
                        <OverlayTrigger
                          placement='top'
                          overlay={props => (
                            <Tooltip id='' {...props}>
                              <span style={{ fontSize: '1.3rem' }}>
                                Edit Certificate
                              </span>
                            </Tooltip>
                          )}
                        >
                          <Link
                            to={`/dashboard/edit-insurance/${row.original?.user?.id}-${row.original?.id}`}
                          >
                            <button>
                              <svg viewBox='0 0 24 24'>
                                <g>
                                  <path
                                    d='M18.656.93,6.464,13.122A4.966,4.966,0,0,0,5,16.657V18a1,1,0,0,0,1,1H7.343a4.966,4.966,0,0,0,3.535-1.464L23.07,5.344a3.125,3.125,0,0,0,0-4.414A3.194,3.194,0,0,0,18.656.93Zm3,3L9.464,16.122A3.02,3.02,0,0,1,7.343,17H7v-.343a3.02,3.02,0,0,1,.878-2.121L20.07,2.344a1.148,1.148,0,0,1,1.586,0A1.123,1.123,0,0,1,21.656,3.93Z'
                                    fill='#9c1c34'
                                    data-original='#000000'
                                  />
                                  <path
                                    xmlns='http://www.w3.org/2000/svg'
                                    d='M23,8.979a1,1,0,0,0-1,1V15H18a3,3,0,0,0-3,3v4H5a3,3,0,0,1-3-3V5A3,3,0,0,1,5,2h9.042a1,1,0,0,0,0-2H5A5.006,5.006,0,0,0,0,5V19a5.006,5.006,0,0,0,5,5H16.343a4.968,4.968,0,0,0,3.536-1.464l2.656-2.658A4.968,4.968,0,0,0,24,16.343V9.979A1,1,0,0,0,23,8.979ZM18.465,21.122a2.975,2.975,0,0,1-1.465.8V18a1,1,0,0,1,1-1h3.925a3.016,3.016,0,0,1-.8,1.464Z'
                                    fill='#9c1c34'
                                    data-original='#000000'
                                  />
                                </g>
                              </svg>
                            </button>
                          </Link>
                        </OverlayTrigger>
                      )}
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>

        <Modal show={reciept} onHide={() => setReciept(null)} centered>
          <Modal.Header closeButton>
            <Modal.Title>reciept Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PrintCertifcate data={reciept} watermarked={true} />
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => setPrint(reciept)}>Print</button>
          </Modal.Footer>
        </Modal>

        <Modal
          size='lg'
          show={certificate}
          onHide={() => setCertificate(null)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Certificate Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <embed
              style={{ width: '100%', height: '60vh' }}
              src={`${baseUrl}/print-certificate/${certificate?.payments?.mapfre_certificate_id}`}
            ></embed>
          </Modal.Body>
        </Modal>

        <div className='mt-2 mb-5'>
          <Pagination>{pages}</Pagination>
        </div>
      </RetryLoader>
    </div>
  );
}
