import { useState, useEffect } from 'react';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import localforage from 'localforage';
import { toast } from 'react-toastify';

import { baseUrl, subProductTypeID } from '../constants';
import useLoginUser from './useLoginUser';
import { headerState } from '../recoil/atoms';

export default function useProducts() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [headers] = useRecoilState(headerState);

  const { updateMapKey } = useLoginUser();

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, []);

  const fetchFromCache = async () => {
    const data = await localforage.getItem('mapfre-products');
    if (!data) return { products: null, reload: true };
    return {
      products: data.products,
      reload: Math.abs(moment(data.createdAt).diff(moment(), 'days')) > 3
    };
  };

  const getProducts = async () => {
    setLoading(true);
    const { products, reload } = await fetchFromCache();
    if (!reload) setProducts(products);
    if (products && products.length) setLoading(false);
    if (reload) {
      try {
        let res = await fetch(`${baseUrl}/products-available`, {
          method: 'POST',
          body: JSON.stringify({
            effectDate: moment().format('DD/MM/YYYY'),
            subProductTypeID
          }),
          headers
        });

        let data = await res.json();
        if (data?.root?.tbl_availableProducts) {
          setProducts(data?.root?.tbl_availableProducts);
          localforage.setItem('mapfre-products', {
            products: data?.root?.tbl_availableProducts,
            createdAt: moment().toString()
          });
          setError(false);
        } else {
          setError(true);
          localforage.removeItem('mapfre-products');
          toast.error('An error occurred, please try again');
        }
        setLoading(false);
      } catch (error) {
        updateMapKey();
        toast.error('An error occurred, please try again');
        setError(true);
      }
    }
    setLoading(false);
  };

  return { products, loading, error, getProducts };
}
