import { useRecoilState } from 'recoil';
import {
  loginState,
  headerState,
  mafreKeyState,
  userDataState,
  permissionuserDataState
} from '../recoil/atoms';
import { baseUrl, subDirectory } from '../constants';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import localforage from 'localforage';

export default function useLoginUser() {
  const [login, setLogin] = useRecoilState(loginState);
  const [headers, setHeaders] = useRecoilState(headerState);
  const [mapKey, setMapKey] = useRecoilState(mafreKeyState);
  const [user, setUser] = useRecoilState(userDataState);
  const [permissions, setPermissions] = useRecoilState(permissionuserDataState);
  const nav = useNavigate();

  useEffect(() => {
    if (login?.first_login) {
      nav('/first-change-password');
    }
    // eslint-disable-next-line
  }, [login]);

  const updateLogin = async loginState => {
    const newHeaders = {
      ...headers,
      Authorization: `Bearer ${loginState.access_token}`
    };

    await fetchUser(newHeaders);
    setHeaders(newHeaders);
  };

  const fetchUser = async paramHeaders => {
    try {
      const response = await fetch(`${baseUrl}/me`, {
        headers: paramHeaders || headers
      });
      const user = await response.json();
      if (user.error || user.errors) {
        throw new Error(user.message);
      }
      if (user.data?.profile?.next_of_kin) {
        user.data.profile.next_of_kin = JSON.parse(
          user.data.profile?.next_of_kin
        );
      }
      setUser(user.data);
      setPermissions(
        (user.data?.role?.tasks || []).reduce(
          (perms, perm) => ({ ...perms, [perm.name]: true }),
          {}
        )
      );
      setLogin(user.data);
      localforage.setItem('app-user', user.data);
    } catch (err) {
      toast.error(
        err.message || 'An error occurred while loading user, please try again'
      );
      setUser(null);
      setPermissions({});
      console.error(err);
    }
  };

  const logoutUser = () => {
    localforage.clear();
    window.location = subDirectory;
  };

  const updateMapKey = async (retry = true) => {
    try {
      const res = await fetch(`${baseUrl}/init`);
      const response = await res.json();

      setHeaders({
        ...headers,
        'Map-Key': response.xhref
      });
      setMapKey(response.root);
    } catch (error) {
      if (retry) updateMapKey(false);
    }
  };

  return {
    updateLogin,
    login,
    headers,
    logoutUser,
    updateMapKey,
    mapKey,
    fetchUser,
    user,
    permissions
  };
}
