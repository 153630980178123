import { useState } from 'react';
import useLoginUser from '../../../hooks/useLoginUser';
import { useEffect } from 'react';
import RetryLoader from '../../../components/commons/retryLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { baseUrl } from '../../../constants';

import UsersTable from '../Users/users.table';
import TasksTable from '../Tasks/tasks.table';

const RoleDetails = () => {
  const { subpath: role_id } = useParams();
  const nav = useNavigate();

  const { headers } = useLoginUser();
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const fetchRole = () => {
    setLoading(true);
    fetch(`${baseUrl}/roles/${role_id}`, { headers })
      .then(response => response.json())
      .then(role => {
        if (role.error || role.errors) throw new Error(role.message);
        setRole(role.data);
        setError(false);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRole();
    if (!role_id) {
      nav('/dashboard/roles', { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='container dashboard-home'>
      <RetryLoader
        retry={fetchRole}
        loading={loading}
        error={error}
        fullscreen={false}
      >
        <div>
          <h1 className='mt-2'>{role?.name}</h1>
          <div>
            <span
              className={`badge badge-${role?.enabled ? 'success' : 'danger'}`}
            >
              {role?.enabled ? 'enbabled' : 'disabled'}
            </span>
          </div>
          <div className='dashboard-home__sub-heading'>{role?.description}</div>
          <hr className='my-5' />

          <div className='dashboard-users__tabs'>
            <div className='dashboard-users__tabs__heading'>
              <div
                className={`dashboard-users__tab dashboard-users__tab--active`}
              >
                Users
              </div>
            </div>

            <div className='dashboard-users__tabs__body'>
              <UsersTable data={role?.users || []} refresh={fetchRole} />
            </div>
          </div>

          <div className='dashboard-users__tabs'>
            <div className='dashboard-users__tabs__heading'>
              <div
                className={`dashboard-users__tab dashboard-users__tab--active`}
              >
                Permissions
              </div>
            </div>

            <div className='dashboard-users__tabs__body'>
              <TasksTable data={role?.tasks || []} />
            </div>
          </div>
        </div>
      </RetryLoader>
    </div>
  );
};

export default RoleDetails;
