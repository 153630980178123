import firebase from 'firebase'

const firebaseConfig = {
    apiKey: 'AIzaSyClLcYR6FGz1RlszIB7CWBWvT17J08kJ1Y',
    authDomain: 'chi-insurance.firebaseapp.com',
    databaseURL: 'https://chi-insurance.firebaseio.com',
    projectId: 'chi-insurance',
    storageBucket: 'chi-insurance.appspot.com',
    messagingSenderId: '848048584328',
    appId: '1:848048584328:web:1485b40932a7b9e678913d',
    measurementId: 'G-12WYM2K6V0'
}

firebase.initializeApp(firebaseConfig);

export default firebase;