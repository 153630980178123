import React from 'react';
import Screens from '../Dashboard/dashboard.screens';
import { dashboardMenu } from './dashboard-menu';
import DashboardHeader from './dashboard-header';
import { useParams } from 'react-router-dom';

import Sidebar from '../../components/commons/Sidebar';

const Dashboard = () => {
  const { path } = useParams();

  return (
    <div className='dashboard'>
      <Sidebar />

      <div className='dashboard__main'>
        <DashboardHeader title={dashboardMenu[path]?.title || 'Dashboard'} />
        <Screens />
      </div>
    </div>
  );
};

export default Dashboard;
