
const travelSchema = {
  destination: {
    required: true,
    label: 'Travel destination'
  },
  purpose: {
    required: true,
    label: 'Purpose of travelling'
  },
  startDate: {
    required: true,
    label: 'Start Date'
  },
  endDate: {
    required: true,
    label: 'End Date'
  },
  duration: {
    required: true,
    minimumValue: 0
  }
};

export { travelSchema };
