import DashboardHome from './DashboardHome/dashboard-home';
import BuyInsurance from './BuyInsurance/buy-insurance.index';
import ProcessInsurance from './BuyInsurance/process-insurance.index';
import Profile from './Profile/profile.index';
import { useParams } from 'react-router-dom';
import UsersIndex from './Users/users.index';
import RolesIndex from './Roles/roles.index';
import TasksIndex from './Tasks/tasks.index';
import UserDetails from './UserDetails/user-details.index';
import RoleDetails from './RoleDetails/role-details.index';
import { useRecoilValue } from 'recoil';
import { permissionuserDataState } from '../../recoil/atoms';
import { toast } from 'react-toastify';
import { useEffect, useMemo } from 'react';
import Reports from './Report/reports';
import Quotes from './BuyInsuranceOffline/quotes.index';
import ContinueBuyInsurance from './ContinueBuyInsurance/process-insurance.index';
import EditInsurance from './EditInsurance/quotes.index';
import ContinueBuyInsuranceOffline from './ContinueBuyInsuranceOffline/quotes.index';

const DashboardScreens = () => {
  const { path } = useParams();

  const permissions = useRecoilValue(permissionuserDataState);

  const screens = useMemo(
    () => ({
      index: {
        component: <DashboardHome />,
        show: true
      },
      'buy-insurance': {
        component: <BuyInsurance />,
        show: true
      },
      'process-insurance': {
        component: <ProcessInsurance />,
        show: true
      },
      profile: {
        component: <Profile />,
        show: true
      },
      users: {
        component: <UsersIndex />,
        show: permissions.list_users
      },
      'user-details': {
        component: <UserDetails />,
        show:
          permissions.get_user_with_role ||
          permissions.list_user_travels ||
          permissions.list_user_payments
      },
      roles: {
        component: <RolesIndex />,
        show: permissions.list_roles
      },
      permissions: {
        component: <TasksIndex />,
        show: permissions.list_tasks
      },
      'role-details': {
        component: <RoleDetails />,
        show: permissions.get_role_with_tasks
      },
      report: {
        component: <Reports />,
        show: permissions.get_role_with_tasks
      },
      'buy-insurance-offline': {
        component: <Quotes />,
        show: permissions.create_user_travel_payment
      },
      'continue-buy-insurance': {
        component: <ContinueBuyInsurance />,
        show: true
      },
      'continue-buy-insurance-offline': {
        component: <ContinueBuyInsuranceOffline />,
        show: permissions.create_user_travel_payment
      },
      'edit-insurance': {
        component: <EditInsurance />,
        show: true
      }
    }),
    [permissions]
  );

  useEffect(() => {
    if (screens[path] && !screens[path].show) {
      toast.info('You dont have permissions to view this page');
    }
  }, [path, screens]);

  return (
    <div>
      {screens[path] && screens[path].show ? (
        screens[path].component
      ) : (
        <DashboardHome />
      )}
    </div>
  );
};

export default DashboardScreens;
