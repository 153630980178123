import { atom } from 'recoil';

const policyHolderState = atom({
  key: 'policyHolderState',
  default: {
    firstName: '',
    lastName: '',
    otherNames: '',
    dateOfBirth: '',
    email: '',
    phoneNumber: '',
    address: '',
    passportNumber: '',
    gender: '',
    title: ''
  }
});

const allPassengersState = atom({
  key: 'allPassengersState',
  default: []
});

const finalSubmitState = atom({
  key: 'finalSubmitState',
  default: false
});

const allInsurersState = atom({
  key: 'allInsurersState',
  default: []
});

const totalPriceState = atom({
  key: 'totalPriceState',
  default: 0
});

const guardianHeightState = atom({
  key: 'guardianHeightState',
  default: {}
});

const isMinorState = atom({
  key: 'isMinorState',
  default: false
});

const allDataState = atom({
  key: 'allDataState',
  default: []
});

const formIndexState = atom({
  key: 'formIndexState',
  default: 0
});

const displayPopUpFormState = atom({
  key: 'displayPopUpFormState',
  default: false
});

const formSubmittedState = atom({
  key: 'formSubmittedState',
  default: false
});

const currentPackageState = atom({
  key: 'currentPackageState',
  default: ''
});

const currentPlanState = atom({
  key: 'currentPlanState',
  default: ''
});

const clearFieldsState = atom({
  key: 'clearFieldsState',
  default: false
});

const fileState = atom({
  key: 'fileState',
  default: null
});

const uploadState = atom({
  key: 'uploadState',
  default: 'failure'
});

const loginState = atom({
  key: 'loginState',
  default: null // set to true to simulate login
});

const quotesPageState = atom({
  key: 'quotesPageState',
  default: 0
});

const travelDetailsState = atom({
  key: 'travelDetailsState',
  default: {
    duration: '',
    purpose: '',
    startDate: '',
    endDate: ''
  }
});
const passengerDetailsState = atom({
  key: 'passengerDetailsState',
  default: {
    firstName: '',
    lastName: '',
    otherNames: '',
    dateOfBirth: '',
    email: '',
    phoneNumber: '',
    address: '',
    passportNumber: '',
    gender: '',
    title: ''
  }
});

const travelDurationState = atom({
  key: 'travelDurationState',
  default: null
});

const nextOfKinDetailsState = atom({
  key: 'nextOfKinDetailsState',
  default: {}
});

const guardianDetailsState = atom({
  key: 'guardianDetailsState',
  default: {
    firstName: '',
    lastName: '',
    otherNames: '',
    dateOfBirth: '',
    email: '',
    phoneNumber: '',
    address: '',
    passportNumber: '',
    gender: '',
    title: '',
    relationship: ''
  }
});

const travelDetailFormState = atom({
  key: 'travelDetailFormState',
  default: {}
});

const dashboardScreenState = atom({
  key: 'dashboardScreenState',
  default: 0
});

const buyInsuranceScreenState = atom({
  key: 'buyInsuranceScreenState',
  default: 0
});

const selectedPlanState = atom({
  key: 'selectedPlanState',
  default: {}
});

const selectedProfileTabState = atom({
  key: 'selectedProfileTabState',
  default: 0
});

const profileInformationState = atom({
  key: 'profileInformationState',
  default: {}
});

const profileNextOfKinState = atom({
  key: 'profileNextOfKinState',
  default: {}
});

const headerState = atom({
  key: 'headerState',
  default: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

const mafreKeyState = atom({
  key: 'mafreKey',
  default: {}
});

const selectedProductState = atom({
  key: 'selectedProductState',
  default: {}
});

const passportUrlState = atom({
  key: 'passportUrlState',
  default: {}
});

const priceInformationState = atom({
  key: 'priceInformationState',
  default: []
});

const insuranceDataState = atom({
  key: 'insuranceDataState',
  default: {}
});

const userDataState = atom({
  key: 'userDataState',
  default: {}
});

const permissionuserDataState = atom({
  key: 'permissionuserDataState',
  default: {}
});

const editUserDataState = atom({
  key: 'editUserDataState',
  default: {}
});

const editRoleDataState = atom({
  key: 'editRoleDataState',
  default: {}
});

const createUserDataState = atom({
  key: 'createUserDataState',
  default: {}
});

const createRoleDataState = atom({
  key: 'createRoleDataState',
  default: {}
});

const userTypeState = atom({
  key: 'userTypeState',
  default: 'customer'
});

const rolesState = atom({
  key: 'rolesState',
  default: []
});

const tasksState = atom({
  key: 'tasksState',
  default: []
});

const tasksStatusState = atom({
  key: 'tasksStatusState',
  default: {
    loading: false,
    error: false
  }
});

const dashboardMenuState = atom({
  key: 'dashboardMenuState',
  default: false
});

const appHeaderState = atom({
  key: 'appHeaderState',
  default: false
});

const profileDataState = atom({
  key: 'profileDataState',
  default: {}
});

const productXmlDataState = atom({
  key: 'productXmlDataState',
  default: {}
});

const priceDataState = atom({
  key: 'priceDataState',
  default: 0
});

export {
  mafreKeyState,
  loginState,
  headerState,
  quotesPageState,
  travelDetailsState,
  travelDurationState,
  passengerDetailsState,
  nextOfKinDetailsState,
  guardianDetailsState,
  travelDetailFormState,
  dashboardScreenState,
  buyInsuranceScreenState,
  selectedPlanState,
  selectedProfileTabState,
  profileInformationState,
  profileNextOfKinState,
  selectedProductState,
  passportUrlState,
  priceInformationState,
  insuranceDataState,
  userDataState,
  permissionuserDataState,
  editUserDataState,
  editRoleDataState,
  rolesState,
  tasksState,
  tasksStatusState,
  dashboardMenuState,
  appHeaderState,
  createUserDataState,
  createRoleDataState,
  profileDataState,
  uploadState,
  fileState,
  userTypeState,
  clearFieldsState,
  currentPackageState,
  currentPlanState,
  formSubmittedState,
  formIndexState,
  displayPopUpFormState,
  allDataState,
  isMinorState,
  guardianHeightState,
  totalPriceState,
  allInsurersState,
  finalSubmitState,
  allPassengersState,
  policyHolderState,
  productXmlDataState,
  priceDataState
};
