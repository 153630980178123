const UserDetailsInformation = ({ user }) => {
  return (
    <div className='profile__information'>
      {/* <h3 className='profile__information__title'>Your Photo</h3>

      <div className='profile__information__photo mt-2'>
        <img src={avatar} alt='avatar' />
        <div className='profile__information__photo__texts'>
          <h3>Upload your photo</h3>
          <h3>Your profile photo helps us identify you</h3>
        </div>
      </div> */}

      <h3 className='profile__information__title mt-4'>Personal Information</h3>

      <div className='row mt-0'>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='first-name'>Other Names</label>
          <input name='firstName' value={user?.name} readOnly={true} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='last-name'>Surname</label>
          <input
            name='lastName'
            value={user?.profile?.last_name}
            readOnly={true}
          />
        </div>
        {/* <div className='col-sm-6 mt-3'>
          <label htmlFor='other-names'>Other Names</label>
          <input
            name='otherNames'
            value={user?.profile?.other_names}
            readOnly={true}
          />
        </div> */}
        <div className='col-sm-6 mt-3'>
          <label htmlFor='date-of-birth'>Date of Birth</label>
          <input
            name='dateOfBirth'
            value={user?.profile?.date_of_birth}
            readOnly={true}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='email'>Email</label>
          <input name='email' value={user?.email} readOnly={true} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>Phone Number</label>
          <input name='phoneNumber' value={user?.phone} readOnly={true} />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>Passport Number</label>
          <input
            name='passportNumber'
            value={user?.profile?.passport_no}
            readOnly={true}
          />
        </div>
        <div className='col-sm-6 mt-3'>
          <label htmlFor='phone'>Address</label>
          <input
            name='address'
            value={user?.profile?.address}
            readOnly={true}
          />
        </div>

        {!user?.enabled && (
          <div className='col-sm-12 mt-3'>
            <div className='p-4 bg-primary-light '>
              <label htmlFor='phone' className='text-danger'>
                User is Suspended
              </label>
              <p>{user?.suspension_reason}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetailsInformation;
