import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { baseUrl } from '../../../../../constants';
import { headerState } from '../../../../../recoil/atoms';
import useLoginUser from '../../../../../hooks/useLoginUser';

export default function useGetProductXml(insuranceData) {
  const headers = useRecoilValue(headerState);
  const [localXmlData, setLocalXmlData] = useState({});
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const { updateMapKey } = useLoginUser();
  const knownError =
    'THE SESSION DOES Not EXIST, THE USER HAS Not BEEN AUTHENTICATED';

  useEffect(() => {
    console.log('error: ', error);
  }, [error]);

  useEffect(() => {
    async function fn(insuranceData) {
      try {
        const res = await fetch(`${baseUrl}/products-xml-formatted`, {
          method: 'POST',
          headers,
          body: JSON.stringify(insuranceData)
        });

        const data = await res.json();
        if (data?.error || data?.errors) {
          console.log('error: ', data?.error);
          console.log('error: ', data?.errors);
          throw new Error('Missing Token');
        }
        //! TO:DO: how to handle this error - data?.data[0] <- "THE SESSION DOES Not EXIST, THE USER HAS Not BEEN AUTHENTICATED"
        if (
          data?.data[0] === knownError ||
          data?.raw_data?.root[0] === knownError
        ) {
          throw new Error(knownError);
        }

        setLocalXmlData(data);
      } catch (error) {
        console.error(error);
        setIsError(true);
        setError(error);
        updateMapKey();
      }
    }

    if (!insuranceData || !Object.keys(insuranceData).length) {
      setIsError(true);
      setError('No insuranceData');
    } else {
      try {
        setIsError(false);
        setError('');
        fn(insuranceData);
      } catch (error) {
        console.log('Error:', error);
        setIsError(true);
        setError(error);
        return null;
      }
    }
  }, [insuranceData, headers]);

  if (isError) return { isError, error };
  return localXmlData;
}
