import React, { useState, useCallback } from 'react';
import { Spinner, ProgressBar } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { baseUrl } from '../../../constants';
// import { firebase } from '../../../utilities';
import useLoginUser from '../../../hooks/useLoginUser';

import upload from '../../../assets/svg/upload.svg';

export default function ProfilePassport() {
  const [file, setFile] = useState(null);
  const [change, setChange] = useState(null);
  const [progress, setProgress] = useState(null);
  const [loading, setLoading] = useState(false);
  const { headers, user, fetchUser } = useLoginUser();

  let interval;
  let baseProgress;

  const handleUpload = () => {
    if (!change) return setChange(true);

    if (!file) return;

    setLoading(true);
    savePassport();

    // const storage = firebase.storage();
    // const storageRef = storage.ref();
    // const uploadTask = storageRef.child('passports/' + file.name).put(file);

    // uploadTask.on(
    //   firebase.storage.TaskEvent.STATE_CHANGED,
    //   snapshot => {
    //     setProgress(
    //       Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 50
    //     );
    //   },
    //   error => {
    //     toast.error('An error occurred, please try again');
    //     setLoading(false);
    //     console.error(error);
    //   },
    //   () => {
    //     uploadTask.snapshot.ref.getDownloadURL().then(url => {
    //       baseProgress = 50;
    //       savePassport({ userId: user.id, url });
    //     });
    //   }
    // );
  };

  const savePassport = async () => {
    interval && clearInterval(interval);
    interval = setInterval(() => {
      baseProgress++;
      baseProgress < 95 && setProgress(baseProgress);
    }, 50);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userId', user?.id);

    const reqHeaders = { ...headers };
    delete reqHeaders['Content-Type'];

    try {
      const res = await fetch(`${baseUrl}/users/savePassport`, {
        method: 'POST',
        headers: reqHeaders,
        body: formData
      });

      const data = await res.json();
      if (data.error || data.errors) throw new Error(data.message);

      await fetchUser();
      setChange(false);
      setFile(null);
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error('An error occurred, please try again');
    }
    clearInterval(interval);
    interval = setInterval(() => {
      baseProgress++;
      baseProgress <= 100 && setProgress(baseProgress);
      if (baseProgress >= 110) {
        setLoading(false);
        clearInterval(interval);
      }
    }, 50);
  };

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles[0] && acceptedFiles[0].size > 204800) {
      toast.error('Please select a file smaller than 200kb');
      return;
    }

    if (acceptedFiles[0] && !acceptedFiles[0].type.includes('image')) {
      toast.error('Please select an image file (jpeg, png, gif)');
      return;
    }

    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className='profile__information'>
      <h3 className='profile__information__title mt-4 mb-5'>Manage Passport</h3>

      {change ? (
        <div>
          <div className='passport-upload' {...getRootProps()}>
            <div className='dropzone'>
              <input {...getInputProps()} accept='image/*' />
              <div className='insurance-documents__upload'>
                <img src={upload} alt='upload' />
                <p className='mb-0'>
                  Drag and drop the file here, or click to select file
                </p>
                <p className='mt-1'>
                  <small>
                    <b>Note: </b>The file should smaller 200kb and must be an
                    image (jpeg,jpg,png,gif)
                  </small>
                </p>
                <button className='button-rounded'>Upload</button>
              </div>
            </div>
          </div>

          {file && (
            <div className='uploaded-file'>
              <img src={upload} alt='upload' width='40px' /> {file.name}
            </div>
          )}

          {loading && (
            <div className='mt-3'>
              <ProgressBar striped variant='primary' now={progress} />
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className='col-sm-6 p-0'>
            <img
              alt='passport'
              src={
                user?.profile?.passport_image ||
                'https://blog.nscsports.org/wp-content/uploads/2014/10/default-img.gif'
              }
              className='img-fluid img-thumbnail'
            />
          </div>
        </div>
      )}

      {
        <div className='profile__information__buttons mt-5'>
          <button
            disabled={(!file || loading) && change}
            onClick={handleUpload}
            className='button-rounded'
          >
            {loading ? (
              <>
                <Spinner /> loading...
              </>
            ) : !change ? (
              'Change'
            ) : (
              'Update'
            )}
          </button>
        </div>
      }
    </div>
  );
}
