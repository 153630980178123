import toCurrency from './toCurrency';
import useInterval from './use-interval';
import firebase from './firebase';
import moment from 'moment';

const getAge = (_date, _format) =>
  moment().diff(moment(_date, _format || 'YYYY-MM-DD'), 'years');

function numberWithCommas(x) {
  x = x.toString().split('.');
  x =
    x[0] +
    '.' +
    (x[1] ? (x[1].length === 1 ? x[1] + '0' : x[1].substring(0, 2)) : '00');
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function getRandomString(length) {
  var randomChars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
}

function getIdRegProductByName(name) {
  var key = name && name.toUpperCase ? name.toUpperCase() : '';
  var products = {
    ECONOMY: '6908',
    EUROPE: '6907',
    FAMILY: '5322',
    PEARL: '6906',
    'PILGRIMAGE PROTECTION BASIC': '8858',
    'PILGRIMAGE PROTECTION EXTRA': '8860',
    'PILGRIMAGE PROTECTION PLUS': '8859',
    'STUDENTS CLASSIC': '5326',
    'STUDENTS ECONOMY': '5332',
    'STUDENTS PREMIUM': '5327',
    TRAVELLER: '6905'
  };

  return products[key];
}

export {
  toCurrency,
  useInterval,
  firebase,
  getAge,
  numberWithCommas,
  getRandomString,
  getIdRegProductByName
};
