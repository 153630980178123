import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import {
  quotesPageState,
  guardianDetailsState,
  travelDetailsState,
  nextOfKinDetailsState,
  passengerDetailsState,
  travelDetailFormState
} from '../../../recoil/atoms';
import QuotesForms from './quotes.forms';
import useLoginUser from '../../../hooks/useLoginUser';

const Quotes = () => {
  const [, setActivePage] = useRecoilState(quotesPageState);
  const { updateMapKey } = useLoginUser();

  const resetForms = () => {
    setGuardianDetails({});
    setNextOfKinDetails({});
    setPassengerDetails({});
    setTravelDetails({});
    setTravelDetailForm({});
  };

  const [, setGuardianDetails] = useRecoilState(guardianDetailsState);
  const [, setTravelDetails] = useRecoilState(travelDetailsState);
  const [, setNextOfKinDetails] = useRecoilState(nextOfKinDetailsState);
  const [, setPassengerDetails] = useRecoilState(passengerDetailsState);
  const [, setTravelDetailForm] = useRecoilState(travelDetailFormState);

  useEffect(() => {
    updateMapKey();
    setActivePage(0);

    return resetForms;
    // eslint-disable-next-line
  }, []);
  return (
    // <div className='quotes'>
    //   <section className='quotes__steps'>
    //     <div className='container'>
    //       <Link to={'/'}>
    //         <img src={logo} alt='CHI' />
    //       </Link>

    //       <ul className='quotes__steps__items'>
    //         {quotesSteps.map((step, index) => (
    //           <Fragment key={index}>
    //             <li
    //               className={`quotes__steps__item ${
    //                 index === activePage && 'quotes__steps__item--active'
    //               }`}
    //             >
    //               <span className='quotes__steps__item__text'>
    //                 {step.title}
    //               </span>
    //             </li>
    //             {index !== quotesSteps.length - 1 && (
    //               <div className='quotes__steps__line'></div>
    //             )}
    //           </Fragment>
    //         ))}
    //       </ul>
    //     </div>
    //   </section>

    <section className='quotes__main'>
      <QuotesForms />
    </section>
    // </div>
  );
};

export default Quotes;
